import { RecruitmentApplicationListOrder } from "../../types/recruitmentApplication";
import { z } from "zod";
import { zRequiredString } from "../rules";
import { baseRequestSchema, baseRestrictRequestSchema } from "./common.schema";
import { ApplicationStatusWithAll } from "./recruitment.schema";

export const getMyApplicationByRecruitmentIdSchema = baseRequestSchema.extend({
  recruitmentId: zRequiredString,
});

export type GetMyApplicationByRecruitmentIdSchema = z.infer<
  typeof getMyApplicationByRecruitmentIdSchema
>;

export const getApplicationListByUserIdSchema = baseRequestSchema.extend({
  userId: zRequiredString,
});

export type GetApplicationListByUserIdSchema = z.infer<
  typeof getApplicationListByUserIdSchema
>;

export const recruitApplicationByEventIdQuerySchema = baseRequestSchema.extend({
  recruitmentId: zRequiredString,
  recruitApplicationEventId: zRequiredString,
});

export type RecruitApplicationByEventIdQuerySchema = z.infer<
  typeof recruitApplicationByEventIdQuerySchema
>;

export const recruitApplicationByEventIdForQRQuerySchema =
  baseRequestSchema.extend({
    recruitApplicationEventId: z.string().uuid(),
  });

export type RecruitApplicationByEventIdForQRQuerySchema = z.infer<
  typeof recruitApplicationByEventIdForQRQuerySchema
>;

export const getRecruitmentApplicationsByScheduleIdQuerySchema =
  baseRestrictRequestSchema.extend({
    recruitmentScheduleId: z.string().uuid(),
    recruitmentId: z.string().uuid(),
  });

export type GetRecruitmentApplicationsByScheduleIdQuerySchema = z.infer<
  typeof getRecruitmentApplicationsByScheduleIdQuerySchema
>;

const applicationFilterSchema = z.object({
  name: z.string(),
  status: z.nativeEnum(ApplicationStatusWithAll),
  recruitmentId: z.string().optional(),
  recruitmentScheduleId: z.string().optional(),
  currentCommunityId: z.string().optional(),
});

export const applicationCountsForFilterSchema = applicationFilterSchema;

export type ApplicationCountsForFilterType = z.infer<
  typeof applicationCountsForFilterSchema
>;

export const applicationListFilterSchema = applicationFilterSchema.extend({
  recruitmentScheduleId: z.string().optional(),
  order: z.nativeEnum(RecruitmentApplicationListOrder).optional(),
});

export type ApplicationListFilterSchema = z.infer<
  typeof applicationListFilterSchema
>;

export const downloadApplicationListSchema = z.object({
  recruitmentId: zRequiredString,
  recruitmentScheduleId: zRequiredString,
  currentCommunityId: z.string().optional(),
});

export type DownloadApplicationListSchemaType = z.infer<
  typeof downloadApplicationListSchema
>;

/**
 * 応募日程追加APIのリクエストボディのスキーマ
 */
export const applicationAddingScheduleBodySchema = z.object({
  recruitmentId: z.string().min(1),
  recruitmentApplicationId: zRequiredString,
  selectedScheduleIds: z.array(z.string()).min(1, {
    message: "少なくとも1つの日程を選択してください",
  }),
  comment: z
    .string()
    .max(3000, {
      message: "コメントは3000文字以内で入力してください",
    })
    .optional(),
});

export type ApplicationAddingScheduleBodySchema = z.infer<
  typeof applicationAddingScheduleBodySchema
>;
