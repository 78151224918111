import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
  ModalHeader,
  ModalBody,
  ModalPortal,
  AlignRight,
} from "src/components/Modal";
import { Button } from "src/components/Button";
import {
  ChatRoom,
  ApprovalStatus,
  ChatRoomMember,
} from "@shared/types/chatRoom";
import { PendingMemberList } from "src/features/common/ChatMessage/components/ChatRoomGroup/PendingMemberList";
import { logger } from "src/utils/logger";
import { useToast } from "src/components/Toast";

type BasePendingInvitationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  chatRoom: ChatRoom;
  members: ChatRoomMember[];
  isLoading: boolean;
  error: Error | null;
  resendInvitationFn: (args: {
    chatRoomId: string;
    accountIds: string[];
  }) => Promise<void>;
};

export const BasePendingInvitationModal: React.FC<
  BasePendingInvitationModalProps
> = ({
  isOpen,
  onClose,
  chatRoom,
  members,
  isLoading,
  error,
  resendInvitationFn,
}) => {
  const toast = useToast();
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [isSending, setIsSending] = useState(false);

  const pendingMembers = useMemo(() => {
    if (!members) return [];
    return members.filter(
      (member) =>
        member.participantChatRoom.approvalStatus === ApprovalStatus.PENDING
    );
  }, [members]);

  if (!isOpen) return null;

  const handleResendInvitation = async () => {
    if (selectedMemberIds.length > 0) {
      try {
        setIsSending(true);
        await resendInvitationFn({
          chatRoomId: chatRoom.id,
          accountIds: selectedMemberIds,
        });
        toast.success("招待の再送に成功しました");
        onClose();
      } catch (error) {
        logger.error(error);
        toast.error("招待の再送に失敗しました");
      } finally {
        setIsSending(false);
      }
    }
  };

  const handleSelectedMembersChange = (memberIds: string[]) => {
    setSelectedMemberIds(memberIds);
  };

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>招待中メンバーへの再通知</ModalHeader>
      <ModalBody>
        <SubTitle>招待中のメンバーに再度招待通知を送信できます。</SubTitle>
        <PendingMemberList
          members={pendingMembers}
          isLoading={isLoading}
          error={error}
          selectedMemberIds={selectedMemberIds}
          onSelectedMembersChange={handleSelectedMembersChange}
        />
      </ModalBody>
      <AlignRight>
        <Button
          color="ghost"
          $fill
          size="large"
          onClick={onClose}
          style={{ marginRight: "8px" }}
        >
          キャンセル
        </Button>
        <Button
          color="primary"
          $fill
          size="large"
          onClick={handleResendInvitation}
          disabled={selectedMemberIds.length === 0 || isSending}
        >
          {isSending ? "送信中..." : "送信"}
        </Button>
      </AlignRight>
    </ModalPortal>
  );
};

const SubTitle = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  color: #333;
`;
