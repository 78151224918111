import { z } from "zod";
import { zRequiredString } from "../../rules";

export const importStatusEnum = z.enum([
  "IMPORTED",
  "EXECUTING",
  "DONE",
  "CANCELED",
]);
export type ImportStatusEnum = z.infer<typeof importStatusEnum>;

export const ptaInvoiceImportSchema = z.object({
  id: z.string().uuid(),
  organizationId: zRequiredString,
  PTAInvoiceProductId: z.string().uuid(),
  status: importStatusEnum,
  idempotencyKey: z.string(),
  createdBy: z.string().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type PTAInvoiceImportType = z.infer<typeof ptaInvoiceImportSchema>;
