import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { ModalPortal, ModalSubmitButtons } from "src/components/Modal";
import { Spinner } from "src/components/icons/Spinner";
import { useToast } from "src/components/Toast";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { wordTrim } from "src/utils/wordTrim";
import { User } from "src/apiClients/users";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useAdminUsersBelongingToOrganization } from "src/hooks/query/users/adminUsers";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { Avatar } from "src/components/Common/Avatar";
import { SearchIcon } from "src/components/icons/SearchIcon";
import { CameraIcon } from "src/components/icons/CameraIcon";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { Margin } from "src/components/Margin";
import { Heading4 } from "src/components/Typo";
import {
  Picture,
  UploadImagesContainer,
  UploadImgButton,
  TextField as TextField2,
} from "src/pages/pta/admin/ProfilePage";
import { TextField } from "src/components/form/TextField";

const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export type BaseCSAdminGroupModalSubmitArgs = {
  roomName: string;
  accountIds: string[];
  file?: File;
};

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  initialSelectedAccountIds?: string[];
  initialRoomName?: string;
  title?: string;
  onSubmit: (args: BaseCSAdminGroupModalSubmitArgs) => void;
};

export const BaseCSAdminGroupModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    initialSelectedAccountIds = [],
    initialRoomName = "",
    title = "管理者間メッセージ作成",
    onSubmit,
  } = props;

  const toast = useToast();
  const { organization } = useGetCurrentOrganization();
  const currentUser = useCurrentUser();

  const { isLoading: isAdminLoading, adminUsers } =
    useAdminUsersBelongingToOrganization({
      organizationId: organization?.id ?? "",
    });

  // 自分を除外した管理者一覧
  const adminUsersWithoutCurrentUser: User[] = useMemo(() => {
    if (!adminUsers) return [];
    return adminUsers.filter((u) => u.id !== currentUser.id);
  }, [adminUsers, currentUser]);

  // 「管理者選択」画面 or 「ルーム名/アイコン」画面
  const [isSelectionCompleted, setIsSelectionCompleted] = useState(false);

  // 選択中のメンバー(accountId)
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>(
    initialSelectedAccountIds
  );

  // 検索用
  const [searchText, setSearchText] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  // 表示用管理者リスト
  const displayAdminUsers = useMemo(() => {
    if (!adminUsersWithoutCurrentUser) return [];
    if (!searchText) return adminUsersWithoutCurrentUser;

    const norm = wordTrim(searchText);
    return adminUsersWithoutCurrentUser.filter((adminUser) => {
      const nameKana =
        (adminUser.baseInfo?.lastNameKana || "") +
        (adminUser.baseInfo?.firstNameKana || "");
      const fullName = wordTrim(adminUser.name);
      return nameKana.includes(norm) || fullName.includes(norm);
    });
  }, [adminUsersWithoutCurrentUser, searchText]);

  // 全選択/全解除
  const handleSelectAll = () => {
    const displayedIds = displayAdminUsers
      .filter((u) => u.account)
      .map((u) => u.account!.id);

    if (selectAll) {
      // 全解除
      const newSelectedIds = selectedMemberIds.filter(
        (id) => !displayedIds.includes(id)
      );
      setSelectedMemberIds(newSelectedIds);
    } else {
      // 全選択
      displayedIds.forEach((id) => {
        setSelectedMemberIds((prev) =>
          prev.includes(id) ? prev : [...prev, id]
        );
      });
    }
    setSelectAll(!selectAll);
  };

  // 「表示されている管理者が全て選択済みか」をチェック
  useEffect(() => {
    if (!displayAdminUsers.length) {
      setSelectAll(false);
      return;
    }
    const allDisplayedSelected = displayAdminUsers
      .filter((u) => u.account)
      .every((u) => selectedMemberIds.includes(u.account!.id));
    setSelectAll(allDisplayedSelected);
  }, [selectedMemberIds, displayAdminUsers]);

  // 個別の選択/解除
  const toggleSelectUser = (accountId: string) => {
    setSelectedMemberIds((prev) =>
      prev.includes(accountId)
        ? prev.filter((id) => id !== accountId)
        : [...prev, accountId]
    );
  };

  // ルーム名 & アイコン画像
  const [roomName, setRoomName] = useState<string>(initialRoomName);
  const [iconFile, setIconFile] = useState<File | undefined>();
  const [imgPreviewUrl, setImgPreviewUrl] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickFile = () => {
    fileInputRef.current?.click();
  };
  const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setIconFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const handleSubmit = async () => {
    if (!roomName.trim()) {
      toast.error("ルーム名を入力してください");
      return;
    }
    if (selectedMemberIds.length === 0) {
      toast.error("メンバーを最低1人は選択してください");
      return;
    }

    setDoubleClickBlocked(true);
    try {
      onSubmit({
        roomName: roomName.trim(),
        accountIds: selectedMemberIds,
        file: iconFile,
      });
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  if (isAdminLoading) {
    return (
      <ModalPortal onClose={onClose}>
        <LoadingCard>
          <Spinner />
        </LoadingCard>
      </ModalPortal>
    );
  }

  return (
    <ModalPortal onClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {!isSelectionCompleted && (
          <>
            <ModalHeader>{title}</ModalHeader>
            <TextField
              value={searchText}
              onChange={(val) => setSearchText(val)}
              placeholder="名前で検索"
              prepend={<SearchIcon size={20} />}
            />
            <Margin marginTop={12} />

            <NameListTable>
              <NameListColumnNameRow head={true}>
                <NameListColumn>
                  <span>管理者一覧</span>
                  <SelectAllCheckBox onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    <label onClick={handleSelectAll}>
                      全選択（{displayAdminUsers.length}名）
                    </label>
                  </SelectAllCheckBox>
                </NameListColumn>
              </NameListColumnNameRow>

              <ScrollWrapper>
                {displayAdminUsers.length === 0 && (
                  <NonUserNameListColumnNameRow>
                    <Name>該当する管理者がいません</Name>
                  </NonUserNameListColumnNameRow>
                )}
                {displayAdminUsers.map((adminUser) => {
                  if (!adminUser.account) return null;
                  const accountId = adminUser.account.id;
                  const isSelected = selectedMemberIds.includes(accountId);
                  return (
                    <NameListColumnNameRow
                      key={adminUser.id}
                      onClick={() => toggleSelectUser(accountId)}
                    >
                      <input type="checkbox" checked={isSelected} readOnly />
                      <Avatar
                        src={adminUser.picture || defaultUserImg}
                        alt={adminUser.name}
                        size={40}
                      />
                      <Name>{adminUser.name}</Name>
                    </NameListColumnNameRow>
                  );
                })}
              </ScrollWrapper>
            </NameListTable>

            {selectedMemberIds.length !== 0 && (
              <SelectedAccountView>
                <p>選択中の管理者</p>
                <SelectedAccountsList>
                  {selectedMemberIds.map((accountId) => {
                    const selectedAdminUser = adminUsersWithoutCurrentUser.find(
                      (u) => u.account?.id === accountId
                    );
                    if (!selectedAdminUser) return null;
                    return (
                      <SelectedResident key={accountId}>
                        <AvatarWrapper>
                          <Avatar
                            src={selectedAdminUser.picture || defaultUserImg}
                            alt={selectedAdminUser.name}
                            size={30}
                          />
                          <RemoveButton
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleSelectUser(accountId);
                            }}
                          >
                            <CloseIcon />
                          </RemoveButton>
                        </AvatarWrapper>
                        <p>{truncateString(selectedAdminUser.name)}</p>
                      </SelectedResident>
                    );
                  })}
                </SelectedAccountsList>
              </SelectedAccountView>
            )}

            <Margin marginTop={20} />
            <ModalSubmitButtons
              submitText="次へ"
              onCancel={onClose}
              onSubmit={() => {
                if (selectedMemberIds.length === 0) return;
                setIsSelectionCompleted(true);
              }}
              disabled={selectedMemberIds.length === 0}
            />
          </>
        )}

        {isSelectionCompleted && (
          <>
            <ModalHeader>{title}</ModalHeader>
            <CreateChatRoomForms>
              <UploadImagesContainer>
                <Picture src={imgPreviewUrl || defaultUserImg} alt="image" />
                <UploadImgButton type="button" onClick={handleClickFile}>
                  <CameraIcon />
                </UploadImgButton>
                <input
                  ref={fileInputRef}
                  hidden
                  type="file"
                  name="image"
                  readOnly
                  accept="image/jpeg, image/png"
                  onChange={handleIconChange}
                  id="file"
                />
              </UploadImagesContainer>
              <TextField2
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
                placeholder="ルーム名"
              />
            </CreateChatRoomForms>
            <SelectedAccountView>
              <Heading4>選択中の管理者</Heading4>
              <SelectedAccountsList>
                {selectedMemberIds.map((accountId) => {
                  const selectedAdminUser = adminUsersWithoutCurrentUser.find(
                    (u) => u.account?.id === accountId
                  );
                  if (!selectedAdminUser) return null;
                  return (
                    <SelectedResident key={accountId}>
                      <AvatarWrapper>
                        <Avatar
                          src={selectedAdminUser.picture || defaultUserImg}
                          alt={selectedAdminUser.name}
                          size={30}
                        />
                      </AvatarWrapper>
                      <p>{truncateString(selectedAdminUser.name)}</p>
                    </SelectedResident>
                  );
                })}
              </SelectedAccountsList>
            </SelectedAccountView>
            <ModalSubmitButtons
              submitText="決定"
              cancelText="戻る"
              onCancel={() => setIsSelectionCompleted(false)}
              onSubmit={handleSubmit}
              disabled={
                selectedMemberIds.length === 0 ||
                !roomName ||
                doubleClickBlocked
              }
            />
          </>
        )}
      </form>
    </ModalPortal>
  );
};

function truncateString(str: string): string {
  if (str.length <= 5) {
    return str;
  }
  return str.slice(0, 5) + "...";
}

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1a1c21;
`;

const NameListTable = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const NameListColumnNameRow = styled.div<{
  head?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-bottom: none;
  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
  ${(props) =>
    props.head &&
    `
      padding: 0px;
      border: none;
      background-color: #f5f7fa;
      font-weight: bold;
      color: #343741;
      cursor: default;
      &:hover {
        filter: brightness(100%);
      }
      span {
        pointer-events: none;
      }
    `};
`;

const NameListColumn = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  background-color: #f5f7fa;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ScrollWrapper = styled.div`
  max-height: 180px;
  overflow-y: auto;
`;

const NonUserNameListColumnNameRow = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
`;

const Name = styled.p`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

const SelectedAccountView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  p {
    font-size: 12px;
  }
`;

const SelectedAccountsList = styled.div`
  display: flex;
  gap: 12px;
  max-width: 100%;
  overflow-x: auto;
`;

const SelectedResident = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  width: fit-content;
`;

const RemoveButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: -8px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aab4c4;
  border-radius: 50%;
  background-color: #343741;
  padding: 3px;
`;

const SelectAllCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  gap: 2px;
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`;

const CreateChatRoomForms = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
`;
