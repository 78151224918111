import React from "react";
import {
  Answer,
  isMultiChoiceAnswer,
  isOpenEndedAnswer,
  sendSurveyAnswers,
} from "../../../apiClients/surveyAnswers";
import { Button } from "../../../components/Button";
import { ModalHeader, ModalPortal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import { EventHandlers } from "./hooks";
import { toDateFormat } from "../../../utils/time";
import { AnswerField } from "./AnswerField";
import styled from "styled-components";
import { logger } from "src/utils/logger";
import { Spinner } from "../../../components/icons/Spinner";
import { SurveyAnswers } from "@shared/types/surveyAnswers";

// アンケート回答用のモーダル本体
// onClose: モーダルを閉じる処理
export function AnswerModal({
  postId,
  surveyAnswers,
  surveyAnswersLoaded,
  eventHandlers,
  deadLine,
  isAlreadyAnswered,
  setIsAlreadyAnswered,
  setButtonText,
  onClose,
}: {
  postId: string;
  surveyAnswers: SurveyAnswers;
  surveyAnswersLoaded: boolean;
  eventHandlers: EventHandlers;
  deadLine: Date;
  isAlreadyAnswered: boolean;
  setIsAlreadyAnswered: (isAlreadyAnswered: boolean) => void;
  setButtonText: (text: string) => void;
  onClose: () => void;
}): JSX.Element {
  // 警告をだしてから閉じる
  // キャンセルや閉じるボタン押下用
  const warnAndCloseModal: () => void = () => {
    if (!confirm("編集内容が破棄されますがよろしいですか？")) {
      return;
    }
    onClose();
  };
  // DB送信時の処理
  const toast = useToast();
  const onSubmit = async (surveyAnswers: SurveyAnswers) => {
    try {
      if (surveyAnswers.answerList.length === 0) {
        logger.error("アンケートの回答がありません", { postId, surveyAnswers });
        toast.error("回答がありません");
        return;
      }

      await sendSurveyAnswers(surveyAnswers);
      onClose();
      toast.success("回答しました");
      setIsAlreadyAnswered(true);
      // ポスト上の表示を更新するため、回答済みに更新する
      eventHandlers.onUpdateIsAlreadyAnswered(surveyAnswers);
      setButtonText("回答済みアンケートを編集");
    } catch (error) {
      logger.error(error, { postId });
    }
  };

  // AnswerModalにおいて、アンケート項目のAPI取得が完了している状態でアンケート項目が空になることは発生しない前提
  // ただし、念のためのログ出力 & 仮画面を表示させている
  if (surveyAnswersLoaded && surveyAnswers.answerList.length === 0) {
    logger.error("アンケート項目が存在しません", { postId });

    return (
      <ModalPortal onClose={warnAndCloseModal}>
        <ModalHeader>アンケート</ModalHeader>
        <AnswerContainer>
          質問を表示できませんでした。サポートデスクまでご連絡ください。
        </AnswerContainer>
        <Footer>
          <Deadline>回答期限：{toDateFormat(deadLine)}</Deadline>
          <Button size="large" color="primary" onClick={warnAndCloseModal}>
            閉じる
          </Button>
        </Footer>
      </ModalPortal>
    );
  }

  return (
    <ModalPortal onClose={warnAndCloseModal}>
      <ModalHeader>アンケート</ModalHeader>
      <AnswerContainer>
        {surveyAnswersLoaded ? (
          surveyAnswers.answerList.map((answer: Answer, index: number) => (
            <AnswerField
              key={index}
              answer={answer}
              eventHandlers={eventHandlers}
            />
          ))
        ) : (
          <Box>
            <Spinner />
          </Box>
        )}
      </AnswerContainer>
      <Footer>
        <Deadline>回答期限：{toDateFormat(deadLine)}</Deadline>
        <Button size="large" color="primary" onClick={warnAndCloseModal}>
          キャンセル
        </Button>
        <Button
          size="large"
          disabled={!surveyAnswersLoaded}
          $fill
          onClick={() => {
            // 内容の妥当性を確認
            if (!checkIsValid(surveyAnswers)) {
              // ダメなら弾く
              return;
            }
            if (!confirm("回答を送信してよろしいですか？")) {
              return;
            }
            // DBに送信、モーダルを閉じる
            onSubmit(surveyAnswers);
          }}
        >
          回答
        </Button>
      </Footer>
    </ModalPortal>
  );
}

// DB保存前にサニタイズする
// TODO: 弾く条件が他にないか考える
// 返り値(isValid)
//   OK -> true
//   NG -> false
function checkIsValid(surveyAnswers: SurveyAnswers): boolean {
  // まとめてエラーメッセージを生成、出力
  const errorMessages: string[] = surveyAnswers.answerList
    .map((answer: Answer, index: number) => {
      if (isMultiChoiceAnswer(answer)) {
        // 選択式
        if (answer.selectedChoices.length === 0) {
          // 一つもチェックされていない場合弾く
          return `質問${index + 1}つめの選択肢が選択されていません`;
        } else {
          return "";
        }
      } else if (isOpenEndedAnswer(answer)) {
        // 記述式
        if (answer.text === "") {
          // 空欄の場合弾く
          return `質問${index + 1}つめが空欄です`;
        } else {
          return "";
        }
      } else {
        // 未実装の質問形式
        // tscの時点でコケさせる
        const _unreachable: never = answer;
        throw new Error(`unknown answer type`);
      }
    })
    .filter((message: string) => {
      // mapはエラーがなければ空の文字列を返しているのでエラーだけ抽出
      return message !== "";
    });
  if (errorMessages.length === 0) {
    return true;
  } else {
    alert(errorMessages.join("\n"));
    return false;
  }
}

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 16px;
`;

const Deadline = styled.p`
  font-size: 14px;
  color: rgba(52, 55, 65, 1);
  margin-right: auto;
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  border-radius: 6px;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
