import React from "react";
import { ChatRoom } from "@shared/types/chatRoom";
import { useGetChatRoomMembers } from "src/hooks/query/boardEducation/chatRooms/chatRoomMembers";
import { resendGroupChatInvitation } from "src/apiClients/boardEducation/chatRoom";
import { BasePendingInvitationModal } from "src/features/common/ChatMessage/components/ChatRoomGroup/BasePendingInvitationModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  chatRoom: ChatRoom;
};

export const BEPendingInvitationModal: React.FC<Props> = (props) => {
  const { members, isLoading, error } = useGetChatRoomMembers(
    props.chatRoom.id
  );

  return (
    <BasePendingInvitationModal
      {...props}
      members={members}
      isLoading={isLoading}
      error={error}
      resendInvitationFn={resendGroupChatInvitation}
    />
  );
};
