import React from "react";
import { ChatRoom, ChatRoomMember, ChatRoomType } from "@shared/types/chatRoom";
import { useGetChatRoomMembers } from "src/hooks/query/chatRoom/chatRoomMembers";
import { usePolyfitHistory } from "src/hooks/router";
import { BaseGroupMemberStatusModal } from "src/features/common/ChatMessage/components/ChatRoomGroup/BaseGroupMemberStatusModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToEditModal: () => void;
  onSwitchToPendingInvitationModal: () => void;
  chatRoom: ChatRoom;
};

export const GroupMemberStatusModal: React.FC<Props> = (props) => {
  const history = usePolyfitHistory();
  const { members, isLoading, error } = useGetChatRoomMembers(
    props.chatRoom.id
  );

  const handleMemberClick = (member: ChatRoomMember) => {
    history.push({
      to: "PTA_LIST_MEMBER",
      query: {
        userId: member.user.id,
      },
    });
  };

  const disableMemberLinks =
    props.chatRoom.type === ChatRoomType.GROUP_CHAT_WITH_CS_ADMIN;

  return (
    <BaseGroupMemberStatusModal
      {...props}
      members={members}
      isLoading={isLoading}
      error={error}
      disableMemberLinks={disableMemberLinks}
      onMemberClick={handleMemberClick}
    />
  );
};
