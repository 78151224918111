import React, { useCallback, useMemo, useState } from "react";
import { entityUrl } from "src/apiClients/storage";
import { Button } from "src/components/Button";
import { ImgField } from "src/components/Form";
import { FilePlusIcon } from "src/components/icons/FilePlusIcon";
import { ModalBody, ModalHeader, ModalPortal } from "src/components/Modal";
import { PdfViewer } from "src/components/PdfViewer";
import { DangerText, InputTitle, ModalLabel } from "../Post/CreatePostModal";
import { Margin } from "src/components/Margin";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { SignatureTemplate } from "@shared/types/signatureTemplate";
import { UseMutationResult } from "@tanstack/react-query";

type MutationArgs = {
  file: File;
  comment?: string;
};

type Props = {
  close: () => void;
  mutation: UseMutationResult<
    SignatureTemplate | undefined,
    Error,
    MutationArgs,
    unknown
  >;
  signatureTemplates: SignatureTemplate[] | undefined;
};

export const SignatureTemplatePTAUploadModal = ({
  close,
  mutation,
  signatureTemplates,
}: Props) => {
  const [file, setFile] = useState<File>();
  const pdfFilePathForPreview = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else if (signatureTemplates?.length && signatureTemplates[0].pdfFilePath)
      return entityUrl(signatureTemplates[0].pdfFilePath);
    else return null;
  }, [file, signatureTemplates]);
  const [errorFile, setErrorFile] = useState(false);
  const [text, setText] = useState<string>("");

  const isValid = useMemo(() => {
    if (!signatureTemplates?.length) {
      return file;
    } else if (file) {
      return file;
    }
  }, [file, signatureTemplates]);

  const onSubmit = useCallback(async () => {
    // ファイルのサイズが30mb超えてる場合
    if (file?.size != null && file?.size > 30 * 1024 * 1024) {
      console.error(
        "ファイルが30MBを超えています。容量を軽くしてアップロードしてください",
        file.size
      );
      setErrorFile(true);
      return;
    }
    if (file) {
      mutation.mutate(
        {
          file,
          comment: text,
        },
        {
          onSuccess: () => setFile(undefined),
        }
      );
    }
    setErrorFile(false);
  }, [signatureTemplates, file, text]);

  return (
    <ModalPortal onClose={() => close()}>
      <ModalHeader>規約をアップロード</ModalHeader>
      <ModalBody>
        <ImgField
          label="入会規約（pdf）"
          accept="application/pdf"
          onChange={setFile}
        >
          {pdfFilePathForPreview ? (
            <PdfViewer width="100%" file={pdfFilePathForPreview} />
          ) : (
            <FilePlusIcon />
          )}
        </ImgField>
        <ModalLabel style={{ display: "flex" }}>
          変更内容
          <Label optional />
        </ModalLabel>
        <Margin marginTop={5} />
        <InputTitle
          type="text"
          value={text}
          placeholder="変更内容を入力"
          onChange={(e) => setText(e.target.value)}
        />
      </ModalBody>
      <Margin marginTop={15} />
      <ModalFooter>
        <Button
          $empty
          color="primary"
          size="large"
          type="button"
          onClick={() => close()}
        >
          キャンセル
        </Button>
        <Button
          $fill
          color="primary"
          size="large"
          type="submit"
          disabled={mutation.isPending || !isValid}
          onClick={onSubmit}
        >
          {mutation.isPending ? "アップロード中" : "アップロード"}
        </Button>
      </ModalFooter>
      {errorFile && (
        <DangerText>
          ファイルが30MBを超えています。容量を軽くしてアップロードしてください
        </DangerText>
      )}
    </ModalPortal>
  );
};

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
