import React, { useCallback, useMemo, useRef, useState } from "react";
import { User, isTeacherUser } from "../../../apiClients/users";
import { useCurrentUser } from "../../../hooks/recoil/user";
import {
  BaseInfo,
  CurrentUser,
  updateBelongAndPart,
  updateIsHasNonEnrolledChild,
  updateTeacherInfoByAdmin,
} from "../../../apiClients/auth";
import { usePolyfitHistory } from "../../../hooks/router";
import {
  GradeNumberSchemaType,
  ClassNumberSchemaType,
} from "@shared/validator/rules/child";
import { FormProvider, useForm } from "react-hook-form";
import { toInputDateFormatYearMonth } from "../../../utils/time";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import { Button } from "../../../components/Button";
import { CheckIcon } from "../../../components/icons/CheckIcon";
import { classOption } from "../../../utils/getChildClass";
import { useRegisterInfo } from "../../../hooks/api/baseInfo";
import { isPtaAdminRole } from "../../../utils/types/role";
import { Organization } from "../../../apiClients/organization";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  parentPTAFormValueSchema,
  teacherPTAFormValueSchema,
} from "src/validator/form/member/PTABaseInfoSchema";
import { sortByOrder } from "src/utils/sortByOrder";
import { logger } from "src/utils/logger";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { NameAndImageSection } from "./components/NameAndImageSection";
import {
  ButtonGroupExtend,
  CheckTabs,
  EditModalFormLabel,
  ErrorMessage,
  ModalHeader,
  ModalWrapper,
  SelectField,
  TextField,
} from ".";
import { PhoneNumberSection } from "./components/PhoneNumberSection";

const isTeacherOption = [
  { value: "true", text: "あり" },
  { value: "false", text: "なし" },
];

const booleanOption = [
  { value: 1, text: "いる" },
  { value: 0, text: "いない" },
];
type PTAEditBaseInfoModalProps = {
  user: User;
  organization: Organization;
  onClose: () => void;
  getUser: () => Promise<void>;
  userId: string;
  cu: CurrentUser;
};

// PTAEditBaseInfoModalFormValues の型
export type PTAEditBaseInfoModalFormValues = {
  baseInfo: Omit<BaseInfo, "isPreschooler"> & {
    isPreschooler?: number;
  };
  teacherInfo: {
    hasHomeroom: boolean;
    grade?: GradeNumberSchemaType;
    class?: ClassNumberSchemaType;
  };
  parentInfo: {
    isHasNonEnrolledChild?: number;
  };
};

export const PTAEditBaseInfoModal = ({
  user,
  organization,
  onClose,
  getUser,
  userId,
  cu,
}: PTAEditBaseInfoModalProps) => {
  const [faceImg, setFaceImg] = useState<File>();
  const currentUser: CurrentUser = useCurrentUser();
  const { registerInfo, registerFaceImgPath } = useRegisterInfo();
  const history = usePolyfitHistory();
  const toast = useToast();
  const gradeOptions = useGradeOptions(organization?.schoolType);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: PTAEditBaseInfoModalFormValues) => {
    setDoubleClickBlocked(true);
    try {
      const { baseInfo, teacherInfo, parentInfo } = data;
      await onSubmitFile();
      const arg = {
        ...baseInfo,
        isPreschooler: Boolean(data.baseInfo.isPreschooler),
        ...parentInfo,
        isHasNonEnrolledChild: Boolean(data.parentInfo.isHasNonEnrolledChild),
      };

      await registerInfo(arg);
      if (user.type === "TEACHER") {
        await updateTeacherInfoByAdmin(
          userId,
          teacherInfo?.hasHomeroom && teacherInfo?.grade && teacherInfo?.class
            ? { grade: teacherInfo.grade, class: teacherInfo.class }
            : null
        );
      }
      if (user.type === "PARENT" && user.account?.id) {
        await updateIsHasNonEnrolledChild({
          accountId: user.account.id,
          isHasNonEnrolledChild: Boolean(arg.isHasNonEnrolledChild),
        });
      }
      // ...existing code...
      if (user.type === "PARENT" || user.type === "TEACHER") {
        await updateBelongAndPart({
          userId: user.id,
          belongId: baseInfo.belongId,
          partId: baseInfo.partId,
        });
      }
      await getUser();
      history.push({
        to: "PTA_LIST_MEMBER",
        query: {
          userId: user.id,
        },
      });
      setDoubleClickBlocked(false);
      onClose();
    } catch (err) {
      setDoubleClickBlocked(false);
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    }
  };

  const selectBelong = async (id: string) => {
    setValue("baseInfo.belongId", id);
  };
  const selectPart = async (id: string) => {
    setValue("baseInfo.partId", id);
  };

  const zodSchema = (() => {
    if (user.type === "PARENT") {
      return parentPTAFormValueSchema;
    } else {
      return teacherPTAFormValueSchema;
    }
  })();
  const methods = useForm<PTAEditBaseInfoModalFormValues>({
    defaultValues: {
      baseInfo: {
        ...user.baseInfo,
        isPreschooler: Number(user.baseInfo?.isPreschooler),
        ageGroup: user.baseInfo?.ageGroup ?? -1,
        birthday: user.baseInfo?.birthday
          ? (toInputDateFormatYearMonth(user.baseInfo.birthday) as any)
          : undefined,
      },
      teacherInfo:
        isTeacherUser(user) && !!user.teacherInfo
          ? {
              hasHomeroom: user.teacherInfo.hasHomeroom,
              grade: user.teacherInfo.grade
                ? user.teacherInfo.grade
                : undefined,
              class: user.teacherInfo.class
                ? user.teacherInfo.class
                : undefined,
            }
          : {
              hasHomeroom: false,
              grade: undefined,
              class: undefined,
            },
      parentInfo: {
        isHasNonEnrolledChild: Number(user?.account?.isHasNonEnrolledChild),
      },
    },
    resolver: zodResolver(zodSchema),
    mode: "onChange",
  });
  const { setValue, watch, formState, register, handleSubmit } = methods;

  const hasHomeroomInput: boolean = watch("teacherInfo.hasHomeroom");
  const inputFileRef = useRef(null);
  const onClickFileInput = () => {
    const inputFileElement = inputFileRef.current! as HTMLInputElement;
    inputFileElement?.click();
  };

  const onInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const files = Array.from(input.files || []);
      const file = files[0];
      if (file) {
        setFaceImg(file);
      }
    },
    []
  );

  const onSubmitFile = async () => {
    if (faceImg) await registerFaceImgPath(faceImg);
  };

  const imgPreviewUrl = useMemo(() => {
    if (faceImg) return URL.createObjectURL(faceImg);
    else return "";
  }, [faceImg]);

  const sortedBelongs = sortByOrder(organization.belongs);
  const sortedParts = sortByOrder(organization.parts);

  return (
    <ModalPortal onClose={onClose}>
      <ModalWrapper>
        <ModalHeader>情報を編集</ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <NameAndImageSection
                imgPreviewUrl={imgPreviewUrl}
                user={user}
                currentUser={currentUser}
                onClickFileInput={onClickFileInput}
                onInputChange={onInputChange}
                inputFileRef={inputFileRef}
              />
              {organization &&
                organization.belongs.length !== 0 &&
                (user.type === "PARENT" || user.type === "TEACHER") && (
                  <div>
                    <EditModalFormLabel>所属</EditModalFormLabel>
                    <CheckTabs>
                      <ButtonGroupExtend>
                        {sortedBelongs.map((belong) => {
                          return (
                            <Button
                              key={belong.id}
                              size="large"
                              $fill
                              color={
                                watch("baseInfo.belongId") !== belong.id
                                  ? "ghost"
                                  : "primary"
                              }
                              className="button"
                              onClick={() => selectBelong(belong.id)}
                              type="button"
                            >
                              {belong.name}
                              {user.baseInfo?.belongId === belong.id && (
                                <CheckIcon size={8} />
                              )}
                            </Button>
                          );
                        })}
                      </ButtonGroupExtend>
                    </CheckTabs>
                  </div>
                )}
              {organization &&
                organization.parts.length !== 0 &&
                (user.type === "PARENT" || user.type === "TEACHER") && (
                  <div>
                    <EditModalFormLabel>役職</EditModalFormLabel>
                    <CheckTabs>
                      <ButtonGroupExtend>
                        {sortedParts.map((part) => {
                          return (
                            <Button
                              key={part.id}
                              size="large"
                              $fill
                              color={
                                watch("baseInfo.partId") !== part.id
                                  ? "ghost"
                                  : "primary"
                              }
                              className="button"
                              onClick={() => selectPart(part.id)}
                              type="button"
                            >
                              {part.name}
                              {user.baseInfo?.partId === part.id && (
                                <CheckIcon size={8} />
                              )}
                            </Button>
                          );
                        })}
                      </ButtonGroupExtend>
                    </CheckTabs>
                  </div>
                )}
              <PhoneNumberSection />

              {user.type === "PARENT" && (
                <div>
                  <EditModalFormLabel>登校班</EditModalFormLabel>
                  <div>
                    <TextField
                      {...register("baseInfo.schoolGroup")}
                      placeholder="例)  13"
                    />
                    {formState.errors.baseInfo?.schoolGroup && (
                      <ErrorMessage>
                        {formState.errors.baseInfo.schoolGroup.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              )}
              {user.type === "PARENT" && (
                <div>
                  <EditModalFormLabel>
                    来年度に入学予定の児童（同学校）
                  </EditModalFormLabel>
                  <SelectField
                    {...register("baseInfo.isPreschooler", {
                      valueAsNumber: true,
                    })}
                  >
                    {booleanOption.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    ))}
                  </SelectField>
                  {formState.errors.baseInfo?.isPreschooler && (
                    <ErrorMessage>
                      {formState.errors.baseInfo.isPreschooler.message}
                    </ErrorMessage>
                  )}
                </div>
              )}

              {user.type === "PARENT" && (
                <div>
                  <EditModalFormLabel>
                    未就園児（保育園や幼稚園に通っていない子ども）
                  </EditModalFormLabel>
                  <SelectField
                    {...register("parentInfo.isHasNonEnrolledChild", {
                      valueAsNumber: true,
                    })}
                  >
                    {booleanOption.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    ))}
                  </SelectField>
                  {formState.errors.parentInfo?.isHasNonEnrolledChild && (
                    <ErrorMessage>
                      {
                        formState.errors.parentInfo.isHasNonEnrolledChild
                          .message
                      }
                    </ErrorMessage>
                  )}
                </div>
              )}

              {(user.type === "PARENT" || user.type === "TEACHER") && (
                <div>
                  <EditModalFormLabel>自由記述</EditModalFormLabel>
                  <div>
                    <TextAreaField {...register("baseInfo.selfFreeMemo")} />
                    {formState.errors.baseInfo?.selfFreeMemo && (
                      <ErrorMessage>
                        {formState.errors.baseInfo.selfFreeMemo.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              )}
              {isPtaAdminRole(cu.role) &&
                (user.type === "PARENT" || user.type === "TEACHER") && (
                  <div>
                    <EditModalFormLabel>
                      自由記述（役員管理用）
                    </EditModalFormLabel>
                    <div>
                      <BlueTextAreaField
                        {...register("baseInfo.adminFreeMemo")}
                      />
                      {formState.errors.baseInfo?.adminFreeMemo && (
                        <ErrorMessage>
                          {formState.errors.baseInfo.adminFreeMemo.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                )}
              <Margin marginBottom={20} />
              {user.type === "TEACHER" && (
                <>
                  <div>
                    <EditModalFormLabel>担任</EditModalFormLabel>
                    <SelectField
                      {...register("teacherInfo.hasHomeroom")}
                      defaultChecked={hasHomeroomInput}
                    >
                      {isTeacherOption.map((opt) => {
                        return (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        );
                      })}
                    </SelectField>
                  </div>
                  {String(hasHomeroomInput) === "true" && (
                    <CheckTabs>
                      <div>
                        <EditModalFormLabel>学年</EditModalFormLabel>
                        <SelectField
                          {...register(`teacherInfo.grade` as const, {
                            required: "入力必須項目です",
                            setValueAs: (v) =>
                              v === "" ? undefined : parseInt(v, 10),
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {gradeOptions.map((opt) => {
                            return (
                              <option key={opt.id} value={opt.id}>
                                {getGradeLabel(
                                  opt.id,
                                  organization?.schoolDisplayType,
                                  true,
                                  false,
                                  "short"
                                )}
                              </option>
                            );
                          })}
                        </SelectField>
                        {formState.errors?.teacherInfo && (
                          <ErrorMessage>
                            {formState.errors.teacherInfo.grade?.message}
                          </ErrorMessage>
                        )}
                      </div>
                      <div>
                        <EditModalFormLabel>クラス</EditModalFormLabel>
                        <SelectField
                          {...register(`teacherInfo.class` as const, {
                            required: "入力必須項目です",
                            setValueAs: (v) =>
                              v === "" ? undefined : parseInt(v, 10),
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {classOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {formState.errors?.teacherInfo && (
                          <ErrorMessage>
                            {formState.errors.teacherInfo.class?.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                  )}
                </>
              )}
              <Margin marginTop={25} />
              <ModalSubmitButtons
                disabled={
                  Object.keys(formState.errors).length > 0 || doubleClickBlocked
                }
                submitText="保存"
                onCancel={onClose}
              />
            </form>
          </FormProvider>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};

const TextAreaField = styled.textarea`
  width: 100%;
  height: 96px;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const BlueTextAreaField = styled.textarea`
  width: 100%;
  height: 96px;
  padding: 10px 12px;
  background: #e5f4ff;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;
