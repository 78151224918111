import { z } from "zod";
import { zRequiredString } from "@shared/validator/rules";
import { gradeByAmountsSchema } from "@shared/validator/models/invoice/ptaInvoiceProduct.schema";

export const PTAInvoiceProcessCreateNewFormSchema = z.object({
  name: zRequiredString,
  amountsByGrades: gradeByAmountsSchema,
});
export type PTAInvoiceProcessCreateNewFormValues = z.infer<
  typeof PTAInvoiceProcessCreateNewFormSchema
>;
