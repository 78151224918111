import React, { useState, useMemo, useEffect, useRef } from "react";
import { createChatMessage } from "src/apiClients/chatMessage";
import { updateParticipantChatRoom } from "src/apiClients/participantChatRoom";
import { useMessageList } from "src/features/Recruitment/chatMessage/hooks/useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import {
  ChatRoom,
  IndividualChatWithBoardEducation,
} from "@shared/types/chatRoom";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { ChatInputForm } from "src/components/form/ChatInputForm";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { useCurrentUser } from "src/hooks/recoil/user";
import { updateAdminOnly } from "src/apiClients/chatRoom";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";
import {
  ChatRoomContainer,
  ChatRoomInfo,
  ChatBox,
  FloatWrapper,
  HeaderTitle,
  SearchInputWrapper,
  FloatDateLabel,
  ChatMessageInputContainer,
  ChatMessageInputArea,
  IndividualHeader,
  IndividualHeaderFlexWrapper,
} from "src/features/common/ChatMessage/components/ChatRoomContent/ChatRoomContentStyled";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoom;
  individualChatWithBoardEducation: IndividualChatWithBoardEducation;
  boardEducationName: string;
  lastReadAt: Date;
};

export const ChatRoomIndividualBEContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const toast = useToast();
  const user = useCurrentUser();
  const isAdmin = props.chatRoomParticipantId !== user.id;
  const { displayItemList, lastReadAt, NewMessageNotification } =
    useMessageList({
      initLastReadAt: props.lastReadAt,
      messageListRef,
      chatRoomParticipantId: props.chatRoomParticipantId,
    });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // チャットルームの最終アクセス日時を更新
  useEffect(() => {
    const updateParticipantChatRoomHandler = () => {
      updateParticipantChatRoom({
        chatRoomId: props.chatRoom.id,
      });
    };

    updateParticipantChatRoomHandler();

    const interval = setInterval(updateParticipantChatRoomHandler, 10000);

    return () => {
      clearInterval(interval);
      updateParticipantChatRoomHandler();
    };
  }, [props.chatRoom]);

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const onSend = async () => {
    if (!canSend) return;
    setSending(true);
    try {
      await createChatMessage(props.chatRoom.id, input, files);
      setInput("");
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        return;
      }
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setSending(false);
    }
  };

  const canSend = useMemo(() => {
    return !sending && (input.length > 0 || files.length > 0);
  }, [sending, input, files]);

  const onCheckedAdminOnly = async (checked: boolean) => {
    await updateAdminOnly({
      chatRoomId: props.chatRoom.id,
      adminOnly: checked,
    });
  };

  if (!displayItemList) return <></>;
  if (props.chatRoom.approvalStatus === "REJECTED") return <></>;

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <IndividualHeader>
          <IndividualHeaderFlexWrapper>
            <ChatRoomBackButton targetRoute="RESIDENT_CHAT" />
            <HeaderTitle>{props.chatRoom.name}</HeaderTitle>
          </IndividualHeaderFlexWrapper>
          <SearchInputWrapper>
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              onPrevResult={handlePrevResult}
              onNextResult={handleNextResult}
              resultCount={searchResults.length}
            />
          </SearchInputWrapper>
        </IndividualHeader>
      </ChatRoomInfo>
      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    searchText={searchText}
                    lastReadAt={lastReadAt}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
            }
          })}
        </ChatMessages>
        <NewMessageNotification />
        <ChatMessageInputContainer>
          <ChatMessageInputArea>
            <ChatInputForm
              isAdmin={isAdmin}
              adminOnly={props.chatRoom.adminOnly}
              onCheckedAdminOnly={onCheckedAdminOnly}
              placeholder="メッセージを入力"
              setValue={setInput}
              setFiles={setFiles}
              canSend={canSend}
              onSend={onSend}
            />
          </ChatMessageInputArea>
        </ChatMessageInputContainer>
      </ChatBox>
    </ChatRoomContainer>
  );
};
