import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import {
  InvoiceStatusLabel,
  PTAParentFamilyInvoiceListType,
} from "@shared/types/ptaParentFamilyInvoice";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getClassName } from "src/utils/getChildClass";
import { Link } from "src/components/Link";
import { CancelInvoiceModal } from "./CancelInvoiceModal";
import { CashPaymentModal } from "./CashPaymentModal";
import { colorsPallet } from "src/theme";
import { zIndexes } from "src/zIndex";
import { ModalBackGround } from "src/components/ListPageFilterSetting";
import { useInvalidatePTAParentFamilyInvoiceList } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import {
  cancelPTAParentFamilyInvoice,
  payByCash,
} from "src/apiClients/ptaParentFamilyInvoice";
import { getGradeColor } from "src/utils/getGradeColor";
import {
  PTAInvoiceDetailContentProps,
  sortChildInvoiceItems,
} from "./PTAInvoiceDetailContent";
import { TextWithHighlight } from "src/components/TextWithHighlight";
import { CancelReasonPopup, CancelReasonButton } from "./CancelReasonPopup";

export const PTAInvoiceUserListContent = ({
  isLoadingParentFamilyInvoices,
  invoiceProductId,
  ptaParentFamilyInvoices,
  searchText,
}: PTAInvoiceDetailContentProps) => {
  const toast = useToast();
  const { organization } = useGetCurrentOrganization();
  const [selectedChildInvoiceItem, setSelectedChildInvoiceItem] = useState<
    PTAParentFamilyInvoiceListType["PTAChildInvoiceItems"][0] | null
  >(null);
  const [invoiceForCancel, setInvoiceForCancel] =
    useState<PTAParentFamilyInvoiceListType | null>(null);
  const [invoiceForCashPayment, setInvoiceForCashPayment] =
    useState<PTAParentFamilyInvoiceListType | null>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCashPaymentModalOpen, setIsCashPaymentModalOpen] = useState(false);
  const [openReasonId, setOpenReasonId] = useState<string | null>(null);
  const { invalidatePTAParentFamilyInvoiceList } =
    useInvalidatePTAParentFamilyInvoiceList();

  const onClickSetting = useCallback(
    (
      childInvoiceItem: PTAParentFamilyInvoiceListType["PTAChildInvoiceItems"][0]
    ) => {
      if (selectedChildInvoiceItem?.id === childInvoiceItem.id) {
        setSelectedChildInvoiceItem(null);
      } else {
        setSelectedChildInvoiceItem(childInvoiceItem);
      }
    },
    [selectedChildInvoiceItem]
  );

  const onOpenCancelModal = useCallback(() => {
    if (!selectedChildInvoiceItem) return;
    const invoice = ptaParentFamilyInvoices.find(
      (targetInvoice) =>
        targetInvoice.id === selectedChildInvoiceItem.PTAParentFamilyInvoiceId
    );
    if (!invoice) return;

    setInvoiceForCancel(invoice);
    setSelectedChildInvoiceItem(null);
    setIsCancelModalOpen(true);
  }, [selectedChildInvoiceItem, ptaParentFamilyInvoices]);

  const onOpenCashPaymentModal = useCallback(() => {
    if (!selectedChildInvoiceItem) return;
    const invoice = ptaParentFamilyInvoices.find(
      (targetInvoice) =>
        targetInvoice.id === selectedChildInvoiceItem.PTAParentFamilyInvoiceId
    );
    if (!invoice) return;

    setInvoiceForCashPayment(invoice);
    setSelectedChildInvoiceItem(null);
    setIsCashPaymentModalOpen(true);
  }, [selectedChildInvoiceItem, ptaParentFamilyInvoices]);

  const handleCancelSubmit = useCallback(
    async (cancelComment: string) => {
      if (!invoiceForCancel) return;

      if (invoiceForCancel.status !== "PENDING") {
        toast.warn("未払いの請求のみキャンセルできます。");
        return;
      }
      try {
        await cancelPTAParentFamilyInvoice({
          invoiceId: invoiceForCancel.id,
          cancelComment: cancelComment,
        });
        invalidatePTAParentFamilyInvoiceList(invoiceProductId);
        toast.warn("請求をキャンセルしました。");
      } catch (err) {
        logger.error(err, { invoiceForCancel });
        toast.error("キャンセルに失敗しました。");
      }

      setIsCancelModalOpen(false);
      setInvoiceForCancel(null);
    },
    [invoiceForCancel, invoiceProductId, invalidatePTAParentFamilyInvoiceList]
  );

  const handleCashPaymentSubmit = useCallback(async () => {
    if (!invoiceForCashPayment) return;

    if (invoiceForCashPayment.status !== "PENDING") {
      toast.warn("未払いの請求のみ現金支払いを受け付けられます。");
      return;
    }

    try {
      await payByCash({ invoiceId: invoiceForCashPayment.id });
      invalidatePTAParentFamilyInvoiceList(invoiceProductId);
      toast.success("現金支払いを受け付けました。");
    } catch (err) {
      logger.error(err, { invoiceForCashPayment });
      toast.error("現金支払いの受付に失敗しました。");
    }

    setIsCashPaymentModalOpen(false);
    setInvoiceForCashPayment(null);
  }, [
    invoiceForCashPayment,
    invoiceProductId,
    invalidatePTAParentFamilyInvoiceList,
  ]);

  const handleCloseModal = useCallback(() => {
    setIsCancelModalOpen(false);
    setIsCashPaymentModalOpen(false);
    setInvoiceForCancel(null);
    setInvoiceForCashPayment(null);
  }, []);

  if (isLoadingParentFamilyInvoices) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <Th>保護者名</Th>
            <Th>子どもごとの内訳</Th>
            <Th>合計請求金額</Th>
            <Th>状況</Th>
          </tr>
        </thead>
        <tbody>
          {ptaParentFamilyInvoices.map((invoice) => (
            <Tr key={invoice.id}>
              <Td>
                {invoice.PTAParentFamily.mainAccount ? (
                  <LinkText
                    to={{
                      to: "PTA_LIST_MEMBER",
                      query: {
                        userId:
                          invoice.PTAParentFamily.mainAccount?.user.id ?? "",
                      },
                    }}
                    $place="start"
                  >
                    <TextWithHighlight
                      text={`${
                        invoice.PTAParentFamily.mainAccount?.user.baseInfo
                          ?.lastName || ""
                      } ${
                        invoice.PTAParentFamily.mainAccount?.user.baseInfo
                          ?.firstName || ""
                      }`}
                      keywords={[searchText]}
                    />
                  </LinkText>
                ) : (
                  <span>[削除済みユーザー]</span>
                )}
              </Td>
              <Td>
                <ChildInvoiceList>
                  {invoice.PTAChildInvoiceItems.slice()
                    .sort(sortChildInvoiceItems)
                    .map((item) => (
                      <ChildInvoiceItem key={item.id}>
                        {item.child ? (
                          <ChildInfo>
                            <ChildName>
                              <TextWithHighlight
                                text={`${item.child.childLastName} ${item.child.childFirstName}`}
                                keywords={[searchText]}
                              />
                            </ChildName>
                            <ChildClass>
                              <GradeLabel
                                style={{
                                  backgroundColor: getGradeColor(
                                    item.child.grade
                                  ),
                                }}
                              >
                                <span>学年</span>
                                <span>
                                  {getGradeLabel(
                                    item.child.grade,
                                    organization?.schoolDisplayType,
                                    true,
                                    true,
                                    "short"
                                  )}
                                </span>
                              </GradeLabel>
                              <ClassLabel>
                                <ClassTitle>クラス</ClassTitle>
                                <ClassValue>
                                  {getClassName(item.child.class, true)}
                                </ClassValue>
                              </ClassLabel>
                            </ChildClass>
                          </ChildInfo>
                        ) : (
                          <Deleted>[削除済み]</Deleted>
                        )}
                        <ChildAmount>{`${item.amount.toLocaleString()}円`}</ChildAmount>
                      </ChildInvoiceItem>
                    ))}
                </ChildInvoiceList>
              </Td>
              <Td>{`${invoice.unitAmount.toLocaleString()}円`}</Td>
              <Td>
                <CancelStatusContainer>
                  <div>
                    <StatusBadge $status={invoice.status}>
                      {InvoiceStatusLabel[invoice.status]}
                    </StatusBadge>
                  </div>
                  {invoice.status === "CANCELED" &&
                    (invoice.cancelComment || invoice.cancelReason) && (
                      <ReasonContainer>
                        <CancelReasonButton
                          onClick={() => {
                            setOpenReasonId(
                              openReasonId === invoice.id ? null : invoice.id
                            );
                          }}
                        >
                          キャンセル理由
                        </CancelReasonButton>
                        <CancelReasonPopup
                          cancelReason={invoice.cancelReason}
                          cancelComment={invoice.cancelComment}
                          isOpen={openReasonId === invoice.id}
                          onClose={() => setOpenReasonId(null)}
                        />
                      </ReasonContainer>
                    )}
                </CancelStatusContainer>
              </Td>
              <Td>
                <MenuContainer>
                  {invoice.status === "PENDING" && (
                    <SettingButton
                      onClick={() =>
                        onClickSetting(invoice.PTAChildInvoiceItems[0])
                      }
                      $isOpen={
                        selectedChildInvoiceItem?.PTAParentFamilyInvoiceId ===
                        invoice.id
                      }
                    >
                      …
                    </SettingButton>
                  )}

                  {selectedChildInvoiceItem?.PTAParentFamilyInvoiceId ===
                    invoice.id && (
                    <>
                      <ModalBackGround
                        onClick={() => setSelectedChildInvoiceItem(null)}
                      />
                      <EditMenuTab>
                        {invoice.status === "PENDING" && (
                          <>
                            <SettingEditInfo onClick={onOpenCashPaymentModal}>
                              現金支払いの受付
                            </SettingEditInfo>
                            <SettingEditInfo onClick={onOpenCancelModal}>
                              <DangerText>請求をキャンセル</DangerText>
                            </SettingEditInfo>
                          </>
                        )}
                      </EditMenuTab>
                    </>
                  )}
                </MenuContainer>
              </Td>
            </Tr>
          ))}
        </tbody>
      </StyledTable>
      {isCancelModalOpen && invoiceForCancel && (
        <CancelInvoiceModal
          invoice={invoiceForCancel}
          onSubmit={handleCancelSubmit}
          onClose={handleCloseModal}
          disabled={invoiceForCancel.status !== "PENDING"}
        />
      )}
      {isCashPaymentModalOpen && invoiceForCashPayment && (
        <CashPaymentModal
          invoice={invoiceForCashPayment}
          onSubmit={handleCashPaymentSubmit}
          onClose={handleCloseModal}
          disabled={invoiceForCashPayment.status !== "PENDING"}
        />
      )}
    </>
  );
};

const LoadingWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr``;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const Td = styled.td`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const LinkText = styled(Link)<{ $place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.$place};
  color: #07c;
`;
const StatusBadge = styled.span<{ $status: string }>`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${({ $status }) =>
    $status === "PENDING"
      ? colorsPallet.danger
      : $status === "PAID"
      ? colorsPallet.success
      : $status === "PROCESSING"
      ? colorsPallet.warning
      : colorsPallet.lightestShade};
  color: ${({ $status }) =>
    $status === "PENDING"
      ? "#fff"
      : $status === "PAID"
      ? "#fff"
      : $status === "PROCESSING"
      ? "#fff"
      : "#333"};
`;

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SettingButton = styled.button<{ $isOpen: boolean }>`
  width: 40px;
  height: 40px;
  background-color: transparent;
  font-size: 16px;
  color: #343741;
  border: none;
  border-radius: 100%;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f3f4f8;
  }
  background-color: ${(props) => (props.$isOpen ? "#f3f4f8" : "transparent")};
`;

const SettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 150px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

const EditMenuTab = styled(SettingEditTab)`
  z-index: ${zIndexes.notModalMenu};
`;

const SettingEditInfo = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const DangerText = styled.span`
  color: ${colorsPallet.danger};
`;

const ChildInvoiceList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildInvoiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const ChildInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChildName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
`;

const ChildClass = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const GradeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #fff;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ClassLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  background-color: #f5f5f5;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ClassTitle = styled.span`
  color: #999;
`;

const ClassValue = styled.span`
  color: #333;
`;

const ChildAmount = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding: 4px 0;
  text-align: right;
`;

const Deleted = styled.span`
  height: 52px;
  display: flex;
  align-items: center;
`;

const CancelStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ReasonContainer = styled.div`
  position: relative;
  margin-top: 8px;
`;
