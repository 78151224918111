import { z } from "zod";
import { zRequiredString } from "../rules";

export const updateIsHasNonEnrolledChildSchema = z.object({
  accountId: zRequiredString,
  isHasNonEnrolledChild: z.boolean(),
});

export type UpdateIsHasNonEnrolledChildSchemaType = z.infer<
  typeof updateIsHasNonEnrolledChildSchema
>;
