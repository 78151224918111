export const estimatedMinutes = (invoiceCount: number) => {
  //一件あたり3秒ほどで考える
  const estimatedSec = invoiceCount * 3;

  const estimatedMinutes = Math.ceil(estimatedSec / 60);
  if (estimatedMinutes < 10) {
    return estimatedMinutes;
  }
  return Math.ceil(estimatedMinutes / 10) * 10;
};
