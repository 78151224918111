import React, { useEffect, useState } from "react";
import { useGetOrCreateCustomAggregationUnit } from "src/hooks/query/lineCustomAggregation";
import {
  format,
  parse,
  isAfter,
  isBefore,
  subDays,
  startOfMonth,
} from "date-fns";
import { LineInsightWithAmountOfLineMessages } from "@shared/types/lineCustomAggregation";
import { getLineInsightWithAmountOfLineMessages } from "src/apiClients/lineCustomAggregation";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/Button";

type Props = {
  editOrganizationId: string;
};

const dateStringSchema = z
  .string()
  .regex(/^\d{8}$/, "日付は8桁の数字である必要があります");

export const lineAggregationSchema = z
  .object({
    fromDate: dateStringSchema.refine(
      (val) =>
        !isAfter(parse(val, "yyyyMMdd", new Date()), subDays(new Date(), 1)),
      {
        message: "開始日は昨日以前の日付を選択してください",
      }
    ),
    toDate: dateStringSchema.refine(
      (val) =>
        !isAfter(parse(val, "yyyyMMdd", new Date()), subDays(new Date(), 1)),
      {
        message: "終了日は昨日以前の日付を選択してください",
      }
    ),
  })
  .refine(
    (data) => {
      const fromDate = parse(data.fromDate, "yyyyMMdd", new Date());
      const toDate = parse(data.toDate, "yyyyMMdd", new Date());
      return !isBefore(toDate, fromDate);
    },
    {
      message: "終了日は開始日以降の日付を選択してください",
      path: ["toDate"],
    }
  );

export type LineAggregationFormValues = z.infer<typeof lineAggregationSchema>;

export default function LineAggregationArea({ editOrganizationId }: Props) {
  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<LineAggregationFormValues>({
    resolver: zodResolver(lineAggregationSchema),
    defaultValues: {
      fromDate: format(startOfMonth(new Date()), "yyyyMMdd"),
      toDate: format(subDays(new Date(), 1), "yyyyMMdd"),
    },
    mode: "onChange",
  });
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const yesterday = format(subDays(new Date(), 1), "yyyyMMdd");

  const { customAggregationUnit, isLoading } =
    useGetOrCreateCustomAggregationUnit(editOrganizationId ?? "");

  useEffect(() => {
    if (customAggregationUnit) {
      onSubmit();
    }
  }, [customAggregationUnit]);

  const [
    lineInsightWithAmountOfLineMessages,
    setLineInsightWithAmountOfLineMessages,
  ] = useState<LineInsightWithAmountOfLineMessages | undefined>(undefined);

  const onSubmit = handleSubmit(async (data) => {
    setLineInsightWithAmountOfLineMessages(undefined);
    const newLineInsightWithAmountOfLineMessages =
      await getLineInsightWithAmountOfLineMessages({
        customAggregationUnit: customAggregationUnit ?? "",
        from: data.fromDate,
        to: data.toDate,
      });
    setLineInsightWithAmountOfLineMessages(
      newLineInsightWithAmountOfLineMessages
    );
  });
  const handleDateChange =
    (name: "fromDate" | "toDate") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newDate = format(
        parse(e.target.value, "yyyy-MM-dd", new Date()),
        "yyyyMMdd"
      );
      setValue(name, newDate);
      trigger();
    };

  return (
    <>
      <h3>LINE統計情報</h3>

      <form onSubmit={onSubmit}>
        <label>
          From:
          <input
            type="date"
            value={format(
              parse(fromDate, "yyyyMMdd", new Date()),
              "yyyy-MM-dd"
            )}
            onChange={handleDateChange("fromDate")}
            max={format(parse(yesterday, "yyyyMMdd", new Date()), "yyyy-MM-dd")}
          />
          {errors.fromDate && <span>{errors.fromDate.message}</span>}
        </label>
        <label>
          To:
          <input
            type="date"
            value={format(parse(toDate, "yyyyMMdd", new Date()), "yyyy-MM-dd")}
            onChange={handleDateChange("toDate")}
            max={format(parse(yesterday, "yyyyMMdd", new Date()), "yyyy-MM-dd")}
          />
          {errors.toDate && (
            <>
              <br />
              <span style={{ color: "red" }}>{errors.toDate.message}</span>
            </>
          )}
        </label>
        <br />
        <Button type="submit" color="primary" $fill disabled={!isValid}>
          統計情報を取得
        </Button>
      </form>
      {isLoading ? (
        <p>統計情報を取得中...</p>
      ) : lineInsightWithAmountOfLineMessages ? (
        <>
          {format(parse(fromDate, "yyyyMMdd", new Date()), "yyyy年MM月dd日")}
          から
          {format(parse(toDate, "yyyyMMdd", new Date()), "yyyy年MM月dd日")}
          までの統計情報
          <table>
            <thead>
              <tr>
                <th>項目</th>
                <th>値</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>メッセージ送信数</td>
                <td>
                  {lineInsightWithAmountOfLineMessages.amountOfLineMessages}
                </td>
              </tr>
              {lineInsightWithAmountOfLineMessages.insight ? (
                <>
                  <tr>
                    <td>メッセージ種類数</td>
                    <td>
                      {
                        lineInsightWithAmountOfLineMessages.insight.messages
                          .length
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>インプレッション数</td>
                    <td>
                      {
                        lineInsightWithAmountOfLineMessages.insight.overview
                          .uniqueImpression
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>インプレッション率</td>
                    <td>
                      {((lineInsightWithAmountOfLineMessages.insight.overview
                        .uniqueImpression ?? 0) /
                        (lineInsightWithAmountOfLineMessages.amountOfLineMessages ??
                          0)) *
                        100}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td>クリック数</td>
                    <td>
                      {
                        lineInsightWithAmountOfLineMessages.insight.overview
                          .uniqueClick
                      }
                    </td>
                  </tr>
                </>
              ) : (
                <p>
                  送信数以外の統計情報を取得できませんでした。時間を置いて
                  再度実行してください。
                </p>
              )}
            </tbody>
          </table>
        </>
      ) : (
        <p>
          統計情報を取得できませんでした。時間を置いて再度実行してください。
        </p>
      )}
    </>
  );
}
