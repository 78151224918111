import React, { JSX } from "react";
import { Button } from "../../../components/Button";
import { ModalHeader, ModalPortal, useModal } from "../../../components/Modal";
import {
  isMultiChoiceQuestion,
  isOpenEndedQuestion,
} from "../../../apiClients/survey";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../components/Responsive";
import { MultiChoiceQuestionField } from "./MultiChoiceQuestionField";
import { OpenEndedQuestionField } from "./OpenEndedQuestionField";
import { toDateFormat } from "../../../utils/time";
import {
  Question,
  SurveyQuestions,
} from "@shared/validator/features/survey.schema";

const QuestionField = ({ question }: { question: Question }): JSX.Element => {
  if (isMultiChoiceQuestion(question)) {
    return <MultiChoiceQuestionField question={question} />;
  }
  if (isOpenEndedQuestion(question)) {
    return <OpenEndedQuestionField question={question} />;
  }
  return <></>;
};

// アンケート確認用のモーダル本体
// onClose: モーダルを閉じる際に走る処理
function CheckModal({
  surveyQuestions,
  onClose,
}: {
  surveyQuestions: SurveyQuestions;
  onClose: () => void;
}): JSX.Element {
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>アンケート</ModalHeader>
      {surveyQuestions.questionList.map((question: Question, index: number) => (
        <QuestionField key={index} question={question} />
      ))}
      <Footer>
        <Deadline>
          回答期限：
          {toDateFormat(new Date(surveyQuestions.deadLine))}
        </Deadline>
        <Button size="large" $fill onClick={onClose}>
          閉じる
        </Button>
      </Footer>
    </ModalPortal>
  );
}

export function CheckSurvey({
  surveyQuestions,
}: {
  surveyQuestions: SurveyQuestions | undefined;
}): JSX.Element {
  // ref: https://vitejs.dev/guide/env-and-mode.html#env-variables
  if (!import.meta.env.VITE_FEATURE_SURVEY) {
    return <></>;
  }
  // サーバー側で弾いているはずだが安全側に倒しておく
  if (surveyQuestions === undefined) {
    return <></>;
  }
  // モーダル状態
  const [, { show, close }] = useModal();
  const [isNewModal, setIsNewModal] = React.useState(false);
  return (
    <>
      <Container>
        <StyledButton
          $fill
          color="primary"
          onClick={(event) => {
            // このボタンのクリックに際してはカード開閉のイベントを無視する
            event.stopPropagation();
            // モーダルを開く
            setIsNewModal(!isNewModal);
            show();
          }}
        >
          アンケートを確認する
        </StyledButton>
      </Container>
      {isNewModal && (
        <CheckModal
          surveyQuestions={surveyQuestions}
          onClose={() => {
            // 内部から閉じられるように
            // モーダルを閉じる際のイベントを定義して渡す
            setIsNewModal(!isNewModal);
            close();
          }}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.PC}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledButton = styled(Button)`
  padding: 6px 12px;
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 16px;
`;

const Deadline = styled.p`
  font-size: 14px;
  color: rgba(52, 55, 65, 1);
  margin-right: auto;
  display: flex;
  align-items: center;
`;
