import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useToast } from "src/components/Toast";
import { ModalPortal, ModalSubmitButtons } from "src/components/Modal";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { Avatar } from "src/components/Common/Avatar";
import { updateGroupChatRoom } from "src/apiClients/boardEducation/chatRoom";
import { ChatRoom } from "@shared/types/chatRoom";
import { Heading4 } from "src/components/Typo";
import {
  Picture,
  UploadImagesContainer,
  UploadImgButton,
  TextField as TextField2,
} from "src/pages/pta/admin/ProfilePage";
import { CameraIcon } from "src/components/icons/CameraIcon";
import { useGetDownloadUrl } from "src/hooks/query/firebaseStorage";
import { logger } from "src/utils/logger";
import { BEResidentUser } from "@shared/types/boardEducation/residentUsers";
import { useInvalidateChatRoomMembers } from "src/hooks/query/boardEducation/chatRooms/chatRoomMembers";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export type chatRoomEditorFormValues = {
  name: string;
  accountIds: string[];
  file: File | undefined;
};

type Props = {
  close: () => void;
  refetch: () => void;
  chatRoom: ChatRoom;
  selectedMemberIds: string[];
  onCancel: () => void;
  residents: BEResidentUser[];
};

export const GroupInfoEditStep = ({
  close,
  refetch,
  chatRoom,
  selectedMemberIds,
  onCancel,
  residents,
}: Props) => {
  const history = usePolyfitHistory();
  const toast = useToast();
  const { invalidateChatRoomMembers } = useInvalidateChatRoomMembers();

  const defaultValues = {
    name: chatRoom.name,
    file: undefined,
  };
  const { tab } = usePolyfitLocationQuery("RESIDENT_CHAT", {});
  const onChatRoomTransition = (roomId: string) => {
    history.push({
      to: "BE_CHATROOM_LIST",
      query: { chatRoomId: roomId, tab },
    });
  };

  const { register, setValue, handleSubmit, watch } =
    useForm<chatRoomEditorFormValues>({
      defaultValues,
      mode: "onBlur",
    });

  const { url } = chatRoom.iconFilePath
    ? useGetDownloadUrl(chatRoom.iconFilePath, "")
    : { url: "" };
  const [imgPreviewUrl, setImgPreviewUrl] = useState<string | null>(
    url || null
  );
  const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("file", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImgPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const name = watch("name");
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: chatRoomEditorFormValues) => {
    setDoubleClickBlocked(true);

    try {
      await updateGroupChatRoom(
        chatRoom.id,
        data.name,
        selectedMemberIds,
        data.file
      );
      invalidateChatRoomMembers(chatRoom.id);
      toast.success("チャットルームの編集に成功しました");
      close();
      await refetch();
      onChatRoomTransition(chatRoom.id);
    } catch (err) {
      logger.error(err);
      toast.error("チャットルームの編集に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  const truncateString = (str: string): string => {
    if (str.length <= 5) {
      return str;
    }
    return str.slice(0, 5) + "...";
  };
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onClickFileInput = () => {
    inputFileRef.current?.click();
  };
  return (
    <ModalPortal onClose={() => close()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>グループメッセージ編集</ModalHeader>
        <CreateChatRoomForms>
          <UploadImagesContainer>
            <Picture
              src={imgPreviewUrl || defaultUserImg} //暫定でdefaultUserImg
              alt="image"
            />
            <UploadImgButton type="button" onClick={onClickFileInput}>
              <CameraIcon />
            </UploadImgButton>
            <input
              ref={inputFileRef}
              hidden
              type="file"
              name="image"
              readOnly={true}
              accept="image/jpeg, image/png"
              onChange={handleIconChange}
              id="file"
            />
          </UploadImagesContainer>
          <TextField2 {...register("name")} placeholder="ルーム名" />
        </CreateChatRoomForms>
        <SelectedAccountView>
          <Heading4>選択中のメンバー</Heading4>
          <SelectedAccountsList>
            {selectedMemberIds.map((accountId) => {
              const selectedResident = residents.find(
                (resident) => resident.accountId === accountId
              );

              return (
                <SelectedResident key={accountId}>
                  <AvatarWrapper>
                    <Avatar
                      src={
                        selectedResident?.account.user.picture || defaultUserImg
                      }
                      alt={selectedResident?.account.user.name || ""}
                      size={30}
                    />
                  </AvatarWrapper>
                  <p>{truncateString(selectedResident!.account.user.name)}</p>
                </SelectedResident>
              );
            })}
          </SelectedAccountsList>
        </SelectedAccountView>
        <ModalSubmitButtons
          submitText="更新"
          cancelText="戻る"
          onCancel={() => onCancel}
          disabled={!name || doubleClickBlocked}
        />
      </form>
    </ModalPortal>
  );
};

const ModalHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1a1c21;
`;

const SelectedAccountView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  p {
    font-size: 12px;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  width: fit-content;
`;

const SelectedResident = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  // justify-content:center;
  align-items: center;
  flex-shrink: 0;
`;

const SelectedAccountsList = styled.div`
  display: flex;
  gap: 12px;
  max-width: 100%;
  overflow-x: auto;
`;

const CreateChatRoomForms = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
`;
