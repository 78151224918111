import React from "react";
import { BREAKPOINTS } from "src/components/Responsive";
import styled from "styled-components";
import { PTAInvoiceMobileCard } from "./PTAInvoiceUserCard";
import { PTAInvoiceUserListContent } from "./PTAInvoiceUserList";
import { PTAParentFamilyInvoiceListType } from "@shared/types/ptaParentFamilyInvoice";
import { Button } from "src/components/Button";
import { Margin } from "src/components/Margin";
import { usePolyfitHistory } from "src/hooks/router";

export const sortChildInvoiceItems = (
  a: PTAParentFamilyInvoiceListType["PTAChildInvoiceItems"][0],
  b: PTAParentFamilyInvoiceListType["PTAChildInvoiceItems"][0]
) => {
  if (a.child?.grade !== b.child?.grade) {
    return (a.child?.grade ?? 1000) - (b.child?.grade ?? 1000);
  }
  return (a.child?.class ?? 1000) - (b.child?.class ?? 1000);
};

export type PTAInvoiceDetailContentProps = {
  isLoadingParentFamilyInvoices: boolean;
  invoiceProductId: string;
  ptaParentFamilyInvoices: PTAParentFamilyInvoiceListType[];
  searchText: string;
  allInvoiceCount?: number;
  onResetFilter?: () => void;
};

export function PTAInvoiceDetailContent({
  isLoadingParentFamilyInvoices,
  invoiceProductId,
  ptaParentFamilyInvoices,
  searchText,
  allInvoiceCount,
  onResetFilter,
}: PTAInvoiceDetailContentProps) {
  const history = usePolyfitHistory();
  if (ptaParentFamilyInvoices.length === 0) {
    return (
      <NoInvoiceWrapper>
        <p>請求情報がありません。</p>
        <p>請求を作成中の場合は、しばらくお待ちください。</p>
        {allInvoiceCount && allInvoiceCount > 0 && onResetFilter ? (
          <>
            <p>フィルターをリセットしてください。</p>
            <Margin marginBottom={16} />
            <Button color="primary" $fill onClick={onResetFilter}>
              フィルターをリセット
            </Button>
          </>
        ) : (
          <>
            <p>
              まだ請求を作成していない場合は、以下のボタンから作成を進めてください。
            </p>
            <Margin marginBottom={16} />
            <Button
              color="primary"
              $fill
              onClick={() =>
                history.push({
                  to: "PTA_INVOICE_PROCESS",
                  query: { id: invoiceProductId, step: "1" },
                })
              }
            >
              請求データを作成する
            </Button>
          </>
        )}
      </NoInvoiceWrapper>
    );
  }
  return (
    <>
      <PCOnly>
        <TableWrapper>
          <PTAInvoiceUserListContent
            isLoadingParentFamilyInvoices={isLoadingParentFamilyInvoices}
            invoiceProductId={invoiceProductId}
            ptaParentFamilyInvoices={ptaParentFamilyInvoices}
            searchText={searchText}
          />
        </TableWrapper>
      </PCOnly>
      <MobileOnly>
        <PTAInvoiceMobileCard
          isLoadingParentFamilyInvoices={isLoadingParentFamilyInvoices}
          invoiceProductId={invoiceProductId}
          ptaParentFamilyInvoices={ptaParentFamilyInvoices}
          searchText={searchText}
        />
      </MobileOnly>
    </>
  );
}

const TableWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
`;
const MobileOnly = styled.div`
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

const PCOnly = styled.div`
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

const NoInvoiceWrapper = styled.div`
  padding: 24px;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
  border-radius: 8px;
`;
