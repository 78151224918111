import { z } from "zod";

export const ptaInvoiceImportRowAmountSchema = z
  .number({
    required_error: "請求料金が未入力です。",
    invalid_type_error: "請求料金は数値で入力してください。",
  })
  .int("請求料金は整数で入力してください。")
  .positive("請求料金は50円以上を入力してください。")
  .min(50, "請求料金は50円以上を入力してください。");

export const ptaInvoiceImportRowSchema = z.object({
  id: z.string().uuid(),
  PTAParentFamilyId: z
    .string({
      required_error: "家族IDが未入力です。",
      invalid_type_error: "家族IDが不正な形式です。",
    })
    .uuid("家族IDが不正な形式です"),
  childId: z
    .string({
      required_error: "子どもIDが未入力です。",
      invalid_type_error: "子どもIDが不正な形式です。",
    })
    .uuid("子どもIDが不正な形式です"),
  amount: ptaInvoiceImportRowAmountSchema,
  parentName: z
    .string({
      required_error: "保護者名が未入力です。",
      invalid_type_error: "保護者名が不正な形式です。",
    })
    .nullable()
    .transform((val) => val ?? undefined)
    .optional(),
  parentNameKana: z
    .string()
    .nullable()
    .transform((val) => val ?? undefined)
    .optional(),
  childName: z
    .string({
      required_error: "子ども名が未入力です。",
      invalid_type_error: "子ども名が不正な形式です。",
    })
    .nullable()
    .transform((val) => val ?? undefined)
    .optional(),
  childNameKana: z
    .string()
    .nullable()
    .transform((val) => val ?? undefined)
    .optional(),
  grade: z
    .number({
      required_error: "学年が未入力です。",
      invalid_type_error: "学年が不正な形式です。",
    })
    .nullable()
    .transform((val) => val ?? undefined)
    .optional(),
  class: z.union([
    z
      .number({
        required_error: "クラスが未入力です。",
        invalid_type_error: "クラスが不正な形式です。",
      })
      .nullable()
      .transform((val) => val ?? undefined)
      .optional(),
    z.literal("その他"),
  ]),
  invoiceCreationStatus: z.enum(["SUCCESS", "FAILED"]).nullable().optional(),
  invoiceCreationErrorMessage: z.string().nullable().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type PTAInvoiceImportRowSchema = z.infer<
  typeof ptaInvoiceImportRowSchema
>;
