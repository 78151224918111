import React from "react";
import { useForm } from "react-hook-form";
import { Partner, upsertPartner } from "../../../apiClients/partner";
import {
  EditModalFormLabel,
  ModalWrapper,
  TextField,
  ErrorMessage,
} from "../../../pages/pta/admin/ProfilePage";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import { ModalBody, ModalHeader, ModalPortal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import styled from "styled-components";
import { UpsertPartnerSchemaType } from "@shared/validator/features/partner.schema";

type Props = {
  onClose: () => void;
  partner: Partner | null;
  fetch: () => void;
};
export const PartnerInfoEditModal = ({ onClose, partner, fetch }: Props) => {
  const toast = useToast();

  const { lastName, firstName, lastNameKana, firstNameKana, email, isShare } =
    partner || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpsertPartnerSchemaType>({
    defaultValues: {
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      email,
    },
    mode: "onBlur",
  });

  const onSubmit = async (data: UpsertPartnerSchemaType) => {
    await upsertPartner(data)
      .then(() => {
        toast.success("他の保護者情報を更新しました");
        fetch();
      })
      .catch(() => {
        toast.error("他の保護者情報の更新に失敗しました");
      });
    onClose();
  };
  return (
    <ModalPortal onClose={onClose}>
      <ModalWrapper>
        <ModalHeader>他の保護者情報</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditModalFormLabel>他の保護者名</EditModalFormLabel>
            <Flex>
              <FlexItem>
                <TextField placeholder="例）山田" {...register("lastName")} />
              </FlexItem>
              <FlexItem>
                <TextField placeholder="例）太郎" {...register("firstName")} />
              </FlexItem>
            </Flex>
            <EditModalFormLabel>ふりがな</EditModalFormLabel>
            <Flex>
              <FlexItem>
                <TextField
                  placeholder="例）やまだ"
                  {...register("lastNameKana", {
                    pattern: {
                      value: /^[ぁ-んーー]+$/u,
                      message: "ひらがなのみで入力してください",
                    },
                  })}
                />
                {errors.lastNameKana && (
                  <ErrorMessage>{errors.lastNameKana.message}</ErrorMessage>
                )}
              </FlexItem>
              <FlexItem>
                <TextField
                  placeholder="例）たろう"
                  {...register("firstNameKana", {
                    pattern: {
                      value: /^[ぁ-んーー]+$/u,
                      message: "ひらがなのみで入力してください",
                    },
                  })}
                />
                {errors.firstNameKana && (
                  <ErrorMessage>{errors.firstNameKana.message}</ErrorMessage>
                )}
              </FlexItem>
            </Flex>
            <EditModalFormLabel>メールアドレス</EditModalFormLabel>
            <TextField
              placeholder="mail.adress@gmail.com"
              {...register("email", {
                required: false,
                pattern: {
                  value:
                    /^([a-zA-Z0-9][a-zA-Z0-9_+.-]*)@([a-zA-Z0-9_.-]+)\.([a-zA-Z]{2,})$/,
                  message: "メールアドレスの形式が不正です",
                },
              })}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
            <Margin marginTop={10} />
            <Label>
              <input
                type="checkbox"
                {...register("isShare")}
                defaultChecked={isShare || false}
              />
              <Margin marginLeft={8} />
              管理者に共有を許可する
            </Label>

            <Margin marginTop={25} />
            <Flex>
              <Button
                type="button"
                color="primary"
                $empty
                size="large"
                onClick={onClose}
              >
                キャンセル
              </Button>
              <Button type="submit" color="primary" $fill size="large">
                保存
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

const FlexItem = styled.div`
  flex: 1;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
