import { useQueryClient } from "@tanstack/react-query";

export function useInvalidateAllQueries() {
  const queryClient = useQueryClient();

  function invalidateAllApiQueries() {
    queryClient.invalidateQueries({
      queryKey: ["api"],
    });
  }

  return {
    invalidateAllApiQueries,
  };
}
