import React from "react";
import { Margin } from "src/components/Margin";
import styled from "styled-components";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "src/components/Modal";
import { deleteParticipantChatRoom } from "src/apiClients/participantChatRoom";
import { ChatRoom } from "@shared/types/chatRoom";

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  chatRoom: ChatRoom;
}

export const DeleteChatRoomModal = (props: Props) => {
  const onSubmit = async () => {
    await deleteParticipantChatRoom({ chatRoomId: props.chatRoom.id });
    props.onSubmit();
  };

  return (
    <ModalPortal onClose={props.onClose}>
      <ModalHeader>
        <p>グループ退会</p>
      </ModalHeader>
      <ModalBody>
        <div>{`${props.chatRoom.name} から退会してもよろしいですか？\n退会した後は全てのメッセージが見れなくなります。`}</div>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText="退会"
        submitColor="danger"
        onSubmit={onSubmit}
        onCancel={props.onClose}
        cancelColor="subPrimary"
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
  white-space: pre-wrap;
`;
