import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as partApi from "src/apiClients/part";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { useInvalidateCurrentOrganization } from "./organization";

export function useCreatePart(organizationId: string) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const invalidateCurrentOrganization = useInvalidateCurrentOrganization();

  return useMutation({
    mutationFn: async ({
      name,
      order,
    }: {
      name: string;
      order: number | null;
    }) => {
      return await partApi.createPart({
        organizationId,
        name,
        order,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "part", organizationId],
      });
      invalidateCurrentOrganization();
      toast.success("役職を作成しました");
    },
    onError: (err) => {
      logger.error(err);
      toast.error("役職の作成に失敗しました");
      throw err;
    },
  });
}

export function useUpdatePart() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const invalidateCurrentOrganization = useInvalidateCurrentOrganization();
  return useMutation({
    mutationFn: async ({
      partId,
      name,
      order,
    }: {
      partId: string;
      name: string;
      order: number | null;
    }) => {
      return await partApi.updatePart({
        partId,
        name,
        order,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "part"],
      });
      invalidateCurrentOrganization();
    },
    onError: (err) => {
      logger.error(err);
      toast.error("役職の更新に失敗しました");
      throw err;
    },
  });
}

export function useDeletePart() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const invalidateCurrentOrganization = useInvalidateCurrentOrganization();
  return useMutation({
    mutationFn: async ({ partId }: { partId: string }) => {
      return await partApi.deletePart({ partId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "part"],
      });
      invalidateCurrentOrganization();
      toast.success("役職を削除しました");
    },
    onError: (err) => {
      logger.error(err);
      toast.error("役職の削除に失敗しました");
      throw err;
    },
  });
}

export function useParts(organizationId: string) {
  const { mutateAsync: createPart } = useCreatePart(organizationId);
  const { mutateAsync: updatePart } = useUpdatePart();
  const { mutateAsync: deletePart } = useDeletePart();

  return { createPart, updatePart, deletePart };
}
