import { z } from "zod";
import { zHiraganaString, zRequiredString } from "../../validator/rules/string";
import { enumTargetClassesType } from "../../validator/rules/child";

export const csChildUpdateQuerySchema = z.array(
  z
    .object({
      id: z.string().optional(),
      accountId: zRequiredString,
      childLastName: zRequiredString,
      childFirstName: zRequiredString,
      childLastNameKana: zHiraganaString,
      childFirstNameKana: zHiraganaString,
      communityId: z.string().min(1, "入力必須項目です").nullable(),
      schoolCode: z.string().optional(),
      grade: z
        .union([
          z
            .number()
            .min(1, { message: "学年は1以上の数字を入力してください" })
            .max(12)
            .nullable(),
          z.number(),
        ])
        .superRefine((value, ctx) => {
          if (value === null) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "入力必須項目です",
            });
          }
        }),
      class: z
        .nativeEnum(enumTargetClassesType)
        .nullable()
        .superRefine((value, ctx) => {
          if (value === null) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "入力必須項目です",
            });
          }
        }),
    })
    .refine(
      (args) => {
        const { grade, schoolCode } = args;
        if (schoolCode && schoolCode.includes("C") && grade && grade > 3) {
          return false;
        }
        return true;
      },
      {
        message: "中学校の場合は3以下である必要があります",
        path: ["grade"],
      }
    )
);
export type CsChildUpdateQueryType = z.infer<typeof csChildUpdateQuerySchema>;

export const getChildrenByAccountIdSchema = z.object({
  accountId: zRequiredString,
});

export type GetChildrenByAccountIdSchemaType = z.infer<
  typeof getChildrenByAccountIdSchema
>;

export const deleteChildrenSchema = z.object({
  accountId: zRequiredString,
});

export type DeleteChildrenSchemaType = z.infer<typeof deleteChildrenSchema>;
