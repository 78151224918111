import React, { useMemo, useRef } from "react";
import { useMessageList } from "src/features/BoardEducation/ChatMessage/hooks/useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import {
  ChatRoom,
  IndividualChatWithOrganization,
} from "@shared/types/chatRoom";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { ChatRoomOrigin } from "@shared/types/chatRoom";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import {
  ChatRoomContainer,
  ChatRoomInfo,
  ChatBox,
  FloatWrapper,
  StatusBadge,
  HeaderTitle,
  SubTitle,
  SearchInputWrapper,
  FloatDateLabel,
  IndividualHeader,
  IndividualHeaderFlexWrapper,
} from "src/features/common/ChatMessage/components/ChatRoomContent/ChatRoomContentStyled";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoom;
  individualChatWithOrganization: IndividualChatWithOrganization;
  communityName: string;
  lastReadAt: Date;
};

export const ChatRoomIndividualContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const { displayItemList, lastReadAt } = useMessageList({
    initLastReadAt: props.lastReadAt,
    messageListRef,
    chatRoomParticipantId: props.chatRoomParticipantId,
  });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  if (!displayItemList) return null;
  if (props.chatRoom.approvalStatus === "REJECTED") return null;

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <IndividualHeader>
          <IndividualHeaderFlexWrapper>
            <ChatRoomBackButton targetRoute="BE_CHATROOM_LIST" />
            <HeaderTitle>
              {props.individualChatWithOrganization.account.name}
            </HeaderTitle>
            {props.chatRoom.origin === ChatRoomOrigin.QRCODE && (
              <StatusBadge $color="#F9AB01">QRコードから連絡</StatusBadge>
            )}
          </IndividualHeaderFlexWrapper>
          <SearchInputWrapper>
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              onPrevResult={handlePrevResult}
              onNextResult={handleNextResult}
              resultCount={searchResults.length}
            />
          </SearchInputWrapper>
        </IndividualHeader>
        <SubTitle>{props.communityName}</SubTitle>
      </ChatRoomInfo>
      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    searchText={searchText}
                    participantId={props.chatRoomParticipantId}
                    lastReadAt={lastReadAt}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
            }
          })}
        </ChatMessages>
      </ChatBox>
    </ChatRoomContainer>
  );
};
