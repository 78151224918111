import React, { useCallback, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import styled from "styled-components";
import { LoadingCard } from "src/components/LoadingCard";
import { GetPTAInvoiceProductListResponseType } from "@shared/validator/features/ptaInvoiceProduct.schema";

interface DeleteInvoiceProductModalProps {
  invoiceProduct: GetPTAInvoiceProductListResponseType;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

export const DeleteInvoiceProductModal = ({
  invoiceProduct,
  onSubmit,
  onClose,
}: DeleteInvoiceProductModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit]);

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>請求項目の削除</ModalHeader>

      <ModalBody>
        <ModalContent>
          <Description>この請求項目を削除してもよろしいですか？</Description>

          <InfoSection>
            <Label>請求項目名</Label>
            <InfoText>{invoiceProduct.name}</InfoText>
          </InfoSection>

          <InfoSection>
            <Label>支払期日</Label>
            <InfoText>
              {new Date(invoiceProduct.dueDate).toLocaleDateString()}
            </InfoText>
          </InfoSection>

          {isSubmitting ? (
            <LoadingCard />
          ) : (
            <ModalSubmitButtons
              submitColor="danger"
              submitText="削除する"
              onSubmit={handleSubmit}
              onCancel={onClose}
            />
          )}
        </ModalContent>
      </ModalBody>
    </ModalPortal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #343741;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;
