import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { Avatar } from "src/components/Common/Avatar";
import { ChatMessageForView } from "src/apiClients/chatMessage";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import styled, { css } from "styled-components";
import { shadow } from "src/theme";
import { usePolyfitHistory } from "src/hooks/router";
import { Button } from "src/components/Button";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isBeAdminRole, isCsAdminRole } from "src/utils/types/role";

type Props = {
  message: ChatMessageForView;
  isMyMessage: boolean;
};

const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export const ChatMessageAvatar = ({ message, isMyMessage }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = usePolyfitHistory();
  const user = useCurrentUser();

  const isAdmin: boolean =
    isBeAdminRole(user?.role) || isCsAdminRole(user?.role);

  const userNameSub: string = isBeAdminRole(message.account?.role.name)
    ? "(教育委員会)"
    : isCsAdminRole(message.account?.role.name)
    ? "(管理者)"
    : "";

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const onClickUserLink = () => {
    history.push({
      to: "PTA_LIST_MEMBER",
      query: {
        userId: message.createdBy,
      },
    });
  };

  return (
    <>
      {!isOpen && (
        <Tooltip
          id={`avatar-tooltip-${message.id}`}
          place="bottom-start"
          content={message.user.name + userNameSub}
          style={{ background: "#616161", fontSize: "13px" }}
          arrowColor="white"
        />
      )}
      <AvatarButton
        data-tooltip-id={`avatar-tooltip-${message.id}`}
        onClick={handleClick}
      >
        <Avatar
          src={message.user.picture ?? defaultUserImg}
          alt={message.user.name}
          size={32}
        />
        {isOpen && (
          <div>
            <PopupContainer $isMyMessage={isMyMessage}>
              <PopupTopWrapper>
                <Avatar
                  src={message.user.picture ?? defaultUserImg}
                  alt={message.user.name}
                  size={48}
                />
                <PopupUserName>{message.user.name + userNameSub}</PopupUserName>
              </PopupTopWrapper>
              {isAdmin && message.account.role.name !== "BEAdmin" && (
                <PopupBottomWrapper>
                  <Button
                    color="primary"
                    $fill
                    size="small"
                    width="100%"
                    onClick={onClickUserLink}
                  >
                    メンバー詳細
                  </Button>
                </PopupBottomWrapper>
              )}
            </PopupContainer>
            <Overlay onClick={() => setIsOpen(false)} />
          </div>
        )}
      </AvatarButton>
    </>
  );
};

const AvatarButton = styled.button`
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

const PopupContainer = styled.div<{ $isMyMessage: boolean }>`
  position: absolute;
  z-index: 100;
  ${({ $isMyMessage }) =>
    $isMyMessage
      ? css`
          right: 5px;
        `
      : css`
          left: 5px;
        `}
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 8px;
  background-color: white;
  box-shadow: ${shadow.bottomSmall};
  overflow: hidden;
`;

const PopupTopWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const PopupUserName = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 200px;
  text-align: left;
`;

const PopupBottomWrapper = styled.div`
  margin-top: 8px;
  border-top: 1px solid #e0e0e0;
  padding-top: 8px;
  display: flex;
  gap: 8px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 99;
`;
