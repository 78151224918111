import React from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import styled from "styled-components";
import { Heading3 } from "../../../components/Typo";
import { APIError } from "../../../utils/types/ApiError";
import { useToast } from "../../../components/Toast";
import { ResidentUserDetail } from "@shared/types/boardEducation/residentUser";
import { deleteAccountById } from "src/apiClients/boardEducation/auth";
import { useGetManagedOrganization } from "src/hooks/query/boardEducation/organizations/organization";
import { useInvalidateAllQueries } from "src/hooks/query/utils/useInvalidateAll";

type LeaveModalProps = {
  account: ResidentUserDetail;
  onClose: () => void;
  isLastAdminUser: boolean;
  currentCommunityId: string;
};

export const BELeaveModal = ({
  account,
  onClose,
  isLastAdminUser,
  currentCommunityId,
}: LeaveModalProps) => {
  const history = usePolyfitHistory();
  const toast = useToast();
  const { organization } = useGetManagedOrganization();
  const { invalidateAllApiQueries } = useInvalidateAllQueries();

  const onSubmit = async () => {
    try {
      // 権限を変更処理
      await deleteAccountById(account.id, currentCommunityId);
      invalidateAllApiQueries();
      onClose();
      history.push({ to: "BE_ADMIN_MEMBER_LIST" });
    } catch (err) {
      console.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
        onClose();
        return;
      }
    }
  };
  const user = account.user;
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>退会</ModalHeader>
      <ModalBody>
        {isLastAdminUser ? (
          <>
            <Message>
              管理者が不在の場合、管理者を新規で増やすことができません。
              <br />
              以後、新規招待や退会処理ができなくなってしまいます。
              <br />
              他の方を管理者権限に移行してください。
            </Message>
          </>
        ) : (
          <>
            <Message>
              {user.name}さんを退会させます。
              <br />
              {`退会させると、メンバーが${
                organization?.name || "組織"
              }の情報にアクセスできなくなります。`}
            </Message>
            <ModalSubmitButtons
              // disabled={memberUser.role === role}
              submitText="退会"
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          </>
        )}
      </ModalBody>
    </ModalPortal>
  );
};

const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
`;

const Message = styled.div`
  padding: 16px 0;
  max-width: 432px;
`;
