import { SchoolType } from "../types/organization";
import { GradeByAmountsSchemaType } from "../validator/models/invoice/ptaInvoiceProduct.schema";
import { getGradesBySchoolType } from "./getGradesBySchoolType";

export const getIsAmountByGradeValid = (
  amountsByGrade: GradeByAmountsSchemaType,
  schoolType: SchoolType | undefined
) => {
  if (!schoolType) return false;
  const grades = getGradesBySchoolType(schoolType);
  const gradeOptions = grades.map((grade) => ({
    id: grade,
    name: grade.toString(),
  }));
  return (
    amountsByGrade.length === gradeOptions.length &&
    amountsByGrade.every((amountByGrade) =>
      gradeOptions.some((grade) => grade.id === amountByGrade.grade)
    )
  );
};
