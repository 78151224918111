import React, { useMemo, useState } from "react";
import { useToast } from "../../../components/Toast";
import {
  ModalPortal,
  ModalBody,
  ModalRemoveButtons,
} from "../../../components/Modal";
import {
  ModalWrapper,
  ModalHeader,
} from "../../../pages/pta/admin/ProfilePage";
import styled from "styled-components";
import { Reauthentication } from "./Reauthentication";
import { User } from "firebase/auth";
import { verifyBeforeUpdateEmail } from "src/utils/firebase";
import * as theme from "../../../theme";
import { StyledInputLabel } from "src/components/Form";
import { Margin } from "src/components/Margin";
import { FirebaseError } from "firebase/app";
import { Button } from "src/components/Button";
import { BREAKPOINTS } from "src/components/Responsive";

type Props = {
  firebaseUser: User;
  isOpen: boolean;
  isFromBaseInfo?: boolean;
  close: () => void;
  hasPasswordProvider?: boolean;
  openSetPasswordModal?: () => void;
};

export const ChangeEmailModal = ({
  firebaseUser,
  isOpen,
  close,
  isFromBaseInfo = false,
  hasPasswordProvider = true,
  openSetPasswordModal = () => {},
}: Props) => {
  const toast = useToast();
  const [reauthenticated, setReauthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const errorMessage = useMemo(() => {
    if (!email) {
      return "続行するにはメールアドレスを入力してください";
    }
    return "";
  }, [email]);

  const handleSend = async () => {
    if (errorMessage || isSending) return;
    setIsSending(true);

    try {
      await verifyBeforeUpdateEmail(firebaseUser, email);
      setIsSent(true);
    } catch (err) {
      if (
        err instanceof FirebaseError &&
        err.code === "auth/email-already-in-use"
      ) {
        toast.error("メールアドレスが既に使用されています");
      } else {
        toast.error("メールアドレスの変更に失敗しました");
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>メールアドレスの変更</ModalHeader>
            {!reauthenticated ? (
              <Reauthentication
                title="メールアドレスの変更には再ログインが必要です"
                firebaseUser={firebaseUser}
                onCancel={close}
                onOk={() => setReauthenticated(true)}
              />
            ) : (
              <>
                <ModalBody>
                  {!isSent ? (
                    <>
                      <FormItem>
                        <StyledInputLabel>
                          新しいメールアドレス
                        </StyledInputLabel>
                        <StyledInput
                          value={email}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setEmail(e.target.value)
                          }
                          type="text"
                          name="email"
                          placeholder="メールアドレスを入力"
                        />
                      </FormItem>

                      <Margin marginTop={20} />
                      <ModalRemoveButtons
                        disabled={!!errorMessage || isSending}
                        submitText="確認メールを送信"
                        onSubmit={handleSend}
                        onCancel={close}
                      />
                    </>
                  ) : (
                    <div>
                      <p>
                        <strong>{email}</strong> に認証リンクを送信しました。
                      </p>
                      <p>
                        メールを確認し、リンクをクリックしてメールアドレスの変更を完了してください。
                      </p>
                      {isFromBaseInfo && (
                        <ButtonWrapper>
                          <Button
                            size="small"
                            color="subPrimary"
                            onClick={close}
                          >
                            情報編集画面に戻る
                          </Button>
                        </ButtonWrapper>
                      )}
                      {!isFromBaseInfo && !hasPasswordProvider && (
                        <SettingPasswordSuggestSection>
                          <SettingPasswordSuggestTitle>
                            パスワードログインを有効化しませんか？
                          </SettingPasswordSuggestTitle>
                          <SettingPasswordSuggestDescription>
                            メールアドレスとパスワードでもログインができるようになります。
                            <br />
                            Googleアカウントでのログインも引き続きご利用いただけます。
                          </SettingPasswordSuggestDescription>
                          <ButtonWrapper>
                            <Button
                              size="small"
                              color="primary"
                              $fill
                              onClick={() => {
                                close();
                                openSetPasswordModal();
                              }}
                            >
                              パスワードを設定
                            </Button>
                            <Button
                              size="small"
                              color="subPrimary"
                              onClick={() => {
                                close();
                              }}
                            >
                              今は設定しない
                            </Button>
                          </ButtonWrapper>
                        </SettingPasswordSuggestSection>
                      )}
                    </div>
                  )}
                </ModalBody>
              </>
            )}
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};

const FormItem = styled.div`
  margin: 0 0 24px;
`;

const StyledInput = styled.input`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(105, 112, 125, 0.2);
  color: ${theme.colorTokens.text};
`;
const ButtonWrapper = styled.div`
  margin-top: 16px;
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
  }
`;

const SettingPasswordSuggestSection = styled.div`
  margin-top: 24px;
`;

const SettingPasswordSuggestTitle = styled.div`
  font-weight: 700;
`;
const SettingPasswordSuggestDescription = styled.p`
  font-size: 14px;
`;
