import React, { useState } from "react";
import { useLoginUser } from "../../../hooks/recoil/user";
import { isInternalRoleAdmin } from "@shared/utils/internalUserRole";
import { TextField } from "src/components/Form";
import {
  deleteUnverifiedUser,
  deleteUserFromBounceList,
  getUnverifiedUserByEmail,
} from "src/apiClients/unverified";
import { Button } from "src/components/Button";
import styled from "styled-components";
import { colorsPallet } from "src/theme";
import { UnverifiedUserStatus } from "@shared/types/unverified";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import BounceDeleteModal from "src/features/Internal/Unverified/BounceDeleteModal";
import UnverifiedUserCard from "src/features/Internal/Unverified/UnverifiedUserCard";
import UserDeleteModal from "src/features/Internal/Unverified/UserDeleteModal";

function DeleteUser() {
  const { loginUser } = useLoginUser();

  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [unverifiedUserStatus, setUnverifiedUserStatus] =
    useState<UnverifiedUserStatus>(null);
  const [isBounceDeleteModalOpen, setIsBounceDeleteModalOpen] =
    useState<boolean>(false);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] =
    useState<boolean>(false);

  const toast = useToast();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setEmail(searchText);
  };
  const reset = () => {
    setUnverifiedUserStatus(null);
    setIsSearched(false);
    setEmail("");
  };
  const handleBounceDelete = async () => {
    if (!unverifiedUserStatus?.user?.id) {
      return;
    }
    try {
      await deleteUserFromBounceList({
        userId: unverifiedUserStatus?.user?.id,
      });
      toast.success("ユーザーの配信停止解除に成功しました");
      searchUser();
    } catch (error) {
      if (error instanceof APIError) {
        toast.error(error.message);
      }
    } finally {
      setIsBounceDeleteModalOpen(false);
    }
  };
  const handleUserDelete = async () => {
    if (!unverifiedUserStatus?.user?.id) {
      return;
    }
    try {
      await deleteUnverifiedUser({ userId: unverifiedUserStatus?.user?.id });
      toast.success("ユーザーの削除に成功しました");
      reset();
    } catch (error) {
      if (error instanceof APIError) {
        toast.error(error.message);
      }
    } finally {
      setIsUserDeleteModalOpen(false);
    }
  };
  const searchUser = async () => {
    setIsLoading(true);
    const unverifiedUserStatus = await getUnverifiedUserByEmail({ email });
    setUnverifiedUserStatus(unverifiedUserStatus);
    setIsLoading(false);
    setIsSearched(true);
  };

  if (!isInternalRoleAdmin(loginUser?.internalRole)) {
    return <div>アクセス権限がありません</div>;
  }

  return (
    <div>
      <h2>メール未認証ユーザー削除</h2>
      <h3 style={{ marginTop: "8px" }}>ユーザーを検索</h3>
      <SearchArea>
        <TextField
          id="search-text-field"
          type="text"
          value={email}
          onChange={onChange}
          placeholder={"メールアドレスで未認証ユーザーを検索"}
        />
        <ButtonWrapper>
          <Button
            onClick={searchUser}
            $fill
            color="primary"
            size="large"
            disabled={!email}
          >
            検索
          </Button>
        </ButtonWrapper>
      </SearchArea>
      {isLoading && <IsSearching>検索中...</IsSearching>}
      {isSearched && !isLoading && (
        <div>
          {unverifiedUserStatus ? (
            <UnverifiedUserCard
              unverifiedUserStatus={unverifiedUserStatus}
              setIsBounceDeleteModalOpen={setIsBounceDeleteModalOpen}
              setIsUserDeleteModalOpen={setIsUserDeleteModalOpen}
            />
          ) : (
            <NotUserFound>
              該当するユーザーは見つかりませんでした。メールアドレスが間違っているか、
              既にユーザーのメール認証が完了した可能性があります。
            </NotUserFound>
          )}
        </div>
      )}
      {isBounceDeleteModalOpen && (
        <BounceDeleteModal
          onClose={() => setIsBounceDeleteModalOpen(false)}
          onConfirm={handleBounceDelete}
        />
      )}
      {isUserDeleteModalOpen && (
        <UserDeleteModal
          onClose={() => setIsUserDeleteModalOpen(false)}
          onConfirm={handleUserDelete}
        />
      )}
    </div>
  );
}

export default DeleteUser;

const SearchArea = styled.div`
  margin-top: 8px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 4px;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;

const IsSearching = styled.div`
  margin-top: 4px;
  width: 100%;
  display: grid;
  place-items: center;
  color: ${colorsPallet.darkShade};
`;

const NotUserFound = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #fff2f2;
  border: 1px solid #ffd1d1;
  border-radius: 4px;
  color: #dc2626;
  font-size: 14px;
`;
