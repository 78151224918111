import React, { useEffect, useState } from "react";
import { PlusIcon } from "../../../components/icons/PlusIcon";
import styled from "styled-components";
import {
  Table,
  TableHeader,
  TableHeaderItem,
  TableBody,
  TableRow,
  TableData,
} from "../../../components/Table2";
import { useCustomReactHookForm } from "../../../components/form/ReactHookForm";
import { Margin } from "../../../components/Margin";
import {
  useModal,
  ModalPortal,
  ModalHeader,
  ModalBody,
  ModalRemoveButtons,
} from "../../../components/Modal";
import { Trash } from "../../../components/icons/Trash";
import { SubTitle, Title } from "../../../components/Title";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Belong } from "@shared/types/belong";
import { Part } from "@shared/types/part";
import { GripVertical } from "src/components/icons/GripVertical";
import { useBelongs } from "src/hooks/query/belong";
import { useParts } from "src/hooks/query/part";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { logger } from "src/utils/logger";

const RemoveBelongPartAlertModal = ({
  onClose,
  isOpen,
  removeBelong,
  setRemoveBelong,
  removePart,
  setRemovePart,
}: {
  isOpen: boolean;
  onClose: () => void;
  removeBelong: { id: string; canRemove: boolean };
  setRemoveBelong: (arg: { id: string; canRemove: boolean }) => void;
  removePart: { id: string; canRemove: boolean };
  setRemovePart: (arg: { id: string; canRemove: boolean }) => void;
}) => {
  const handleOnSubmit = () => {
    if (removeBelong.id) {
      setRemoveBelong({ id: removeBelong.id, canRemove: true });
    } else if (removePart.id) {
      setRemovePart({ id: removePart.id, canRemove: true });
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={onClose}>
          <ModalHeader>
            <StyledFlex>削除</StyledFlex>
          </ModalHeader>
          <ModalBody>
            所属役職を削除します。
            <br />
            削除すると、メンバーは自動的に所属なしに移動します。
            <ModalRemoveButtons
              disabled={false}
              submitText="削除"
              onSubmit={handleOnSubmit}
              onCancel={onClose}
            />
          </ModalBody>
        </ModalPortal>
      )}
    </>
  );
};

type OrganizationSettingArgs = {
  belong: string;
  part: string;
};

type SortableRow = {
  belong: Belong | undefined;
  part: Part | undefined;
  show: () => void;
  setRemove: React.Dispatch<
    React.SetStateAction<{
      id: string;
      canRemove: boolean;
    }>
  >;
};

const SortableRow = ({
  belong,
  part = undefined,
  show,
  setRemove,
}: SortableRow) => {
  const SortableRowContent = (obj: Belong | Part) => {
    const id = obj.id;
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const rowStyle = {
      transform: CSS.Transform.toString(transform),
      transition,
      display: "grid",
      gridTemplateColumns: "1fr",
      zIndex: 100,
      cursor: "move",

      // とても不本意だが、ヘッダーとずれてしまうので対策する
      width: "100% + 1px",
      marginLeft: "-1px",
    };
    const handleStyle = {
      width: "100%",
      display: "flex",
      alignItems: "center",
    };

    return (
      <span ref={setNodeRef} style={rowStyle}>
        <TableRow>
          <TableDataExtend>
            <HoverHandle {...attributes} {...listeners} style={handleStyle}>
              <HoverWrap className="grip-vertical">
                <GripVertical size={16} />
              </HoverWrap>
              {obj.name}
            </HoverHandle>
            <IconButton
              onClick={() => {
                show();
                setRemove({
                  id: obj.id,
                  canRemove: false,
                });
              }}
            >
              <Trash size={16} />
            </IconButton>
          </TableDataExtend>
        </TableRow>
      </span>
    );
  };
  if (belong) {
    return SortableRowContent(belong);
  }
  if (part) {
    return SortableRowContent(part);
  }
  return;
};

export default function PTAOrganizationSettingPage() {
  const { TextField, Form, errors, getValues, setValue, trigger, clearErrors } =
    useCustomReactHookForm<OrganizationSettingArgs>();
  const { organization } = useGetCurrentOrganization();
  const { createBelong, deleteBelong, updateBelong } = useBelongs(
    organization?.id ?? ""
  );
  const { createPart, deletePart, updatePart } = useParts(
    organization?.id ?? ""
  );
  const [isShown, { show, close }] = useModal();

  const [removeBelong, setRemoveBelong] = useState<{
    id: string;
    canRemove: boolean;
  }>({ id: "", canRemove: false });

  const [removePart, setRemovePart] = useState<{
    id: string;
    canRemove: boolean;
  }>({ id: "", canRemove: false });

  const onSubmitBelong = async () => {
    clearErrors();
    const result = await trigger("belong");
    if (result) {
      await createBelong({
        name: getValues("belong") ?? "",
        order: belongs.length + 1,
      });
      setValue("belong", "");
    }
  };
  const onSubmitPart = async () => {
    clearErrors();
    const result = await trigger("part");
    if (result) {
      await createPart({
        name: getValues("part") ?? "",
        order: parts.length + 1,
      });
      setValue("part", "");
    }
  };

  useEffect(() => {
    if (removeBelong.canRemove) {
      try {
        deleteBelong({ belongId: removeBelong.id });
        setRemoveBelong({ id: "", canRemove: false });
        close();
      } catch (error) {
        logger.error(error);
      }
    }
  }, [removeBelong]);

  useEffect(() => {
    if (removePart.canRemove) {
      try {
        deletePart({ partId: removePart.id });
        setRemovePart({ id: "", canRemove: false });
        close();
      } catch (error) {
        logger.error(error);
      }
    }
  }, [removePart]);

  const [belongs, setBelongs] = useState(organization?.belongs ?? []);
  const [parts, setParts] = useState(organization?.parts ?? []);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  useEffect(() => {
    if (organization && organization.belongs) {
      const sortedBelongs = [...organization.belongs].sort(
        (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity)
      );
      setBelongs(sortedBelongs);
    }
  }, [organization]);
  useEffect(() => {
    if (organization && organization.parts) {
      const sortedParts = [...organization.parts].sort(
        (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity)
      );
      setParts(sortedParts);
    }
  }, [organization]);
  const handleBelongDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setBelongs((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        const newItems = arrayMove(items, oldIndex, newIndex);

        newItems.forEach((item, index) => {
          updateBelong({
            belongId: item.id,
            name: item.name,
            order: index + 1,
          });
        });

        return newItems;
      });
    }
  };
  const handlePartDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setParts((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over?.id);

        const newItems = arrayMove(items, oldIndex, newIndex);

        newItems.forEach((item, index) => {
          updatePart({
            partId: item.id,
            name: item.name,
            order: index + 1,
          });
        });

        return newItems;
      });
    }
  };

  return (
    <>
      <StyledContainer>
        <Margin marginTop={10} />
        <Title>所属役職の設定</Title>
        <SubTitle>
          本部や委員会、会長や書記など所属・役職を設定できます
        </SubTitle>
        <Margin marginTop={15} />
        <FormWrapper>
          <Form>
            <Table className="table-belongs">
              <TableHeader>
                <TableHeaderItem>所属</TableHeaderItem>
              </TableHeader>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleBelongDragEnd}
              >
                <SortableContext
                  items={belongs.map((b) => b.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <TableBody>
                    {organization &&
                      belongs.map((item) => (
                        <SortableRow
                          key={item.id}
                          belong={item}
                          part={undefined}
                          show={show}
                          setRemove={setRemoveBelong}
                        ></SortableRow>
                      ))}
                    <TableRow>
                      <TableData className="td-input">
                        <TextFieldWrapper>
                          <TextField
                            placeholder="例) 本部、委員会"
                            name="belong"
                            options={{
                              required: "入力してから追加してください",
                              deps: ["belong"],
                            }}
                            isDanger={Boolean(errors.belong)}
                            className="text-field"
                          ></TextField>
                        </TextFieldWrapper>
                      </TableData>
                    </TableRow>
                  </TableBody>
                </SortableContext>
              </DndContext>
            </Table>
            <div className="error-wrapper">
              {errors.belong && (
                <span className="error-message">{errors.belong.message}</span>
              )}
              <button
                className="submit-button"
                type="button"
                onClick={() => onSubmitBelong()}
              >
                <IconWrapper>
                  <PlusIcon size={18} />
                  <span>追加</span>
                </IconWrapper>
              </button>
            </div>
          </Form>
          <form>
            <Table className="table-belongs">
              <TableHeader>
                <TableHeaderItem>役職</TableHeaderItem>
              </TableHeader>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handlePartDragEnd}
              >
                <SortableContext
                  items={parts.map((b) => b.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <TableBody>
                    {organization &&
                      parts.map((item) => (
                        <SortableRow
                          key={item.id}
                          belong={undefined}
                          part={item}
                          show={show}
                          setRemove={setRemovePart}
                        ></SortableRow>
                      ))}
                    <TableRow>
                      <TableData className="td-input">
                        <TextFieldWrapper>
                          <TextField
                            placeholder="例) 会長、副会長、書記"
                            name="part"
                            options={{
                              required: "入力してから追加してください",
                              deps: ["part"],
                            }}
                            isDanger={Boolean(errors.part)}
                            className="text-field"
                          ></TextField>
                        </TextFieldWrapper>
                      </TableData>
                    </TableRow>
                  </TableBody>
                </SortableContext>
              </DndContext>
            </Table>
            <div className="error-wrapper">
              {errors.part && (
                <span className="error-message">{errors.part.message}</span>
              )}
              <button
                className="submit-button"
                type="button"
                onClick={() => onSubmitPart()}
              >
                <IconWrapper>
                  <PlusIcon size={18} />
                  <span>追加</span>
                </IconWrapper>
              </button>
            </div>
          </form>
        </FormWrapper>
      </StyledContainer>

      <RemoveBelongPartAlertModal
        isOpen={isShown}
        removeBelong={removeBelong}
        setRemoveBelong={setRemoveBelong}
        onClose={close}
        removePart={removePart}
        setRemovePart={setRemovePart}
      />
    </>
  );
}

const StyledContainer = styled.div`
  padding-bottom: 88px;
  table {
    border-collapse: collapse;
    background-color: #fff;
  }
  th,
  td {
    font-size: 16px;
    font-weight: normal;
  }
  tr {
    border: 1px solid #ccd1d9;
  }
  thead tr {
    border: 1px solid #ccd1d9;
  }
  .td-input {
    padding: 0;
  }
  .submit-button {
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    padding-bottom: 8px;
  }
  .sub {
    padding: 8px 0;
  }
  .error-wrapper {
    display: flex;
    flex-direction: column;

    .error-message {
      font-size: 12px;
      color: #bd271e;
    }
  }
`;

const TextFieldWrapper = styled.div`
  .text-field {
    width: 100%;
    padding-left: 20px;
    line-height: 3rem;
    outline: none;
    border: none;
    font-size: 16px;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  .submit-button {
    border: none;
    background: transparent;
  }
  @media (max-width: 800px) {
    grid-template-columns: 100%;
    row-gap: 24px;
  }
`;

const IconWrapper = styled.div`
  width: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    padding-top: 2px;
  }
`;

const TableDataExtend = styled(TableData)`
  display: grid;
  grid-template-columns: 1fr auto;

  width: 100% !important;
  justify-content: space-between;
  span {
    // overflow-y: scroll;
  }
  position: relative;
  &.edit {
    width: 270px !important;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const IconButton = styled.button.attrs({ type: "button" })`
  border: none;
  padding-right: 8px;
  padding-left: 8px;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HoverHandle = styled.span`
  &:hover .grip-vertical {
    opacity: 100%;
  }
`;
const HoverWrap = styled.div`
  opacity: 0%;
  display: grid;
  position: center;
  margin-left: -16px;
`;
