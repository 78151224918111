import styled from "styled-components";
import { BREAKPOINTS } from "src/components/Responsive";

export const ChatRoomContainer = styled.div<{ $isOpen: boolean }>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  background: #fff;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    display: ${(props) => (props.$isOpen ? "block" : "none")};
  }
`;

export const ChatRoomInfo = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ChatBox = styled.div<{ $paddingTop: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) =>
    props.$paddingTop ? props.$paddingTop + "px 8px 0 8px" : "60px 8px 0 8px"};
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  & > button:first-child {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

export const IndividualHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: nowrap;
`;

export const IndividualHeaderFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;

  & > button.chat-back-button {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

export const StatusBadge = styled.span<{ $color: string }>`
  background: ${(props) => props.color};
  color: white;
  font-size: 12px;
  padding: 4px 4px 0px 4px;
  border-radius: 20px;
  margin-left: auto;
  max-width: 150px;
  white-space: nowrap;
`;

export const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    max-width: 100px;
  }
`;

export const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #69707d;
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  max-width: 240px;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    max-width: 150px;
  }
`;

export const FloatWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
`;

export const FloatDateLabel = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  font-weight: 600;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  line-height: 22px;
`;

export const MenuButton = styled.button`
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 6px;
  border: none;
  padding-bottom: 10px;
  cursor: pointer;
`;

export const ChatMessageInputContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChatMessageInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const LinkWrapper = styled.div`
  display: flex;
  height: 27px;
  padding: 1px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 4px;
  text-align: center;
  line-height: 18px; /* 150% */
  margin-left: auto;
  cursor: pointer;
`;

export const RecruitmentTitle = styled.div`
  display: flex;
  align-items: center;
  color: #313131;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  gap: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SearchInputWrapperForMobile = styled.div`
  width: 100%;
`;
