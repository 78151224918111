import React from "react";
import { type Control, Controller, type FieldError } from "react-hook-form";
import styled from "styled-components";
import { Label } from "src/components/form/Label";
import { Margin } from "src/components/Margin";

type Props = {
  name:
    | "mainElementarySchoolType"
    | "mainJuniorHighSchoolType"
    | "community.mainElementarySchoolType"
    | "community.mainJuniorHighSchoolType";
  fieldError?: FieldError;
  control: Control<any>;
};

export const CommunityUserTypeOptions = [
  { value: "PARENT", text: "保護者", description: "お子様が通学している方" },
  {
    value: "TEACHER",
    text: "教職員",
    description: "学校で教職員として勤務されている方",
  },
  { value: "RESIDENT", text: "地域住民", description: "校区内にお住まいの方" },
] as const;

export const CommunityUserTypeSelect = (props: Props) => {
  const label = /mainElementarySchoolType$/.test(props.name)
    ? "最寄り小学校区での役割"
    : "最寄り中学校区での役割";

  return (
    <RadioInputContainer>
      <StyledLabel>
        {label}
        <RequiredMark>※必須</RequiredMark>
      </StyledLabel>
      <RadioGroup>
        {CommunityUserTypeOptions.map((option, i) => (
          <Controller
            key={i}
            name={props.name}
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <RadioCard $isSelected={value === option.value}>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    checked={value === option.value}
                    onChange={() => onChange(option.value)}
                  />
                  <RadioContent>
                    <RadioTitle>{option.text}</RadioTitle>
                    <RadioDescription>{option.description}</RadioDescription>
                  </RadioContent>
                </RadioLabel>
              </RadioCard>
            )}
          />
        ))}
      </RadioGroup>
      {props.fieldError ? (
        <ErrorMessage>{props.fieldError.message}</ErrorMessage>
      ) : (
        <Margin marginTop={8} />
      )}
    </RadioInputContainer>
  );
};

const RadioInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #1a1c21;
  margin-top: 16px;
`;

const StyledLabel = styled(Label)`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
`;

const RequiredMark = styled.span`
  color: #be6464;
  margin-left: 4px;
  font-size: 12px;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RadioCard = styled.div<{ $isSelected: boolean }>`
  background: ${(props) => (props.$isSelected ? "#e8f0fe" : "white")};
  border: 1px solid ${(props) => (props.$isSelected ? "#4285f4" : "#e8e8e8")};
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;

  &:hover {
    border-color: #4285f4;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  gap: 12px;
`;

const RadioInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  margin-top: 2px;
  position: relative;
  cursor: pointer;

  &:checked {
    border-color: #4285f4;
    background: white;

    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #4285f4;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    border-color: #4285f4;
  }
`;

const RadioContent = styled.div`
  flex: 1;
`;

const RadioTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
`;

const RadioDescription = styled.div`
  font-size: 12px;
  color: #666;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #be6464;
`;
