import { Role } from "./role";
import { User } from "./user";

// chatMessage 内で system が出した chatMessage を判別するための participantId
export const UNIQUE_INTERNAL_PARTICIPANT_IDS = {
  SYSTEM: "system_participant_id",
} as const;

export type UNIQUE_INTERNAL_PARTICIPANT_IDS =
  (typeof UNIQUE_INTERNAL_PARTICIPANT_IDS)[keyof typeof UNIQUE_INTERNAL_PARTICIPANT_IDS];

export type CreateChatMessageRequest = {
  chatRoomId: string;
  content: string;
};

export type ChatMessage = PlainChatMessage | ChatMessageWithHeader;

export type ChatMessageBase = {
  id: string;
  chatRoomId: string;
  participantId: string;
  content: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  enabledAt: string;
  account: {
    role: {
      id: string;
      name: Role;
    };
  };
};

export type PlainChatMessage = ChatMessageBase & {
  type: "PLAIN";
};

export type ChatMessageWithHeader = ChatMessageBase & {
  type: "WITH_HEADER";
  headerText: string;
  headerBackgroundColor: string;
};

export type ChatMessageFile = {
  id: string;
  path: string;
  name: string;
  type: "IMAGE" | "PDF";
};

export type ChatMessageForView = ChatMessage & {
  user: Pick<User, "picture" | "name">;
  files: ChatMessageFile[];
};
