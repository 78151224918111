import * as client from "./client";
import {
  PTAParentFamily,
  PTAParentFamilyBaseInfo,
} from "@shared/types/ptaParentFamily";

export const getPTAParentFamily = async (): Promise<PTAParentFamily | null> => {
  return await client.get("/ptaParentFamily/getByCurrentAccount", {});
};

export const getPTAParentFamilyBaseInfo =
  async (): Promise<PTAParentFamilyBaseInfo> => {
    return await client.get("/ptaParentFamily/getBaseInfoByCurrentAccount", {});
  };
