import React, { useEffect, useState } from "react";
import { Label } from "src/components/form/Label";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { RecruitmentFormValue } from "./useRecruitmentForm";
import styled from "styled-components";
import { DangerText } from "src/features/Post/CreatePostModal";
import { toLocalDateTimeFormat } from "src/utils/time";

type Props = {
  defaultValue: string | null;
  errors: FieldErrors<RecruitmentFormValue>;
  register: UseFormRegister<RecruitmentFormValue>;
  setValue: UseFormSetValue<RecruitmentFormValue>;
  trigger: UseFormTrigger<RecruitmentFormValue>;
};

export const RecruitmentFormDeadlineInput = ({
  defaultValue,
  errors,
  register,
  setValue,
  trigger,
}: Props) => {
  const [tmpValue, setTmpValue] = useState<string | null>(
    defaultValue ? toLocalDateTimeFormat(new Date(defaultValue)) : null
  );

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    setTmpValue(date ? toLocalDateTimeFormat(date) : null);
  };

  const watchDeadlineEffect = () => {
    if (tmpValue || tmpValue === null) {
      setValue("deadline", tmpValue ? tmpValue : null);
      trigger("deadline");
    }
  };

  useEffect(() => {
    watchDeadlineEffect();
  }, [tmpValue]);

  return (
    <div>
      <Label size="s" optional>
        締切日
      </Label>

      <InlineDateWrapper>
        <DateTimePickerField
          type="datetime-local"
          id="deadline"
          value={tmpValue || ""}
          {...register("deadline")}
          onChange={onChangeText}
        />
      </InlineDateWrapper>

      {errors.deadline && <DangerText>{errors.deadline.message}</DangerText>}
    </div>
  );
};

export const TextButton = styled.div`
  margin-left: auto;
  color: #005ec4;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
`;

const InlineDateWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const DateTimePickerField = styled.input`
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  padding-left: 40px;
  ::placeholder {
    color: #aab4c4;
  }
`;
