import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as belongApi from "src/apiClients/belong";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { useInvalidateCurrentOrganization } from "./organization";
export function useCreateBelong(organizationId: string) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const invalidateCurrentOrganization = useInvalidateCurrentOrganization();

  return useMutation({
    mutationFn: async ({
      name,
      order,
    }: {
      name: string;
      order: number | null;
    }) => {
      return await belongApi.createBelong({
        organizationId,
        name,
        order,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "belong", organizationId],
      });
      invalidateCurrentOrganization();
      toast.success("所属を作成しました");
    },
    onError: (err) => {
      logger.error(err);
      toast.error("所属の作成に失敗しました");
      throw err;
    },
  });
}

export function useUpdateBelong() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const invalidateCurrentOrganization = useInvalidateCurrentOrganization();
  return useMutation({
    mutationFn: async ({
      belongId,
      name,
      order,
    }: {
      belongId: string;
      name: string;
      order: number | null;
    }) => {
      return await belongApi.updateBelong({
        belongId,
        name,
        order,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "belong"],
      });
      invalidateCurrentOrganization();
    },
    onError: (err) => {
      logger.error(err);
      toast.error("所属の更新に失敗しました");
      throw err;
    },
  });
}

export function useDeleteBelong() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const invalidateCurrentOrganization = useInvalidateCurrentOrganization();
  return useMutation({
    mutationFn: async ({ belongId }: { belongId: string }) => {
      return await belongApi.deleteBelong({ belongId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["api", "belong"],
      });
      invalidateCurrentOrganization();
      toast.success("所属を削除しました");
    },
    onError: (err) => {
      logger.error(err);
      toast.error("所属の削除に失敗しました");
      throw err;
    },
  });
}

export function useBelongs(organizationId: string) {
  const { mutateAsync: createBelong } = useCreateBelong(organizationId);
  const { mutateAsync: updateBelong } = useUpdateBelong();
  const { mutateAsync: deleteBelong } = useDeleteBelong();

  return { createBelong, updateBelong, deleteBelong };
}
