import { colorsPallet } from "src/theme";
import { ApprovalStatus } from "@shared/types/chatRoom";

export const getStatusColor = (status: ApprovalStatus): string => {
  switch (status) {
    case "UNAPPROVED":
      return colorsPallet.warning;
    case "PENDING":
      return colorsPallet.mediumShade;
    case "APPROVED":
      return colorsPallet.success;
    case "REJECTED":
      return colorsPallet.danger;
  }
};
