import * as client from "./client";
import {
  CancelPTAParentFamilyInvoiceType,
  CreatePTAParentFamilyInvoiceType,
  GetInvoicesByUserIdType,
  GetPTAParentFamilyInvoiceListByProductIdType,
  PayByCashType,
  RemindPTAParentFamilyInvoiceType,
} from "@shared/validator/features/ptaParentFamilyInvoice.schema";
import {
  PTAParentFamilyInvoiceListResponseType,
  PTAParentFamilyInvoiceWithProductListType,
} from "@shared/types/ptaParentFamilyInvoice";

export async function getPTAParentFamilyInvoiceList(
  args: GetPTAParentFamilyInvoiceListByProductIdType
): Promise<PTAParentFamilyInvoiceListResponseType> {
  return await client.get<
    GetPTAParentFamilyInvoiceListByProductIdType,
    PTAParentFamilyInvoiceListResponseType
  >("/invoice/ptaParentFamilyInvoice/list", args);
}

/**
 * 請求を実行する
 */
export async function executePTAParentFamilyInvoice(
  args: CreatePTAParentFamilyInvoiceType
): Promise<void> {
  const res = await client.post<CreatePTAParentFamilyInvoiceType, void>(
    "/invoice/ptaParentFamilyInvoice/execute",
    args
  );
  return res;
}

/**
 * 指定された請求IDの請求をキャンセルする
 */
export async function cancelPTAParentFamilyInvoice(
  args: CancelPTAParentFamilyInvoiceType
): Promise<void> {
  const res = await client.post<CancelPTAParentFamilyInvoiceType, void>(
    "/invoice/ptaParentFamilyInvoice/cancel",
    args
  );
  return res;
}

/**
 * 未支払いステータスの請求に請求リマインド通知を送信する
 */
export async function remindPTAParentFamilyInvoice(
  args: RemindPTAParentFamilyInvoiceType
): Promise<void> {
  const res = await client.post<RemindPTAParentFamilyInvoiceType, void>(
    "/invoice/ptaParentFamilyInvoice/remind",
    args
  );
  return res;
}

export async function getInvoicesByUserId(
  args: GetInvoicesByUserIdType
): Promise<PTAParentFamilyInvoiceWithProductListType[]> {
  return await client.get<
    GetInvoicesByUserIdType,
    PTAParentFamilyInvoiceWithProductListType[]
  >("/invoice/ptaParentFamilyInvoice/getInvoicesByUserId", args);
}

export async function payByCash(args: PayByCashType): Promise<void> {
  return await client.post<PayByCashType, void>(
    "/invoice/ptaParentFamilyInvoice/payByCash",
    args
  );
}
