import React, { useRef } from "react";
import { Button } from "src/components/Button";
import { BreadCrumb } from "src/components/Common/BreadCrumb";
import { Header } from "src/components/Header";
import { Link } from "src/components/Link";
import { LoadingCard } from "src/components/LoadingCard";
import { RecruitmentQRCard } from "src/features/Recruitment/recruitment/qr/RecruitmentQRCard";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isInternalRoleAdmin } from "@shared/utils/internalUserRole";
import { useGetRecruitApplicationEventForQR } from "src/hooks/query/recruitments/recruitmentApplicationByEventIdForQR";

export default function ResidentRecruitmentQRCodePage() {
  const history = usePolyfitHistory();
  const { eventId } = usePolyfitLocationQuery("RESIDENT_RECRUITMENT_QR_CODE", {
    eventId: "",
  });

  const { application, isLoading, isError } =
    useGetRecruitApplicationEventForQR(eventId, {
      enabled: !!eventId,
    });
  const user = useCurrentUser();
  const isAdmin = isInternalRoleAdmin(user.internalRole);

  const handleBackToListPage = () => {
    history.push({
      to: "RESIDENT_RECRUITMENT_PARTICIPATE_LIST",
    });
  };
  const componentRef = useRef<HTMLDivElement>(null);

  const printCard = useReactToPrint({
    documentTitle: "出席用QRコード",
    contentRef: componentRef,
  });

  const handlePrint = () => {
    printCard();
  };
  if (!isAdmin) {
    return <>閲覧できません</>;
  }
  if (isLoading) {
    return (
      <>
        <Header title="出席用QRコード" />
        <LoadingCard />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <Header title="出席用QRコード" />
        <ErrorMessage>出席用QRコードの読み込みに失敗しました</ErrorMessage>
        <BackButtonContainer>
          <Button color="subPrimary" onClick={handleBackToListPage}>
            一覧に戻る
          </Button>
        </BackButtonContainer>
      </>
    );
  }

  if (application?.eventStatus.selectionStatus !== "APPROVED") {
    return <>参加が決定していないため、QRコードを表示できません</>;
  }

  return (
    <>
      <BreadCrumb>
        <Link
          to={{
            to: "RESIDENT_RECRUITMENT_PARTICIPATE_LIST",
          }}
        >
          募集
        </Link>
        <Link
          to={{
            to: "RESIDENT_RECRUITMENT_DETAIL",
            query: {
              id: application?.recruitment.id ?? "",
            },
          }}
        >
          {application?.recruitment.title}
        </Link>
        <LinkWrap>出席用QRコード</LinkWrap>
      </BreadCrumb>

      <Header title="出席用QRコード" />
      <Description>
        募集の出席に必要なQRコードです。募集当日にQRコードを提示してください。
        <br />
        募集参加証を印刷するほか、お使いの端末でこのページを表示することで出席することができます。
      </Description>
      <CardSection>
        <div ref={componentRef}>
          <RecruitmentQRCard
            recruitmentName={application?.recruitment.title ?? ""}
            schoolName={
              application?.recruitment.origin === "BOARD_EDUCATION"
                ? application?.boardEducationName ?? ""
                : application?.recruitment.community.name ?? ""
            }
            start={
              application?.eventStatus.recruitmentSchedule.start ?? new Date()
            }
            end={application?.eventStatus.recruitmentSchedule.end ?? new Date()}
            userName={application?.user.name ?? ""}
            recruitmentId={application?.recruitment.id ?? ""}
            recruitmentScheduleId={
              application?.eventStatus.recruitmentSchedule.id ?? ""
            }
            recruitApplicationEventId={eventId ?? ""}
            isBE={application?.recruitment.origin === "BOARD_EDUCATION"}
          />
        </div>
      </CardSection>
      <PrintSection>
        <Button color="primary" $fill onClick={handlePrint} size="large">
          印刷する
        </Button>
      </PrintSection>
    </>
  );
}

const PrintSection = styled.div`
  padding-bottom: 16px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 8px;
  color: #d32f2f;
  padding: 16px;
  text-align: center;
  margin: 20px 0;
`;

const BackButtonContainer = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const CardSection = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
`;

const Description = styled.div`
  width: 100%;
  font-size: 16px;
`;
