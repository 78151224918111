import React from "react";
import {
  BaseCSAdminGroupModal,
  BaseCSAdminGroupModalSubmitArgs,
} from "./BaseCSAdminGroupModal";
import { createCSAdminChatRoom } from "src/apiClients/chatRoom";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { useInvalidateChatRooms } from "src/hooks/query/chatRoomList";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

export const NewCSAdminGroupModal = ({ isOpen, onClose, refetch }: Props) => {
  const toast = useToast();
  const history = usePolyfitHistory();
  const { tab } = usePolyfitLocationQuery("RESIDENT_CHAT", {});
  const { invalidateChatRooms } = useInvalidateChatRooms();

  const handleSubmit = async (result: BaseCSAdminGroupModalSubmitArgs) => {
    const { roomName, accountIds, file } = result;

    const onChatRoomTransition = (roomId: string) => {
      history.push({
        to: "RESIDENT_CHAT",
        query: { chatRoomId: roomId, tab },
      });
    };

    try {
      const newRoom = await createCSAdminChatRoom(roomName, accountIds, file);
      toast.success("チャットルームを作成しました");
      invalidateChatRooms();
      onClose();
      onChatRoomTransition(newRoom.id);
    } catch (err) {
      logger.error(err);
      toast.error("チャットルームの作成に失敗しました");
    }
  };

  return (
    <BaseCSAdminGroupModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="管理者間メッセージ作成"
    />
  );
};
