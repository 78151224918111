import * as client from "./client";
import { PTAInvoiceProductType } from "@shared/types/ptaInvoiceProduct";
import {
  CreatePTAInvoiceProductType,
  UpdatePTAInvoiceProductType,
  GetPTAInvoiceProductType,
  GetPTAInvoiceProductListResponseType,
  DeletePTAInvoiceProductType,
  UpdatePTAInvoiceProductNameType,
} from "@shared/validator/features/ptaInvoiceProduct.schema";

export async function getPTAInvoiceProductList(): Promise<
  GetPTAInvoiceProductListResponseType[]
> {
  return await client.get<{}, GetPTAInvoiceProductListResponseType[]>(
    "/invoice/ptaInvoiceProduct/list",
    {}
  );
}

export async function getPTAInvoiceProduct(id: string) {
  return await client.get<
    GetPTAInvoiceProductType,
    PTAInvoiceProductType | null
  >("/invoice/ptaInvoiceProduct/get", { id });
}

export async function createPTAInvoiceProduct(
  data: CreatePTAInvoiceProductType
): Promise<PTAInvoiceProductType> {
  return await client.post<CreatePTAInvoiceProductType, PTAInvoiceProductType>(
    "/invoice/ptaInvoiceProduct/create",
    data
  );
}

export async function updatePTAInvoiceProduct(
  data: UpdatePTAInvoiceProductType
): Promise<PTAInvoiceProductType> {
  return await client.post<UpdatePTAInvoiceProductType, PTAInvoiceProductType>(
    "/invoice/ptaInvoiceProduct/update",
    data
  );
}

export async function deletePTAInvoiceProduct(
  data: DeletePTAInvoiceProductType
): Promise<PTAInvoiceProductType> {
  return await client.post<DeletePTAInvoiceProductType, PTAInvoiceProductType>(
    "/invoice/ptaInvoiceProduct/delete",
    data
  );
}

export async function updatePTAInvoiceProductName(
  data: UpdatePTAInvoiceProductNameType
): Promise<PTAInvoiceProductType> {
  return await client.post<
    UpdatePTAInvoiceProductNameType,
    PTAInvoiceProductType
  >("/invoice/ptaInvoiceProduct/updateName", data);
}
