import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { createChatMessage } from "src/apiClients/chatMessage";
import { updateParticipantChatRoom } from "src/apiClients/participantChatRoom";
import { useMessageList } from "src/features/Recruitment/chatMessage/hooks/useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import {
  ChatRoom,
  IndividualChatWithOrganization,
} from "@shared/types/chatRoom";
import {
  isCsAdminRole,
  isCsGuestRole,
  isCsMemberRole,
} from "src/utils/types/role";
import { useCurrentUser } from "src/hooks/recoil/user";
import { IndividualContentChatMessageInputArea } from "./IndividualContentChatMessageInputArea";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { ApprovalStatus, ChatRoomOrigin } from "@shared/types/chatRoom";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { useGetCommunityById } from "src/hooks/query/communityById";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import {
  ChatRoomContainer,
  ChatRoomInfo,
  ChatBox,
  FloatWrapper,
  HeaderTitle,
  SearchInputWrapper,
  FloatDateLabel,
  StatusBadge,
  IndividualHeader,
  IndividualHeaderFlexWrapper,
} from "src/features/common/ChatMessage/components/ChatRoomContent/ChatRoomContentStyled";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoom;
  individualChatWithOrganization: IndividualChatWithOrganization;
  lastReadAt: Date;
};

export const ChatRoomIndividualContent = (props: Props) => {
  const { community } = useGetCommunityById(props.chatRoom.communityId ?? "");
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const toast = useToast();
  const user = useCurrentUser();
  const isUnapprovedOrPending =
    (isCsMemberRole(user.role) || isCsGuestRole(user.role)) &&
    (props.chatRoom.approvalStatus === "UNAPPROVED" ||
      props.chatRoom.approvalStatus === "PENDING");
  const { displayItemList, lastReadAt, NewMessageNotification } =
    useMessageList({
      initLastReadAt: props.lastReadAt,
      messageListRef,
      chatRoomParticipantId: props.chatRoomParticipantId,
    });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
    isUnapprovedOrPending,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  // チャットルームの最終アクセス日時を更新
  useEffect(() => {
    const updateParticipantChatRoomHandler = () => {
      updateParticipantChatRoom({
        chatRoomId: props.chatRoom.id,
      });
    };

    updateParticipantChatRoomHandler();

    const interval = setInterval(updateParticipantChatRoomHandler, 10000);

    return () => {
      clearInterval(interval);
      updateParticipantChatRoomHandler();
    };
  }, [props.chatRoom]);

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const onSend = async () => {
    if (!canSend) return;
    setSending(true);
    try {
      await createChatMessage(props.chatRoom.id, input, files);
      setInput("");
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        return;
      }
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setSending(false);
    }
  };

  const canSend = useMemo(() => {
    return !sending && (input.length > 0 || files.length > 0);
  }, [sending, input, files]);

  if (!displayItemList) return null;
  if (props.chatRoom.approvalStatus === "REJECTED") return null;

  const headerTitle: string = (() => {
    if (
      props.chatRoom.origin === ChatRoomOrigin.CSADMIN ||
      (props.chatRoom.origin === ChatRoomOrigin.QRCODE &&
        props.chatRoom.approvalStatus === ApprovalStatus.APPROVED)
    ) {
      if (isCsAdminRole(user.role)) {
        return props.individualChatWithOrganization.account.name;
      } else {
        return `${community?.name} 管理者`;
      }
    } else {
      if (isCsAdminRole(user.role)) {
        return "QRCodeからの連絡";
      } else {
        return "校区別管理者への連絡";
      }
    }
  })();

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <IndividualHeader>
          <IndividualHeaderFlexWrapper>
            <ChatRoomBackButton targetRoute="RESIDENT_CHAT" />
            <HeaderTitle>{headerTitle}</HeaderTitle>
            {isCsAdminRole(user.role) &&
              props.chatRoom.origin === ChatRoomOrigin.QRCODE && (
                <StatusBadge $color="#F9AB01">QRコードから連絡</StatusBadge>
              )}
          </IndividualHeaderFlexWrapper>
          <SearchInputWrapper>
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              onPrevResult={handlePrevResult}
              onNextResult={handleNextResult}
              resultCount={searchResults.length}
            />
          </SearchInputWrapper>
        </IndividualHeader>
        {isUnapprovedOrPending && (
          <HeaderContent>
            このチャットは、学校区の管理者ユーザーと共有されます。
            また、初回の連絡が承認されない場合は、その後継続して連絡を送ることはできません。
          </HeaderContent>
        )}
      </ChatRoomInfo>
      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    searchText={searchText}
                    lastReadAt={lastReadAt}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
            }
          })}
        </ChatMessages>
        <NewMessageNotification />
        <IndividualContentChatMessageInputArea
          input={input}
          setInput={setInput}
          setFiles={setFiles}
          canSend={canSend}
          onSend={onSend}
          chatRoom={props.chatRoom}
          chatRoomParticipantId={props.chatRoomParticipantId}
          individualChatWithOrganization={props.individualChatWithOrganization}
        />
      </ChatBox>
    </ChatRoomContainer>
  );
};

const HeaderContent = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
`;
