import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import { getRecruitApplicationsByEventIdForQR } from "src/apiClients/recruitmentApplication";

export const useGetRecruitApplicationEventForQR = (
  recruitApplicationEventId: string,
  queryOptions: QueryOptions = {}
) => {
  const query = useQuery({
    queryKey: [
      "api",
      "recruitment",
      "getRecruitApplicationEventDataForQR",
      recruitApplicationEventId,
    ],
    queryFn: async () =>
      await getRecruitApplicationsByEventIdForQR({
        recruitApplicationEventId,
      }),
    ...queryOptions,
  });

  return {
    application: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};
