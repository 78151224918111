import React, { useState, useMemo, useEffect, useRef } from "react";
import ExternalLinkIcon from "src/components/icons/ExternalLinkIcon";
import { createPolyfitUrl } from "src/hooks/router";
import { createChatMessage } from "src/apiClients/chatMessage";
import { updateParticipantChatRoom } from "src/apiClients/participantChatRoom";
import { useMessageList } from "src/features/Recruitment/chatMessage/hooks/useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { ChatInputForm } from "src/components/form/ChatInputForm";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { Col, MobileViewOnly, PcViewOnly } from "src/components/Responsive";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { useCurrentUser } from "src/hooks/recoil/user";
import { ChatRoom as ChatRoomType } from "@shared/types/chatRoom";
import { updateAdminOnly } from "src/apiClients/chatRoom";
import { useGetCommunityById } from "src/hooks/query/communityById";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";
import {
  ChatRoomContainer,
  ChatRoomInfo,
  ChatBox,
  Header,
  FloatWrapper,
  SubTitle,
  SearchInputWrapper,
  FlexWrapper,
  FloatDateLabel,
  ChatMessageInputArea,
  RecruitmentTitle,
  LinkWrapper,
  SearchInputWrapperForMobile,
} from "src/features/common/ChatMessage/components/ChatRoomContent/ChatRoomContentStyled";

type Props = {
  isOpen: boolean;
  chatRoom: ChatRoomType;
  chatRoomParticipantId: string;
  lastReadAt: Date;
  recruitmentId: string;
  recruitmentDateAndStatusLabel: string | null;
  recruitmentTitle: string;
  isDeletedRecruitment: boolean;
};

export const ChatRoomRecruitmentContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const toast = useToast();
  const user = useCurrentUser();
  const isAdmin = props.chatRoomParticipantId !== user.id;
  const { community } = useGetCommunityById(props.chatRoom.communityId ?? "");
  const { displayItemList, lastReadAt, NewMessageNotification } =
    useMessageList({
      initLastReadAt: props.lastReadAt,
      messageListRef,
      chatRoomParticipantId: props.chatRoomParticipantId,
    });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  // チャットルームの最終アクセス日時を更新
  useEffect(() => {
    const updateParticipantChatRoomHandler = async () => {
      if (!props.chatRoom.id) return;
      await updateParticipantChatRoom({
        chatRoomId: props.chatRoom.id,
      });
    };

    updateParticipantChatRoomHandler();

    const interval = setInterval(updateParticipantChatRoomHandler, 10000);

    return () => {
      clearInterval(interval);
      updateParticipantChatRoomHandler();
    };
  }, [props.chatRoom.id]);

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const applicationDetailUrl = useMemo(() => {
    return createPolyfitUrl({
      to: "RESIDENT_RECRUITMENT_DETAIL",
      query: { id: props.recruitmentId },
    });
  }, [props.recruitmentId]);

  const onSend = async () => {
    if (!canSend) return;
    setSending(true);
    try {
      await createChatMessage(props.chatRoom.id, input, files);
      setInput("");
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        return;
      }
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setSending(false);
    }
  };

  const canSend = useMemo(() => {
    return !sending && (input.length > 0 || files.length > 0);
  }, [sending, input, files]);

  const onCheckedAdminOnly = async (checked: boolean) => {
    await updateAdminOnly({
      chatRoomId: props.chatRoom.id,
      adminOnly: checked,
    });
  };

  if (!displayItemList) return null;

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <FlexWrapper>
            <ChatRoomBackButton targetRoute="RESIDENT_CHAT" />
            <Col>
              <RecruitmentTitle> {props.recruitmentTitle}</RecruitmentTitle>
              {!isAdmin && <SubTitle>{community?.name}</SubTitle>}
              <SubTitle>{props.recruitmentDateAndStatusLabel}</SubTitle>
            </Col>

            <PcViewOnly>
              <FlexWrapper>
                <SearchInputWrapper>
                  <SearchInput
                    searchText={searchText}
                    setSearchText={setSearchText}
                    onPrevResult={handlePrevResult}
                    onNextResult={handleNextResult}
                    resultCount={searchResults.length}
                  />
                </SearchInputWrapper>

                {!props.isDeletedRecruitment && (
                  <LinkWrapper>
                    <a
                      href={applicationDetailUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ExternalLinkIcon />
                    </a>
                  </LinkWrapper>
                )}
              </FlexWrapper>
            </PcViewOnly>
          </FlexWrapper>
        </Header>
        <MobileViewOnly>
          <FlexWrapper>
            <SearchInputWrapperForMobile>
              <SearchInput
                searchText={searchText}
                setSearchText={setSearchText}
                onPrevResult={handlePrevResult}
                onNextResult={handleNextResult}
                resultCount={searchResults.length}
              />
            </SearchInputWrapperForMobile>
            {!props.isDeletedRecruitment && (
              <LinkWrapper>
                <a href={applicationDetailUrl} target="_blank" rel="noreferrer">
                  <ExternalLinkIcon />
                </a>
              </LinkWrapper>
            )}
          </FlexWrapper>
        </MobileViewOnly>
      </ChatRoomInfo>

      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    searchText={searchText}
                    participantId={props.chatRoomParticipantId}
                    lastReadAt={lastReadAt}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
            }
          })}
        </ChatMessages>
        <NewMessageNotification />
        {!props.isDeletedRecruitment && (
          <ChatMessageInputArea>
            <ChatInputForm
              isAdmin={isAdmin}
              adminOnly={props.chatRoom.adminOnly}
              onCheckedAdminOnly={onCheckedAdminOnly}
              placeholder="メッセージを入力"
              setValue={setInput}
              setFiles={setFiles}
              canSend={canSend}
              onSend={onSend}
            />
          </ChatMessageInputArea>
        )}
      </ChatBox>
    </ChatRoomContainer>
  );
};
