import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubTitle, Title } from "src/components/Title";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import styled from "styled-components";
import {
  createOrUpdateGradeSizeSchema,
  CreateOrUpdateGradeSizeSchemaType,
} from "@shared/validator/features/gradeSize.schema";
import {
  useGetGradeSizes,
  useCreateOrUpdateGradeSizes,
} from "src/hooks/query/gradeSize";
import { useEffect } from "react";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { Button } from "src/components/Button";
import { BREAKPOINTS } from "src/components/Responsive";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { ConfirmModal } from "src/features/Post/ConfirmModal";
import { ModalHeader, ModalPortal } from "src/components/Modal";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isPtaAdminRole } from "src/utils/types/role";

export default function GradeSizeSettingPage() {
  const currentUser = useCurrentUser();
  const isPTAAdmin = isPtaAdminRole(currentUser.role);
  const { organization } = useGetCurrentOrganization();
  const schoolType = organization?.schoolType;
  const schoolDisplayType = organization?.schoolDisplayType;
  const grades = useGradeOptions(schoolType);
  const { gradeSizes } = useGetGradeSizes();
  const toast = useToast();
  const { mutateAsync: createOrUpdateGradeSizes } =
    useCreateOrUpdateGradeSizes();
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<CreateOrUpdateGradeSizeSchemaType>({
    resolver: zodResolver(createOrUpdateGradeSizeSchema),
    defaultValues: {
      gradeSizes: grades.map((grade) => ({
        grade: grade.id,
        size: undefined,
      })),
    },
  });

  useEffect(() => {
    if (gradeSizes) {
      const initialValues = {
        gradeSizes: grades.map((grade) => {
          const existingSize = gradeSizes.find((gs) => gs.grade === grade.id);
          return {
            grade: grade.id,
            size: existingSize?.size ?? undefined,
          };
        }),
      };
      setValue("gradeSizes", initialValues.gradeSizes);
    }
  }, [gradeSizes, grades, setValue]);

  const onSubmit = async (data: CreateOrUpdateGradeSizeSchemaType) => {
    try {
      await createOrUpdateGradeSizes({ gradeSizes: data.gradeSizes });
      toast.success("学年別人数の保存に成功しました。");
      reset(data);
      setIsEditing(false);
    } catch (error) {
      if (error instanceof APIError) {
        toast.error(error.message);
      } else {
        toast.error("学年別人数の保存に失敗しました。");
      }
    }
  };

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const handleCancel = () => {
    if (gradeSizes) {
      const initialValues = {
        gradeSizes: grades.map((grade) => {
          const existingSize = gradeSizes.find((gs) => gs.grade === grade.id);
          return {
            grade: grade.id,
            size: existingSize?.size ?? undefined,
          };
        }),
      };
      setValue("gradeSizes", initialValues.gradeSizes);
      reset(initialValues);
    }
    setIsEditing(false);
  };
  if (!isPTAAdmin) {
    return <>この画面の閲覧権限がありません</>;
  }

  return (
    <>
      <Title>学年別人数設定</Title>
      <SubTitle>学年ごとの生徒の人数を設定できます。</SubTitle>
      <FormContainer>
        <DisplayContainer>
          <FormInputs>
            {grades.map((grade) => {
              const gradeSize = gradeSizes?.find((gs) => gs.grade === grade.id);
              return (
                <FormGroup key={grade.id}>
                  <FormLabel>
                    {getGradeLabel(
                      grade.id,
                      schoolDisplayType,
                      true,
                      true,
                      "full"
                    )}
                  </FormLabel>
                  <DisplayValue>{gradeSize?.size ?? "-"}</DisplayValue>
                </FormGroup>
              );
            })}
          </FormInputs>
          <ButtonWrapper>
            <Button
              type="button"
              $fill
              color="primary"
              size="large"
              onClick={() => setIsEditing(true)}
            >
              編集
            </Button>
          </ButtonWrapper>
        </DisplayContainer>
        {isEditing && (
          <ModalPortal
            onClose={() =>
              isDirty ? setCancelModalOpen(true) : handleCancel()
            }
          >
            <ModalHeader>学年別人数設定</ModalHeader>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormInputs>
                {grades.map((grade, index) => (
                  <FormGroup key={grade.id}>
                    <FormLabel>
                      {getGradeLabel(
                        grade.id,
                        schoolDisplayType,
                        true,
                        true,
                        "full"
                      )}
                    </FormLabel>
                    <InputText
                      type="number"
                      disabled={!isEditing}
                      {...register(`gradeSizes.${index}.size`, {
                        setValueAs: (value) =>
                          isNaN(value) || value === ""
                            ? undefined
                            : Number(value),
                      })}
                    />

                    {errors.gradeSizes?.[index]?.size && (
                      <ErrorMessage>
                        {errors.gradeSizes[index]?.size?.message}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                ))}
              </FormInputs>
              <ButtonWrapper>
                <Button
                  type="button"
                  size="large"
                  onClick={() =>
                    isDirty ? setCancelModalOpen(true) : handleCancel()
                  }
                >
                  キャンセル
                </Button>
                <Button type="submit" $fill color="primary" size="large">
                  保存
                </Button>
              </ButtonWrapper>
            </Form>
          </ModalPortal>
        )}
      </FormContainer>
      <ConfirmModal
        isOpen={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        onConfirm={handleCancel}
        title="編集内容を破棄"
        message="編集内容を破棄しますか？"
        confirmText="破棄する"
        cancelText="キャンセル"
      />
    </>
  );
}

const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const Form = styled.form``;
const FormInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: 1fr;
  }
  row-gap: 4px;
  column-gap: 16px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputText = styled.input`
  color: ${(props) => (props.disabled ? "#1322951A" : "#000000")};
  background-color: #fbfcfd;
  border: 1px solid
    ${(props) => (props.disabled ? "#ddd" : "rgba(19, 34, 149, 0.1)")};
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
`;

const ErrorMessage = styled.span`
  color: #ff4d4d;
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin: 16px 0 16px 0;
`;
const FormLabel = styled.label`
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-top: 14px;
  margin-bottom: 4px;
  display: inline-block;
`;

const DisplayContainer = styled.div`
  width: 600px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    width: 100%;
    padding: 0 16px;
  }
`;

const DisplayValue = styled.div`
  background-color: #fbfcfd;
  border: 1px solid #fbfcfd;
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
`;
