import { Organization } from "@shared/types/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { getGradesBySchoolType } from "@shared/utils/getGradesBySchoolType";
import {
  ClassNumberSchemaType,
  GradeNumberSchemaType,
} from "@shared/validator/rules/child";

import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { CheckBox, CheckBoxRHF } from "src/components/form/CheckBox";
import { Margin } from "src/components/Margin";
import { getClassName } from "src/utils/getChildClass";
import { zIndexes } from "src/zIndex";
import styled, { css } from "styled-components";
import { ParentFamilyInvoiceListFilterType } from "@shared/validator/features/ptaParentFamilyInvoice.schema";

type Props = {
  organization: Organization;
  setListFilter: Dispatch<SetStateAction<ParentFamilyInvoiceListFilterType>>;
  listFilter: ParentFamilyInvoiceListFilterType;
  onClose: () => void;
};

export const InvoiceProductPageFilterSetting = ({
  organization,
  setListFilter,
  listFilter,
  onClose,
}: Props) => {
  const { watch, setValue, control, register } =
    useForm<ParentFamilyInvoiceListFilterType>({
      defaultValues: listFilter,
    });

  /**
   * 学年一覧
   */
  const grades: GradeNumberSchemaType[] = getGradesBySchoolType(
    organization.schoolType
  );

  const invoiceStatusOptions = [
    { value: "ALL", text: "すべて" },
    { value: "PENDING", text: "未払い" },
    { value: "PROCESSING", text: "支払い処理中" },
    { value: "PAID", text: "支払い済み" },
    { value: "CANCELED", text: "キャンセル" },
  ];

  /**
   * クラス一覧
   * 100はその他を表す
   * -1は未設定を表す
   */
  const classes: ClassNumberSchemaType[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 100, -1,
  ];

  watch((value) => {
    const cleanFilter = {
      ...value,
      grades: value.grades?.filter(
        (g): g is NonNullable<typeof g> => g !== undefined
      ) as GradeNumberSchemaType[],
      classes: value.classes?.filter(
        (c): c is NonNullable<typeof c> => c !== undefined
      ) as ClassNumberSchemaType[],
      status: value.status ?? "ALL",
    };
    setListFilter(cleanFilter);
  });

  const checkAllGrades = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setValue("grades", grades);
    } else {
      setValue("grades", []);
    }
  };

  const checkAllClasses = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      setValue("classes", classes);
    } else {
      setValue("classes", []);
    }
  };

  const COLUMN_NUM = 3;

  return (
    <>
      <FilterModalWrap>
        <FilterModal>
          <form action="">
            <Label>
              <CheckBox defaultChecked onChange={checkAllGrades} label="学年" />
            </Label>
            {Array.from({ length: Math.ceil(grades.length / COLUMN_NUM) }).map(
              (_, i) => {
                return (
                  <CheckBoxRow key={i}>
                    {grades
                      .slice(i * COLUMN_NUM, i * COLUMN_NUM + COLUMN_NUM)
                      .map((grade) => {
                        return (
                          <CheckBoxWrapper key={grade}>
                            <CheckBoxRHF
                              label={`${getGradeLabel(
                                grade,
                                organization.schoolDisplayType,
                                true,
                                true,
                                "short",
                                organization.schoolDisplayType === "CONTINUOUS"
                                  ? "年生"
                                  : ""
                              )}`}
                              control={control}
                              value={grade}
                              name="grades"
                            />
                          </CheckBoxWrapper>
                        );
                      })}
                  </CheckBoxRow>
                );
              }
            )}

            <Margin marginTop={15} />
            <Label>
              <CheckBox
                defaultChecked
                onChange={checkAllClasses}
                label="クラス"
              />
            </Label>
            {Array.from({ length: Math.ceil(classes.length / COLUMN_NUM) }).map(
              (_, i) => {
                return (
                  <CheckBoxRow key={i}>
                    {classes
                      .slice(i * COLUMN_NUM, i * COLUMN_NUM + COLUMN_NUM)
                      .map((classNumber) => {
                        return (
                          <CheckBoxWrapper key={classNumber}>
                            <CheckBoxRHF
                              label={getClassName(classNumber, true)}
                              control={control}
                              value={classNumber}
                              name="classes"
                            />
                          </CheckBoxWrapper>
                        );
                      })}
                  </CheckBoxRow>
                );
              }
            )}
            <Margin marginTop={22} />

            <Label>請求状態</Label>
            <InputSelect {...register("status")}>
              {invoiceStatusOptions.map((opt) => {
                return (
                  <option key={opt.value} value={opt.value}>
                    {opt.text}
                  </option>
                );
              })}
            </InputSelect>
          </form>
        </FilterModal>
      </FilterModalWrap>
      <ModalBackGround onClick={onClose}></ModalBackGround>
    </>
  );
};
const CheckBoxRow = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const FilterModalWrap = styled.div`
  position: relative;
  z-index: ${zIndexes.notModalMenu};
`;

export const ModalBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const FilterModal = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  padding: 14px;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const Label = styled.div`
  width: fit-content;
  color: #1a1c21;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
`;

const CheckBoxWrapper = styled.div`
  width: 75px;
`;

type InputSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  value?: string;
  disabled?: boolean;
};

const InputSelect = styled.select<InputSelectProps>`
  color: ${(props) => (props.value === "" ? "#aab4c4" : "#343741")};
  ${(props) =>
    props.disabled &&
    css`
      color: #aab4c4;
      background-color: #fbfcfd;
      cursor: not-allowed;
      opacity: 0.5;
      border-color: #ddd;
    `}

  appearance: none;
  width: 100%;
  padding: 8px 10px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
`;
