import React from "react";
import { SwitchForNotificationSetting } from "src/features/Profile/NotificationSetting/components/SwitchForNotificationSetting";
import { usePushNotificationSetting } from "src/hooks/query/pushNotification";
import styled from "styled-components";
import { checkIsIOS, checkIsPWA } from "../utils";
import { useNotificationPermission } from "src/hooks/useNotificationPermissions";
import { colorsPallet } from "src/theme";
import { PWAOnboarding } from "src/features/Onboarding/PWA/PWAOnboarding";

export const PushNotificationSettingSection = () => {
  const {
    isPushNotificationEnabled,
    updatePushNotificationEnabled,
    isLoading,
    isPending,
    canUpdatePushNotificationEnabled,
  } = usePushNotificationSetting();
  const isNotificationEnabled = useNotificationPermission();

  const ErrorDescription = () => {
    if (isPWA) {
      return (
        <SettingErrorDescription>
          通知が無効になっているため、このデバイスで通知を受け取ることができません。端末の設定から通知を有効にした後、アプリを再起動して再度お試しください。
        </SettingErrorDescription>
      );
    } else {
      return (
        <SettingErrorDescription>
          通知が無効になっているため、このデバイスで通知を受け取ることができません。ブラウザの設定から通知を有効にした後、ページを更新して再度お試しください。
        </SettingErrorDescription>
      );
    }
  };
  const NotificationSettingState = () => {
    if (isPushNotificationEnabled) {
      if (
        isNotificationEnabled === "denied" ||
        (isIOS && isNotificationEnabled === "default" && isPWA)
      ) {
        return <ErrorDescription />;
      }
    }
  };

  const handleChange = () => {
    updatePushNotificationEnabled(!isPushNotificationEnabled);
  };
  const isIOS = checkIsIOS();
  const isPWA = checkIsPWA();
  return (
    <>
      <SettingMainItem>
        <SettingItemLabel>
          <SettingItemName>プッシュ通知設定</SettingItemName>
        </SettingItemLabel>
        <SettingAction>
          <SwitchForNotificationSetting
            value={isPushNotificationEnabled ?? false}
            onChange={handleChange}
            isLoading={isLoading || isPending}
            disabled={!isPWA && isIOS}
          />
        </SettingAction>
      </SettingMainItem>
      {!canUpdatePushNotificationEnabled &&
        (!isIOS
          ? isNotificationEnabled === "denied"
          : isNotificationEnabled === "default") && <ErrorDescription />}
      <NotificationSettingState />

      {isIOS && !isPWA ? (
        <div>
          <SettingErrorDescription>
            プッシュ通知設定はアプリ内でのみ行うことができます。先にアプリをダウンロードしてください。
          </SettingErrorDescription>
          <SettingItemDetailDescription>
            有効にすると連絡やチャットの通知を受け取ることができるようになります。
            <br />
            PWA（ホーム画面に追加するアプリ）についての詳細は
            <NotificationLink href="https://www.youtube.com/watch?v=YJp2lhKjKSI">
              こちら
            </NotificationLink>
          </SettingItemDetailDescription>
          <PWAOnboarding />
        </div>
      ) : (
        <div>
          <SettingItemDetailDescription>
            連絡やチャットの通知を受け取ることができるようになります。アプリをご利用の場合は、デバイスの通知設定を行い、ホーム画面にアプリを追加してください。
            <br />
            PWA（ホーム画面に追加するアプリ）についての詳細は
            <NotificationLink href="https://www.youtube.com/watch?v=YJp2lhKjKSI">
              こちら
            </NotificationLink>
          </SettingItemDetailDescription>
          <PWAOnboarding />
        </div>
      )}
    </>
  );
};

const SettingItemLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingItemName = styled.span`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;
const SettingMainItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SettingItemDetailDescription = styled.p`
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  color: #808080;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const SettingErrorDescription = styled.p`
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  color: ${colorsPallet.danger};
`;

const SettingAction = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 10px;
`;

const NotificationLink = styled.a`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
