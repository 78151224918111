import * as client from "./client";
import {
  CreatePTAInvoiceImportResultType,
  CreatePTAInvoiceImportType,
  GetPTAInvoiceImportRowsResponseType,
  GetPTAInvoiceImportRowsType,
  PostPTAInvoiceImportType,
} from "@shared/validator/features/ptaInvoiceImport.schema";

export async function createPTAInvoiceImport(
  data: CreatePTAInvoiceImportType & { file: File }
): Promise<CreatePTAInvoiceImportResultType> {
  const { file, ...args } = data;
  const payload = {
    data: JSON.stringify(args),
    file,
  };

  const res = await client.postWithFormData<
    PostPTAInvoiceImportType & { file: File },
    CreatePTAInvoiceImportResultType
  >("/invoice/ptaInvoiceImport", payload);
  return res;
}

export async function getPTAInvoiceImportRows(
  params: GetPTAInvoiceImportRowsType
): Promise<GetPTAInvoiceImportRowsResponseType> {
  return await client.get<
    GetPTAInvoiceImportRowsType,
    GetPTAInvoiceImportRowsResponseType
  >("/invoice/ptaInvoiceImport", params);
}
