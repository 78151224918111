import { z } from "zod";
import { ptaInvoiceImportRowSchema } from "../models/invoice/ptaInvoiceImportRow.schema";
import { zRequiredString } from "../rules";
import { importStatusEnum } from "../models/invoice/ptaInvoiceImport.schema";

export const getPTAInvoiceImportRowsSchema = z.object({
  importId: z.string().uuid(),
  PTAInvoiceProductId: z.string().uuid(),
});
export type GetPTAInvoiceImportRowsType = z.infer<
  typeof getPTAInvoiceImportRowsSchema
>;

export const importRowSchema = z
  .array(ptaInvoiceImportRowSchema.omit({ id: true }))
  .min(1, "有効な請求データがありません");
export type ImportRowType = z.infer<typeof importRowSchema>;

export const csvRowErrorSchema = ptaInvoiceImportRowSchema
  .omit({
    id: true,
  })
  .extend({
    errorMessage: z.array(zRequiredString),
    index: z.number(),
  });
export type CSVRowErrorType = z.infer<typeof csvRowErrorSchema>;
export const createPTAInvoiceImportSchema = z.object({
  PTAInvoiceProductId: z.string().uuid(),
  idempotencyKey: zRequiredString,
  rows: importRowSchema,
});
export type CreatePTAInvoiceImportType = z.infer<
  typeof createPTAInvoiceImportSchema
>;
export const postPTAInvoiceImportSchema = z.object({
  data: zRequiredString,
});
export type PostPTAInvoiceImportType = z.infer<
  typeof postPTAInvoiceImportSchema
>;

export const createPTAInvoiceImportResultSchema = z.discriminatedUnion("isOk", [
  z.object({
    isOk: z.literal(true),
    importId: z.string().uuid(),
  }),
  z.object({
    isOk: z.literal(false),
    errors: z.array(csvRowErrorSchema),
  }),
]);
export type CreatePTAInvoiceImportResultType = z.infer<
  typeof createPTAInvoiceImportResultSchema
>;

export const getPTAInvoiceImportRowsResponseSchema = z.object({
  id: z.string().uuid(),
  idempotencyKey: zRequiredString,
  organizationId: zRequiredString,
  PTAInvoiceProductId: z.string().uuid(),
  status: importStatusEnum,
  rows: z.array(ptaInvoiceImportRowSchema),
});
export type GetPTAInvoiceImportRowsResponseType = z.infer<
  typeof getPTAInvoiceImportRowsResponseSchema
>;
