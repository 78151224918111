import React from "react";
import { User } from "../../apiClients/users";
import { usePolyfitHistory } from "../../hooks/router";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../components/Modal";
import styled from "styled-components";
import { Heading3 } from "../../components/Typo";
import { Account, deleteAccountById } from "../../apiClients/auth";
import { APIError } from "../../utils/types/ApiError";
import { useToast } from "../../components/Toast";
import { logger } from "src/utils/logger";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { PTAParentFamilyInvoiceWithProductListType } from "@shared/types/ptaParentFamilyInvoice";
import { colorsPallet } from "src/theme";
import { Link } from "react-router-dom";
import { useInvalidateAllQueries } from "src/hooks/query/utils/useInvalidateAll";

type LeaveModalProps = {
  user: User;
  account: Account;
  onClose: () => void;
  isLastAdminUser: boolean;
  pendingInvoices: PTAParentFamilyInvoiceWithProductListType[];
};

export const LeaveModal = ({
  user,
  account,
  onClose,
  isLastAdminUser,
  pendingInvoices,
}: LeaveModalProps) => {
  const history = usePolyfitHistory();
  const { invalidateAllApiQueries } = useInvalidateAllQueries();
  const { organization } = useGetCurrentOrganization();
  const toast = useToast();

  const onSubmit = async () => {
    try {
      // 権限を変更処理
      await deleteAccountById(account.id);
      invalidateAllApiQueries();
      onClose();
      history.push({ to: "PTA_LIST" });
    } catch (err) {
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
        onClose();
        return;
      }
    }
  };
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>退会</ModalHeader>
      <ModalBody>
        {isLastAdminUser ? (
          <>
            <Message>
              管理者が不在の場合、管理者を新規で増やすことができません。
              <br />
              以後、新規招待や退会処理ができなくなってしまいます。
              <br />
              他の方を管理者権限に移行してください。
            </Message>
          </>
        ) : (
          <>
            <Message>
              {user.name}さんを退会させます。
              <br />
              {`退会させると、メンバーが${
                organization?.name || "組織"
              }の情報にアクセスできなくなります。`}
            </Message>
            {pendingInvoices.length > 0 && (
              <WarningMessage>
                <WarningText>
                  退会させると、以下の未払い請求がキャンセルされます。
                </WarningText>
                <InvoiceList>
                  {pendingInvoices.map((invoice) => (
                    <InvoiceItem key={invoice.id}>
                      <InvoiceTitle>
                        <Link
                          style={{ color: "#07c" }}
                          to={`/pta/invoice/detail?id=${invoice.PTAInvoiceProduct.id}`}
                        >
                          {invoice.PTAInvoiceProduct.name}
                        </Link>
                      </InvoiceTitle>
                      <InvoiceAmount>
                        {invoice.unitAmount.toLocaleString()}円
                      </InvoiceAmount>
                    </InvoiceItem>
                  ))}
                </InvoiceList>
              </WarningMessage>
            )}
            <ModalSubmitButtons
              submitText="退会"
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          </>
        )}
      </ModalBody>
    </ModalPortal>
  );
};

const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
`;

const Message = styled.div`
  padding: 16px 0;
  max-width: 432px;
`;

const WarningMessage = styled.div`
  padding: 16px;
  background-color: #f5f7fa;
  border-radius: 8px;
`;

const WarningText = styled.p`
  color: ${colorsPallet.danger};
  font-weight: bold;
  margin: 0 0 12px 0;
`;

const InvoiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InvoiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InvoiceTitle = styled.span`
  font-size: 14px;
`;

const InvoiceAmount = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
