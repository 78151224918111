import React from "react";
import styled from "styled-components";
import { colorsPallet, shadow } from "src/theme";
import {
  InvoiceCancelReason,
  invoiceCancelReasonLabel,
} from "@shared/validator/models/invoice/ptaParentFamilyInvoice.schema";

type Props = {
  cancelReason?: InvoiceCancelReason | null;
  cancelComment?: string | null;
  isOpen: boolean;
  onClose: () => void;
};

export const CancelReasonPopup = ({
  cancelReason,
  cancelComment,
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) return null;
  if (!cancelReason) return null;

  return (
    <div>
      <ReasonPopupContainer>
        <ReasonPopupContent>
          <ReasonPopupComment>
            <ReasonPopupCommentTitle>キャンセル理由</ReasonPopupCommentTitle>
            {invoiceCancelReasonLabel[cancelReason]}
          </ReasonPopupComment>
          {cancelComment && (
            <ReasonPopupComment>
              <ReasonPopupCommentTitle>
                管理者からのコメント
              </ReasonPopupCommentTitle>
              {cancelComment}
            </ReasonPopupComment>
          )}
        </ReasonPopupContent>
      </ReasonPopupContainer>
      <Overlay onClick={onClose} />
    </div>
  );
};

export const CancelReasonButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  color: ${colorsPallet.primary};
  font-size: 12px;
  cursor: pointer;
  padding: 4px;
  text-decoration: underline;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 99;
`;

const ReasonPopupContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  border-radius: 4px;
  box-shadow: ${shadow.bottomSmall};
  min-width: 200px;
  max-width: 60vw;
  overflow: hidden;
  background-color: #fff;
  margin-top: 4px;
`;

const ReasonPopupContent = styled.div`
  background-color: #fff;
  padding: 12px 16px;
`;

const ReasonPopupComment = styled.p`
  font-size: 12px;
  line-height: 1.6;
  margin: 0;
  white-space: pre-wrap;
  color: #333;
`;

const ReasonPopupCommentTitle = styled.p`
  font-size: 12px;
  line-height: 1.6;
  margin: 0;
  color: #333;
  font-weight: bold;
`;
