import React, { useMemo, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  ModalWrapper,
  ModalHeader,
  EditModalFormLabel,
  CheckTabs,
  TextField,
  SelectField,
  ErrorMessage,
  StyledFlexSpaceBetween,
} from "../../../pages/pta/admin/ProfilePage";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import {
  TrashButton,
  TrashIcon,
  AddChildForm,
} from "../../../pages/registration/pta/RegisterChildInfoPage";
import { Margin } from "../../../components/Margin";
import { useToast } from "../../../components/Toast";
import { useGetChildren } from "../../../hooks/api/child";
import { upsertAndDeleteChildren } from "../../../apiClients/child";
import { Child } from "@shared/types/child";
import { useCurrentUser, type BaseInfo } from "../../../hooks/recoil/user";
import { classOption } from "../../../utils/getChildClass";
import { useInvalidateGetChildrenByCondition } from "src/hooks/query/childrenList";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "../../../hooks/query/organization";
import { User } from "src/apiClients/users";
import { useGetInvoicesByUserId } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { isPtaAdminRole } from "src/utils/types/role";
import { Tooltip } from "react-tooltip";
import { PTAParentFamilyInvoiceWithProductListType } from "@shared/types/ptaParentFamilyInvoice";
import styled from "styled-components";
import { APIError } from "src/utils/types/ApiError";
import { PlusIcon } from "src/components/icons/PlusIcon";

type FormValues = {
  baseInfo: BaseInfo;
  children: Child[];
};

const ChildInfoEditModal = ({
  isOpen,
  userChildren,
  close,
  user,
}: {
  isOpen: boolean;
  userChildren: Child[];
  close: () => void;
  user: User;
}) => {
  const { getChildren } = useGetChildren(user.id);
  const currentUser = useCurrentUser();
  const toast = useToast();
  const { invalidateGetChildrenByCondition } =
    useInvalidateGetChildrenByCondition();
  const { organization } = useGetCurrentOrganization();
  const gradeOptions = useGradeOptions(organization?.schoolType);
  const { invoices } = useGetInvoicesByUserId({
    userId: user.id,
    isSubMemberGet: true,
  });
  const pendingInvoices = invoices?.filter(
    (invoice) => invoice.status === "PENDING"
  );
  const isAdmin = isPtaAdminRole(currentUser?.role);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [confirmCancelInvoices, setConfirmCancelInvoices] = useState<
    PTAParentFamilyInvoiceWithProductListType[]
  >([]);
  const [confirmCancelInvoicesIndex, setConfirmCancelInvoicesIndex] = useState<
    number | null
  >(null);

  const defaultValues = useMemo(() => {
    return {
      children: userChildren,
    };
  }, [userChildren]);

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { fields, append, remove } = useFieldArray({
    name: "children",
    control,
  });

  const newChildInfo = useMemo(() => {
    return {
      childLastName: user.baseInfo?.lastName ?? "",
      childFirstName: "",
      childLastNameKana: user.baseInfo?.lastNameKana ?? "",
      childFirstNameKana: "",
      grade: undefined,
      class: undefined,
    };
  }, [user.baseInfo]);

  const handleRemove = ({
    index,
    childPendingInvoices,
  }: {
    index: number;
    childPendingInvoices: PTAParentFamilyInvoiceWithProductListType[];
  }) => {
    if (childPendingInvoices.length > 0) {
      if (!isAdmin) {
        toast.error(
          "このお子さまに紐づく未払いの請求があるため、削除できません"
        );
        return;
      }
      setConfirmCancelInvoices(childPendingInvoices);
      setConfirmCancelInvoicesIndex(index);
      setConfirmDeleteModalOpen(true);
    } else {
      remove(index);
    }
  };

  const onSubmitChildInfo = async (): Promise<void> => {
    try {
      const children = getValues("children");
      const childrenWithUserId = children.map((child) => {
        return {
          childFirstName: child.childFirstName,
          childFirstNameKana: child.childFirstNameKana,
          childLastName: child.childLastName,
          childLastNameKana: child.childLastNameKana,
          class: child.class,
          grade: child.grade,
          userId: user.id,
          id: child.id,
        };
      });

      if (childrenWithUserId.length === 0) {
        toast.error("お子さまの情報を入力してください");
        return;
      }

      await upsertAndDeleteChildren(user.id, childrenWithUserId);
      await getChildren();
      invalidateGetChildrenByCondition();

      close();
      toast.success("保存しました");
    } catch (err) {
      console.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    }
  };
  const children = getValues("children");

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={close} modalBoxIsHeightFull={true}>
          <ModalWrapper>
            <ModalHeader>お子様情報</ModalHeader>
            <ModalBody>
              {fields.map((_field, index) => {
                const childPendingInvoices = pendingInvoices.filter((invoice) =>
                  invoice.PTAChildInvoiceItems.some(
                    (item) => item.childId === children[index].id
                  )
                );
                return (
                  <div key={_field.id}>
                    <StyledFlexSpaceBetween>
                      <EditModalFormLabel>
                        お子さまのお名前({index + 1}人目)
                      </EditModalFormLabel>

                      {!isAdmin
                        ? fields.length > 1 && (
                            <>
                              <TrashButton
                                style={{
                                  marginTop: "12px",
                                  marginRight: "5px",
                                }}
                                type="button"
                                onClick={() =>
                                  handleRemove({ index, childPendingInvoices })
                                }
                                disabled={childPendingInvoices.length > 0}
                                data-tooltip-id={`trash-button-tooltip-${index}`}
                              >
                                <TrashIcon />
                              </TrashButton>
                              {childPendingInvoices.length > 0 && (
                                <Tooltip
                                  id={`trash-button-tooltip-${index}`}
                                  place="bottom"
                                >
                                  <span>
                                    このお子さまに紐づく未払いの請求があるため、削除できません。
                                    <br />
                                    支払いを行うか、管理者にお問い合わせください。
                                  </span>
                                </Tooltip>
                              )}
                            </>
                          )
                        : fields.length > 1 && (
                            <>
                              <TrashButton
                                style={{
                                  marginTop: "12px",
                                  marginRight: "5px",
                                }}
                                type="button"
                                onClick={() =>
                                  handleRemove({ index, childPendingInvoices })
                                }
                                disabled={false}
                              >
                                <TrashIcon />
                              </TrashButton>
                            </>
                          )}
                    </StyledFlexSpaceBetween>

                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) 山田"
                          {...register(
                            `children.${index}.childLastName` as const,
                            {
                              required: "入力必須項目です",
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastName && (
                            <ErrorMessage>
                              {/* @ts-ignore */}
                              {errors.children[index].childLastName?.message}
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) 太郎"
                          {...register(
                            `children.${index}.childFirstName` as const,
                            {
                              required: "入力必須項目です",
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstName && (
                            <ErrorMessage>
                              {/* @ts-ignore */}
                              {errors.children[index].childFirstName?.message}
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <EditModalFormLabel>ふりがな</EditModalFormLabel>
                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) やまだ"
                          {...register(
                            `children.${index}.childLastNameKana` as const,
                            {
                              required: "入力必須項目です",
                              pattern: {
                                value: /^[ぁ-んーー]+$/u,
                                message: "ひらがなのみで入力してください",
                              },
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastNameKana && (
                            <ErrorMessage>
                              {
                                //@ts-ignore
                                errors.children[index].childLastNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) たろう"
                          {...register(
                            `children.${index}.childFirstNameKana` as const,
                            {
                              required: "入力必須項目です",
                              pattern: {
                                value: /^[ぁ-んーー]+$/u,
                                message: "ひらがなのみで入力してください",
                              },
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstNameKana && (
                            <ErrorMessage>
                              {
                                //@ts-ignore
                                errors.children[index].childFirstNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <EditModalFormLabel>学年</EditModalFormLabel>
                        <SelectField
                          {...register(`children.${index}.grade` as const, {
                            required: "入力必須項目です",
                            setValueAs: (v) =>
                              v === "" ? undefined : parseInt(v, 10),
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {gradeOptions.map((opt) => {
                            return (
                              <option key={opt.id} value={opt.id}>
                                {getGradeLabel(
                                  opt.id,
                                  organization?.schoolDisplayType,
                                  true,
                                  false,
                                  "short"
                                )}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.grade && (
                          <ErrorMessage>
                            {/* @ts-ignore */}
                            {errors.children[index].grade?.message}
                          </ErrorMessage>
                        )}
                      </div>
                      <div>
                        <EditModalFormLabel>クラス</EditModalFormLabel>
                        <SelectField
                          {...register(`children.${index}.class` as const, {
                            required: "入力必須項目です",
                            setValueAs: (v) =>
                              v === "" ? undefined : parseInt(v, 10),
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {classOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.class && (
                          <ErrorMessage>
                            {/* @ts-ignore */}
                            {errors.children[index].class?.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                  </div>
                );
              })}

              <Margin marginTop={20} />
              <AddChildForm
                type="button"
                onClick={() => {
                  append(newChildInfo);
                }}
              >
                <PlusIcon size={16} />
                お子さまを追加
              </AddChildForm>
              <Margin marginTop={25} />
              <ModalSubmitButtons
                submitText="保存"
                onCancel={close}
                onSubmit={() => handleSubmit(onSubmitChildInfo)()}
              />
            </ModalBody>
          </ModalWrapper>
          {confirmDeleteModalOpen && (
            <ModalPortal onClose={() => setConfirmDeleteModalOpen(false)}>
              <ModalWrapper>
                <ModalHeader>未払いの請求があります</ModalHeader>
                <ModalBody>
                  <div>
                    <p>
                      このお子さまに紐づく未払いの請求があります。このお子さまを削除すると、以下の請求も自動でキャンセルされます。
                    </p>
                    <p>
                      ※子ども情報の変更を保存したタイミングで、請求のキャンセルは確定します。
                    </p>
                    <StyledTable>
                      <thead>
                        <tr>
                          <Th>請求項目</Th>
                          <Th>対象のお子さま</Th>
                          <Th>請求金額</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {confirmCancelInvoices.map((invoice) => {
                          return (
                            <Tr key={invoice.id}>
                              <Td>{invoice.PTAInvoiceProduct.name}</Td>
                              <Td>
                                {invoice.PTAChildInvoiceItems.map(
                                  (item, index) => (
                                    <ChildInfoText key={item.id}>
                                      <div>
                                        {item.child?.childLastName}
                                        {item.child?.childFirstName}
                                      </div>
                                      {item.amount.toLocaleString()}円
                                    </ChildInfoText>
                                  )
                                )}
                              </Td>
                              <Td>{invoice.unitAmount.toLocaleString()}円</Td>
                            </Tr>
                          );
                        })}
                      </tbody>
                    </StyledTable>
                    <ModalSubmitButtons
                      submitText="削除する"
                      submitColor="danger"
                      cancelText="戻る"
                      onCancel={() => setConfirmDeleteModalOpen(false)}
                      onSubmit={() => {
                        remove(confirmCancelInvoicesIndex ?? 0);
                        setConfirmCancelInvoicesIndex(null);
                        setConfirmCancelInvoices([]);
                        setConfirmDeleteModalOpen(false);
                      }}
                    />
                  </div>
                </ModalBody>
              </ModalWrapper>
            </ModalPortal>
          )}
        </ModalPortal>
      )}
    </>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const Tr = styled.tr``;

const Td = styled.td`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const ChildInfoText = styled.span`
  font-size: 14px;
  line-height: 1.6;
  display: flex;
  justify-content: space-between;
`;

export default ChildInfoEditModal;
