import React, { memo } from "react";
import { RegisterStepper } from "../RegisterStepper";

type StepResidentProps = {
  stepNum: number;
};

export const StepResident = memo(({ stepNum }: StepResidentProps) => {
  return (
    <RegisterStepper
      count={4}
      current={stepNum}
      title="入力することでpolyfitをより快適に使っていただけます！"
    />
  );
});
