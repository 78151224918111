import { z } from "zod";
import { zRequiredString } from "../rules/string";
import { UserType } from "../../types/userType";
import { ServiceType } from "../../types/serviceType";
import { accountCommunityRoleSchema } from "./accountCommunityRole.schema";
import { Role } from "../../types/role";

export const accountSchema = z.object({
  id: zRequiredString,
  organizationId: zRequiredString,
  userId: zRequiredString,
  roleId: zRequiredString,
  belongId: z.string().nullable(),
  partId: z.string().nullable(),
  able: z.boolean(),
  signUpState: z.boolean(),
  isApprover: z.boolean(),
  isHasNonEnrolledChild: z.boolean(),
  type: z.nativeEnum(UserType),
  serviceType: z.nativeEnum(ServiceType),
  schoolGroup: z.string().nullable(),
  isPreschooler: z.boolean(),
  selfFreeMemo: z.string(),
  adminFreeMemo: z.string(),
  communityRoles: z.array(accountCommunityRoleSchema),
  createdAt: z.date(),
  updatedAt: z.date(),
  role: z.object({
    id: zRequiredString,
    name: z.nativeEnum(Role),
  }),
});

export type Account = z.infer<typeof accountSchema>;
