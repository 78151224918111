import React from "react";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { BREAKPOINTS } from "src/components/Responsive";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { GradeOption } from "src/hooks/useGradeOptions";
import { SchoolDisplayType } from "@shared/types/organization";
import { colorsPallet } from "src/theme";
import { PTAInvoiceProcessCreateNewFormValues } from "../utils/schema";

type Props = {
  register: UseFormRegister<PTAInvoiceProcessCreateNewFormValues>;
  errors: FieldErrors<PTAInvoiceProcessCreateNewFormValues>;
  grades: GradeOption[];
  schoolDisplayType: SchoolDisplayType | undefined;
};

export const GradeAmountSection = ({
  register,
  errors,
  grades,
  schoolDisplayType,
}: Props) => {
  return (
    <>
      <Label size="m" required>
        学年別請求金額
      </Label>
      <Description>
        学年ごとに請求金額を設定することができます。
        <br />
        各メンバーに個別に請求金額を設定する場合は、次ページでCSVをダウンロードした後、編集を行ってください。
      </Description>
      <GradeAmountWrapper>
        <GradeAmountList>
          {grades.map((grade, index) => (
            <div key={grade.id}>
              <GradeAmount>
                <GradeLabel>
                  {getGradeLabel(
                    grade.id,
                    schoolDisplayType,
                    true,
                    true,
                    "full"
                  )}
                </GradeLabel>
                <InputText
                  type="number"
                  {...register(`amountsByGrades.${index}.amount`, {
                    setValueAs: (value) =>
                      isNaN(value) || value === "" ? undefined : Number(value),
                  })}
                  placeholder="3000"
                />
                円
              </GradeAmount>
              <ErrorMessage>
                {errors.amountsByGrades?.[index]?.amount?.message}
              </ErrorMessage>
            </div>
          ))}
        </GradeAmountList>
      </GradeAmountWrapper>
    </>
  );
};

const InputText = styled.input`
  width: 100%;
  color: ${(props) => (props.disabled ? "#1322951A" : "#000000")};
  background-color: #fbfcfd;
  border: 1px solid
    ${(props) => (props.disabled ? "#ddd" : "rgba(19, 34, 149, 0.1)")};
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};

  ::placeholder {
    color: ${(props) => (props.disabled ? "#1322951A" : "#343741")};
  }
`;
const ErrorMessage = styled.span`
  color: ${colorsPallet.danger};
  font-size: 12px;
`;

const GradeAmountWrapper = styled.div`
  padding: 0 8px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    /* grid-template-columns: repeat(1, 1fr); */
  }
`;

const GradeAmountList = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
`;

const GradeAmount = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr minmax(auto, max-content);
  column-gap: 8px;
  align-items: center;
`;

const GradeLabel = styled.div`
  flex-shrink: 0;
  font-size: 16px;
`;

const Description = styled.div`
  font-size: 12px;
  color: #808080;
`;
