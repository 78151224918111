import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Button } from "src/components/Button";
import { useGetPTAInvoiceProduct } from "src/hooks/query/invoice/ptaInvoiceProduct";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { Link } from "src/components/Link";
import { BreadCrumb } from "src/components/Common/BreadCrumb";
import { PageHeader } from "src/components/Page";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
  usePolyfitUrl,
} from "src/hooks/router";
import { useGetPTAParentFamilyInvoiceList } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { remindPTAParentFamilyInvoice } from "src/apiClients/ptaParentFamilyInvoice";
import { Tooltip } from "react-tooltip";
import { ResendInvoiceReminderModal } from "./components/ResendInvoiceReminderModal";
import { useModal } from "src/components/Modal";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { BREAKPOINTS } from "src/components/Responsive";
import { PTAInvoiceDetailContent } from "./components/PTAInvoiceDetailContent";
import { InvoiceProductPageFilterSetting } from "src/features/Invoice/InvoiceProductPageFilterSetting";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { SearchIcon } from "src/components/icons/SearchIcon";
import { TextField } from "src/components/form/TextField";
import { ParentFamilyInvoiceListFilterType } from "@shared/validator/features/ptaParentFamilyInvoice.schema";
import { getGradesBySchoolType } from "@shared/utils/getGradesBySchoolType";
import { exportCsvOfPTAInvoiceDetail } from "src/apiClients/csv";
import { toInputDateFormatYearMonth } from "src/utils/time";
import { useFileDownload } from "src/hooks/useFileDownload";
import { zIndexes } from "src/zIndex";
import { ChangeNameModal } from "./components/ChangeNameModal";
import { updatePTAInvoiceProductName } from "src/apiClients/ptaInvoiceProduct";

export function PTAInvoiceProductPage() {
  const history = usePolyfitHistory();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const { download } = useFileDownload();
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    "PTA_INVOICE_DETAIL",
    {
      id: "",
    }
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { organization } = useGetCurrentOrganization();
  const gradeOptions = getGradesBySchoolType(organization?.schoolType ?? "B");
  const defaultListFilter: ParentFamilyInvoiceListFilterType = {
    grades: gradeOptions,
    classes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 100, -1],
    status: "ALL",
  };
  const [listFilter, setListFilter] =
    useState<ParentFamilyInvoiceListFilterType>(defaultListFilter);
  const [searchText, setSearchText] = useState<string>("");

  const searchCondition = useMemo(() => {
    const withOutDeleted = !(
      searchText === "" &&
      listFilter.grades.length === gradeOptions.length &&
      listFilter.classes.length === 14
    );

    return { q: searchText, filter: listFilter, withOutDeleted };
  }, [listFilter, searchText]);
  const {
    ptaInvoiceProduct,
    isLoading: isLoadingInvoiceProduct,
    refetch,
  } = useGetPTAInvoiceProduct(invoiceProductId);
  const { ptaParentFamilyInvoices, isLoading: isLoadingParentFamilyInvoices } =
    useGetPTAParentFamilyInvoiceList({
      ptaInvoiceProductId: invoiceProductId,
      ...searchCondition,
    });
  const backUrl = usePolyfitUrl({ to: "PTA_INVOICE_LIST" });

  const [
    isShownRemindModal,
    { show: showRemindModal, close: closeRemindModal },
  ] = useModal();
  const [
    isShownChangeNameModal,
    { show: showChangeNameModal, close: closeChangeNameModal },
  ] = useModal();
  const pendingInvoiceCount = ptaParentFamilyInvoices.pendingInvoiceCount;
  const isBeforeDueDate = ptaInvoiceProduct?.dueDate
    ? new Date() < new Date(ptaInvoiceProduct.dueDate)
    : false;
  const disabledRemind = pendingInvoiceCount === 0 || !isBeforeDueDate;
  const handleRemindInvoice = async () => {
    if (!invoiceProductId || disabledRemind) return;
    try {
      await remindPTAParentFamilyInvoice({
        PTAInvoiceProductId: invoiceProductId,
      });
      toast.success("請求リマインド通知を送信しました。");
    } catch (err) {
      logger.error(err, { ptaInvoiceProduct });
      toast.error("リマインド通知の送信に失敗しました。");
    }
    closeRemindModal();
  };
  const handleExportCsv = async () => {
    if (ptaParentFamilyInvoices.PTAParentFamilyInvoiceItems.length === 0) {
      return;
    }
    try {
      const blob = await exportCsvOfPTAInvoiceDetail({
        PTAInvoiceProductId: invoiceProductId,
        filter: JSON.stringify(listFilter),
        q: searchText,
      });
      const date = toInputDateFormatYearMonth(new Date());
      const fileName = `請求詳細_${ptaInvoiceProduct?.name}_${date}.csv`;
      download(blob, fileName);
    } catch (err) {
      logger.error(err, { ptaInvoiceProduct });
      toast.error("CSVダウンロードに失敗しました。");
    }
  };

  const handleChangeName = async (name: string) => {
    try {
      await updatePTAInvoiceProductName({
        id: invoiceProductId,
        name: name,
      });
      toast.success("請求項目名を変更しました。");
      refetch();
    } catch (err) {
      logger.error(err, { ptaInvoiceProduct });
      toast.error("請求項目名の変更に失敗しました。");
    }
    closeChangeNameModal();
  };

  const onResetFilter = () => {
    setListFilter(defaultListFilter);
    setSearchText("");
  };

  const remindTooltipMessage = !isBeforeDueDate
    ? "支払期限を過ぎた請求にはリマインド通知を送信できません。"
    : pendingInvoiceCount === 0
    ? "未払いの請求がありません。"
    : "";

  const csvDownloadTooltipMessage =
    "請求が存在しない場合はCSVをダウンロードすることができません";

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  if (ptaInvoiceProduct?.deletedAt) {
    return <div>請求項目が見つかりませんでした</div>;
  }
  return (
    <div>
      <BreadCrumb>
        <Link
          to={{
            to: "PTA_INVOICE_LIST",
          }}
        >
          請求一覧
        </Link>
        <LinkWrap>請求詳細</LinkWrap>
      </BreadCrumb>
      <HeaderRow>
        <div>
          {/* モバイル時のみ戻るボタン表示 */}
          <MobileInvoiceDiv>
            <PageHeader backTo={backUrl}>
              <Title>{`請求詳細: ${ptaInvoiceProduct?.name}`}</Title>
            </PageHeader>
          </MobileInvoiceDiv>
          <PCInvoiceDiv>
            <Title>{`請求詳細: ${ptaInvoiceProduct?.name}`}</Title>
          </PCInvoiceDiv>
        </div>
        {
          <ButtonRowWrapper>
            <ButtonRow>
              <SearchTextFieldWrapper>
                <TextField
                  placeholder="氏名で検索"
                  onChange={(text) => setSearchText(text)}
                  value={searchText}
                  prepend={<SearchIcon size={20} />}
                  heightPadding="6px"
                />
              </SearchTextFieldWrapper>
              <div>
                <Button
                  width="112px"
                  $fill
                  size="large"
                  color="dark"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                  type="button"
                >
                  表示設定
                </Button>
                {showFilter && organization && (
                  <InvoiceProductPageFilterSetting
                    organization={organization}
                    setListFilter={setListFilter}
                    listFilter={listFilter}
                    onClose={() => {
                      setShowFilter(false);
                    }}
                  />
                )}
              </div>
              <div style={{ position: "relative" }}>
                <SettingButton
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  ...
                </SettingButton>
                {isOpen && (
                  <>
                    <ModalBackGround
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    />
                    <SettingEditTab>
                      <SettingEditInfo
                        onClick={() => {
                          history.push({
                            to: "PTA_INVOICE_ADDITIONAL_PROCESS",
                            query: { id: invoiceProductId, step: "1" },
                          });
                        }}
                      >
                        請求を追加発行
                      </SettingEditInfo>
                      <SettingEditInfo
                        onClick={() => {
                          if (disabledRemind) return;
                          showRemindModal();
                        }}
                        style={{
                          opacity: disabledRemind ? 0.5 : 1,
                          cursor: disabledRemind ? "not-allowed" : "pointer",
                        }}
                        data-tooltip-id="remind-tooltip"
                      >
                        請求リマインド通知
                      </SettingEditInfo>
                      {disabledRemind && remindTooltipMessage && (
                        <Tooltip
                          id="remind-tooltip"
                          place="bottom"
                          content={remindTooltipMessage}
                          style={{ maxWidth: "300px" }}
                        />
                      )}
                      <SettingEditInfo
                        onClick={handleExportCsv}
                        style={{
                          opacity:
                            ptaParentFamilyInvoices.PTAParentFamilyInvoiceItems
                              .length === 0
                              ? 0.5
                              : 1,
                          cursor:
                            ptaParentFamilyInvoices.PTAParentFamilyInvoiceItems
                              .length === 0
                              ? "not-allowed"
                              : "pointer",
                        }}
                        data-tooltip-id="csv-download-tooltip"
                      >
                        CSVダウンロード
                      </SettingEditInfo>
                      {ptaParentFamilyInvoices.PTAParentFamilyInvoiceItems
                        .length === 0 && (
                        <Tooltip
                          id="csv-download-tooltip"
                          place="bottom"
                          content={csvDownloadTooltipMessage}
                          style={{ maxWidth: "300px" }}
                        />
                      )}
                      <SettingEditInfo
                        onClick={() => {
                          showChangeNameModal();
                        }}
                      >
                        請求項目名変更
                      </SettingEditInfo>
                    </SettingEditTab>
                  </>
                )}
              </div>
            </ButtonRow>
          </ButtonRowWrapper>
        }
      </HeaderRow>

      {ptaInvoiceProduct?.isProcessing && (
        <IsProcessing>
          現在請求作成処理を実行中です。完了までお待ちください。
          作成の完了はメールでお知らせします。
        </IsProcessing>
      )}
      {!isLoadingInvoiceProduct && !ptaInvoiceProduct?.isProcessing && (
        <PTAInvoiceDetailContent
          isLoadingParentFamilyInvoices={isLoadingParentFamilyInvoices}
          invoiceProductId={invoiceProductId}
          ptaParentFamilyInvoices={
            ptaParentFamilyInvoices.PTAParentFamilyInvoiceItems
          }
          searchText={searchText}
          allInvoiceCount={ptaParentFamilyInvoices.allInvoiceCount}
          onResetFilter={onResetFilter}
        />
      )}
      {ptaInvoiceProduct && isShownRemindModal && (
        <ResendInvoiceReminderModal
          ptaInvoiceProduct={ptaInvoiceProduct}
          pendingInvoiceCount={pendingInvoiceCount}
          onSubmit={handleRemindInvoice}
          onClose={closeRemindModal}
        />
      )}
      {isShownChangeNameModal && (
        <ChangeNameModal
          id={invoiceProductId}
          defaultName={ptaInvoiceProduct?.name ?? ""}
          onSubmit={handleChangeName}
          onClose={closeChangeNameModal}
        />
      )}
    </div>
  );
}

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const MobileInvoiceDiv = styled.div`
  @media (min-width: 1279px) {
    display: none;
  }
`;

const PCInvoiceDiv = styled.div`
  padding-bottom: 18px;
  padding-top: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const ButtonRowWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const IsProcessing = styled.div`
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchTextFieldWrapper = styled.div`
  flex: 1;
  height: auto;
`;

const SettingButton = styled.button`
  width: 35px;
  height: 35px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  padding-bottom: 8.75px;
  cursor: pointer;
`;

const SettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 160px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
  cursor: pointer;
  z-index: ${zIndexes.notModalMenu};
`;

const SettingEditInfo = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const ModalBackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndexes.notModalMenuBackground};
`;
