import { z } from "zod";
import { transformStringOrNumberToNumber } from "../rules/transform";
import { baseRequestSchema } from "./common.schema";
import { zRequiredString } from "../rules";

export const fetchChatRoomsSchema = z.object({
  filter: z.string(),
  take: transformStringOrNumberToNumber().optional(),
  page: transformStringOrNumberToNumber().optional(),
  currentCommunityId: z.string().optional(),
});

export type FetchChatRoomsSchemaType = z.infer<typeof fetchChatRoomsSchema>;

export const fetchChatRoomByIdSchema = baseRequestSchema.extend({
  chatRoomId: zRequiredString,
});

export type FetchChatRoomByIdSchemaType = z.infer<
  typeof fetchChatRoomByIdSchema
>;

export const getOrCreateIndividualChatRoomByCsAdminSchema =
  baseRequestSchema.extend({
    accountId: zRequiredString,
  });

export type GetOrCreateIndividualChatRoomByCsAdminSchemaType = z.infer<
  typeof getOrCreateIndividualChatRoomByCsAdminSchema
>;

export const createGroupChatRoomSchema = z.object({
  accountIds: z.array(zRequiredString).min(1, {
    message: "1名以上のユーザーを選択してください",
  }),
  name: zRequiredString,
});
export type CreateGroupChatRoomType = z.infer<typeof createGroupChatRoomSchema>;

export const updateGroupChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
  accountIds: z.array(zRequiredString),
  name: zRequiredString,
});
export type UpdateGroupChatRoomType = z.infer<typeof updateGroupChatRoomSchema>;

export const approveChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
});

export type ApproveChatRoomSchemaType = z.infer<typeof approveChatRoomSchema>;

export const rejectChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
});

export type RejectChatRoomSchemaType = z.infer<typeof rejectChatRoomSchema>;

export const deleteChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
});

export type DeleteChatRoomSchemaType = z.infer<typeof deleteChatRoomSchema>;

export const updateAdminOnlyChatRoomSchema = z.object({
  chatRoomId: zRequiredString,
  adminOnly: z.boolean(),
});

export type UpdateAdminOnlyChatRoomSchemaType = z.infer<
  typeof updateAdminOnlyChatRoomSchema
>;

export const getChatRoomMembersSchema = baseRequestSchema.extend({
  chatRoomId: zRequiredString,
});
export type GetChatRoomMembersType = z.infer<typeof getChatRoomMembersSchema>;

export const resendGroupChatInvitationSchema = z.object({
  chatRoomId: zRequiredString,
  accountIds: z.array(zRequiredString),
});
export type ResendGroupChatInvitationType = z.infer<
  typeof resendGroupChatInvitationSchema
>;
