import * as csChildApi from "../../apiClients/csChild";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";

export function useGetCSChildren(
  accountId: string | undefined,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: [
      "api",
      "csChild",
      "getChildrenByAccountId",
      apiContext,
      accountId,
    ],
    queryFn: accountId
      ? async () => {
          return await csChildApi.getChildrenByAccountId(accountId);
        }
      : undefined,
    enabled: !!accountId,
    ...queryOptions,
  });

  return {
    csChildren: useMemo(() => query.data, [query.data]),
    ...query,
  };
}

export function useInvalidateGetCSChildren() {
  const queryClient = useQueryClient();

  function invalidateGetCSChildren() {
    queryClient.invalidateQueries({
      queryKey: ["api", "csChild", "getChildrenByAccountId"],
    });
  }

  return {
    invalidateGetCSChildren,
  };
}

export function useGetCSChildrenByOrganizationId(
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "csChild", "getChildrenByOrganizationId", apiContext],
    queryFn: async () => {
      return await csChildApi.getChildrenByOrganizationId();
    },
    ...queryOptions,
  });

  return {
    csChildren: useMemo(() => query.data, [query.data]),
    ...query,
  };
}
