import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getChatRoomMembers } from "src/apiClients/chatRoom";
import { ChatRoomMember } from "@shared/types/chatRoom";

export function useGetChatRoomMembers(chatRoomId: string) {
  const query = useQuery<ChatRoomMember[], Error>({
    queryKey: ["api", "chatRoomMembers", chatRoomId],
    queryFn: () => getChatRoomMembers({ chatRoomId }),
    enabled: !!chatRoomId,
  });

  return {
    members: query.data ?? [],
    ...query,
  };
}

export function useInvalidateChatRoomMembers() {
  const queryClient = useQueryClient();

  function invalidateChatRoomMembers(chatRoomId: string | undefined) {
    queryClient.invalidateQueries({
      queryKey: ["api", "chatRoomMembers", chatRoomId],
    });
  }

  return { invalidateChatRoomMembers };
}
