import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Avatar } from "src/components/Common/Avatar";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { useGetRecruitApplicationsByScheduleId } from "src/hooks/query/recruitments/recruitmentApplicationsByScheduleId";
import { ModalPortal, ModalSubmitButtons } from "src/components/Modal";
import { Spinner } from "src/components/icons/Spinner";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { RecruitmentQRCard } from "./RecruitmentQRCard";
import { useReactToPrint } from "react-to-print";
import { useToast } from "src/components/Toast";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isInternalRoleAdmin } from "@shared/utils/internalUserRole";

type Props = {
  recruitmentId?: string;
  recruitmentScheduleId?: string;
  onClose: () => void;
};

export const ParticipantsQRCodeModal = ({
  recruitmentId,
  recruitmentScheduleId,
  onClose,
}: Props) => {
  const { recruitment, isLoading } = useGetRecruitApplicationsByScheduleId(
    recruitmentId || "",
    recruitmentScheduleId || ""
  );
  const currentUser = useCurrentUser();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const selectedApplications = recruitment?.RecruitmentApplication?.filter(
    (app) => selectedUsers.includes(app.id)
  );
  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");
  const componentRef = useRef<HTMLDivElement>(null);
  const toast = useToast();
  const printCard = useReactToPrint({
    documentTitle: "出席用QRコード",
    contentRef: componentRef,
    onAfterPrint: () => {
      onClose();
    },
    onPrintError: (error) => {
      console.error(error);
      toast.error("印刷に失敗しました");
    },
  });
  const handlePrint = () => {
    printCard();
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedUsers(
        recruitment?.RecruitmentApplication?.map((app) => app.id) || []
      );
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (userId: string) => {
    setSelectedUsers((prev) => {
      if (prev.includes(userId)) {
        return prev.filter((id) => id !== userId);
      }
      return [...prev, userId];
    });
  };

  const isAllSelected =
    recruitment?.RecruitmentApplication?.length === selectedUsers.length;

  const truncateString = (str: string): string => {
    if (str.length <= 5) {
      return str;
    }
    return str.slice(0, 5) + "...";
  };

  if (!isInternalRoleAdmin(currentUser.internalRole)) {
    return <></>;
  }

  if (isLoading) {
    return (
      <ModalPortal onClose={onClose}>
        <LoadingCard>
          <Spinner />
        </LoadingCard>
      </ModalPortal>
    );
  }

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>参加者QRコード印刷</ModalHeader>
        <Description>
          参加者の出席のために必要なQRコードを印刷できます。QRコードを印刷するユーザーを選択してください。
        </Description>
        <NameListTable>
          <NameListColumnNameRow head={true}>
            <NameListColumn>
              <span>参加者一覧</span>
              <SelectAllCheckBox onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                />
                <label
                  onClick={() =>
                    handleSelectAll({
                      target: { checked: !isAllSelected },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                >
                  全選択（{recruitment?.RecruitmentApplication?.length || 0}名）
                </label>
              </SelectAllCheckBox>
            </NameListColumn>
          </NameListColumnNameRow>
          <ScrollWrapper>
            {recruitment?.RecruitmentApplication?.length === 0 && (
              <NonUserNameListColumnNameRow>
                <Name>該当するユーザーがいません</Name>
              </NonUserNameListColumnNameRow>
            )}
            {recruitment?.RecruitmentApplication?.map((application) => (
              <NameListColumnNameRow
                key={application.id}
                onClick={() => handleSelectUser(application.id)}
              >
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(application.id)}
                />
                <Avatar
                  src={application.user.picture || defaultUserImg}
                  alt="ユーザーのアイコン"
                  size={40}
                />
                <Name>{application.user.name}</Name>
              </NameListColumnNameRow>
            ))}
          </ScrollWrapper>
        </NameListTable>

        {selectedUsers.length > 0 && (
          <SelectedAccountView>
            <p>選択中の参加者</p>
            <SelectedAccountsList>
              {selectedUsers.map((userId) => {
                const selectedApplication =
                  recruitment?.RecruitmentApplication?.find(
                    (app) => app.id === userId
                  );

                return (
                  <SelectedResident key={userId}>
                    <AvatarWrapper>
                      <Avatar
                        src={
                          selectedApplication?.user.picture || defaultUserImg
                        }
                        alt={selectedApplication?.user.name || ""}
                        size={30}
                      />
                      <RemoveButton onClick={() => handleSelectUser(userId)}>
                        <CloseIcon />
                      </RemoveButton>
                    </AvatarWrapper>
                    <p>
                      {truncateString(selectedApplication?.user.name || "")}
                    </p>
                  </SelectedResident>
                );
              })}
            </SelectedAccountsList>
          </SelectedAccountView>
        )}

        <ModalSubmitButtons
          onCancel={onClose}
          onSubmit={handlePrint}
          submitText="印刷する"
          cancelText="閉じる"
          disabled={selectedUsers.length === 0}
        />
      </ModalPortal>

      {/* 印刷用のDOM */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          visibility: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            flexWrap: "wrap",
          }}
          ref={componentRef}
        >
          {selectedApplications?.map((application) => (
            <RecruitmentQRCard
              key={application.id}
              recruitmentName={recruitment?.title || ""}
              schoolName={recruitment?.community.name || ""}
              userName={application.user.name}
              start={recruitment?.schedule.start || new Date()}
              end={recruitment?.schedule.end || new Date()}
              recruitmentId={recruitment?.id || ""}
              recruitmentScheduleId={recruitment?.schedule.id || ""}
              recruitApplicationEventId={application.eventStatus.id}
              isBE={false}
            />
          ))}
        </div>
      </div>
    </>
  );
};
const ModalHeader = styled.div`
  padding-bottom: 12px;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1a1c21;
`;

const Description = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
`;

const NameListTable = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const NameListColumn = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  background-color: #f5f7fa;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const NonUserNameListColumnNameRow = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;
  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
`;

const NameListColumnNameRow = styled.div<{
  head?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  border-left: none;
  border-right: none;
  border-bottom: none;
  justify-content: flex-start;
  background-color: #fff;
  border-collapse: collapse;
  cursor: pointer;
  &:hover {
    filter: brightness(95%);
  }
  ${(props) =>
    props.head &&
    `
    padding: 0px;
    border: none;
    background-color: #f5f7fa;
    font-weight: bold;
    color: #343741;
    cursor: default;
    &:hover {
      filter: brightness(100%);
    }
      span{
       pointer-events: none;
      }
    `};
`;

const ScrollWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const Name = styled.p`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: center;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectedAccountView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  p {
    font-size: 12px;
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  margin-top: 8px;
  width: fit-content;
`;

const RemoveButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: -8px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aab4c4;
  border-radius: 50%;
  background-color: #343741;
  padding: 3px;
`;

const SelectedResident = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`;

const SelectedAccountsList = styled.div`
  display: flex;
  gap: 12px;
  max-width: 100%;
  overflow-x: auto;
`;

const SelectAllCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  gap: 2px;
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`;
