import React from "react";
import { Button } from "src/components/Button";
import { RegisterStepper } from "src/components/RegisterStepper";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import * as Typo from "src/components/Typo";
import { Margin } from "src/components/Margin";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { useCurrentUser } from "src/hooks/recoil/user";
import { Container, ContentWrapper, PaddingWrapper } from "./components/styles";
import styled from "styled-components";
import { InvoiceFlowType } from "./utils/types";
import { estimatedMinutes } from "./utils/estimatedMinutes";
import { useGetPTAInvoiceImportRows } from "src/hooks/query/invoice/ptaInvoiceImport";

const CURRENT_STEP = 5;

type Props = {
  flowType: InvoiceFlowType;
};

export const PTAInvoiceProcessImportCompletePage = ({ flowType: _ }: Props) => {
  const currentUser = useCurrentUser();
  const history = usePolyfitHistory();

  const { id: invoiceProductId, importId } = usePolyfitLocationQuery(
    "PTA_INVOICE_PROCESS",
    {
      id: "",
      importId: "",
    }
  );
  const { invoiceImportRows } = useGetPTAInvoiceImportRows(
    invoiceProductId,
    importId ?? ""
  );

  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  return (
    <Container>
      <RegisterStepper count={5} current={CURRENT_STEP} title="" />
      <Margin marginBottom={16} />
      <PaddingWrapper>
        <ContentWrapper>
          <Typo.Heading2>請求作成を受け付けました</Typo.Heading2>
          <Margin marginBottom={24} />
          <Description>
            請求書の作成を受け付けました。作成の完了はメールでお知らせします。
            <br />
            作成完了までの予想時間：約
            {estimatedMinutes(invoiceImportRows?.rows?.length ?? 0)}分
            <br />
            <br />
            作成した請求は作成完了後に請求詳細で確認できます。
          </Description>
          <CenteredButtonWrapper>
            <Button
              color="primary"
              $fill
              size="large"
              onClick={() => {
                history.push({
                  to: "PTA_INVOICE_DETAIL",
                  query: {
                    id: invoiceProductId,
                  },
                });
              }}
            >
              請求詳細へ
            </Button>
          </CenteredButtonWrapper>
        </ContentWrapper>
      </PaddingWrapper>
    </Container>
  );
};

const CenteredButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
`;

const Description = styled.div`
  font-size: 14px;
`;
