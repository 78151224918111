import React from "react";
import styled from "styled-components";
import { ModalBody } from "src/components/Modal";
import { Button } from "src/components/Button";
import {
  AdminGroupChatIcon,
  MessageLinesIcon,
  UserGroupIcon,
} from "src/components/icons/SvgIcons";
import { ChatRoomType } from "@shared/types/chatRoom";
import { colorsPallet } from "src/theme";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isCsAdminRole } from "src/utils/types/role";

type Props = {
  setChatRoomType: (chatRoomType: ChatRoomType | undefined) => void;
};

export const ChatRoomSelectorToAppend = ({ setChatRoomType }: Props) => {
  const user = useCurrentUser();

  return (
    <>
      <>
        <ModalHeader>メッセージルームを作成</ModalHeader>
        <ModalBody>
          <IconWrapper>
            <ButtonWrapper
              onClick={() => {
                setChatRoomType("INDIVIDUAL_CHAT_WITH_ORGANIZATION");
              }}
            >
              <UserGroupIcon size="45" color={colorsPallet.primary} />
              <p>個別メッセージ</p>
            </ButtonWrapper>
            <ButtonWrapper
              onClick={() => {
                setChatRoomType("GROUP_CHAT_WITH_ORGANIZATION");
              }}
            >
              <MessageLinesIcon size="45" color={colorsPallet.primary} />
              <p>グループメッセージ</p>
            </ButtonWrapper>
            {isCsAdminRole(user.role) ? (
              <ButtonWrapper
                onClick={() => {
                  setChatRoomType("GROUP_CHAT_WITH_CS_ADMIN");
                }}
              >
                <div style={{ transform: "scale(1.2)" }}>
                  <AdminGroupChatIcon size="50" color={colorsPallet.primary} />
                </div>
                <p>管理者間メッセージ</p>
              </ButtonWrapper>
            ) : null}
          </IconWrapper>
        </ModalBody>
      </>
    </>
  );
};

const IconWrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ButtonWrapper = styled(Button)`
  display: block;
  background: #fbfcfd;
  width: 150px;
  padding: 10px 1px;
  border: 1px solid ${colorsPallet.lightShade};
  color: ${colorsPallet.primary};
  font-weight: bold;
  text-align: center;
  align-items: center;
  flex-direction: column;
  &:disabled {
    opacity: 0.3;
  }
`;

const ModalHeader = styled.div`
  padding-bottom: 24px;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1a1c21;
`;
