import styled, { css } from "styled-components";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  ChatRoomType,
  participantApprovalStatusOptions,
} from "@shared/types/chatRoom";
import {
  ChatRoomFilterType,
  csRoleOptions,
} from "@shared/types/boardEducation/chatRoom";
import { applicationStatusOptions } from "src/apiClients/recruitmentApplication";
import { DatePicker } from "src/components/form/DatePicker";
import { Label } from "src/components/form/Label";
import { approvalStatusOptions } from "@shared/types/chatRoom";
import { Button } from "src/components/Button";
import { useExtractSchools } from "../../Member/Community/utils/hooks";
import { Community } from "@shared/types/community";
import { zIndexes } from "src/zIndex";
import { BREAKPOINTS } from "src/components/Responsive";

export type Props = {
  setChatRoomFilter: Dispatch<SetStateAction<ChatRoomFilterType>>;
  chatRoomFilter: ChatRoomFilterType;
  onClose: () => void;
  resetFilter: () => void;
  communities: Community[];
  loading: boolean;
};

export const ChatRoomFilter = ({
  onClose,
  chatRoomFilter,
  setChatRoomFilter,
  resetFilter,
  communities,
  loading,
}: Props) => {
  const { handleSubmit, register, control, watch, setValue } =
    useForm<ChatRoomFilterType>({
      defaultValues: chatRoomFilter,
    });
  const { elementarySchools, juniorHighSchools } =
    useExtractSchools(communities);

  const watchChatRoomType = watch("type");
  const chatRoomName = watch("chatRoomName");

  const onSubmit = (data: ChatRoomFilterType) => {
    setChatRoomFilter(data);
    onClose();
  };

  const onReset = () => {
    resetFilter();
    onClose();
  };

  const onChatRoomTypeChange = (type: ChatRoomType) => {
    setValue("recruitmentName", "");
    setValue("recruitmentSchedule", null);
    setValue("applicationStatus", "");
    setValue("chatRoomName", "");
    setValue("approvalStatus", "");
    setValue("participantChatRoomApprovalStatus", "");
    setValue("communityIds", []);
    setValue("roles", []);

    switch (type) {
      case ChatRoomType.RECRUITMENT_APPLICATION:
      case ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION:
      case ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION:
      case ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION:
      case ChatRoomType.GROUP_CHAT_WITH_CS_ADMIN:
        setValue("showCSOrganizationChat", true);
        break;
      default:
        setValue("showCSOrganizationChat", false);
    }
  };

  const showFilter = useCallback(
    (type: ChatRoomType) => {
      return type === watchChatRoomType;
    },
    [watchChatRoomType]
  );

  const disabledFilter = useCallback(
    (type: ChatRoomType) => {
      return type !== watchChatRoomType;
    },
    [watchChatRoomType]
  );

  return (
    <>
      <FilterModalWrap>
        <FilterModal>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ScrollArea>
              <Section>
                <SectionTitle>チャットタイプ</SectionTitle>
                <SectionInputArea>
                  <InputContainer>
                    <Label>
                      <input
                        type="radio"
                        value={
                          ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION
                        }
                        {...register("type", {
                          onChange: (e) => onChatRoomTypeChange(e.target.value),
                        })}
                      />
                      教育委員会個別メッセージ
                    </Label>

                    <Label>
                      <input
                        type="radio"
                        value={ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION}
                        {...register("type", {
                          onChange: (e) => onChatRoomTypeChange(e.target.value),
                        })}
                      />
                      教育委員会グループチャット
                    </Label>

                    <Label>
                      <input
                        type="radio"
                        value={
                          ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                        }
                        {...register("type", {
                          onChange: (e) => onChatRoomTypeChange(e.target.value),
                        })}
                      />
                      教育委員会応募メッセージ
                    </Label>

                    <Label>
                      <input
                        type="radio"
                        value={ChatRoomType.GROUP_CHAT_WITH_CS_ADMIN}
                        {...register("type", {
                          onChange: (e) => onChatRoomTypeChange(e.target.value),
                        })}
                      />
                      管理者間メッセージ
                    </Label>

                    <Label>
                      <input
                        type="radio"
                        value={ChatRoomType.RECRUITMENT_APPLICATION}
                        {...register("type", {
                          onChange: (e) => onChatRoomTypeChange(e.target.value),
                        })}
                      />
                      応募メッセージ
                    </Label>
                  </InputContainer>

                  <InputContainer>
                    <Label>
                      <input
                        type="radio"
                        value={ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION}
                        {...register("type", {
                          onChange: (e) => onChatRoomTypeChange(e.target.value),
                        })}
                      />
                      個別メッセージ
                    </Label>
                  </InputContainer>

                  <InputContainer>
                    <Label>
                      <input
                        type="radio"
                        value={ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION}
                        {...register("type", {
                          onChange: (e) => onChatRoomTypeChange(e.target.value),
                        })}
                      />
                      グループメッセージ
                    </Label>
                  </InputContainer>
                </SectionInputArea>
              </Section>

              {(showFilter(ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION) ||
                showFilter(ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION)) && (
                <Section>
                  <SectionTitle>役割</SectionTitle>
                  <SectionInputArea>
                    <InputContainer>
                      {csRoleOptions.map((option) => (
                        <Label key={option.value}>
                          <input
                            checked={watch("roles")?.includes(option.value)}
                            onChange={(e) => {
                              const currentRoles = watch("roles") || [];
                              const newRoles = e.target.checked
                                ? [...currentRoles, option.value]
                                : currentRoles.filter(
                                    (role) => role !== option.value
                                  );
                              setValue("roles", newRoles);
                            }}
                            type="checkbox"
                          />
                          {option.text}
                        </Label>
                      ))}
                    </InputContainer>
                  </SectionInputArea>
                </Section>
              )}

              {(showFilter(ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION) ||
                showFilter(ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION) ||
                showFilter(ChatRoomType.RECRUITMENT_APPLICATION)) && (
                <>
                  <Section>
                    <SectionTitle>小学校区</SectionTitle>
                    <SectionInputArea>
                      <InputContainer>
                        {elementarySchools.map((school) => (
                          <Label key={school.id}>
                            <input
                              checked={watch("communityIds")?.includes(
                                school.id
                              )}
                              onChange={(e) => {
                                const currentIds = watch("communityIds") || [];
                                const newIds = e.target.checked
                                  ? [...currentIds, school.id]
                                  : currentIds.filter((id) => id !== school.id);
                                setValue("communityIds", newIds);
                              }}
                              type="checkbox"
                            />
                            {school.name}
                          </Label>
                        ))}
                      </InputContainer>
                    </SectionInputArea>
                  </Section>

                  <Section>
                    <SectionTitle>中学校区</SectionTitle>
                    <SectionInputArea>
                      <InputContainer>
                        {juniorHighSchools.map((school) => (
                          <Label key={school.id}>
                            <input
                              checked={watch("communityIds")?.includes(
                                school.id
                              )}
                              onChange={(e) => {
                                const currentIds = watch("communityIds") || [];
                                const newIds = e.target.checked
                                  ? [...currentIds, school.id]
                                  : currentIds.filter((id) => id !== school.id);
                                setValue("communityIds", newIds);
                              }}
                              type="checkbox"
                            />
                            {school.name}
                          </Label>
                        ))}
                      </InputContainer>
                    </SectionInputArea>
                  </Section>
                </>
              )}

              {showFilter(
                ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION
              ) && (
                <>
                  <Section>
                    <SectionTitle>名前</SectionTitle>
                    <SectionInputArea>
                      <TextField
                        value={chatRoomName}
                        onChange={(e) =>
                          setValue("chatRoomName", e.target.value)
                        }
                        placeholder="名前を入力"
                        disabled={disabledFilter(
                          ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION
                        )}
                      />
                    </SectionInputArea>
                  </Section>
                </>
              )}

              {showFilter(ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION) && (
                <>
                  <Section>
                    <SectionTitle>招待ステータス</SectionTitle>
                    <SectionInputArea>
                      <Select
                        {...register("participantChatRoomApprovalStatus")}
                        disabled={disabledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION
                        )}
                      >
                        {[
                          { value: "", text: "すべて" },
                          ...participantApprovalStatusOptions,
                        ].map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                    </SectionInputArea>
                  </Section>
                  <Section>
                    <SectionTitle>名前</SectionTitle>
                    <SectionInputArea>
                      <TextField
                        value={chatRoomName}
                        onChange={(e) =>
                          setValue("chatRoomName", e.target.value)
                        }
                        placeholder="グループ名を入力"
                        disabled={disabledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION
                        )}
                      />
                    </SectionInputArea>
                  </Section>
                </>
              )}

              {(showFilter(ChatRoomType.RECRUITMENT_APPLICATION) ||
                showFilter(
                  ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                )) && (
                <>
                  <Section>
                    <SectionTitle>募集名</SectionTitle>
                    <SectionInputArea>
                      <TextField
                        {...register("recruitmentName")}
                        placeholder="募集名を入力"
                        disabled={
                          disabledFilter(
                            ChatRoomType.RECRUITMENT_APPLICATION
                          ) &&
                          disabledFilter(
                            ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                          )
                        }
                      />
                    </SectionInputArea>
                  </Section>

                  <Section>
                    <SectionTitle>応募日</SectionTitle>
                    <SectionInputArea>
                      <DatePicker
                        control={control}
                        name="recruitmentSchedule"
                        disabled={
                          disabledFilter(
                            ChatRoomType.RECRUITMENT_APPLICATION
                          ) &&
                          disabledFilter(
                            ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                          )
                        }
                      />
                    </SectionInputArea>
                  </Section>

                  <Section>
                    <SectionTitle>応募ステータス</SectionTitle>
                    <SectionInputArea>
                      <Select
                        {...register("applicationStatus")}
                        disabled={
                          disabledFilter(
                            ChatRoomType.RECRUITMENT_APPLICATION
                          ) &&
                          disabledFilter(
                            ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION
                          )
                        }
                      >
                        {[
                          { value: "", text: "すべて" },
                          ...applicationStatusOptions,
                        ].map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                    </SectionInputArea>
                  </Section>
                </>
              )}

              {showFilter(ChatRoomType.GROUP_CHAT_WITH_CS_ADMIN) && (
                <>
                  <Section>
                    <SectionTitle>名前</SectionTitle>
                    <SectionInputArea>
                      <TextField
                        value={chatRoomName}
                        onChange={(e) =>
                          setValue("chatRoomName", e.target.value)
                        }
                        placeholder="グループ名を入力"
                        disabled={disabledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_CS_ADMIN
                        )}
                      />
                    </SectionInputArea>
                  </Section>
                </>
              )}

              {showFilter(ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION) && (
                <>
                  <Section>
                    <SectionTitle>承認ステータス</SectionTitle>
                    <SectionInputArea>
                      <Select
                        {...register("approvalStatus")}
                        disabled={disabledFilter(
                          ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION
                        )}
                      >
                        {[
                          { value: "", text: "すべて" },
                          ...approvalStatusOptions,
                        ].map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                    </SectionInputArea>
                  </Section>
                  <Section>
                    <SectionTitle>名前</SectionTitle>
                    <SectionInputArea>
                      <TextField
                        value={chatRoomName}
                        onChange={(e) =>
                          setValue("chatRoomName", e.target.value)
                        }
                        placeholder="名前を入力"
                        disabled={disabledFilter(
                          ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION
                        )}
                      />
                    </SectionInputArea>
                  </Section>
                </>
              )}

              {showFilter(ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION) && (
                <>
                  <Section>
                    <SectionTitle>招待ステータス</SectionTitle>
                    <SectionInputArea>
                      <Select
                        {...register("participantChatRoomApprovalStatus")}
                        disabled={disabledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION
                        )}
                      >
                        {[
                          { value: "", text: "すべて" },
                          ...participantApprovalStatusOptions,
                        ].map((opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        ))}
                      </Select>
                    </SectionInputArea>
                  </Section>
                  <Section>
                    <SectionTitle>名前</SectionTitle>
                    <SectionInputArea>
                      <TextField
                        value={chatRoomName}
                        onChange={(e) =>
                          setValue("chatRoomName", e.target.value)
                        }
                        placeholder="グループ名を入力"
                        disabled={disabledFilter(
                          ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION
                        )}
                      />
                    </SectionInputArea>
                  </Section>
                </>
              )}

              <FilterButtonWrapper>
                <Button style={{ width: "100%" }} onClick={onReset}>
                  クリア
                </Button>
                <Button
                  $fill
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={loading}
                >
                  この条件で検索
                </Button>
              </FilterButtonWrapper>
            </ScrollArea>
          </form>
        </FilterModal>
      </FilterModalWrap>
      <ModalBackGround onClick={onClose}></ModalBackGround>
    </>
  );
};

export const FilterModalWrap = styled.div`
  position: relative;
  z-index: ${zIndexes.notModalMenu};
`;

export const ModalBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

export const FilterModal = styled.div`
  width: 355px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  padding: 14px;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const ScrollArea = styled.div`
  overflow-y: auto;
  max-height: calc(100vh * 0.6);
  @media (max-width: ${BREAKPOINTS.SP}) {
    max-height: calc(100vh * 0.4);
  }
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 20px;
  width: 100%;
`;

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  width: 113px;
`;

const SectionInputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: #aab4c4;
      background-color: #fbfcfd;
      cursor: not-allowed;
      opacity: 0.5;
      border-color: #ddd;
    `}
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  appearance: none;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      color: #aab4c4;
      background-color: #fbfcfd;
      cursor: not-allowed;
      opacity: 0.5;
      border-color: #ddd;
    `}
`;

const InputContainer = styled.div`
  color: #1a1c21;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 8px;
    cursor: pointer;
  }
`;

const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;
