import React, { useEffect } from "react";
import { Button } from "src/components/Button";
import { RegisterStepper } from "src/components/RegisterStepper";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import styled from "styled-components";
import * as Typo from "src/components/Typo";
import { Margin } from "src/components/Margin";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import {
  useCreatePTAInvoiceProduct,
  useGetPTAInvoiceProduct,
  useUpdatePTAInvoiceProduct,
} from "src/hooks/query/invoice/ptaInvoiceProduct";
import { useToast } from "src/components/Toast";
import { Label } from "src/components/form/Label";
import { GradeAmountSection } from "./components/GradeAmountSection";
import {
  PTAInvoiceProcessCreateNewFormSchema,
  PTAInvoiceProcessCreateNewFormValues,
} from "./utils/schema";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import {
  Container,
  PaddingWrapper,
  ContentWrapper,
  SubTitleWrapper,
  ErrorMessage,
  ButtonWrapper,
} from "./components/styles";
import { INVOICE_PROCESS_PATH, InvoiceFlowType } from "./utils/types";

type Props = {
  flowType: InvoiceFlowType;
};

export const PTAInvoiceProcessCreateNewPage = ({ flowType }: Props) => {
  const currentUser = useCurrentUser();
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );
  const { organization } = useGetCurrentOrganization();
  const schoolType = organization?.schoolType;
  const grades = useGradeOptions(schoolType);
  const history = usePolyfitHistory();
  const toast = useToast();
  const createMutation = useCreatePTAInvoiceProduct();
  const updateMutation = useUpdatePTAInvoiceProduct();
  const { id: invoiceProductId } = usePolyfitLocationQuery(
    INVOICE_PROCESS_PATH[flowType],
    {
      id: "",
      step: "1",
    }
  );
  const { data: currentProduct } = useGetPTAInvoiceProduct(invoiceProductId);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PTAInvoiceProcessCreateNewFormValues>({
    resolver: zodResolver(PTAInvoiceProcessCreateNewFormSchema),
    mode: "onBlur",
    defaultValues: {
      name: "",
      amountsByGrades: [],
    },
  });

  useEffect(() => {
    if (currentProduct) {
      setValue("name", currentProduct.name);
      if (Array.isArray(currentProduct.amountsByGrades)) {
        setValue("amountsByGrades", currentProduct.amountsByGrades);
      }
    } else if (grades.length > 0) {
      setValue(
        "amountsByGrades",
        grades.map((grade) => ({
          grade: grade.id,
          amount: NaN,
        }))
      );
    }
  }, [currentProduct, grades, setValue]);

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    // 閲覧できないための画面/ロジック
    return <div>閲覧できません</div>;
  }

  const prevStep = () => {
    if (flowType === "new") {
      history.push({
        to: "PTA_INVOICE_LIST",
      });
    } else {
      history.push({
        to: "PTA_INVOICE_DETAIL",
        query: { id: invoiceProductId },
      });
    }
  };

  const onSubmit = async (data: PTAInvoiceProcessCreateNewFormValues) => {
    try {
      const product = invoiceProductId
        ? await updateMutation.mutateAsync({
            id: invoiceProductId,
            name: data.name,
            amountsByGrades: data.amountsByGrades,
          })
        : await createMutation.mutateAsync({
            name: data.name,
            amountsByGrades: data.amountsByGrades,
          });

      history.push({
        to: INVOICE_PROCESS_PATH[flowType],
        query: { id: product.id, step: "2" },
      });
    } catch (error) {
      toast.error("請求項目の作成に失敗しました");
    }
  };

  const pageTitle = currentProduct
    ? flowType === "new"
      ? "請求項目の編集"
      : "追加発行する請求項目の編集"
    : flowType === "new"
    ? "請求項目の発行"
    : "請求項目の追加発行";

  if (currentProduct?.deletedAt) {
    return <div>この請求項目は削除されています</div>;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RegisterStepper count={5} current={1} title="" />
        <Margin marginBottom={16} />
        <PaddingWrapper>
          <ContentWrapper>
            <SubTitleWrapper>
              <Typo.Heading3>{pageTitle}</Typo.Heading3>
            </SubTitleWrapper>
            <Margin marginBottom={16} />
            <InputWrapper>
              <Label size="m" required>
                請求項目名
              </Label>
              {flowType === "new" ? (
                <InputText
                  {...register("name")}
                  placeholder="例: 2025年度PTA会費"
                />
              ) : (
                <StaticText>{currentProduct?.name}</StaticText>
              )}
              <ErrorMessage>{errors.name?.message}</ErrorMessage>
            </InputWrapper>
            <GradeAmountSection
              register={register}
              errors={errors}
              grades={grades}
              schoolDisplayType={organization?.schoolDisplayType}
            />
          </ContentWrapper>
        </PaddingWrapper>
        <ButtonWrapper>
          <Button color="text" $fill onClick={prevStep} size="large">
            {flowType === "new" ? "請求一覧へ" : "請求詳細へ"}
          </Button>
          <Button color="primary" $fill type="submit" size="large">
            次へ
          </Button>
        </ButtonWrapper>
      </form>
    </Container>
  );
};

const InputText = styled.input`
  width: 100%;
  color: ${(props) => (props.disabled ? "#1322951A" : "#000000")};
  background-color: #fbfcfd;
  border: 1px solid
    ${(props) => (props.disabled ? "#ddd" : "rgba(19, 34, 149, 0.1)")};
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};

  ::placeholder {
    color: ${(props) => (props.disabled ? "#1322951A" : "#343741")};
  }
`;

const StaticText = styled.div`
  font-size: 14px;
`;

const InputWrapper = styled.div`
  margin-bottom: 16px;
`;
