import { z } from "zod";
import { gradeByAmountsSchema } from "../models/invoice/ptaInvoiceProduct.schema";

export const invoiceCSVCustomInfoSchema = z.object({
  amountsByGrades: gradeByAmountsSchema,
  onlyAdmin: z.boolean(),
  PTAInvoiceProductId: z.string().uuid(),
});

export type InvoiceCSVCustomInfoSchemaType = z.infer<
  typeof invoiceCSVCustomInfoSchema
>;

export const invoiceCSVCustomInfoSchemaForServer = z.object({
  filter: z.string(),
});

export type InvoiceCSVCustomInfoSchemaForServerType = z.infer<
  typeof invoiceCSVCustomInfoSchemaForServer
>;

export const exportCsvOfPTAInvoiceDetailSchema = z.object({
  PTAInvoiceProductId: z.string().uuid(),
  filter: z.string().optional(),
  q: z.string().optional(),
});

export type ExportCsvOfPTAInvoiceDetailSchemaType = z.infer<
  typeof exportCsvOfPTAInvoiceDetailSchema
>;
