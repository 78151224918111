import React, { useState, forwardRef, useEffect } from "react";
import styled from "styled-components";

export interface TextFieldProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: string;
  onChange: (value: string) => void;
  prepend?: React.ReactNode;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  heightPadding?: string;
}

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => {
    const {
      value: originalValue,
      onChange,
      prepend,
      onKeyDown,
      heightPadding,
      ...others
    } = props;

    const [value, setValue] = useState(originalValue);
    const [isComposing, setIsComposing] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);

      if (!isComposing) {
        onChange?.(e.target.value);
      }
    };

    const handleCompositionStart = () => {
      setIsComposing(true);
    };

    const handleCompositionEnd = (
      e: React.CompositionEvent<HTMLInputElement>
    ) => {
      setIsComposing(false);
      onChange?.(value);
    };

    useEffect(() => {
      setValue(originalValue);
    }, [originalValue]);

    return (
      <TextFieldWrapper ref={ref} heightPadding={heightPadding}>
        {prepend && <PrependWrapper>{prepend}</PrependWrapper>}
        <TextFieldInput
          type="text"
          value={value}
          onChange={handleChange}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          onKeyDown={onKeyDown}
          {...others}
        />
      </TextFieldWrapper>
    );
  }
);

const TextFieldWrapper = styled.div<{ heightPadding?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  padding: ${({ heightPadding }) =>
    heightPadding ? `${heightPadding} 12px` : "10px 12px"};
  width: 100%;
  background: #fbfcfd;
`;

const PrependWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const TextFieldInput = styled.input`
  width: 100%;
  border: none;
  font-size: 14px;
  color: #343741;
  background: #fbfcfd;
  line-height: 21px;
  &::placeholder {
    color: #aab4c4;
  }
`;
