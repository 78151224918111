import React from "react";
import styled from "styled-components";
import { ChatRoomMember } from "@shared/types/chatRoom";
import { colorsPallet } from "src/theme";
import { ChatRoomMemberRow } from "./ChatRoomMemberRow";

type Props = {
  members: ChatRoomMember[];
  isLoading?: boolean;
  error?: Error | null;
  disableMemberLinks?: boolean;
  onMemberClick?: (member: ChatRoomMember) => void;
};

export const ChatRoomMemberList: React.FC<Props> = ({
  members,
  isLoading,
  error,
  disableMemberLinks,
  onMemberClick,
}) => {
  if (isLoading) return <></>;
  if (error)
    return <ErrorMessage>メンバー情報の取得に失敗しました</ErrorMessage>;
  if (!members || members.length === 0)
    return <EmptyMessage>参加中のメンバーがいません</EmptyMessage>;

  return (
    <NameListTable>
      <NameListColumnNameRow head>
        <NameListColumn>メンバー一覧</NameListColumn>
      </NameListColumnNameRow>
      <ScrollWrapper>
        {members.map((member) => (
          <ChatRoomMemberRow
            key={member.participantChatRoom.id}
            member={member}
            onMemberClick={disableMemberLinks ? undefined : onMemberClick}
          />
        ))}
      </ScrollWrapper>
    </NameListTable>
  );
};

const NameListTable = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const NameListColumnNameRow = styled.div<{ head?: boolean }>`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f7fa;
  border-collapse: collapse;

  ${(props) =>
    props.head &&
    `
    font-weight: bold;
    color: #343741;
    &:hover {
      filter: brightness(100%);
    }
  `}
`;

const NameListColumn = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

const ScrollWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const EmptyMessage = styled.div`
  padding: 16px;
  color: ${colorsPallet.mediumShade};
  text-align: center;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  background-color: #fff0ef;
  color: #bd271e;
  font-size: 12px;
  text-align: start;
  padding: 6px 10px;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
`;
