import { SchoolDisplayType } from "@shared/types/organization";
import { TargetGradesType } from "@shared/types/post/api";
import { GradeSize } from "@shared/validator/features/gradeSize.schema";
import React from "react";
import Chart from "./Chart";
import styled from "styled-components";
import { Button } from "src/components/Button";
import { usePolyfitHistory } from "src/hooks/router";

function ChartList({
  childrenCountsByGrade,
  gradeSizes,
  grades,
  schoolDisplayType,
}: {
  childrenCountsByGrade: { grade: number; _count: number }[];
  gradeSizes: GradeSize[] | undefined;
  grades: { id: TargetGradesType; name: string }[];
  schoolDisplayType: SchoolDisplayType;
}) {
  const history = usePolyfitHistory();
  return (
    <>
      {gradeSizes?.length === 0 ? (
        <NavigateToGradeSizeSetting>
          グラフを表示するには生徒数を設定してください
          <Button
            $fill
            color="primary"
            onClick={() => {
              history.push({ to: "PTA_GRADE_SIZE_SETTING" });
            }}
          >
            生徒数を設定する
          </Button>
        </NavigateToGradeSizeSetting>
      ) : (
        <ChartListSection>
          {grades.map((grade) => (
            <ChartWrapper key={grade.id}>
              <Chart
                childrenCounts={
                  childrenCountsByGrade.find(
                    (count) => count.grade === grade.id
                  )?._count ?? undefined
                }
                gradeSize={
                  gradeSizes?.find((size) => size.grade === grade.id)?.size
                }
                grade={grade}
                schoolDisplayType={schoolDisplayType}
              />
            </ChartWrapper>
          ))}
        </ChartListSection>
      )}
    </>
  );
}

export default ChartList;

const ChartWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
`;

const ChartListSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 24px;
  width: 100%;
  column-gap: 8px;
`;

const NavigateToGradeSizeSetting = styled.div`
  width: 100%;
  box-shadow: 0px 0px 0.8px rgba(0, 0, 0, 0.06), 0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04), 0px 0px 17px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background-color: #fff;
  border-radius: 6px;
  font-size: 14px;
`;
