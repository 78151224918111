import styled from "styled-components";
import React from "react";
import { Button } from "src/components/Button";
import { Navigation } from "src/components/Navigation3";

type ErrorPageLayoutProps = {
  image: React.ReactNode;
  title: React.ReactNode;
  message: React.ReactNode;
};

export const ErrorPageLayout = (props: ErrorPageLayoutProps) => {
  const { image, title, message } = props;

  return (
    <>
      <Navigation />
      <Wrapper>
        <Content>
          {image}
          <Title>{title}</Title>
          <Message>{message}</Message>
          <Button
            size="large"
            $fill={true}
            width="160px"
            onClick={() => location.replace("/loading")}
          >
            ホームへ
          </Button>
        </Content>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 7.5vh;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 440px;
  width: 100%;
  padding: 14px;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 22px;
  color: #343741;
`;

const Message = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #343741;
  text-align: center;
`;
