import React, { JSX, useState } from "react";
import { Button } from "../../../components/Button";
import { useModal } from "../../../components/Modal";
import { toDateFormat } from "../../../utils/time";
import { useSurveyAnswersHooks } from "./hooks";
import { AnswerModal } from "./AnswerModal";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../components/Responsive";
import { SurveyQuestions } from "@shared/validator/features/survey.schema";

export function AnswerSurvey({
  postId,
  surveyQuestions,
  isAlreadyAnswered,
  setIsAlreadyAnswered,
}: {
  postId: string;
  surveyQuestions: SurveyQuestions | undefined;
  isAlreadyAnswered: boolean;
  setIsAlreadyAnswered: (isAlreadyAnswered: boolean) => void;
}): JSX.Element {
  // ref: https://vitejs.dev/guide/env-and-mode.html#env-variables
  if (!import.meta.env.VITE_FEATURE_SURVEY) {
    return <></>;
  }
  // サーバー側で弾いているはずだが安全側に倒しておく
  if (surveyQuestions === undefined) {
    return <></>;
  }
  // 期限切れであればボタンをdisabledにしてモーダル開閉を禁止する
  const pastDeadline: boolean = new Date(surveyQuestions.deadLine) < new Date();
  // アンケート回答の状態と管理するイベントを質問から生成
  const { surveyAnswers, surveyAnswersLoaded, eventHandlers } =
    useSurveyAnswersHooks(surveyQuestions);
  // モーダル状態
  const [, { show, close }] = useModal();
  const [isNewModal, setIsNewModal] = useState(false);
  // ボタン表示はすでに回答されているかで変化する
  const [buttonText, setButtonText] = useState<string>(
    isAlreadyAnswered ? "回答済みアンケートを編集" : "アンケートに回答"
  );

  return (
    <>
      <Container>
        <StyledButton
          $fill
          color="primary"
          disabled={pastDeadline}
          onClick={(event) => {
            // このボタンのクリックに際してはカード開閉のイベントを無視する
            event.stopPropagation();
            // モーダルを開く
            setIsNewModal(!isNewModal);
            show();
          }}
        >
          {buttonText}
        </StyledButton>
        <Deadline>
          回答期限：
          {toDateFormat(new Date(surveyQuestions.deadLine))}
        </Deadline>
      </Container>
      {isNewModal && (
        <AnswerModal
          postId={postId}
          surveyAnswers={surveyAnswers}
          surveyAnswersLoaded={surveyAnswersLoaded}
          eventHandlers={eventHandlers}
          deadLine={new Date(surveyQuestions.deadLine)}
          setButtonText={setButtonText}
          isAlreadyAnswered={isAlreadyAnswered}
          setIsAlreadyAnswered={setIsAlreadyAnswered}
          onClose={() => {
            // 内部から閉じられるように
            // モーダルを閉じる際のイベントを定義して渡す
            setIsNewModal(!isNewModal);
            close();
          }}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.PC}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledButton = styled(Button)`
  padding: 6px 12px;
`;

const Deadline = styled.p`
  color: rgba(105, 112, 125, 1);
  font-size: 14px;
`;
