import React, { useEffect } from "react";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { useCurrentUser } from "../../../hooks/recoil/user";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { useFieldArray, useForm } from "react-hook-form";
import { unreachable } from "../../../utils/unreachable";
import { classOption } from "../../../utils/getChildClass";
import { useGradeOptions } from "src/hooks/useGradeOptions";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { StepResident } from "src/components/icons/StepResident";
import { Role } from "src/utils/types/role";
import { updateChildren } from "src/apiClients/csChild";
import { useBaseInfo } from "src/hooks/api/baseInfo";
import {
  useGetCSChildren,
  useInvalidateGetCSChildren,
} from "src/hooks/query/csChild";
import {
  csChildUpdateQuerySchema,
  CsChildUpdateQueryType,
} from "@shared/validator/features/csChild.schema";
import { PlusIcon } from "src/components/icons/PlusIcon";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import { TrashIcon } from "src/components/icons/TrashIcon2";
import { Label } from "src/components/form/Label";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

export default function ResidentChildRegistrationPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { csChildren, isLoading: isCSChildLoading } = useGetCSChildren(
    user.account?.id
  );
  const { invalidateGetCSChildren } = useInvalidateGetCSChildren();
  const { currentBaseInfo, isLoading: isLoadingBaseInfo } = useBaseInfo();
  const { organization } = useGetCurrentOrganization();
  const { communityId } = useCurrentCommunityId();
  const { communities } = useOrganizationCommunity();

  const gradeOptions = useGradeOptions(organization?.schoolType);
  const { token } = usePolyfitLocationQuery("RESIDENT_CHILD_REGISTRATION", {
    token: undefined,
  });

  const onSubmit = async (data: FormValues) => {
    const childQuery: CsChildUpdateQueryType = [];
    for (const value of data.itemRows) {
      childQuery.push({
        communityId: value.communityId ?? communityId,
        accountId: user.account?.id ?? "",
        childLastName: value.childLastName,
        childFirstName: value.childFirstName,
        childLastNameKana: value.childLastNameKana,
        childFirstNameKana: value.childFirstNameKana ?? "",
        grade: value.grade ?? null,
        class: value.class ?? null,
        schoolCode: value.schoolCode ?? "",
      });
    }

    try {
      await updateChildren(childQuery);
      invalidateGetCSChildren();
      switch (user.role) {
        case Role.CSAdmin:
        case Role.CSMember:
        case Role.CSGuest:
        case Role.CSOperator:
          history.push({
            to: "RESIDENT_CONTACT_INFO",
            query: {
              ...(token && { token }),
            },
          });
          break;
        case Role.PTAAdmin:
        case Role.PTAMember:
        case Role.BEAdmin:
          break;
        default:
          unreachable(user.role);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // FormValues の型
  type FormValues = {
    itemRows: CsChildUpdateQueryType;
  };

  const formSchema = z.object({
    itemRows: csChildUpdateQuerySchema,
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onBlur",
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    name: "itemRows",
    control,
  });

  // itemRowsの入力状態を監視させる
  const watchFieldArray = watch("itemRows");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    // setValue関数は一度しか実行できないため、取得完了後に一度だけ実行する
    if (isCSChildLoading || isLoadingBaseInfo) {
      return;
    }

    if (csChildren?.length) {
      setValue(
        "itemRows",
        csChildren.map((child) => {
          const selectedCommunity = communities.find(
            (community) => community.id === child.communityId
          );
          return {
            id: child.id,
            accountId: user.account?.id ?? "",
            communityId: child.communityId,
            childLastName: child.childLastName ?? currentBaseInfo.lastName,
            childFirstName: child.childFirstName,
            childLastNameKana:
              child.childLastNameKana ?? currentBaseInfo.lastNameKana,
            childFirstNameKana: child.childFirstNameKana,
            schoolCode: selectedCommunity?.schoolCode ?? "",
            grade: child.grade ?? null,
            class: child.class ?? null,
          };
        })
      );
    } else {
      const selectedCommunity = communities.find(
        (community) => community.id === communityId
      );
      setValue("itemRows", [
        {
          accountId: user.account?.id ?? "",
          communityId: "",
          childLastName: currentBaseInfo.lastName,
          childFirstName: "",
          childLastNameKana: currentBaseInfo.lastNameKana,
          childFirstNameKana: "",
          schoolCode: selectedCommunity?.schoolCode ?? "",
          grade: null,
          class: null,
        },
      ]);
    }
  }, [
    csChildren,
    isCSChildLoading,
    isLoadingBaseInfo,
    currentBaseInfo,
    communities,
    communityId,
  ]);

  return (
    <div>
      <Margin marginBottom={24} />
      <StepResident stepNum={3} />

      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <StyledContainer>
          <Margin marginBottom={16} />
          <Typo.Heading3>お子さま情報の入力</Typo.Heading3>
          <Margin marginBottom={8} />
          <Responsive.Row>
            {controlledFields.map((field, index) => {
              return (
                <Responsive.Col key={index}>
                  <Typo.Heading4> {index + 1}人目</Typo.Heading4>
                  <Margin marginBottom={10} />
                  <Label required>お名前</Label>
                  <Margin marginBottom={8} />
                  <HorizontalInputWrapper>
                    <SelectWrapper>
                      <TextChildField
                        placeholder="例) 山田"
                        {...register(
                          `itemRows.${index}.childLastName` as const
                        )}
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childLastName != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childLastName?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                    <SelectWrapper>
                      <TextChildField
                        placeholder="例）太朗"
                        {...register(
                          `itemRows.${index}.childFirstName` as const
                        )}
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childFirstName != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childFirstName?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                  </HorizontalInputWrapper>
                  <Label required>ふりがな</Label>
                  <Margin marginBottom={8} />
                  <HorizontalInputWrapper>
                    <SelectWrapper>
                      <TextChildField
                        {...register(
                          `itemRows.${index}.childLastNameKana` as const
                        )}
                        placeholder="例）やまだ"
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childLastNameKana != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childLastNameKana?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                    <SelectWrapper>
                      <TextChildField
                        {...register(
                          `itemRows.${index}.childFirstNameKana` as const
                        )}
                        placeholder="例）たろう"
                      />
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.childFirstNameKana != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.childFirstNameKana?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                  </HorizontalInputWrapper>

                  <SelectWrapper>
                    <Label required>学校</Label>
                    <Margin marginBottom={8} />
                    <SelectChildInfo
                      {...register(`itemRows.${index}.communityId` as const, {
                        setValueAs: (v) => {
                          const selectedCommunity = communities.find(
                            (community) => community.id === v
                          );
                          setValue(
                            `itemRows.${index}.schoolCode` as const,
                            selectedCommunity?.schoolCode
                          );
                          return v;
                        },
                      })}
                    >
                      <option value="" disabled>
                        選択してください
                      </option>
                      {communities.map((opt) => {
                        return (
                          <option key={opt.id} value={opt.id}>
                            {opt.name}
                          </option>
                        );
                      })}
                    </SelectChildInfo>
                    {errors?.itemRows?.[index]?.communityId ? (
                      <span className="error-message">
                        {errors?.itemRows?.[index]?.communityId?.message}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </SelectWrapper>

                  <HorizontalInputWrapper>
                    <SelectWrapper>
                      <Label required>学年</Label>
                      <Margin marginBottom={8} />
                      <SelectChildInfo
                        {...register(`itemRows.${index}.grade` as const, {
                          setValueAs: (v) =>
                            v === "" ? null : parseInt(v, 10),
                          validate: (value) => {
                            if (value === undefined || value === null) {
                              return false;
                            }
                          },
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {gradeOptions.map((opt) => {
                          return (
                            <option key={opt.id} value={opt.id}>
                              {getGradeLabel(
                                opt.id,
                                organization?.schoolDisplayType,
                                true,
                                false,
                                "short"
                              )}
                            </option>
                          );
                        })}
                      </SelectChildInfo>
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.grade != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.grade?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                    <SelectWrapper>
                      <Label required>クラス</Label>
                      <Margin marginBottom={8} />
                      <SelectChildInfo
                        {...register(`itemRows.${index}.class` as const, {
                          setValueAs: (v) =>
                            v === "" ? null : parseInt(v, 10),
                          validate: (value) => {
                            if (value === undefined || value === null) {
                              return false;
                            }
                          },
                        })}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          選択してください
                        </option>
                        {classOption.map((opt) => {
                          return (
                            <option key={opt.value} value={opt.value}>
                              {opt.text}
                            </option>
                          );
                        })}
                      </SelectChildInfo>
                      {errors?.itemRows != null &&
                      errors?.itemRows[index]?.class != null ? (
                        <span className="error-message">
                          {errors?.itemRows[index]?.class?.message}
                        </span>
                      ) : (
                        <Margin marginTop={16} />
                      )}
                    </SelectWrapper>
                  </HorizontalInputWrapper>
                  {controlledFields.length > 1 && (
                    <TrashButton onClick={() => remove(index)} type="button">
                      <TrashIcon />
                    </TrashButton>
                  )}
                </Responsive.Col>
              );
            })}
            <AddChildForm
              type="button"
              onClick={() => {
                append({
                  accountId: user.account?.id ?? "",
                  communityId: "",
                  childLastName: currentBaseInfo.lastName,
                  childFirstName: "",
                  childLastNameKana: currentBaseInfo.lastNameKana,
                  childFirstNameKana: "",
                  schoolCode: "",
                  //@ts-ignore
                  grade: undefined,
                  //@ts-ignore
                  class: undefined,
                });
              }}
            >
              <PlusIcon size={16} />
              お子さまを追加
            </AddChildForm>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter onClickNext={handleSubmit(onSubmit)} />
      </form>
    </div>
  );
}

export const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

export const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

export const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

export const TrashButton = styled.button`
  border: none;
  display: block;
  margin: 0 0 0 auto;
  cursor: pointer;
  background: none;
`;

export const AddChildForm = styled.button`
  border: none;
  display: block;
  margin: 0 0 0 auto;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  width: 138px;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #5a606b;
`;

export const TextChildField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

export const SelectChildInfo = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;
