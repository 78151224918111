import React, { useState } from "react";
import { PostType } from "../../hooks/api/post";
import { Button } from "../../components/Button";
import { RePostModal } from "./CreatePostModal";

type Props = {
  post: PostType;
  title: "下書き編集" | "再編集";
  refetch: () => void;
};

export const RePostButton = ({ post, title, refetch }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  return (
    <>
      <Button
        $fill={true}
        color="dark"
        size="large"
        onClick={() => setIsOpenModal(true)}
      >
        {title}
      </Button>
      {isOpenModal && (
        <RePostModal
          post={post}
          onClose={() => {
            setIsOpenModal(false);
          }}
          title={title}
          refetch={refetch}
        />
      )}
    </>
  );
};
