import React, { useCallback } from "react";
import styled from "styled-components";
import { SideLineHeaderText } from "../../../components/SideLineHeaderText";
import { FlexLayout } from "../../../components/Container";
import { Panel } from "../../../components/Panel";
import { Heading1 } from "../../../components/Typo";
import * as authApi from "../../../apiClients/auth";
import * as theme from "../../../theme";
import { usePolyfitHistory } from "../../../hooks/router";
import {
  useCurrentUser,
  useFetchCurrentUser,
} from "../../../hooks/recoil/user";
import { UserType } from "@shared/types/userType";
import { Navigation } from "../../../components/Navigation3";

export default function RegistrationUserTypePage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const fetch = useFetchCurrentUser();

  const handleParentButton = useCallback(async () => {
    // ユーザータイプが変わる場合のみAPIから変更する
    if (user.type !== "PARENT") {
      await authApi.registerUserType(UserType.PARENT);
      await fetch();
    }
    history.push({
      to: "REGISTER_DESCRIPTION",
    });
  }, []);

  const handleTeacherButton = useCallback(async () => {
    // ユーザータイプが変わる場合のみAPIから変更する
    if (user.type !== "TEACHER") {
      await authApi.registerUserType(UserType.TEACHER);
      await fetch();
    }
    history.push({
      to: "REGISTER_DESCRIPTION",
    });
  }, []);

  return (
    <Container>
      <Navigation />

      <ContainerBox>
        <FlexLayout direction="column">
          <Heading1>作成したいアカウントを選択</Heading1>

          <Panel>
            <SideLineHeaderText text="生徒の保護者の方はこちら" border />

            <SelectUserTypeButton onClick={handleParentButton}>
              生徒の保護者用アカウントを作成
            </SelectUserTypeButton>

            <Spacer />

            <SideLineHeaderText text="学校の先生の方はこちら" border />

            <SelectUserTypeButton onClick={handleTeacherButton}>
              先生用のアカウントを作成
            </SelectUserTypeButton>
          </Panel>
        </FlexLayout>
      </ContainerBox>
    </Container>
  );
}

const Container = styled.div`
  background: ${theme.states.pageBackgroundColor};
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SelectUserTypeButton = styled.button`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  color: ${theme.colorsPallet.primary};
  background-color: #ffffff;
  border: 1px solid ${theme.colorsPallet.primary};
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.7;
  }
`;

const ContainerBox = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  left: 0;
  right: 0;
  top: 10%;
  width: 100%;
  /* height: 100%; */

  ${Panel} {
    padding: 24px;
    text-align: center;
    width: 340px;
    min-height: 200px;
  }

  ${Heading1} {
    text-align: center;
    font-weight: 700;
    color: #1a1c21;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: "Inter";
  }
`;
