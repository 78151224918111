import { ApprovalStatus, ChatRoomMember } from "@shared/types/chatRoom";

export const sortMembersByApprovalStatus = (
  members: ChatRoomMember[]
): ChatRoomMember[] => {
  const approvalPriority = {
    [ApprovalStatus.APPROVED]: 1,
    [ApprovalStatus.PENDING]: 2,
    [ApprovalStatus.REJECTED]: 3,
    [ApprovalStatus.UNAPPROVED]: 4,
  };

  return [...members].sort((a, b) => {
    const statusA = a.participantChatRoom.approvalStatus;
    const statusB = b.participantChatRoom.approvalStatus;
    return approvalPriority[statusA] - approvalPriority[statusB];
  });
};
