import { z } from "zod";
import { zRequiredString } from "../rules/string";
import { ptaParentFamilyInvoiceSchema } from "../models/invoice/ptaParentFamilyInvoice.schema";
import { ptaInvoiceProductSchema } from "../models/invoice/ptaInvoiceProduct.schema";
import { gradeByAmountsSchema } from "../models/invoice/ptaInvoiceProduct.schema";

export const createPTAInvoiceProductSchema = z.object({
  name: zRequiredString,
  amountsByGrades: gradeByAmountsSchema.optional(),
});
export type CreatePTAInvoiceProductType = z.infer<
  typeof createPTAInvoiceProductSchema
>;

export const updatePTAInvoiceProductSchema = z.object({
  id: zRequiredString,
  name: zRequiredString,
  amountsByGrades: gradeByAmountsSchema.optional(),
});
export type UpdatePTAInvoiceProductType = z.infer<
  typeof updatePTAInvoiceProductSchema
>;

export const getPTAInvoiceProductSchema = z.object({
  id: zRequiredString,
});
export type GetPTAInvoiceProductType = z.infer<
  typeof getPTAInvoiceProductSchema
>;

export const getPTAInvoiceProductListResponseSchema =
  ptaInvoiceProductSchema.extend({
    PTAParentFamilyInvoices: z.array(ptaParentFamilyInvoiceSchema),
  });

export type GetPTAInvoiceProductListResponseType = z.infer<
  typeof getPTAInvoiceProductListResponseSchema
>;

export const deletePTAInvoiceProductSchema = z.object({
  id: zRequiredString,
});
export type DeletePTAInvoiceProductType = z.infer<
  typeof deletePTAInvoiceProductSchema
>;

export const updatePTAInvoiceProductNameSchema = z.object({
  id: zRequiredString,
  name: zRequiredString,
});
export type UpdatePTAInvoiceProductNameType = z.infer<
  typeof updatePTAInvoiceProductNameSchema
>;
