import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ChatRoomMember } from "@shared/types/chatRoom";
import { colorsPallet } from "src/theme";
import { PendingMemberRow } from "./PendingMemberRow";

type Props = {
  members: ChatRoomMember[];
  isLoading?: boolean;
  error?: Error | null;
  selectedMemberIds: string[];
  onSelectedMembersChange: (accountIds: string[]) => void;
};

export const PendingMemberList: React.FC<Props> = ({
  members,
  isLoading,
  error,
  selectedMemberIds,
  onSelectedMembersChange,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (
      members.length > 0 &&
      members.every((member) =>
        selectedMemberIds.includes(member.user.accountId)
      )
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedMemberIds, members]);

  const handleSelectAll = () => {
    if (selectAll) {
      onSelectedMembersChange([]);
    } else {
      const allMemberIds = members.map((member) => member.user.accountId);
      onSelectedMembersChange(allMemberIds);
    }
    setSelectAll(!selectAll);
  };

  const handleMemberClick = (memberId: string) => {
    const newSelectedIds = selectedMemberIds.includes(memberId)
      ? selectedMemberIds.filter((id) => id !== memberId)
      : [...selectedMemberIds, memberId];

    onSelectedMembersChange(newSelectedIds);
  };

  return (
    <NameListTable>
      <NameListColumnNameRow head>
        <NameListColumn>
          <span>招待中メンバー一覧</span>
          <SelectAllCheckBox onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <label onClick={handleSelectAll}>
              全選択（{members.length}名）
            </label>
          </SelectAllCheckBox>
        </NameListColumn>
      </NameListColumnNameRow>
      <ScrollWrapper>
        {isLoading && <LoadingMessage>読み込み中...</LoadingMessage>}
        {error && <ErrorMessage>メンバー情報の取得に失敗しました</ErrorMessage>}
        {!isLoading && !error && members.length === 0 && (
          <EmptyMessage>招待中のメンバーはいません</EmptyMessage>
        )}
        {!isLoading &&
          !error &&
          members.length > 0 &&
          members.map((member) => (
            <PendingMemberRow
              key={member.participantChatRoom.id}
              member={member}
              isSelected={selectedMemberIds.includes(member.user.accountId)}
              onMemberClick={handleMemberClick}
            />
          ))}
      </ScrollWrapper>
    </NameListTable>
  );
};

const NameListTable = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
`;

const NameListColumnNameRow = styled.div<{ head?: boolean }>`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  background-color: #f5f7fa;
  border-collapse: collapse;
`;

const NameListColumn = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  background-color: #f5f7fa;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ScrollWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const EmptyMessage = styled.div`
  padding: 16px;
  color: ${colorsPallet.mediumShade};
  text-align: center;
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
  background-color: #fff0ef;
  color: #bd271e;
  font-size: 12px;
  text-align: start;
  padding: 6px 10px;
  border: 1px solid #e3e6eb;
  border-radius: 6px;
`;

const LoadingMessage = styled.div`
  padding: 16px;
  color: ${colorsPallet.mediumShade};
  text-align: center;
`;

const SelectAllCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  input {
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
`;
