import React from "react";
import { Button } from "../../components/Button";
import styled from "styled-components";

type Props = {
  isDisplay: boolean;
  onClick: () => void;
};

export const CreateLinkButton = ({ isDisplay, onClick }: Props) => {
  if (!isDisplay) return null;

  return (
    <StyledButton $fill={true} onClick={onClick} height="40px">
      リンクを作成
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  white-space: nowrap;
`;
