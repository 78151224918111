import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  executePTAParentFamilyInvoice,
  getInvoicesByUserId,
  getPTAParentFamilyInvoiceList,
} from "src/apiClients/ptaParentFamilyInvoice";
import { logger } from "src/utils/logger";
import {
  CreatePTAParentFamilyInvoiceType,
  GetInvoicesByUserIdType,
} from "@shared/validator/features/ptaParentFamilyInvoice.schema";
import { ParentFamilyInvoiceListFilterType } from "@shared/validator/features/ptaParentFamilyInvoice.schema";
import { useMemo } from "react";

export function useGetPTAParentFamilyInvoiceList({
  ptaInvoiceProductId,
  q,
  filter,
  withOutDeleted,
}: {
  ptaInvoiceProductId: string;
  q?: string;
  filter?: ParentFamilyInvoiceListFilterType;
  withOutDeleted?: boolean;
}) {
  const query = useQuery({
    queryKey: [
      "api",
      "ptaParentFamilyInvoice",
      "list",
      ptaInvoiceProductId,
      q,
      filter,
      withOutDeleted,
    ],
    queryFn: async () => {
      if (!ptaInvoiceProductId) {
        return {
          PTAParentFamilyInvoiceItems: [],
          pendingInvoiceCount: 0,
          allInvoiceCount: 0,
          paidInvoiceCount: 0,
          ptaParentFamilyCount: 0,
        };
      }
      return await getPTAParentFamilyInvoiceList({
        ptaInvoiceProductId,
        q,
        filter: JSON.stringify(filter),
        withOutDeleted,
      });
    },
    enabled: !!ptaInvoiceProductId,
  });

  return {
    ptaParentFamilyInvoices: query.data ?? {
      PTAParentFamilyInvoiceItems: [],
      pendingInvoiceCount: 0,
      allInvoiceCount: 0,
      paidInvoiceCount: 0,
      ptaParentFamilyCount: 0,
    },
    ...query,
  };
}

export function useInvalidatePTAParentFamilyInvoiceList() {
  const queryClient = useQueryClient();

  function invalidatePTAParentFamilyInvoiceList(ptaInvoiceProductId?: string) {
    queryClient.invalidateQueries({
      queryKey: ["api", "ptaParentFamilyInvoice", "list", ptaInvoiceProductId],
    });
  }

  return { invalidatePTAParentFamilyInvoiceList };
}

export function useExecutePTAParentFamilyInvoice() {
  return useMutation({
    mutationFn: (args: CreatePTAParentFamilyInvoiceType) =>
      executePTAParentFamilyInvoice(args),
    onSuccess: (response) => {
      console.log("請求作成成功:", response);
    },
    onError: (error) => {
      logger.error(error, { message: "請求作成失敗" });
    },
  });
}

export function useGetInvoicesByUserId(args: GetInvoicesByUserIdType) {
  const query = useQuery({
    queryKey: [
      "api",
      "ptaParentFamilyInvoice",
      "invoicesByUserId",
      args.userId,
    ],
    queryFn: async () => getInvoicesByUserId(args),
    enabled: !!args.userId,
  });

  return {
    invoices: useMemo(() => query.data ?? [], [query.data]),
    ...query,
  };
}
