import React, { useMemo, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  ModalWrapper,
  ModalHeader,
  CheckTabs,
  TextField,
  SelectField,
  ErrorMessage,
} from "../../../pages/pta/admin/ProfilePage";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import {
  TrashButton,
  TrashIcon,
  AddChildForm,
} from "../../../pages/registration/pta/RegisterChildInfoPage";
import { Margin } from "../../../components/Margin";
import { useToast } from "../../../components/Toast";
import { classOption } from "../../../utils/getChildClass";
import { CSChild } from "@shared/types/csChild";
import { deleteChildren, updateChildren } from "src/apiClients/csChild";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import { User } from "src/apiClients/users";
import {
  CsChildUpdateQueryType,
  csChildUpdateQuerySchema,
} from "src/@shared/validator/features/csChild.schema";
import { PlusIcon } from "src/components/icons/PlusIcon";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

type FormValues = {
  children: CsChildUpdateQueryType;
};
const formSchema = z.object({
  children: csChildUpdateQuerySchema,
});
export const CsChildInfoEditModal = ({
  isOpen,
  userChildren,
  refetch,
  close,
  user,
}: {
  isOpen: boolean;
  userChildren: CSChild[];
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<CSChild[], Error>>;
  close: () => void;
  user: User;
}) => {
  const toast = useToast();
  const { communities } = useOrganizationCommunity();
  const convertedArgs: CsChildUpdateQueryType = useMemo(() => {
    return userChildren.map((child) => ({
      id: child.id,
      accountId: user.account?.id ?? "",
      childLastName: child.childLastName,
      childFirstName: child.childFirstName,
      childLastNameKana: child.childLastNameKana,
      childFirstNameKana: child.childFirstNameKana,
      communityId: child.communityId ?? null,
      grade: child.grade,
      class: child.class,
    }));
  }, [userChildren, user]);

  const defaultValues = useMemo(() => {
    return {
      children: userChildren.length
        ? convertedArgs
        : [
            {
              accountId: user.account?.id ?? "",
              childLastName: user.baseInfo?.lastName ?? "",
              childFirstName: "",
              childLastNameKana: user.baseInfo?.lastNameKana ?? "",
              childFirstNameKana: "",
              communityId: "",
              grade: null,
              class: null,
            },
          ],
    };
  }, [userChildren, user]);

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { fields, append, remove } = useFieldArray({
    name: "children",
    control,
  });

  const newChildInfo = useMemo(() => {
    return {
      accountId: user.account?.id ?? "",
      childLastName: user.baseInfo?.lastName ?? "",
      childFirstName: "",
      childLastNameKana: user.baseInfo?.lastNameKana ?? "",
      childFirstNameKana: "",
      communityId: "",
      grade: null,
      class: null,
    };
  }, [user.baseInfo]);

  // 元の子供情報がない状態で子供削除の処理はできない
  const onSubmitChildInfo = async (): Promise<void> => {
    try {
      if (fields.length === 0) {
        await deleteChildren(user.account?.id ?? "");
      } else {
        const children = getValues("children");
        const args: CsChildUpdateQueryType = children.map((child) => {
          const arg: CsChildUpdateQueryType[number] = {
            accountId: user.account?.id ?? "",
            childFirstName: child.childFirstName,
            childFirstNameKana: child.childFirstNameKana,
            childLastName: child.childLastName,
            childLastNameKana: child.childLastNameKana,
            communityId: child.communityId,
            grade: child.grade,
            class: child.class,
            schoolCode: child.schoolCode,
          };
          console.log(arg);
          if (child.id) {
            arg.id = child.id;
          }
          return arg;
        });
        await updateChildren(args);
      }
      await refetch();
      close();
      toast.success("保存しました");
    } catch (err) {
      console.error(err);
    }
  };

  const gradeOption = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
    { value: "6", text: "6" },
  ];

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={close} modalBoxIsHeightFull={true}>
          <ModalWrapper>
            <ModalHeader>お子さま情報</ModalHeader>
            {fields.length === 0 && <div>お子さま情報を全て削除します</div>}
            <ModalBody>
              <FieldWrapper>
                <ModalDescription>
                  <p>※保存時に学年、クラス、名前で自動並び替えされます。</p>
                </ModalDescription>
                {fields.map((_field, index) => (
                  <div key={index}>
                    <StyledFlexSpaceBetween>
                      <LabelWrapper>
                        <EditModalFormLabel>
                          お子さまのお名前({index + 1}人目)
                        </EditModalFormLabel>
                        <Label required />
                      </LabelWrapper>

                      {!(fields.length === 1 && userChildren.length === 0) && (
                        <TrashButton
                          style={{ marginTop: "12px", marginRight: "5px" }}
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <TrashIcon />
                        </TrashButton>
                      )}
                    </StyledFlexSpaceBetween>

                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) 山田"
                          {...register(
                            `children.${index}.childLastName` as const
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastName && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childLastName
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) 太郎"
                          {...register(
                            `children.${index}.childFirstName` as const
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstName && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childFirstName
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <LabelWrapper>
                      <EditModalFormLabel>ふりがな</EditModalFormLabel>
                      <Label required />
                    </LabelWrapper>
                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) やまだ"
                          {...register(
                            `children.${index}.childLastNameKana` as const
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastNameKana && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childLastNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) たろう"
                          {...register(
                            `children.${index}.childFirstNameKana` as const
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstNameKana && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childFirstNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <LabelWrapper>
                          <EditModalFormLabel>学校</EditModalFormLabel>
                          <Label required />
                        </LabelWrapper>
                        <SelectWrapper>
                          <SelectField
                            {...register(
                              `children.${index}.communityId` as const,
                              {
                                setValueAs: (v) => {
                                  const selectedCommunity = communities.find(
                                    (community) => community.id === v
                                  );
                                  setValue(
                                    `children.${index}.schoolCode` as const,
                                    selectedCommunity?.schoolCode ?? ""
                                  );
                                  return selectedCommunity?.id ?? "";
                                },
                              }
                            )}
                            defaultValue=""
                          >
                            <option value="" disabled>
                              選択してください
                            </option>
                            {communities.map((community) => {
                              return (
                                <option key={community.id} value={community.id}>
                                  {community.name}
                                </option>
                              );
                            })}
                          </SelectField>
                          {errors?.children &&
                            errors.children[index]?.communityId && (
                              <ErrorMessage>
                                {
                                  errors?.children?.[index]?.communityId
                                    ?.message
                                }
                              </ErrorMessage>
                            )}
                        </SelectWrapper>
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <LabelWrapper>
                          <EditModalFormLabel>学年</EditModalFormLabel>
                          <Label required />
                        </LabelWrapper>
                        <SelectField
                          {...register(`children.${index}.grade` as const, {
                            setValueAs: (v) => {
                              if (
                                v === null ||
                                v === undefined ||
                                isNaN(Number(v))
                              ) {
                                return null;
                              }
                              return parseInt(v, 10);
                            },
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {gradeOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.grade && (
                          <ErrorMessage>
                            {errors?.children?.[index]?.grade?.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <LabelWrapper>
                          <EditModalFormLabel>クラス</EditModalFormLabel>
                          <Label required />
                        </LabelWrapper>
                        <SelectField
                          {...register(`children.${index}.class` as const, {
                            setValueAs: (v) => {
                              if (
                                v === null ||
                                v === undefined ||
                                isNaN(Number(v))
                              ) {
                                return null;
                              }
                              return parseInt(v, 10);
                            },
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {classOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.class && (
                          <ErrorMessage>
                            {errors?.children?.[index]?.class?.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                  </div>
                ))}
              </FieldWrapper>

              <Margin marginTop={20} />
              <AddChildForm
                type="button"
                onClick={() => {
                  append(newChildInfo);
                }}
              >
                <PlusIcon size={16} />
                お子さまを追加
              </AddChildForm>
              <Margin marginTop={25} />
              <ModalSubmitButtons
                submitText="保存"
                onCancel={close}
                onSubmit={() => handleSubmit(onSubmitChildInfo)()}
                disabled={fields.length === 0 && userChildren.length === 0}
              />
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalDescription = styled.div`
  font-size: 12px;
  color: #5a606b;
`;

const StyledFlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 30px;

  @media (max-width: 1025px) {
    gap: 10px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 4px;
`;

const EditModalFormLabel = styled.label`
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`;

const SelectWrapper = styled.div`
  min-width: 200px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;
