import React from "react";
import { generateRecruitmentAttendanceUrl } from "@shared/utils/generateRecruitmentAttendanceUrl";
import { useCurrentCommunityId } from "src/hooks/router";
import { colorsPallet } from "src/theme";
import { toDateIntervalFormat } from "src/utils/time";
import styled from "styled-components";
import { QRCodeCanvas } from "qrcode.react";

type RecruitmentQRCardProps = {
  recruitmentName: string;
  schoolName: string;
  userName: string;
  start: Date;
  end: Date;
  recruitmentId: string;
  recruitmentScheduleId: string;
  recruitApplicationEventId: string;
  isBE: boolean;
};

export const RecruitmentQRCard = ({
  recruitmentName,
  schoolName,
  userName,
  start,
  end,
  recruitmentId,
  recruitmentScheduleId,
  recruitApplicationEventId,
  isBE,
}: RecruitmentQRCardProps) => {
  const { communityId } = useCurrentCommunityId();
  const frontUrl = window.location.origin;
  const attendanceUrl = generateRecruitmentAttendanceUrl({
    frontUrl,
    recruitmentId,
    recruitmentScheduleId,
    recruitApplicationEventId,
    communityId,
    isBE,
  });

  return (
    <CardContainer>
      <CardHeader>
        <Title>出欠証</Title>
      </CardHeader>

      <CardContent>
        <Section>
          {isBE ? (
            <SectionTitle>教育委員会名</SectionTitle>
          ) : (
            <SectionTitle>学校名</SectionTitle>
          )}
          <SectionContent>{schoolName}</SectionContent>
        </Section>

        <Section>
          <SectionTitle>来訪者</SectionTitle>
          <SectionContent>{userName}</SectionContent>
        </Section>

        <Section>
          <SectionTitle>募集情報</SectionTitle>
          <SectionContent>{recruitmentName}</SectionContent>
        </Section>

        <Section>
          <SectionTitle>時間・曜日</SectionTitle>
          <SectionContent>
            {toDateIntervalFormat(new Date(start), new Date(end))}
          </SectionContent>
        </Section>

        <QRSection>
          <QRCodeCanvas
            value={attendanceUrl}
            size={120}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
          />
          <QRCodeWarn>QRコードを受付に提示してください</QRCodeWarn>
        </QRSection>
      </CardContent>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  padding: 12px;
  background: #ffffff;
  width: 320px;
  height: 540px;
  @media print {
    // 印刷時のスタイル
    width: 80mm;
    transform-origin: top left;
    transform: scale(var(--print-scale));
    break-inside: avoid;
    page-break-inside: avoid;
  }
  position: relative;
`;

const CardHeader = styled.div`
  background: ${colorsPallet.primary};
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 6px;
  margin-bottom: 8px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 0;
  font-weight: bold;
`;

const CardContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &::after {
    content: "";
    position: absolute;
    bottom: 8px;
    left: 12px;
    width: calc(100% - 24px);
    height: 8px;
    border-radius: 4px;
    background-color: ${colorsPallet.primary};
  }
`;

const SectionTitle = styled.h3`
  color: #69707d;
  font-size: 12px;
`;

const SectionContent = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #343741;
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Section = styled.div`
  padding-left: 16px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: ${colorsPallet.primary};
    border-radius: 2px;
  }
`;

const QRSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const QRCodeWarn = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colorsPallet.danger};
`;
