import React from "react";
import styled from "styled-components";
import { Avatar } from "src/components/Common/Avatar";
import { DEFAULT_USER_IMAGE } from "src/utils/constants/images";
import { colorsPallet } from "src/theme";
import { ChatRoomMember as ChatRoomMemberType } from "@shared/types/chatRoom";
import { getStatusLabel } from "./utils/getStatusLabel";
import { getStatusColor } from "./utils/getStatusColor";

type Props = {
  member: ChatRoomMemberType;
  onMemberClick?: (member: ChatRoomMemberType) => void;
};

export const ChatRoomMemberRow: React.FC<Props> = ({
  member,
  onMemberClick,
}) => {
  const participant = member.participantChatRoom;
  const label = getStatusLabel(participant.approvalStatus);
  const color = getStatusColor(participant.approvalStatus);

  const isClickable = !!onMemberClick;

  const handleMemberClick = () => {
    if (onMemberClick) {
      onMemberClick(member);
    }
  };

  return (
    <NameListColumnNameRow
      $clickable={isClickable}
      onClick={isClickable ? handleMemberClick : undefined}
    >
      <Avatar
        src={member.user?.picture || DEFAULT_USER_IMAGE}
        alt={member.user?.name || "-"}
        size={40}
      />
      <MemberName $clickable={isClickable}>
        {member.user?.name || "-"}
      </MemberName>
      <StatusBadge $color={color}>{label}</StatusBadge>
    </NameListColumnNameRow>
  );
};

const NameListColumnNameRow = styled.div<{ $clickable?: boolean }>`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  background-color: #fff;

  ${(props) =>
    props.$clickable
      ? `
      cursor: pointer;
      &:hover {
        filter: brightness(95%);
      }
    `
      : ""}
`;

const MemberName = styled.div<{ $clickable: boolean }>`
  font-size: 14px;
  color: ${colorsPallet.darkestShade};
  flex: 1;
  ${(props) =>
    props.$clickable &&
    `
    &:hover {
      text-decoration: underline;
      color: ${colorsPallet.primary};
    }
  `}
`;
const StatusBadge = styled.div<{ $color: string }>`
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  color: ${colorsPallet.emptyShade};
  background-color: ${(props) => props.$color};
`;
