import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import {
  InvoiceStatusLabel,
  PTAParentFamilyInvoiceListType,
} from "@shared/types/ptaParentFamilyInvoice";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getClassName } from "src/utils/getChildClass";
import { Link } from "src/components/Link";
import { CancelInvoiceModal } from "./CancelInvoiceModal";
import { CashPaymentModal } from "./CashPaymentModal";
import { colorsPallet } from "src/theme";
import { zIndexes } from "src/zIndex";
import { ModalBackGround } from "src/components/ListPageFilterSetting";
import { useInvalidatePTAParentFamilyInvoiceList } from "src/hooks/query/invoice/ptaParentFamilyInvoices";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import {
  cancelPTAParentFamilyInvoice,
  payByCash,
} from "src/apiClients/ptaParentFamilyInvoice";
import { getGradeColor } from "src/utils/getGradeColor";
import { PTAInvoiceDetailContentProps } from "./PTAInvoiceDetailContent";
import { sortChildInvoiceItems } from "./PTAInvoiceDetailContent";
import { TextWithHighlight } from "src/components/TextWithHighlight";
import { CancelReasonPopup, CancelReasonButton } from "./CancelReasonPopup";

export const PTAInvoiceMobileCard = ({
  isLoadingParentFamilyInvoices,
  invoiceProductId,
  ptaParentFamilyInvoices,
  searchText,
}: PTAInvoiceDetailContentProps) => {
  const toast = useToast();
  const { organization } = useGetCurrentOrganization();
  const [selectedInvoice, setSelectedInvoice] =
    useState<PTAParentFamilyInvoiceListType | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<string | null>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCashPaymentModalOpen, setIsCashPaymentModalOpen] = useState(false);
  const [openReasonId, setOpenReasonId] = useState<string | null>(null);
  const { invalidatePTAParentFamilyInvoiceList } =
    useInvalidatePTAParentFamilyInvoiceList();

  const handleCancelSubmit = useCallback(
    async (cancelComment: string) => {
      if (!selectedInvoice) return;

      if (selectedInvoice.status !== "PENDING") {
        toast.warn("未払いの請求のみキャンセルできます。");
        return;
      }

      try {
        await cancelPTAParentFamilyInvoice({
          invoiceId: selectedInvoice.id,
          cancelComment,
        });
        invalidatePTAParentFamilyInvoiceList(invoiceProductId);
        toast.warn("請求をキャンセルしました。");
      } catch (err) {
        logger.error(err, { selectedInvoice });
        toast.error("キャンセルに失敗しました。");
      }

      setIsCancelModalOpen(false);
      setSelectedInvoice(null);
    },
    [selectedInvoice, invoiceProductId, invalidatePTAParentFamilyInvoiceList]
  );

  const handleCashPaymentSubmit = useCallback(async () => {
    if (!selectedInvoice) return;

    if (selectedInvoice.status !== "PENDING") {
      toast.warn("未払いの請求のみ現金支払いを受け付けられます。");
      return;
    }

    try {
      await payByCash({ invoiceId: selectedInvoice.id });
      invalidatePTAParentFamilyInvoiceList(invoiceProductId);
      toast.success("現金支払いを受け付けました。");
    } catch (err) {
      logger.error(err, { selectedInvoice });
      toast.error("現金支払いの受付に失敗しました。");
    }

    setIsCashPaymentModalOpen(false);
    setSelectedInvoice(null);
  }, [selectedInvoice, invoiceProductId, invalidatePTAParentFamilyInvoiceList]);

  const handleCloseModal = useCallback(() => {
    setIsCancelModalOpen(false);
    setIsCashPaymentModalOpen(false);
    setSelectedInvoice(null);
  }, []);

  const handleOpenMenu = useCallback(
    (invoice: PTAParentFamilyInvoiceListType) => {
      setIsMenuOpen(invoice.id);
    },
    []
  );

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(null);
  }, []);

  const handleOpenCancelModal = useCallback(
    (invoice: PTAParentFamilyInvoiceListType) => {
      setSelectedInvoice(invoice);
      setIsCancelModalOpen(true);
      handleCloseMenu();
    },
    []
  );

  const handleOpenCashPaymentModal = useCallback(
    (invoice: PTAParentFamilyInvoiceListType) => {
      setSelectedInvoice(invoice);
      setIsCashPaymentModalOpen(true);
      handleCloseMenu();
    },
    []
  );

  if (isLoadingParentFamilyInvoices) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <CardContainer>
        {ptaParentFamilyInvoices.map((invoice) => (
          <Card key={invoice.id}>
            <CardHeader>
              <TopSection>
                <ParentName>
                  {invoice.PTAParentFamily.mainAccount ? (
                    <LinkText
                      $place="start"
                      to={{
                        to: "PTA_LIST_MEMBER",
                        query: {
                          userId:
                            invoice.PTAParentFamily.mainAccount?.user.id ?? "",
                        },
                      }}
                    >
                      <TextWithHighlight
                        text={`${
                          invoice.PTAParentFamily.mainAccount?.user.baseInfo
                            ?.lastName || ""
                        } ${
                          invoice.PTAParentFamily.mainAccount?.user.baseInfo
                            ?.firstName || ""
                        }`}
                        keywords={[searchText]}
                      />
                    </LinkText>
                  ) : (
                    <span>[削除済みユーザー]</span>
                  )}
                </ParentName>
                {invoice.status === "PENDING" && (
                  <MenuContainer>
                    <SettingButton
                      onClick={() => handleOpenMenu(invoice)}
                      $isOpen={isMenuOpen === invoice.id}
                    >
                      …
                    </SettingButton>
                    {isMenuOpen === invoice.id && (
                      <>
                        <ModalBackGround onClick={handleCloseMenu} />
                        <EditMenuTab>
                          <SettingEditInfo
                            onClick={() => handleOpenCashPaymentModal(invoice)}
                          >
                            現金支払いの受付
                          </SettingEditInfo>
                          <SettingEditInfo
                            onClick={() => handleOpenCancelModal(invoice)}
                          >
                            <DangerText>請求をキャンセル</DangerText>
                          </SettingEditInfo>
                        </EditMenuTab>
                      </>
                    )}
                  </MenuContainer>
                )}
              </TopSection>
              <HeaderRight>
                <StatusBadge $status={invoice.status}>
                  {InvoiceStatusLabel[invoice.status]}
                </StatusBadge>
                {invoice.status === "CANCELED" &&
                  (invoice.cancelComment || invoice.cancelReason) && (
                    <ReasonContainer>
                      <CancelReasonButton
                        onClick={() => {
                          setOpenReasonId(
                            openReasonId === invoice.id ? null : invoice.id
                          );
                        }}
                      >
                        キャンセル理由
                      </CancelReasonButton>
                      <CancelReasonPopup
                        cancelReason={invoice.cancelReason}
                        cancelComment={invoice.cancelComment}
                        isOpen={openReasonId === invoice.id}
                        onClose={() => setOpenReasonId(null)}
                      />
                    </ReasonContainer>
                  )}
              </HeaderRight>
            </CardHeader>

            <CardContent>
              <TotalAmount>
                <Label>合計請求金額</Label>
                <Amount>{`${invoice.unitAmount.toLocaleString()}円`}</Amount>
              </TotalAmount>

              <ChildrenSection>
                <SectionLabel>子どもごとの内訳</SectionLabel>
                {invoice.PTAChildInvoiceItems.slice()
                  .sort(sortChildInvoiceItems)
                  .map((item) => (
                    <ChildItem key={item.id}>
                      {item.child ? (
                        <ChildInfo>
                          <ChildName>
                            <TextWithHighlight
                              text={`${item.child.childLastName} ${item.child.childFirstName}`}
                              keywords={[searchText]}
                            />
                            {item.child.deletedAt && <span> [削除済み]</span>}
                          </ChildName>
                          <ClassInfo>
                            <GradeLabel
                              style={{
                                backgroundColor: getGradeColor(
                                  item.child.grade
                                ),
                              }}
                            >
                              <span>学年</span>
                              <span>
                                {getGradeLabel(
                                  item.child.grade,
                                  organization?.schoolDisplayType,
                                  true,
                                  true,
                                  "short"
                                )}
                              </span>
                            </GradeLabel>
                            <ClassLabel>
                              <span>クラス</span>
                              <span>
                                {getClassName(item.child.class, true)}
                              </span>
                            </ClassLabel>
                          </ClassInfo>
                        </ChildInfo>
                      ) : (
                        <Deleted>[削除済み]</Deleted>
                      )}
                      <ChildAmount>{`${item.amount.toLocaleString()}円`}</ChildAmount>
                    </ChildItem>
                  ))}
              </ChildrenSection>
            </CardContent>
          </Card>
        ))}
      </CardContainer>

      {isCancelModalOpen && selectedInvoice && (
        <CancelInvoiceModal
          invoice={selectedInvoice}
          onSubmit={handleCancelSubmit}
          onClose={handleCloseModal}
          disabled={selectedInvoice.status !== "PENDING"}
        />
      )}

      {isCashPaymentModalOpen && selectedInvoice && (
        <CashPaymentModal
          invoice={selectedInvoice}
          onSubmit={handleCashPaymentSubmit}
          onClose={handleCloseModal}
          disabled={selectedInvoice.status !== "PENDING"}
        />
      )}
    </>
  );
};

const LoadingWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const LinkText = styled(Link)<{ $place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.$place};
  color: #07c;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const CardHeader = styled.div`
  padding: 16px;
  gap: 6px;
  display: flex;
  flex-direction: column;
`;

const ParentName = styled.div`
  font-weight: 500;
`;

const StatusBadge = styled.span<{ $status: string }>`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${({ $status }) =>
    $status === "PENDING"
      ? colorsPallet.danger
      : $status === "PAID"
      ? colorsPallet.success
      : $status === "PROCESSING"
      ? colorsPallet.warning
      : colorsPallet.lightestShade};
  color: ${({ $status }) =>
    $status === "PENDING"
      ? "#fff"
      : $status === "PAID"
      ? "#fff"
      : $status === "PROCESSING"
      ? "#fff"
      : "#333"};
`;

const CardContent = styled.div`
  padding: 16px;
`;

const TotalAmount = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const Amount = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #333;
`;

const ChildrenSection = styled.div`
  padding-top: 16px;
`;

const SectionLabel = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
`;

const ChildItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const ChildInfo = styled.div`
  flex: 1;
`;

const ChildName = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const ClassInfo = styled.div`
  display: flex;
  gap: 8px;
`;

const GradeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ClassLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  background-color: #f5f5f5;
  padding: 2px 8px;
  border-radius: 12px;
`;

const ChildAmount = styled.div`
  font-size: 14px;
  font-weight: 500;
  min-width: 80px;
  text-align: right;
`;

const Deleted = styled.span`
  height: 52px;
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SettingButton = styled.button<{ $isOpen: boolean }>`
  width: 32px;
  height: 32px;
  background-color: transparent;
  font-size: 16px;
  color: #343741;
  border: none;
  border-radius: 100%;
  padding-bottom: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f3f4f8;
  }
  background-color: ${(props) => (props.$isOpen ? "#f3f4f8" : "transparent")};
`;

const EditMenuTab = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  width: 200px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
  z-index: ${zIndexes.notModalMenu};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SettingEditInfo = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #f3f4f8;
  }
`;

const DangerText = styled.span`
  color: ${colorsPallet.danger};
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ReasonContainer = styled.div`
  position: relative;
`;
