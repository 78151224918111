import { Child } from "./child";
import { AccountWithUser } from "./auth";
import { BaseInfo } from "../validator/models/baseInfo.schema";

export type PTAParentFamily = {
  id: string;
  organizationId: string;
  mainAccount: AccountWithUser;
  subAccount: AccountWithUser | null;
  children: Child[];
  createdAt: Date;
  updatedAt: Date;
};

export type PTAParentFamilyBaseInfo = {
  mainAccountBaseInfo: BaseInfo;
  subAccountBaseInfo: BaseInfo | null;
};

export const ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY = {
  main: "main",
  sub: "sub",
} as const;
export type AccountTypeOfPTAParentFamily =
  keyof typeof ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY;
