import React, {
  useRef,
  ChangeEvent,
  useState,
  useCallback,
  useEffect,
} from "react";
import styled from "styled-components";
import { PaperclipIcon } from "src/components/icons/PaperclipIcon";
import { Button } from "src/components/Button";
import { buttons } from "src/theme";
import { PdfIcon } from "src/components/icons/PdfIcon";
import { CloseIcon } from "src/components/icons/CloseIcon";
import { ImageView } from "src/components/Image";
import { CheckBox } from "src/components/form/CheckBox";
import { Margin } from "src/components/Margin";
import { Label } from "src/components/form/Label";
import { BREAKPOINTS } from "../Responsive";

interface EditableDivWithImagesProps {
  placeholder?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  canSend: boolean;
  onSend: () => void;
  isAdmin: boolean;
  adminOnly: boolean;
  onCheckedAdminOnly: (checked: boolean) => void;
}

const MAX_FILE_SIZE = 30 * 1024 * 1024;

export const ChatInputForm: React.FC<EditableDivWithImagesProps> = ({
  placeholder,
  setValue,
  setFiles,
  canSend,
  onSend,
  isAdmin,
  adminOnly,
  onCheckedAdminOnly,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<File[]>([]);
  const [pdfs, setPdfs] = useState<File[]>([]);

  useEffect(() => {
    const files = [...images, ...pdfs];
    setFiles(files);
  }, [images, pdfs]);

  function convertNewlines(text: string): string {
    //入力よりも改行コードが多く送信されるので、入力と同じように表示されるようにする
    return text.replace(/\n{2,}/g, (match) => {
      const count = match.length;
      const result = (count + 1) / 2;
      return "\n".repeat(result);
    });
  }

  const updateContent = () => {
    if (divRef.current) {
      const convertedText = convertNewlines(divRef.current.innerText);
      setValue(convertedText);
    }
  };

  const handlePaste = useCallback((event: React.ClipboardEvent) => {
    event.preventDefault();
    // クリップボードからテキストを取得
    const text = event.clipboardData.getData("text/plain");

    // テキストを現在の選択範囲に挿入
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      const textNode = document.createTextNode(text);
      range.insertNode(textNode);
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
      setValue(divRef.current?.innerText ?? "");
    }
  }, []);

  const onDeleteImage = (index: number) => {
    setImages((prev) => {
      const next = prev.filter((_, i) => i !== index);
      if (inputRef.current) {
        const newFileArray = [...next, ...pdfs].filter(
          (file, index, self) =>
            self.findIndex((f) => f.name === file.name) === index // 同じファイル名の重複を削除
        );
        const dt = new DataTransfer();
        newFileArray.forEach((file) => dt.items.add(file));
        inputRef.current.files = dt.files; // input内のFileListを更新
      }
      return next;
    });
  };

  const onDeletePdf = (index: number) => {
    setPdfs((prev) => {
      const next = prev.filter((_, i) => i !== index);
      if (inputRef.current) {
        const newFileArray = [...images, ...next].filter(
          (file, index, self) =>
            self.findIndex((f) => f.name === file.name) === index // 同じファイル名の重複を削除
        );
        const dt = new DataTransfer();
        newFileArray.forEach((file) => dt.items.add(file));
        inputRef.current.files = dt.files; // input内のFileListを更新
      }
      return next;
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    if (!inputRef.current?.files) return;
    if (e.target.files.length + [...images, ...pdfs].length > 20) {
      alert("ファイルは同時に20個までしか送信できません");
      return;
    }
    if (
      Array.from(e.target.files).some((file) => {
        return file.size !== null && file.size > MAX_FILE_SIZE;
      })
    ) {
      alert("送信可能なファイルの最大サイズは30MBです");
      return;
    }

    const newFileArray = [
      ...images,
      ...pdfs,
      ...Array.from(e.target.files),
    ].filter(
      (file, index, self) =>
        self.findIndex((f) => f.name === file.name) === index // 同じファイル名の重複を削除
    );
    const dt = new DataTransfer();
    newFileArray.forEach((file) => dt.items.add(file));
    inputRef.current.files = dt.files; // input内のFileListを更新

    const imageFiles = newFileArray.filter((file) =>
      file.type.startsWith("image/")
    );
    const pdfFiles = newFileArray.filter(
      (file) => file.type === "application/pdf"
    );
    setImages(imageFiles);
    setPdfs(pdfFiles);
  };

  const handleSend = () => {
    if ([...images, ...pdfs].length > 20) {
      alert("ファイルは同時に20個までしか送信できません");
      return;
    }
    if (divRef.current) {
      divRef.current.innerText = "";
    }
    if (inputRef.current) {
      inputRef.current.files = null;
      setImages([]);
      setPdfs([]);
    }
    onSend();
  };

  const canEdit = isAdmin || !adminOnly;

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        if (canSend && canEdit) {
          handleSend();
        }
      }
    },
    [canSend, canEdit, handleSend]
  );

  return (
    <Container>
      {isAdmin && (
        <>
          <CheckBox
            label="管理者のみ送信可能"
            defaultChecked={adminOnly}
            onChange={(event) => onCheckedAdminOnly(event.target.checked)}
          ></CheckBox>
          <Margin marginBottom={4} />
        </>
      )}
      <EditableContainer>
        <EditableDiv
          ref={divRef}
          contentEditable={canEdit}
          readOnly={!canEdit}
          placeholder={!canEdit ? "一般ユーザーは送信できません" : placeholder}
          onInput={updateContent}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
        ></EditableDiv>
        {images.length > 0 && (
          <ImageContainer>
            {images.map((image, index) => {
              return (
                <ImagePreview key={image.name}>
                  <a target="blank" href={URL.createObjectURL(image)}>
                    <ImageView
                      src={URL.createObjectURL(image)}
                      alt={image.name}
                      width="100px"
                      height="100px"
                    ></ImageView>
                  </a>
                  <ImageRemoveButton
                    type="button"
                    onClick={() => onDeleteImage(index)}
                  >
                    <CloseIcon color="#343741" />
                  </ImageRemoveButton>
                </ImagePreview>
              );
            })}
          </ImageContainer>
        )}
        {pdfs.length > 0 && (
          <PdfContainer>
            {pdfs.map((pdf, index) => {
              return (
                <PdfPreview key={pdf.name}>
                  <PdfIcon size={24} />
                  <PdfLabel target="blank" href={URL.createObjectURL(pdf)}>
                    {pdf.name}
                  </PdfLabel>
                  <PdfRemoveButton
                    type="button"
                    onClick={() => onDeletePdf(index)}
                  >
                    <CloseIcon color="#343741" />
                  </PdfRemoveButton>
                </PdfPreview>
              );
            })}
          </PdfContainer>
        )}
      </EditableContainer>
      <ControlsContainer>
        {!canEdit ? (
          <Label disabled marginBottom={0}>
            注意：このチャットルームでは、管理者のみがメッセージを送信できます。一般ユーザーは閲覧のみ可能です。
          </Label>
        ) : (
          <FileInputLabel>
            <FileInput
              ref={inputRef}
              type="file"
              multiple
              accept="image/png, image/jpeg, application/pdf"
              onChange={handleFileChange}
            />
            <PaperclipIcon />
            <div style={{ marginLeft: "4px" }}>添付</div>
          </FileInputLabel>
        )}
        <ButtonAndSendMessage>
          <SendMessage>
            {canSend && canEdit ? "ctrl / cmd + Enterで送信" : ""}
          </SendMessage>
          <Button
            color={"primary"}
            $fill
            style={{ padding: "4px 12px" }}
            onClick={handleSend}
            disabled={!canSend || !canEdit}
            type={"button"}
          >
            {"送信"}
          </Button>
        </ButtonAndSendMessage>
      </ControlsContainer>
    </Container>
  );
};

const Container = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 16px;
`;

const EditableContainer = styled.div`
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
  display: flex;
  padding: 12px;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #ececec;
`;

const EditableDiv = styled.div<{ placeholder?: string; readOnly?: boolean }>`
  width: 100%;
  line-height: 20px;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};

  &:empty:before {
    content: attr(placeholder);
    color: #888;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 8px;
`;

const ImagePreview = styled.div`
  position: relative;
  margin-top: 4px;
`;

const ImageRemoveButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  padding: 0;
`;

const PdfContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 8px;
`;

const PdfPreview = styled.div`
  max-width: 208px;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  margin-top: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

const PdfLabel = styled.a`
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    text-decoration: underline;
  }
`;

const PdfRemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  background-color: transparent;
  padding: 0;
  margin-left: 8px;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #ececec;
  border-top: transparent;
  padding: 4px 12px;
`;

const FileInputLabel = styled.label`
  cursor: pointer;
  color: ${buttons.text.color};
  display: flex;
  align-items: center;
`;

const FileInput = styled.input`
  display: none;
`;

const ButtonAndSendMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SendMessage = styled.div`
  color: #5a605b;
  @media (max-width: ${BREAKPOINTS.SP}) {
    display: none;
  }
`;
