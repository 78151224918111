import React from "react";
import { AlignRight, ModalHeader, ModalPortal } from "src/components/Modal";
import { Button, ButtonGroup } from "src/components/Button";
import styled from "styled-components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
};

export function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = "はい",
  cancelText = "いいえ",
}: Props) {
  if (!isOpen) return null;

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>{title}</ModalHeader>
      <MessageWrapper>{message}</MessageWrapper>
      <AlignRight>
        <ButtonGroup>
          <Button color="ghost" size="large" onClick={onClose}>
            {cancelText}
          </Button>
          <Button
            color="danger"
            size="large"
            $fill
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {confirmText}
          </Button>
        </ButtonGroup>
      </AlignRight>
    </ModalPortal>
  );
}

const MessageWrapper = styled.div`
  margin: 24px 0;
`;
