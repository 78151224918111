import { SchoolDisplayType } from "@shared/types/organization";
import { TargetGradesType } from "@shared/types/post/api";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { getGradeColor } from "../../utils/getGradeColor";
import React from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import { Button } from "src/components/Button";
import { colorsPallet } from "src/theme";
import styled from "styled-components";
import { usePolyfitHistory } from "src/hooks/router";

const Chart = ({
  childrenCounts,
  gradeSize,
  grade,
  schoolDisplayType,
}: {
  childrenCounts: number | undefined;
  gradeSize: number | undefined;
  grade: { id: TargetGradesType; name: string };
  schoolDisplayType: SchoolDisplayType;
}) => {
  const data = [
    {
      name: "登録数",
      value: childrenCounts ?? 0,
    },
    {
      name: "未登録数",
      value:
        gradeSize == 0 && !childrenCounts
          ? 1
          : (gradeSize ?? 0) - (childrenCounts ?? 0) > 0
          ? (gradeSize ?? 0) - (childrenCounts ?? 0)
          : 0,
    },
  ];
  const gradeColor = getGradeColor(grade.id);
  const COLORS = [gradeColor, colorsPallet.lightShade];
  const history = usePolyfitHistory();

  return (
    <>
      <ChartSection>
        <ChartTitle>
          {getGradeLabel(grade.id, schoolDisplayType, true, true, "full")}
        </ChartTitle>
        {gradeSize == undefined ? (
          <ChartEmpty>
            グラフを表示するには生徒数を設定してください
            <Button
              onClick={() => {
                history.push({ to: "PTA_GRADE_SIZE_SETTING" });
              }}
              $fill
              color="primary"
            >
              生徒数を設定する
            </Button>
          </ChartEmpty>
        ) : (
          <>
            <div style={{ width: "180px", height: "180px", outline: "none" }}>
              <PieChart width={180} height={180}>
                <Pie
                  data={data}
                  cx={80}
                  cy={80}
                  innerRadius={50}
                  outerRadius={80}
                  paddingAngle={1}
                  dataKey="value"
                  style={{ outline: "none" }}
                  animationDuration={0}
                  startAngle={90}
                  endAngle={-270}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                  <Label
                    content={({ viewBox }) => {
                      if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                        return (
                          <Center
                            x={viewBox.cx}
                            y={viewBox.cy}
                            textAnchor="middle"
                            dominantBaseline="middle"
                          >
                            <CenterValue x={viewBox.cx} y={viewBox.cy}>
                              {childrenCounts ?? 0}/{gradeSize}
                            </CenterValue>
                            <CenterText
                              x={viewBox.cx}
                              y={(viewBox.cy || 0) + 24}
                            ></CenterText>
                          </Center>
                        );
                      }
                    }}
                  />
                </Pie>
              </PieChart>
            </div>
            <ChartLegend>
              <ChartLegendItem>
                <ChartLegendItemLeft>
                  <ChartLegendItemColor
                    style={{ backgroundColor: COLORS[0] }}
                  />
                  <ChartLegendItemText>登録数</ChartLegendItemText>
                </ChartLegendItemLeft>
                <ChartLegendItemValue>
                  {childrenCounts ?? 0}
                </ChartLegendItemValue>
              </ChartLegendItem>
              <ChartLegendItem>
                <ChartLegendItemLeft>
                  <ChartLegendItemColor
                    style={{ backgroundColor: COLORS[1] }}
                  />
                  <ChartLegendItemText>未登録数</ChartLegendItemText>
                </ChartLegendItemLeft>
                {(gradeSize ?? 0) - (childrenCounts ?? 0) >= 0 ? (
                  <ChartLegendItemValue>
                    {(gradeSize ?? 0) - (childrenCounts ?? 0)}
                  </ChartLegendItemValue>
                ) : (
                  <ChartLegendItemError>
                    設定された生徒数を上回る数が登録されています
                  </ChartLegendItemError>
                )}
              </ChartLegendItem>
              <ChartLegendTotal>
                <ChartLegendItemText>生徒合計</ChartLegendItemText>
                <ChartLegendItemValue>{gradeSize}</ChartLegendItemValue>
              </ChartLegendTotal>
            </ChartLegend>
          </>
        )}
      </ChartSection>
    </>
  );
};
export default Chart;
const Center = styled.text`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ChartTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 100%;
`;

const ChartSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
`;

const CenterValue = styled.tspan`
  font-size: 24px;
  font-weight: bold;
`;

const CenterText = styled.tspan`
  font-size: 14px;
`;

const ChartLegend = styled.div`
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ChartLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`;

const ChartLegendItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
`;

const ChartLegendItemColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
`;

const ChartLegendItemText = styled.div`
  font-size: 14px;
`;

const ChartLegendItemValue = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  font-size: 14px;
`;

const ChartLegendTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(19, 34, 149, 0.2);
  width: 100%;
  margin-top: 4px;
  padding-top: 4px;
`;

const ChartEmpty = styled.div`
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  height: 180px;
  justify-content: center;
  font-size: 14px;
`;

const ChartLegendItemError = styled.div`
  color: #ff4d4d;
  font-size: 12px;
`;
