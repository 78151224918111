import React, { useState } from "react";
import styled from "styled-components";
import { zIndexes } from "src/zIndex";
import { ChatRoom } from "@shared/types/chatRoom";
import { EditModal } from "./Modal/EditModal";
import { DeleteChatRoomModal } from "./DeleteChatRoomModal";
import { colorsPallet } from "src/theme";
import { BEGroupMemberStatusModal } from "./Modal/BEGroupMemberStatusModal";
import { BEPendingInvitationModal } from "./Modal/BEPendingInvitationModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  chatRoom: ChatRoom;
  refetch: () => void;
};

export const BEMenuModal = ({ isOpen, onClose, chatRoom, refetch }: Props) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenGroupStatusModal, setIsOpenGroupStatusModal] = useState(false);
  const [isOpenPendingInvitationModal, setIsOpenPendingInvitationModal] =
    useState(false);

  const switchToEditModal = () => {
    setIsOpenGroupStatusModal(false);
    setIsOpenEditModal(true);
  };

  const switchToPendingInvitationModal = () => {
    setIsOpenGroupStatusModal(false);
    setIsOpenPendingInvitationModal(true);
  };

  return (
    <>
      {isOpen && (
        <>
          <ModalWrapper>
            <MenuList>
              <MenuItem onClick={() => setIsOpenGroupStatusModal(true)}>
                参加状況の確認
              </MenuItem>
              <MenuItem onClick={() => setIsOpenEditModal(true)}>
                グループ編集
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsOpenDeleteModal(true);
                }}
                color={colorsPallet.danger}
              >
                グループ削除
              </MenuItem>
            </MenuList>
          </ModalWrapper>
          <ModalBackground onClick={() => onClose()} />
          {isOpenGroupStatusModal && (
            <BEGroupMemberStatusModal
              isOpen={isOpenGroupStatusModal}
              onClose={() => {
                setIsOpenGroupStatusModal(false);
                onClose();
              }}
              onSwitchToEditModal={switchToEditModal}
              onSwitchToPendingInvitationModal={switchToPendingInvitationModal}
              chatRoom={chatRoom}
            />
          )}
          {isOpenEditModal && (
            <EditModal
              close={() => {
                setIsOpenEditModal(false);
                onClose();
              }}
              chatRoom={chatRoom}
              refetch={refetch}
            />
          )}
          {isOpenDeleteModal && (
            <DeleteChatRoomModal
              onClose={() => {
                setIsOpenDeleteModal(false);
                onClose();
              }}
              onSubmit={() => {
                setIsOpenDeleteModal(false);
                onClose();
                refetch();
              }}
              chatRoom={chatRoom}
            />
          )}
          {isOpenPendingInvitationModal && (
            <BEPendingInvitationModal
              isOpen={isOpenPendingInvitationModal}
              onClose={() => {
                setIsOpenPendingInvitationModal(false);
                onClose();
              }}
              chatRoom={chatRoom}
            />
          )}
        </>
      )}
    </>
  );
};

const ModalWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: ${zIndexes.modal};
  background: #ffffff;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const MenuList = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li<{ color?: string }>`
  font-size: 14px;
  padding: 16px;
  cursor: pointer;
  border-radius: 6px;
  color: ${(props) => props.color};
  &:hover {
    background-color: #e4e6f3;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: ${zIndexes.modalBackground};
`;
