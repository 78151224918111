import { CommunityUserType, UserType } from "./userType";
import {
  applicationAttendanceStatus,
  ApplicationAttendanceStatus,
  applicationSelectionStatus,
  ApplicationSelectionStatus,
} from "./recruitmentApplicationEvent";
import { CSChild } from "./csChild";
import {
  recruitmentStatusSchema,
  RecruitmentStatusSchemaType,
  ApplicationStatus,
  ApplicationStatusWithAll,
} from "../validator/features/recruitment.schema";
import { RecruitmentOrigin } from "./recruitment";
import { z } from "zod";
import { userSchema } from "../validator/models/user.schema";

export { ApplicationStatus, ApplicationStatusWithAll };

type EventStatus = {
  id: string;
  selectionStatus: ApplicationSelectionStatus;
  rejectedAt: Date | null;
  attendanceStatus: ApplicationAttendanceStatus | null;
  recruitmentSchedule: {
    id: string;
    start: Date;
    end: Date;
    date: Date;
  };
};

/**
 * 応募系APIクライアント 取得系
 */
export type RecruitmentApplication = {
  id: string;
  accountId: string;
  user: {
    id: string;
    email: string;
    name: string;
    picture: string | null;
  };
  coverLetterChatMessage?: {
    content: string;
  };
  communityUserType: CommunityUserType | null;
  recruitment: {
    id: string;
    picture: string | null;
    title: string | null;
    status: RecruitmentStatusSchemaType;
    createdBy?: string;
  };
  eventStatuses: EventStatus[];
  cSChildren?: CSChild[];
};

export type RecruitmentApplicationWithSingleEventStatus = Omit<
  RecruitmentApplication,
  "cSChildren" | "eventStatuses"
> & {
  eventStatus: EventStatus;
  recruitment: {
    id: string;
    picture: string | null;
    title: string | null;
    status: RecruitmentStatusSchemaType;
    createdBy?: string;
    origin: RecruitmentOrigin;
    community: {
      id: string;
      name: string;
    };
  };
};

export type RecruitmentApplicationWithSingleEventStatusForQR =
  RecruitmentApplicationWithSingleEventStatus & {
    boardEducationName: string | null;
  };

export const recruitmentApplicationsByScheduleIdResponseSchema = z.object({
  id: z.string(),
  picture: z.string().nullable(),
  title: z.string().nullable(),
  status: recruitmentStatusSchema,
  createdBy: z.string().optional(),
  origin: z.nativeEnum(RecruitmentOrigin),
  community: z.object({
    id: z.string().uuid(),
    name: z.string(),
  }),
  schedule: z.object({
    id: z.string().uuid(),
    start: z.date(),
    end: z.date(),
    date: z.date(),
  }),
  RecruitmentApplication: z.array(
    z.object({
      id: z.string().uuid(),
      accountId: z.string().uuid(),
      communityUserType: z.nativeEnum(UserType).nullable(),
      user: userSchema.pick({
        id: true,
        name: true,
        email: true,
        picture: true,
      }),
      eventStatus: z.object({
        id: z.string().uuid(),
        selectionStatus: z.nativeEnum(applicationSelectionStatus),
        rejectedAt: z.date().nullable(),
        attendanceStatus: z.nativeEnum(applicationAttendanceStatus).nullable(),
      }),
    })
  ),
});

export type RecruitmentApplicationsByScheduleIdResponseSchema = z.infer<
  typeof recruitmentApplicationsByScheduleIdResponseSchema
>;

export type ApplicationStatusCount = {
  status: ApplicationStatus;
  count: number;
};

export type ApplicationUserTypeCounts = {
  type: CommunityUserType;
  count: number;
};

export type ApplicationCountsForFilter = {
  statusCounts: ApplicationStatusCount[];
  userTypeCounts: ApplicationUserTypeCounts[];
};

export const RecruitmentApplicationListOrder = {
  USER_NAME: "USER_NAME",
  RECRUITMENT_TITLE: "RECRUITMENT_TITLE",
  RECRUITMENT_START_DATE: "RECRUITMENT_START_DATE",
} as const;

export type RecruitmentApplicationListOrder =
  (typeof RecruitmentApplicationListOrder)[keyof typeof RecruitmentApplicationListOrder];
