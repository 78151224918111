import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { usePolyfitHistory } from "../hooks/router";

export const RegistrationFooter = ({
  onClickNext,
  nextButtonDisabled = false,
  isDisplayBeforeButton = true,
}: {
  onClickNext?: () => void;
  nextButtonDisabled?: boolean;
  isDisplayBeforeButton?: boolean;
}) => {
  const history = usePolyfitHistory();
  return (
    <Footer>
      <ButtonExtend
        color="disabled"
        onClick={() => {
          history.goBack();
        }}
        type="button"
        isDisplay={isDisplayBeforeButton}
      >
        戻る
      </ButtonExtend>
      {!nextButtonDisabled && (
        <ButtonExtend
          size="large"
          $fill
          type="submit"
          onClick={onClickNext}
          isDisplay={true}
        >
          次へ
        </ButtonExtend>
      )}
    </Footer>
  );
};

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 12px 24px;
  background: #ffffff;
`;

const ButtonExtend = styled(Button)<{ isDisplay: boolean }>`
  width: 112px;
  height: 40px;
  visibility: ${(props) => !props.isDisplay && "hidden"};
`;
