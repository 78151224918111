import { PTAParentFamilyInvoiceListType } from "@shared/types/ptaParentFamilyInvoice";

export const sortChildInvoiceItemsByGrade = (
  invoice: PTAParentFamilyInvoiceListType
) => {
  return [...invoice.PTAChildInvoiceItems].sort((a, b) => {
    if (a.child?.grade === undefined && b.child?.grade === undefined) return 0;
    if (a.child?.grade === undefined) return 1;
    if (b.child?.grade === undefined) return -1;
    return a.child.grade - b.child.grade;
  });
};
