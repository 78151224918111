import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Spinner } from "src/components/icons/Spinner";
import { NotFoundInvoiceProductList } from "./NotFoundInvoiceProductList";
import { usePolyfitHistory } from "src/hooks/router";
import { toDateFormat } from "src/utils/time";
import { BREAKPOINTS } from "src/components/Responsive";
import { GetPTAInvoiceProductListResponseType } from "@shared/validator/features/ptaInvoiceProduct.schema";
import { colorsPallet } from "src/theme";
import { useDeletePTAInvoiceProduct } from "src/hooks/query/invoice/ptaInvoiceProduct";
import { APIError } from "src/utils/types/ApiError";
import { useToast } from "src/components/Toast";
import { DeleteInvoiceProductModal } from "../detail/components/DeleteInvoiceProductModal";
import { TrashIcon } from "src/components/icons/TrashIcon2";
interface Props {
  isLoading: boolean;
  ptaInvoiceProducts: GetPTAInvoiceProductListResponseType[];
}

export const PTAInvoiceProductListContent: React.FC<Props> = ({
  isLoading,
  ptaInvoiceProducts,
}) => {
  const history = usePolyfitHistory();
  const { mutate: deletePTAInvoiceProduct } = useDeletePTAInvoiceProduct();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [invoiceProductForDelete, setInvoiceProductForDelete] =
    useState<GetPTAInvoiceProductListResponseType | null>(null);

  const toast = useToast();
  const onOpenDeleteModal = useCallback(
    (product: GetPTAInvoiceProductListResponseType) => {
      setInvoiceProductForDelete(product);
      setIsDeleteModalOpen(true);
    },
    []
  );

  const handleDeleteSubmit = useCallback(async () => {
    if (!invoiceProductForDelete) return;
    deletePTAInvoiceProduct(
      { id: invoiceProductForDelete.id },
      {
        onSuccess: () => {
          setIsDeleteModalOpen(false);
          setInvoiceProductForDelete(null);
          toast.success("請求項目を削除しました。");
        },
        onError: (err) => {
          if (err instanceof APIError) {
            toast.error(err.message);
          } else {
            toast.error("請求項目の削除に失敗しました。");
          }
        },
      }
    );
  }, [invoiceProductForDelete]);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setInvoiceProductForDelete(null);
  }, []);

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    );
  }

  if (ptaInvoiceProducts.length === 0) {
    return <NotFoundInvoiceProductList />;
  }

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <Th>請求項目</Th>
            <Th>支払期日</Th>
            <PCOnlyHeaderText>対象人数</PCOnlyHeaderText>
            <PCOnlyHeaderText>支払い済み人数</PCOnlyHeaderText>
            <PCOnlyHeaderText>支払い率</PCOnlyHeaderText>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {ptaInvoiceProducts.map((product) => (
            <Tr
              key={product.id}
              onClick={() => {
                history.push({
                  to: "PTA_INVOICE_DETAIL",
                  query: { id: product.id },
                });
              }}
            >
              <Td>
                <TitleWrap>
                  {product.name}
                  {product.isProcessing && (
                    <IsProcessingText>処理中</IsProcessingText>
                  )}
                </TitleWrap>
              </Td>
              <Td>{toDateFormat(new Date(product.dueDate))}</Td>
              <PCOnly>
                {product.isProcessing
                  ? "-"
                  : product.PTAParentFamilyInvoices.filter(
                      (invoice) => invoice.status !== "CANCELED"
                    ).length}
              </PCOnly>
              <PCOnly>
                {product.isProcessing
                  ? "-"
                  : product.PTAParentFamilyInvoices.filter(
                      (invoice) => invoice.status == "PAID"
                    ).length}
              </PCOnly>
              <PCOnly>
                {product.isProcessing
                  ? "-"
                  : product.PTAParentFamilyInvoices.filter(
                      (invoice) => invoice.status !== "CANCELED"
                    ).length > 0
                  ? Math.round(
                      (product.PTAParentFamilyInvoices.filter(
                        (invoice) => invoice.status == "PAID"
                      ).length /
                        product.PTAParentFamilyInvoices.filter(
                          (invoice) => invoice.status !== "CANCELED"
                        ).length) *
                        100
                    )
                  : "-"}
                %
              </PCOnly>
              <SettingButtonTd>
                <MenuContainer>
                  {product.PTAParentFamilyInvoices.length === 0 &&
                    !product.isProcessing && (
                      <SettingButtonContainer>
                        <SettingButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onOpenDeleteModal(product);
                          }}
                        >
                          <TrashIcon color={colorsPallet.danger} />
                        </SettingButton>
                      </SettingButtonContainer>
                    )}
                </MenuContainer>
              </SettingButtonTd>
            </Tr>
          ))}
        </tbody>
      </StyledTable>
      {isDeleteModalOpen && invoiceProductForDelete && (
        <DeleteInvoiceProductModal
          invoiceProduct={invoiceProductForDelete}
          onSubmit={handleDeleteSubmit}
          onClose={handleCloseDeleteModal}
        />
      )}
    </>
  );
};

const LoadingWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  cursor: pointer;

  &:hover {
    background-color: #f7f8fa;
  }
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const Td = styled.td`
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #e3e6eb;
`;

const PCOnly = styled(Td)`
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

const PCOnlyHeaderText = styled(Th)`
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IsProcessingText = styled.span`
  background-color: ${colorsPallet.accent};
  color: #fff;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
`;

const SettingButtonTd = styled.td`
  border-bottom: 1px solid #e3e6eb;
  align-items: center;
`;

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SettingButton = styled.button`
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
`;
const SettingButtonContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`;
