import React, { JSX, useCallback, useEffect, useMemo, useState } from "react";
import {
  SurveyResult,
  SurveyResults,
  fetchSurveyResults,
} from "../../../apiClients/surveyResults";
import { ModalHeader, ModalPortal } from "../../../components/Modal";
import { saveToCSV } from "./saveToCSV";
import { Button } from "../../../components/Button";
import { SurveyResultField } from "./SurveyResultField";
import styled from "styled-components";
import { ResendModal } from "./ResendModal";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { isPtaAdminRole } from "../../../utils/types/role";
import { GlyphIcon } from "../../../components/icons/GlyphIcon";
import { useSurveyPostTargetUsers } from "src/hooks/api/post";
import { useGetCurrentOrganization } from "src/hooks/query/organization";

// アンケート結果閲覧用のモーダル本体
// onClose: モーダルを閉じる処理
export function SurveyResultModal({
  surveyQuestionsId,
  onClose,
  postId,
  isOpen,
  isExpired,
  isAlreadyAnswered,
}: {
  surveyQuestionsId: string;
  onClose: () => void;
  postId: string;
  isOpen: boolean;
  isExpired: boolean;
  isAlreadyAnswered: boolean;
}): JSX.Element {
  // DBから集計結果を取得する
  const [surveyResults, setSurveyResults] = useState<SurveyResults>({
    isAllowedToShowResults: false,
    resultList: Array<SurveyResult>(),
  });
  const [isOpenResendModal, setIsOpenResendModal] = useState(false);
  const currentUser = useCurrentUser();
  const { targetUsers } = useSurveyPostTargetUsers(surveyQuestionsId);
  const { organization, isLoading } = useGetCurrentOrganization();

  /**
   * 回答者数
   */
  const answeredCounter = useCallback(() => {
    if (
      surveyResults.resultList.length === 0 ||
      surveyResults.resultList[0].answers.length === 0
    )
      return 0;
    return surveyResults.resultList[0].answers.length;
  }, [surveyResults, targetUsers]);

  const answeredCount = useMemo(answeredCounter, [answeredCounter]);
  const unAnsweredCount = targetUsers.length - answeredCount;

  const getSurveyResults = async (surveyQuestionsId: string) => {
    const surveyResults = await fetchSurveyResults(surveyQuestionsId);
    setSurveyResults(surveyResults);
  };

  // TODO: 本人が回答した時に再取得する際にローディングがあった方がよい
  useEffect(() => {
    getSurveyResults(surveyQuestionsId);
  }, [surveyQuestionsId, isAlreadyAnswered]);
  // サーバーが閲覧権限なしと判定した場合表示しない
  if (!surveyResults.isAllowedToShowResults) {
    return <></>;
  }
  const isPTAAdmin = isPtaAdminRole(currentUser.role);

  /**
   * 未回答者情報
   */
  const unAnsweredUsers = targetUsers.filter(
    (targetUser) =>
      !surveyResults.resultList[0].answers.some(
        (answer) => answer.userId === targetUser.id
      )
  );

  /**
   * 未回答者数を表示させる判定条件
   * - PTAAdmin && 承認者
   */
  const isShowUnansweredCount = currentUser?.isApprover || isPTAAdmin;

  const openResendModal = () => {
    setIsOpenResendModal(true);
    onClose();
  };

  if (isLoading || !organization) return <></>;

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={onClose}>
          <ModalHeader>アンケート結果</ModalHeader>
          <TextButton
            type="button"
            onClick={() =>
              saveToCSV(surveyResults, organization.schoolDisplayType)
            }
          >
            CSV出力
          </TextButton>
          <SurveyResultContainer>
            {surveyResults.resultList.map(
              (surveyResult: SurveyResult, index: number) => (
                <SurveyResultField key={index} surveyResult={surveyResult} />
              )
            )}
          </SurveyResultContainer>
          {isShowUnansweredCount && (
            <UnansweredBlock>
              <GlyphIcon />
              未回答数:{" "}
              {unAnsweredCount === 0 ? (
                "0"
              ) : (
                <UnansweredModalButton onClick={openResendModal}>
                  {unAnsweredCount}
                </UnansweredModalButton>
              )}
              /{targetUsers.length}
            </UnansweredBlock>
          )}

          <StyledButton size="large" $fill onClick={onClose}>
            閉じる
          </StyledButton>
        </ModalPortal>
      )}
      <ResendModal
        isOpen={isOpenResendModal}
        onClose={() => setIsOpenResendModal(false)}
        postId={postId}
        unAnsweredUsers={unAnsweredUsers}
        isExpired={isExpired}
      />
    </>
  );
}

const SurveyResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
`;

const UnansweredBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
`;

const TextButton = styled.button`
  color: #005ec4;
  font-size: 14px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
  position: absolute;
  top: 40px;
  right: 48px;
`;

const UnansweredModalButton = styled.button`
  color: #005ec4;
  font-size: 20px;
  font-weight: normal;
  border-width: 0;
  background-color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;
