import React, { useMemo, useRef } from "react";
import ExternalLinkIcon from "src/components/icons/ExternalLinkIcon";
import { createPolyfitUrl } from "src/hooks/router";
import { useMessageList } from "src/features/BoardEducation/ChatMessage/hooks/useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import { Col, MobileViewOnly, PcViewOnly } from "src/components/Responsive";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";
import {
  ChatRoomContainer,
  ChatRoomInfo,
  ChatBox,
  Header,
  FloatWrapper,
  SubTitle,
  SearchInputWrapper,
  FlexWrapper,
  FloatDateLabel,
  RecruitmentTitle,
  LinkWrapper,
  SearchInputWrapperForMobile,
} from "src/features/common/ChatMessage/components/ChatRoomContent/ChatRoomContentStyled";

type Props = {
  isOpen: boolean;
  chatRoomId: string;
  chatRoomParticipantId: string;
  lastReadAt: Date;
  recruitmentId: string;
  recruitmentDateAndStatusLabel: string | null;
  recruitmentTitle: string;
  communityName: string;
  isDeletedRecruitment: boolean;
};

export const ChatRoomRecruitmentContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const { displayItemList, lastReadAt } = useMessageList({
    initLastReadAt: props.lastReadAt,
    messageListRef,
    chatRoomParticipantId: props.chatRoomParticipantId,
  });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const applicationDetailUrl = useMemo(() => {
    return createPolyfitUrl({
      to: "BE_ADMIN_RECRUITMENT_DETAIL",
      query: { id: props.recruitmentId },
    });
  }, [props.recruitmentId]);

  if (!displayItemList) return null;

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <FlexWrapper>
            <ChatRoomBackButton targetRoute="BE_CHATROOM_LIST" />
            <Col>
              <RecruitmentTitle>{props.recruitmentTitle}</RecruitmentTitle>
              <SubTitle>{props.communityName}</SubTitle>
              <SubTitle>{props.recruitmentDateAndStatusLabel}</SubTitle>
            </Col>
          </FlexWrapper>
          <PcViewOnly>
            <FlexWrapper>
              <SearchInputWrapper>
                <SearchInput
                  searchText={searchText}
                  setSearchText={setSearchText}
                  onPrevResult={handlePrevResult}
                  onNextResult={handleNextResult}
                  resultCount={searchResults.length}
                />
              </SearchInputWrapper>
              {!props.isDeletedRecruitment && (
                <LinkWrapper>
                  <a
                    href={applicationDetailUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLinkIcon />
                  </a>
                </LinkWrapper>
              )}
            </FlexWrapper>
          </PcViewOnly>
        </Header>

        <MobileViewOnly>
          <FlexWrapper>
            <SearchInputWrapperForMobile>
              <SearchInput
                searchText={searchText}
                setSearchText={setSearchText}
                onPrevResult={handlePrevResult}
                onNextResult={handleNextResult}
                resultCount={searchResults.length}
              />
            </SearchInputWrapperForMobile>
            {!props.isDeletedRecruitment && (
              <LinkWrapper>
                <a href={applicationDetailUrl} target="_blank" rel="noreferrer">
                  <ExternalLinkIcon />
                </a>
              </LinkWrapper>
            )}
          </FlexWrapper>
        </MobileViewOnly>
      </ChatRoomInfo>
      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    searchText={searchText}
                    participantId={props.chatRoomParticipantId}
                    lastReadAt={lastReadAt}
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
            }
          })}
        </ChatMessages>
      </ChatBox>
    </ChatRoomContainer>
  );
};
