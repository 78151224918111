import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Button, ButtonGroup } from "src/components/Button";
import {
  StyledPasswordInputWrapper,
  StyledPasswordInput,
  StyledInputLabel,
  StyledEyeIconWrapper,
} from "../../../components/Form";
import { Google } from "src/components/icons/SvgIcons";
import { User } from "firebase/auth";
import { useToast } from "src/components/Toast";
import {
  reauthenticateWithGoogle,
  reauthenticateWithPassword,
} from "src/utils/firebase";
import { EyeOpenIcon } from "src/components/icons/EyeOpenIcon";
import { EyeClosedIcon } from "src/components/icons/EyeClosedIcon";

interface ReauthenticationProps {
  title: string;
  firebaseUser: User;
  onCancel: () => void;
  onOk: () => void;
}

export const Reauthentication: React.FC<ReauthenticationProps> = ({
  title,
  firebaseUser,
  onCancel,
  onOk,
}) => {
  const toast = useToast();
  const [currentPassword, setCurrentPassword] = useState("");
  const [isDisplayPassword, setIsDisplayPassword] = useState<boolean>(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const hasPasswordProvider = useMemo(() => {
    return firebaseUser.providerData.some((p) => p.providerId === "password");
  }, [firebaseUser]);

  const googleProvider = useMemo(() => {
    return firebaseUser.providerData.find((p) => p.providerId === "google.com");
  }, [firebaseUser]);

  const handleReauthenticateWithPassword = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      await reauthenticateWithPassword(firebaseUser, currentPassword);
      toast.success("認証に成功しました");
      onOk();
    } catch (err) {
      toast.error("パスワードが間違っています");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReauthenticateWithGoogle = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      await reauthenticateWithGoogle(firebaseUser);
      toast.success("認証に成功しました");
      onOk();
    } catch (err) {
      toast.error("Googleでの認証に失敗しました");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Container>
      <Description>{title}</Description>

      {hasPasswordProvider ? (
        <>
          <CodeContainer>
            <StyledInputLabel>パスワードを入力</StyledInputLabel>
            <StyledPasswordInputWrapper>
              <StyledPasswordInput
                type={isDisplayPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentPassword(e.target.value)
                }
                placeholder="パスワードを入力"
              />
              <StyledEyeIconWrapper
                onClick={() => setIsDisplayPassword(!isDisplayPassword)}
              >
                {isDisplayPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
              </StyledEyeIconWrapper>
            </StyledPasswordInputWrapper>
          </CodeContainer>

          <ButtonContainer>
            <ButtonGroup>
              <Button color="primary" $empty size="large" onClick={onCancel}>
                キャンセル
              </Button>
              <Button
                color="primary"
                $fill
                size="large"
                type="button"
                onClick={handleReauthenticateWithPassword}
                style={{ marginLeft: "8px" }}
                disabled={isProcessing}
              >
                認証する
              </Button>
            </ButtonGroup>
          </ButtonContainer>
        </>
      ) : (
        <SSOContainer>
          {googleProvider && (
            <Button
              onClick={handleReauthenticateWithGoogle}
              color={"subPrimary"}
              $fill={true}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Google />
              Googleでログイン
            </Button>
          )}
        </SSOContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  /* Add any container styles here */
`;

const Description = styled.div`
  margin: -10px 0 20px;
`;

const CodeContainer = styled.div`
  margin-top: 15px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const SSOContainer = styled.div`
  margin: 30px 0 12px;
`;
