import React, { useMemo } from "react";
import styled from "styled-components";
import {
  ModalHeader,
  ModalBody,
  ModalPortal,
  ModalThreeButtons,
} from "src/components/Modal";
import { ChatRoom, ChatRoomMember } from "@shared/types/chatRoom";
import { ChatRoomMemberList } from "src/features/common/ChatMessage/components/ChatRoomGroup/ChatRoomMemberList";
import { sortMembersByApprovalStatus } from "src/features/common/ChatMessage/components/ChatRoomGroup/utils/sortMembersByApprovalStatus";

type BaseGroupMemberStatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToEditModal: () => void;
  onSwitchToPendingInvitationModal: () => void;
  chatRoom: ChatRoom;
  members: ChatRoomMember[];
  isLoading: boolean;
  error: Error | null;
  disableMemberLinks?: boolean;
  onMemberClick: (member: ChatRoomMember) => void;
};

export const BaseGroupMemberStatusModal: React.FC<
  BaseGroupMemberStatusModalProps
> = ({
  isOpen,
  onClose,
  onSwitchToEditModal,
  onSwitchToPendingInvitationModal,
  members,
  isLoading,
  error,
  disableMemberLinks,
  onMemberClick,
}) => {
  if (!isOpen) return null;

  const sortedMembers = useMemo(
    () => sortMembersByApprovalStatus(members),
    [members]
  );

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>参加状況の確認</ModalHeader>
      <ModalBody>
        <SubTitle>グループメンバーの参加状況を確認できます。</SubTitle>
        <ChatRoomMemberList
          members={sortedMembers}
          isLoading={isLoading}
          error={error}
          disableMemberLinks={disableMemberLinks}
          onMemberClick={onMemberClick}
        />
      </ModalBody>
      <ModalThreeButtons
        cancelText="閉じる"
        onCancel={onClose}
        submitSecondText="グループ編集"
        submitSecondEmpty={true}
        submitSecondColor="subPrimary"
        onSecondSubmit={onSwitchToEditModal}
        submitPrimaryText="招待中メンバーへの再通知"
        submitPrimaryEmpty={true}
        submitPrimaryColor="subPrimary"
        onPrimarySubmit={onSwitchToPendingInvitationModal}
      />
    </ModalPortal>
  );
};

const SubTitle = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  color: #333;
`;
