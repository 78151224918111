import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { applicationStatusOptions } from "src/apiClients/recruitmentApplication";
import { Button } from "src/components/Button";
import { BREAKPOINTS } from "src/components/Responsive";
import { ExecuteApplicationsModal } from "src/features/common/Recruitment/application/ExecuteApplicationsModal";
import { buttons, colorsPallet } from "src/theme";
import styled from "styled-components";

type Props = {
  applicants: RecruitmentApplication[];
  randomSelectedApplicants: RecruitmentApplication[];
  onClickRandomSelection: (count: number) => void;
  onClickExecute: (status: string, comment: string) => void;
};

export const RandomSelection = ({
  applicants,
  randomSelectedApplicants,
  onClickRandomSelection,
  onClickExecute,
}: Props) => {
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string>(
    applicationStatusOptions[0].value
  );
  const [isExecuteModal, setIsExecuteModal] = useState<boolean>(false);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const changeStatusText = applicationStatusOptions.find(
    (option) => option.value === selectedStatus
  )?.text;

  // 応募idが全て同じでないとランダム抽選できない
  const isDisabledRandomSelection = applicants.some(
    (applicant) => applicant.recruitment.id !== applicants[0].recruitment.id
  );

  const isDisabledExecute = !(
    ((selectedCount > 0 && // 選択人数が1以上
      randomSelectedApplicants.length > 0) || // ランダム抽選結果が1以上
      selectedAll) && // 全選択が選択されている
    selectedStatus !== applicationStatusOptions[0].value
  ); // 選考ステータスが「未選考」

  const handleRandomSelection = () => {
    const count =
      selectedCount > applicants.length ? applicants.length : selectedCount;
    setSelectedCount(count);
    onClickRandomSelection(count);
  };

  useEffect(() => {
    if (selectedAll) {
      setSelectedCount(applicants.length);
    } else if (selectedCount !== applicants.length) {
      setSelectedAll(false);
    }
  }, [selectedAll, selectedCount, applicants.length]);

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAll(e.target.checked);
    if (e.target.checked) {
      setSelectedCount(applicants.length);
    } else {
      setSelectedCount(0);
    }
  };

  const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    if (Number(value) > applicants.length) {
      setSelectedCount(applicants.length);
    } else {
      setSelectedCount(Number(value));
    }
    if (Number(value) === applicants.length) {
      setSelectedAll(true);
    } else {
      setSelectedAll(false);
    }
  };

  const executeSelectedApplicants = selectedAll
    ? applicants
    : randomSelectedApplicants;

  return (
    <Container>
      <LeftSection>
        <CheckboxWrapper>
          <Label htmlFor="select-all" data-tooltip-id="select-all-tooltip">
            <StyledCheckbox
              id="select-all"
              type="checkbox"
              disabled={isDisabledRandomSelection}
              checked={selectedAll}
              onChange={handleSelectAllChange}
              data-tooltip-id="select-all-tooltip"
            />
            すべて
          </Label>
          {isDisabledRandomSelection && (
            <Tooltip
              id="select-all-tooltip"
              place="bottom"
              content="募集内容が同一の場合のみ全選択できます。"
              style={{ maxWidth: "312px", zIndex: 100 }}
            />
          )}
        </CheckboxWrapper>
        <StyledInput
          type="number"
          placeholder="人数を入力"
          min={1}
          max={applicants.length}
          value={selectedCount === 0 ? "" : selectedCount}
          onChange={handleCountChange}
          data-tooltip-id="select-count-tooltip"
          disabled={isDisabledRandomSelection}
        />
        {isDisabledRandomSelection && (
          <Tooltip
            id="select-count-tooltip"
            place="bottom"
            content="募集内容が同一の場合のみ選択人数を入力できます。"
            style={{ maxWidth: "312px", zIndex: 100 }}
          />
        )}
        <StyledButton
          $fill
          disabled={isDisabledRandomSelection || selectedCount === 0}
          $disabled={isDisabledRandomSelection || selectedCount === 0}
          onClick={handleRandomSelection}
          data-tooltip-id="random-selection-tooltip"
        >
          ランダム抽選
        </StyledButton>
        {isDisabledRandomSelection && (
          <Tooltip
            id="random-selection-tooltip"
            place="bottom"
            content="募集内容が同一かつ、選択人数が1人以上の場合のみランダム抽選できます。"
            style={{ maxWidth: "312px", zIndex: 100 }}
          />
        )}
      </LeftSection>
      <RightSection>
        <SPExecuteWrapper>
          <p>{selectedCount}名を</p>
          <StyledSelect
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            {applicationStatusOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.text}
              </option>
            ))}
          </StyledSelect>
          <p>に設定</p>
        </SPExecuteWrapper>
        <SPExecuteWrapper>
          <ExecuteButton
            disabled={isDisabledExecute}
            $disabled={isDisabledExecute}
            onClick={() => {
              setIsExecuteModal(true);
            }}
            data-tooltip-id="execute-applications-tooltip"
          >
            一括処理実行
          </ExecuteButton>
          {isDisabledExecute && (
            <Tooltip
              id="execute-applications-tooltip"
              place="bottom"
              content="ランダム抽選結果が1人以上かつ、選考ステータスが「選考中」以外の場合のみ一括処理実行できます。"
              style={{ maxWidth: "312px", zIndex: 100 }}
            />
          )}
        </SPExecuteWrapper>
      </RightSection>

      {isExecuteModal && (
        <ExecuteApplicationsModal
          status={selectedStatus}
          applicants={executeSelectedApplicants}
          onSubmit={onClickExecute}
          onClose={() => setIsExecuteModal(false)}
          disabled={false}
          changeStatusText={changeStatusText}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: #fff;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SPExecuteWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledInput = styled.input`
  padding: 8px;
  border: 1px solid ${colorsPallet.lightShade};
  border-radius: 4px;
  font-size: 16px;
  width: 120px;

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 14px;
    width: 100px;
  }
`;

const StyledCheckbox = styled.input`
  margin-left: 4px;
`;

const Label = styled.label`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledButton = styled(Button)<{ $disabled: boolean }>`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 14px;
  }
`;

const StyledSelect = styled.select`
  padding: 8px;
  border: 1px solid ${colorsPallet.lightShade};
  border-radius: 4px;
  font-size: 16px;

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 14px;
  }
`;

const ExecuteButton = styled(Button)<{ $disabled: boolean }>`
  padding: 8px 16px;
  background-color: ${(props) =>
    props.$disabled ? buttons.disabled.bg : colorsPallet.success};
  color: ${(props) => (props.$disabled ? buttons.disabled.color : "white")};
  border: none;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: ${(props) =>
      props.$disabled ? buttons.ghost : colorsPallet.success};
  }

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 14px;
  }
`;
