import React, { useMemo, useState } from "react";
import { Margin } from "src/components/Margin";
import { PageBody, PageContainer } from "src/components/Page";
import { SubTitle, Title } from "src/components/Title";
import { Button } from "src/components/Button";
import { SignatureTemplatePTAUploadModal } from "./SignatureTemplatePTAUploadModal";
import { useSignatureTemplateList } from "src/hooks/query/signatureTemplate";
import { Label } from "src/components/form/Label";
import { SignatureTemplateHistoryTable } from "./SignatureTemplateHistoryTable";
import styled from "styled-components";
import { BREAKPOINTS } from "src/components/Responsive";

export const SignatureTemplatesPTATab = ({ isAdmin }: { isAdmin: boolean }) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const { query, mutation } = useSignatureTemplateList({
    type: "PTA",
  });
  const signatureTemplates = useMemo(() => query.data, [query.data]);

  return (
    <PageContainer>
      <HeaderContainer>
        <TitleWrapper>
          <Title>入会規約設定</Title>
          <SubTitle>PTAの入会規約の設定が行えます</SubTitle>
        </TitleWrapper>
        {isAdmin && (
          <ButtonGroup>
            <UploadButton
              size="large"
              $fill
              onClick={() => setShowUploadModal(true)}
            >
              規約をアップロード
            </UploadButton>
          </ButtonGroup>
        )}
      </HeaderContainer>
      <Margin marginBottom={8} />
      <PageBody>
        {showUploadModal && (
          <SignatureTemplatePTAUploadModal
            close={() => {
              setShowUploadModal(false);
            }}
            mutation={mutation}
            signatureTemplates={signatureTemplates}
          />
        )}

        <Label>入会規約履歴</Label>
        <Margin marginBottom={8} />
        <SignatureTemplateHistoryTable
          signatureTemplates={signatureTemplates}
          isLoading={query.isLoading}
        />
      </PageBody>
    </PageContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonGroup = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
    width: 100%;
    margin-top: 8px;
    text-align: right;
  }
`;

const UploadButton = styled(Button)`
  height: 40px;
  width: fit-content;
`;
