import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import * as usersApi from "src/apiClients/users";
import { QueryOptions } from "../QueryOptions";

export function useAdminUsersBelongingToOrganization(
  { organizationId }: { organizationId: string },
  queryOptions: QueryOptions = {}
) {
  const query = useQuery({
    queryKey: [
      "api",
      "users",
      "adminUsersBelongingToOrganization",
      organizationId,
    ],
    queryFn: async () => {
      const response = await usersApi.getAdminUsersBelongingToOrganization();
      return response.users;
    },
    enabled: !!organizationId,
    ...queryOptions,
  });

  return {
    adminUsers: useMemo(() => query.data ?? [], [query.data]),
    ...query,
  };
}

export function useInvalidateAdminUsersBelongingToOrganization() {
  const queryClient = useQueryClient();
  function invalidateAdminUsersBelongingToOrganization() {
    queryClient.invalidateQueries({
      queryKey: ["api", "users", "adminUsersBelongingToOrganization"],
    });
  }
  return { invalidateAdminUsersBelongingToOrganization };
}
