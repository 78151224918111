import * as client from "./client";
import { ChildrenFilter } from "@shared/validator/models/child.schema";
import {
  exportCsvOfPTAInvoiceDetailSchema,
  ExportCsvOfPTAInvoiceDetailSchemaType,
  invoiceCSVCustomInfoSchema,
  InvoiceCSVCustomInfoSchemaForServerType,
  InvoiceCSVCustomInfoSchemaType,
} from "@shared/validator/features/invoiceCSVCustomInfo";
import {
  exportCsvSchema,
  ExportCsvSchemaType,
  exportCsvByInternalAdminSchema,
  ExportCsvByInternalAdminSchemaType,
} from "@shared/validator/features/csv.schema";
import {
  downloadApplicationListSchema,
  DownloadApplicationListSchemaType,
} from "@shared/validator/features/recruitment-application.schema";

export async function exportCsv(
  type: "PARENT" | "TEACHER",
  q?: string,
  filter?: ChildrenFilter
): Promise<Blob> {
  const filterQuery = filter ? JSON.stringify(filter) : undefined;
  const args = exportCsvSchema.parse({
    type,
    ...(q && { q }),
    ...(filterQuery && { filter: filterQuery }),
  });
  const res = await client.getBlob<ExportCsvSchemaType, Blob>(
    "/csv/exportCsv",
    args
  );
  return res;
}

export async function exportCsvByInternalAdmin(
  organizationId: string
): Promise<Blob> {
  const args = exportCsvByInternalAdminSchema.parse({
    organizationId,
  });
  const res = await client.getBlob<ExportCsvByInternalAdminSchemaType, Blob>(
    "/csv/exportCsvByInternalAdmin",
    args
  );
  return res;
}

// 請求用PTA名簿CSVダウンロード
export async function exportCsvByPTAInvoiceProcess(
  csvCustom: InvoiceCSVCustomInfoSchemaType
): Promise<Blob> {
  const args = invoiceCSVCustomInfoSchema.parse(csvCustom);
  const filter = JSON.stringify(args);
  const res = await client.getInvoiceBlob<
    InvoiceCSVCustomInfoSchemaForServerType,
    Blob
  >("/csv/exportCsvByPTAInvoiceProcess", { filter });
  return res;
}

// 請求詳細CSVダウンロード
export async function exportCsvOfPTAInvoiceDetail(
  csvCustom: ExportCsvOfPTAInvoiceDetailSchemaType
): Promise<Blob> {
  const args = exportCsvOfPTAInvoiceDetailSchema.parse(csvCustom);
  const res = await client.getInvoiceBlob<
    ExportCsvOfPTAInvoiceDetailSchemaType,
    Blob
  >("/csv/exportCsvOfPTAInvoiceDetail", args);
  return res;
}

//応募情報CSVダウンロード
export async function exportRecruitmentCSV(
  arg: DownloadApplicationListSchemaType
) {
  const args = downloadApplicationListSchema.parse(arg);
  const res = await client.getBlob<DownloadApplicationListSchemaType, Blob>(
    "/csv/exportCsvRecruitmentApplicationByFilter",
    args
  );
  return res;
}
