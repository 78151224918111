import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/Button";
import { ModalPortal } from "src/components/Modal";
import { ModalHeader, ModalBody } from "src/components/Modal";
import styled from "styled-components";
import { updatePTAInvoiceProductNameSchema } from "src/@shared/validator/features/ptaInvoiceProduct.schema";
import { Margin } from "src/components/Margin";

type Props = {
  id: string;
  defaultName: string;
  onSubmit: (name: string) => Promise<void>;
  onClose: () => void;
};

type FormValues = {
  id: string;
  name: string;
};

export const ChangeNameModal: React.FC<Props> = ({
  id,
  defaultName,
  onSubmit,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: zodResolver(updatePTAInvoiceProductNameSchema),
    defaultValues: { id, name: defaultName },
  });

  const handleFormSubmit = async (data: FormValues) => {
    await onSubmit(data.name);
  };

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>請求項目名変更</ModalHeader>
      <ModalBody>
        <ModalContent>
          <Description>新しい請求項目名を入力してください。</Description>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Input placeholder="例: 2025年度PTA会費" {...register("name")} />
            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
            <Margin marginTop={16} />
            <Button type="submit" $fill disabled={!!errors.name}>
              変更
            </Button>
          </form>
        </ModalContent>
      </ModalBody>
    </ModalPortal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #343741;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
`;
