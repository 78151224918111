import React, { useMemo } from "react";
import styled from "styled-components";
import { getStaticImageUrl } from "../../utils/getStaticImageUrl";
import { getGenderText } from "../../utils/types/gender";
import { getAgeGroupText } from "../../utils/types/ageGroup";
import { toDisplayDateFormatYearMonth } from "../../utils/time";
import {
  dayOfWeekOptions,
  getDayOfWeekText,
} from "../../utils/types/dayOfWeek";
import { getClassName } from "../../utils/getChildClass";
import { getCommunityUserTypeText } from "./Community/utils/utils";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { User } from "src/apiClients/users";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import * as usersApi from "src/apiClients/users";
import { OrganizationWithSignatureTemplates } from "@shared/types/organization";
import { roleOptions } from "src/utils/types/role";
import {
  useFindAdditionalCommunities,
  useFindMainCommunity,
} from "./Community/utils/hooks";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import { usePartner } from "src/hooks/api/partner";
import { usePartnerForAdmin } from "src/hooks/api/partnerForAdmin";
import { useMyPostUserGroupList } from "src/hooks/query/postUserGroup";
import { Avatar } from "src/components/Common/Avatar";
import { Tag } from "src/components/icons/Tag";
import { PencilIcon } from "src/components/icons/PencilIcon";
import { colorsPallet } from "src/theme";
import { calculateDeclinedRate } from "src/features/common/Recruitment/application/utils/calculateDeclinedRate";
import { calculateAbsentRate } from "src/features/common/Recruitment/application/utils/calculateAbsentRate";
import { BREAKPOINTS } from "src/components/Responsive";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

type MemberInfoContentProps = {
  memberUser: User;
  userId: string;
  isPtaMember: boolean;
  isPtaAdmin: boolean;
  isCurrentUser: boolean;
  isAdmin: boolean;
  recruitmentList: AppliedRecruitment[] | null;
  inPTA: boolean;
  isCSAdmin: boolean;
  organization: OrganizationWithSignatureTemplates;
  openEditBaseInfoModal: () => void;
};
/**
 * PTAMemberPage用のコンポーネント
 */
export const MemberInfoContent = ({
  memberUser,
  userId,
  isPtaMember,
  isPtaAdmin,
  isAdmin,
  isCurrentUser,
  recruitmentList,
  inPTA,
  isCSAdmin,
  organization,
  openEditBaseInfoModal,
}: MemberInfoContentProps) => {
  // TODO: usePartnerForAdminとusePartnerを統合する
  const { partner } = usePartnerForAdmin(userId, isPtaAdmin);
  const { partner: myPartner } = usePartner(inPTA);
  const { myPostGroups } = useMyPostUserGroupList(
    {
      accountId: memberUser?.account?.id || "",
    },
    {},
    inPTA
  );

  const communityRoles = memberUser?.account?.communityRoles ?? [];
  const { elementarySchoolCommunities, juniorHighSchoolCommunities } =
    useOrganizationCommunity();

  const mainElementarySchool = useFindMainCommunity(
    elementarySchoolCommunities,
    communityRoles
  );
  const mainJuniorHighSchool = useFindMainCommunity(
    juniorHighSchoolCommunities,
    communityRoles
  );
  const elementarySchools = useFindAdditionalCommunities(
    elementarySchoolCommunities,
    communityRoles
  );
  const juniorHighSchools = useFindAdditionalCommunities(
    juniorHighSchoolCommunities,
    communityRoles
  );

  const declineRate = useMemo(
    () => calculateDeclinedRate(recruitmentList),
    [recruitmentList]
  );
  const absentRate = useMemo(
    () => calculateAbsentRate(recruitmentList),
    [recruitmentList]
  );

  return (
    <MemberWrap>
      <PreUserNameCell>
        {/* 画像 */}
        <PictureWrap>
          <Avatar
            src={memberUser?.picture || defaultUserImg}
            alt={memberUser?.name}
            size={80}
          />
          {/* 名前 */}
          <Name>
            <KanaNameDisplay>
              {memberUser?.baseInfo?.lastNameKana}{" "}
              {memberUser?.baseInfo?.firstNameKana}
            </KanaNameDisplay>
            <NameDisplay>
              {memberUser?.baseInfo?.lastName} {memberUser?.baseInfo?.firstName}
            </NameDisplay>
            {(memberUser.type === "PARENT" ||
              memberUser.type === "TEACHER") && (
              <PartAndBelongDisplay>
                {memberUser.baseInfo?.belong?.name}{" "}
                {memberUser.baseInfo?.part?.name}
                {(isPtaMember || isPtaAdmin) && (
                  <OptionalWrapper>
                    {myPostGroups.map((group) => (
                      <Badge key={group.id}>
                        <Icon>
                          <Tag size={14} />
                        </Icon>
                        {group.name}
                      </Badge>
                    ))}
                  </OptionalWrapper>
                )}
              </PartAndBelongDisplay>
            )}
            {(declineRate !== null || absentRate !== null) && (
              <RateTagContainer>
                {declineRate !== null && (
                  <DeclineRateTag>不参加率 {declineRate}％</DeclineRateTag>
                )}
                {absentRate !== null && (
                  <AbsentRateTag>当日欠席率 {absentRate}％</AbsentRateTag>
                )}
              </RateTagContainer>
            )}
          </Name>
        </PictureWrap>

        {/* 基本情報 */}
        <div style={{ padding: "15px", flex: 1 }}>
          <BaseInfoWrap>
            <tbody>
              {memberUser && usersApi.isResidentUser(memberUser) && (
                <>
                  <tr>
                    <BaseInfoTh>性別</BaseInfoTh>
                    <BaseInfoTd>
                      {memberUser.baseInfo?.gender &&
                        getGenderText(memberUser.baseInfo?.gender)}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>年代</BaseInfoTh>
                    <BaseInfoTd>
                      {getAgeGroupText(memberUser.baseInfo?.ageGroup)}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>生年月日</BaseInfoTh>
                    <BaseInfoTd>
                      {memberUser.baseInfo?.birthday &&
                        toDisplayDateFormatYearMonth(
                          memberUser.baseInfo?.birthday
                        )}
                    </BaseInfoTd>
                  </tr>
                </>
              )}
              {memberUser?.email && (
                <tr>
                  <BaseInfoTh>メールアドレス</BaseInfoTh>
                  <BaseInfoTd>{memberUser?.email}</BaseInfoTd>
                </tr>
              )}
              {memberUser.baseInfo?.phoneNumber && (
                <tr>
                  <BaseInfoTh>電話番号</BaseInfoTh>
                  <BaseInfoTd>{memberUser.baseInfo?.phoneNumber}</BaseInfoTd>
                </tr>
              )}
              {memberUser.baseInfo?.schoolGroup &&
                memberUser.type === "PARENT" && (
                  <tr>
                    <BaseInfoTh>登校班</BaseInfoTh>
                    <BaseInfoTd>{memberUser.baseInfo?.schoolGroup}</BaseInfoTd>
                  </tr>
                )}
              {inPTA && (
                <tr>
                  <BaseInfoTh>ユーザータイプ</BaseInfoTh>
                  <BaseInfoTd>
                    {memberUser?.type === "PARENT"
                      ? "保護者"
                      : memberUser?.type === "TEACHER"
                      ? "教員"
                      : memberUser?.type === "RESIDENT"
                      ? "地域住民"
                      : ""}
                  </BaseInfoTd>
                </tr>
              )}
              {memberUser && usersApi.isTeacherUser(memberUser) && (
                <tr>
                  <BaseInfoTh>担任</BaseInfoTh>
                  <BaseInfoTd>
                    {memberUser.teacherInfo?.hasHomeroom
                      ? `学年 ${getGradeLabel(
                          memberUser.teacherInfo.grade,
                          organization?.schoolDisplayType,
                          true,
                          false,
                          "short"
                        )} / クラス ${getClassName(
                          memberUser.teacherInfo.class
                        )}`
                      : "なし"}
                  </BaseInfoTd>
                </tr>
              )}
              {(inPTA || isCSAdmin) && (
                <tr>
                  <BaseInfoTh>権限</BaseInfoTh>
                  <BaseInfoTd>
                    {
                      roleOptions.find((role) => memberUser.role === role.value)
                        ?.text
                    }
                  </BaseInfoTd>
                </tr>
              )}
              {memberUser.type === "PARENT" && (
                <tr>
                  <BaseInfoTh>来年度に入学予定の児童（同学校）</BaseInfoTh>
                  <BaseInfoTd>
                    {memberUser.account?.isPreschooler ? "いる" : "いない"}
                  </BaseInfoTd>
                </tr>
              )}
              {memberUser.type === "PARENT" && (
                <tr>
                  <BaseInfoTh>
                    未就園児（保育園や幼稚園に通っていない子ども）
                  </BaseInfoTh>
                  <BaseInfoTd>
                    {memberUser.account?.isHasNonEnrolledChild
                      ? "いる"
                      : "いない"}
                  </BaseInfoTd>
                </tr>
              )}
              {(memberUser.type === "PARENT" ||
                memberUser.type === "TEACHER") &&
                memberUser?.baseInfo?.selfFreeMemo && (
                  <tr>
                    <BaseInfoTh>自由記述</BaseInfoTh>
                    <BaseInfoTd>
                      {memberUser?.baseInfo?.selfFreeMemo
                        .split("\n")
                        .map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                    </BaseInfoTd>
                  </tr>
                )}
              {(memberUser.type === "PARENT" ||
                memberUser.type === "TEACHER") &&
                isPtaAdmin &&
                memberUser?.baseInfo?.adminFreeMemo && (
                  <tr>
                    <BaseInfoTh>自由記述（役員管理用）</BaseInfoTh>
                    <BaseInfoTd>
                      {memberUser?.baseInfo?.adminFreeMemo
                        .split("\n")
                        .map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                    </BaseInfoTd>
                  </tr>
                )}
              {partner && (
                <>
                  <tr>
                    <BaseInfoTh>追加保護者</BaseInfoTh>
                    <BaseInfoTd>
                      {partner?.lastName + " " + partner?.firstName}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>追加保護者のメールアドレス</BaseInfoTh>
                    <BaseInfoTd>{partner?.email}</BaseInfoTd>
                  </tr>
                </>
              )}
              {!partner && myPartner && isCurrentUser && (
                <>
                  <tr>
                    <BaseInfoTh>追加保護者</BaseInfoTh>
                    <BaseInfoTd>
                      {myPartner?.lastName + " " + myPartner?.firstName}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>追加保護者のメールアドレス</BaseInfoTh>
                    <BaseInfoTd>{myPartner?.email}</BaseInfoTd>
                  </tr>
                </>
              )}

              {memberUser && usersApi.isResidentUser(memberUser) && (
                <>
                  {isCSAdmin && (
                    <tr>
                      <BaseInfoTh>住所</BaseInfoTh>
                      <BaseInfoTd>
                        〒{memberUser?.baseInfo?.postalCode}{" "}
                        {memberUser?.baseInfo?.prefecture}
                        {memberUser?.baseInfo?.city}
                        {memberUser?.baseInfo?.address1}
                        {memberUser?.baseInfo?.address2}
                      </BaseInfoTd>
                    </tr>
                  )}
                  <tr>
                    <BaseInfoTh>希望活動曜日</BaseInfoTh>
                    <BaseInfoTd>
                      {!!memberUser.residentInfo?.preferredDays &&
                      memberUser.residentInfo?.preferredDays?.length > 0
                        ? memberUser.residentInfo?.preferredDays
                            ?.sort((a, b) => {
                              const indexA = dayOfWeekOptions.findIndex(
                                (dayObj) => dayObj.value === a
                              );
                              const indexB = dayOfWeekOptions.findIndex(
                                (dayObj) => dayObj.value === b
                              );
                              return indexA - indexB;
                            })
                            .map((day) => getDayOfWeekText(day) ?? "")
                            .join("、")
                        : "該当なし"}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>スキル・資格</BaseInfoTh>
                    <BaseInfoTd>
                      {[
                        ...(memberUser.residentInfo?.teacherLicenses ?? []),
                        ...(memberUser.residentInfo?.medicalLicenses ?? []),
                        ...(memberUser.residentInfo?.skills ?? []),
                      ].join("、")}
                      {[
                        ...(memberUser.residentInfo?.teacherLicenses ?? []),
                        ...(memberUser.residentInfo?.medicalLicenses ?? []),
                        ...(memberUser.residentInfo?.skills ?? []),
                      ].length === 0 && "該当なし"}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>希望する活動内容（有償・無償）</BaseInfoTh>
                    <BaseInfoTd>
                      {memberUser.residentInfo?.preferredPaid &&
                      memberUser.residentInfo.preferredPaid.includes("PAID") &&
                      memberUser.residentInfo.preferredPaid.includes("FREE")
                        ? "どちらでも"
                        : memberUser.residentInfo?.preferredPaid &&
                          memberUser.residentInfo.preferredPaid.includes("PAID")
                        ? "有償"
                        : memberUser.residentInfo?.preferredPaid &&
                          memberUser.residentInfo.preferredPaid.includes("FREE")
                        ? "無償"
                        : "該当なし"}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>希望ボランティア</BaseInfoTh>
                    <BaseInfoTd>
                      {memberUser.residentInfo?.preferredVolunteerType &&
                        (
                          memberUser.residentInfo.preferredVolunteerType ?? []
                        ).join("、")}
                      {(memberUser.residentInfo?.preferredVolunteerType ?? [])
                        .length === 0 && "該当なし"}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>所属団体</BaseInfoTh>
                    <BaseInfoTd>
                      {[
                        ...(memberUser.residentInfo?.privateEnterprise
                          ? [memberUser.residentInfo?.privateEnterprise]
                          : []),
                        ...(memberUser.residentInfo?.university
                          ? [memberUser.residentInfo?.university]
                          : []),
                        ...(memberUser.residentInfo?.externalOrganization
                          ? [memberUser.residentInfo?.externalOrganization]
                          : []),
                        ...(memberUser.residentInfo?.pta
                          ? [memberUser.residentInfo?.pta]
                          : []),
                      ].join("、")}
                      {[
                        ...(memberUser.residentInfo?.privateEnterprise
                          ? [memberUser.residentInfo?.privateEnterprise]
                          : []),
                        ...(memberUser.residentInfo?.university
                          ? [memberUser.residentInfo?.university]
                          : []),
                        ...(memberUser.residentInfo?.externalOrganization
                          ? [memberUser.residentInfo?.externalOrganization]
                          : []),
                        ...(memberUser.residentInfo?.pta
                          ? [memberUser.residentInfo?.pta]
                          : []),
                      ].length === 0 && "該当なし"}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>最寄りの小学校区</BaseInfoTh>
                    <BaseInfoTd>
                      {mainElementarySchool?.community?.name ?? ""}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>最寄りの小学校区での役割</BaseInfoTh>
                    <BaseInfoTd>
                      {getCommunityUserTypeText(
                        mainElementarySchool?.communityRole?.type
                      )}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>最寄りの中学校区</BaseInfoTh>
                    <BaseInfoTd>
                      {mainJuniorHighSchool?.community.name ?? ""}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>最寄りの中学校区での役割</BaseInfoTh>
                    <BaseInfoTd>
                      {getCommunityUserTypeText(
                        mainJuniorHighSchool?.communityRole.type
                      )}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>追加小学校区</BaseInfoTh>
                    <BaseInfoTd>
                      {elementarySchools.length > 0 &&
                        elementarySchools
                          .map((community) => community.name)
                          .join("、")}
                    </BaseInfoTd>
                  </tr>
                  <tr>
                    <BaseInfoTh>追加中学校区</BaseInfoTh>
                    <BaseInfoTd>
                      {juniorHighSchools.length > 0 &&
                        juniorHighSchools
                          .map((community) => community.name)
                          .join("、")}
                    </BaseInfoTd>
                  </tr>
                </>
              )}
            </tbody>
          </BaseInfoWrap>
        </div>
        {(isAdmin || isCurrentUser) && (
          <EditLinkContainer
            onClick={() => {
              openEditBaseInfoModal();
            }}
          >
            <PencilIcon />
            <EditBaseInfoIcon>編集</EditBaseInfoIcon>
          </EditLinkContainer>
        )}
      </PreUserNameCell>
    </MemberWrap>
  );
};

const MemberWrap = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
`;

const PreUserNameCell = styled.div`
  padding: 14px 24px 14px 4px;
  display: grid;
  grid-template-columns: minmax(auto, 400px) 1fr 48px;
  align-items: start;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const PictureWrap = styled.div`
  padding: 20px;
  display: flex;
`;

const Name = styled.div`
  color: #343741;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 40px 0 20px;
  @media (max-width: 1279px) {
    width: 100%;
    padding: 0;
    padding-left: 44px;
  }
`;

const NameDisplay = styled.div`
  font-weight: 700;
  font-size: 22px;
`;

const KanaNameDisplay = styled.div`
  font-size: 14px;
`;

const PartAndBelongDisplay = styled.div`
  margin-top: 8px;
  color: #69707d;
`;

const RateTagContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.SP}) {
    display: inline-flex;
    gap: 8px;
  }
`;

const DeclineRateTag = styled.div`
  display: inline-block;
  background-color: #f1d86f;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 3px;
  margin-top: 8px;
`;

const AbsentRateTag = styled(DeclineRateTag)`
  background-color: #f89c8b;
`;

const OptionalWrapper = styled.div`
  align-items: center;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const Badge = styled.div`
  background: #f5f7fa;
  padding: 4px 8px;
  font-weight: normal;
  height: fit-content;
  font-size: 12px;
  border-radius: 5px;
  color: #343741;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const Icon = styled.span`
  flex-shrink: 0;
  display: grid;
  position: center;
`;

const BaseInfoWrap = styled.table`
  font-weight: normal;
  border-collapse: collapse;
  text-align: left;
`;

const BaseInfoTh = styled.th`
  padding: 5px 15px;
  color: #69707d;
  font-weight: normal;
  vertical-align: top;
  @media (max-width: 1279px) {
    min-width: 145px;
    padding: 5px 0;
    font-size: 14px;
  }
`;

const BaseInfoTd = styled.td`
  word-break: break-all;
  padding: 5px 15px;
  color: #343741;
  @media (max-width: 1279px) {
    padding: 5px 0;
    font-size: 14px;
  }
`;

const EditLinkContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  flex-wrap: nowrap;

  @media (max-width: 1000px) {
    margin-top: 0;
    position: absolute;
    right: 24px;
    top: 24px;
  }
  @media (max-width: 580px) {
    position: relative;
    top: inherit;
    right: inherit;
  }
`;

const EditBaseInfoIcon = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0077cc;
  white-space: nowrap;
  color: ${colorsPallet.primary};
`;
