import React, { useMemo } from "react";
import { ChatRoom } from "@shared/types/chatRoom";
import {
  BaseCSAdminGroupModal,
  BaseCSAdminGroupModalSubmitArgs,
} from "./BaseCSAdminGroupModal";
import { updateCSAdminChatRoom } from "src/apiClients/chatRoom";
import { useToast } from "src/components/Toast";
import { logger } from "src/utils/logger";
import { useAdminUsersBelongingToOrganization } from "src/hooks/query/users/adminUsers";
import { User } from "src/apiClients/users";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { ModalPortal } from "src/components/Modal";
import { Spinner } from "src/components/icons/Spinner";
import styled from "styled-components";
import { useInvalidateChatRooms } from "src/hooks/query/chatRoomList";
import { useInvalidateChatRoomMembers } from "src/hooks/query/chatRoom/chatRoomMembers";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  chatRoom: ChatRoom;
};

export const EditCSAdminGroupModal = ({ isOpen, onClose, chatRoom }: Props) => {
  const toast = useToast();
  const currentUser = useCurrentUser();
  const { organization } = useGetCurrentOrganization();
  const { invalidateChatRooms } = useInvalidateChatRooms();
  const { invalidateChatRoomMembers } = useInvalidateChatRoomMembers();
  const { isLoading: isAdminLoading, adminUsers } =
    useAdminUsersBelongingToOrganization({
      organizationId: organization?.id ?? "",
    });

  // 自分を除外した管理者一覧
  const adminUsersWithoutCurrentUser: User[] = useMemo(() => {
    if (!adminUsers) return [];
    return adminUsers.filter((u) => u.id !== currentUser.id);
  }, [adminUsers, currentUser]);

  const participantUserIds = chatRoom.ParticipantChatRooms.filter(
    (p) => p.participantType === "USER" && p.approvalStatus !== "REJECTED"
  ).map((p) => p.participantId);

  const userIdToAccountIdMap = new Map<string, string | undefined>();
  adminUsersWithoutCurrentUser.forEach((user) => {
    userIdToAccountIdMap.set(user.id, user.account?.id);
  });

  const participantAccountIds = participantUserIds
    .map((userId) => userIdToAccountIdMap.get(userId))
    .filter((accountId): accountId is string => accountId !== undefined);

  const handleSubmit = async (result: BaseCSAdminGroupModalSubmitArgs) => {
    const { roomName, accountIds, file } = result;
    try {
      await updateCSAdminChatRoom(
        { chatRoomId: chatRoom.id, name: roomName, accountIds },
        file
      );
      invalidateChatRoomMembers(chatRoom.id);
      invalidateChatRooms();
      toast.success("チャットルームを編集しました");
      onClose();
    } catch (err) {
      logger.error(err);
      toast.error("チャットルームの編集に失敗しました");
    }
  };

  if (isAdminLoading) {
    return (
      <ModalPortal onClose={onClose}>
        <LoadingCard>
          <Spinner />
        </LoadingCard>
      </ModalPortal>
    );
  }

  return (
    <BaseCSAdminGroupModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="管理者間メッセージ"
      initialRoomName={chatRoom.name}
      initialSelectedAccountIds={participantAccountIds}
    />
  );
};

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
