import { User } from "src/apiClients/users";
import * as client from "./client";
import {
  fetchChatMessagesByRoomIdSchema,
  FetchChatMessagesByRoomIdSchemaType,
  createChatMessageSchema,
  CreateChatMessageSchemaType,
} from "src/@shared/validator/features/chatMessage.schema";
import { Role } from "@shared/types/role";

export type ChatMessage = PlainChatMessage | ChatMessageWithHeader;

export type ChatMessageBase = {
  id: string;
  chatRoomId: string;
  participantId: string;
  content: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  enabledAt: string;
  account: {
    role: {
      id: string;
      name: Role;
    };
  };
};

export type PlainChatMessage = ChatMessageBase & {
  type: "PLAIN";
};

export type ChatMessageWithHeader = ChatMessageBase & {
  type: "WITH_HEADER";
  headerText: string;
  headerBackgroundColor: string;
};

export type ChatMessageFile = {
  id: string;
  path: string;
  name: string;
  type: "IMAGE" | "PDF";
};

export type ChatMessageForView = ChatMessage & {
  user: Pick<User, "picture" | "name">;
  files: ChatMessageFile[];
};

export async function fetchChatMessagesByRoomId(
  args: FetchChatMessagesByRoomIdSchemaType
) {
  fetchChatMessagesByRoomIdSchema.parse(args);
  const chatMessages = await client.get<
    FetchChatMessagesByRoomIdSchemaType,
    ChatMessageForView[]
  >("/chat-message/fetchChatMessagesByRoomId", args);
  return chatMessages;
}

export async function createChatMessage(
  chatRoomId: CreateChatMessageSchemaType["chatRoomId"],
  content: CreateChatMessageSchemaType["content"],
  files: File[]
): Promise<void> {
  const args = { chatRoomId, content };
  createChatMessageSchema.parse(args);
  await client.postWithFormData<
    CreateChatMessageSchemaType & { files: File[] },
    { message: ChatMessage }
  >("/chat-message/create", { ...args, files });
}
