import { z } from "zod";
import { zRequiredString } from "@shared/validator/rules/string";
import { baseInfoSchema } from "@shared/validator/models/baseInfo.schema";
import { baseInfoTeacherInfoSchema } from "@shared/validator/models/teacherInfo.schema";
const baseInfoCommonFormSchema = baseInfoSchema.pick({
  userId: true,
  lastName: true,
  lastNameKana: true,
  firstName: true,
  firstNameKana: true,
  belongId: true,
  partId: true,
  phoneNumber: true,
  schoolGroup: true,
  selfFreeMemo: true,
  adminFreeMemo: true,
  isPreschooler: true,
});
/**
 * ホーム/プロフィール
 */
export const PTAFormValueSchema = z.object({
  baseInfo: baseInfoCommonFormSchema.extend({
    isPreschooler: z.number().optional(),
  }),
  isHasNonEnrolledChild: z.number().optional(),
});

/**
 * 名簿/プロフィール
 * 保護者用
 */
export const parentPTAFormValueSchema = z.object({
  baseInfo: baseInfoCommonFormSchema.extend({
    userId: zRequiredString,
    isPreschooler: z.number().optional(),
  }),
  parentInfo: z.object({
    isHasNonEnrolledChild: z.number().optional(),
  }),
});

/**
 * 名簿/プロフィール
 * 教員用
 */
export const teacherPTAFormValueSchema = z.object({
  baseInfo: baseInfoCommonFormSchema.extend({
    userId: zRequiredString,
    isPreschooler: z.number().optional(),
  }),
  teacherInfo: baseInfoTeacherInfoSchema,
});
