import { useMemo } from "react";
import {
  getPTAParentFamily,
  getPTAParentFamilyBaseInfo,
} from "src/apiClients/ptaParentFamily";
import { getOrGenerateInvitationTokenForPTAParentFamily } from "src/apiClients/invitation";
import { useQuery } from "@tanstack/react-query";
import { useApiContext } from "src/apiClients/client";
import {
  ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY,
  AccountTypeOfPTAParentFamily,
} from "@shared/types/ptaParentFamily";

export const usePTAParentFamily = () => {
  const apiContext = useApiContext();

  const query = useQuery({
    queryKey: ["api", "ptaParentFamily", apiContext],
    queryFn: async () => {
      return await getPTAParentFamily();
    },
  });

  return {
    ptaParentFamily: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};

export const usePTAParentFamilyBaseInfo = () => {
  const apiContext = useApiContext();

  const query = useQuery({
    queryKey: ["api", "ptaParentFamilyBaseInfo", apiContext],
    queryFn: async () => {
      return await getPTAParentFamilyBaseInfo();
    },
  });

  return {
    ptaParentFamilyBaseInfo: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};

export const usePTAParentFamilyInvitationToken = () => {
  const apiContext = useApiContext();

  const query = useQuery({
    queryKey: ["api", "invitation", "ptaParentFamily", apiContext],
    queryFn: async () => {
      return await await getOrGenerateInvitationTokenForPTAParentFamily({});
    },
  });

  return {
    token: useMemo(() => query.data || "", [query.data]),
    ...query,
  };
};

export const useAccountTypeOfPTAParentFamily = (
  currentAccountId: string | undefined
) => {
  const apiContext = useApiContext();

  const query = useQuery({
    queryKey: ["api", "ptaParentFamily", apiContext, currentAccountId],
    queryFn: async (): Promise<AccountTypeOfPTAParentFamily | null> => {
      const family = await getPTAParentFamily();
      if (!family || !currentAccountId) {
        return null;
      } else if (family?.mainAccount.id === currentAccountId) {
        return ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY.main;
      } else if (family?.subAccount?.id === currentAccountId) {
        return ACCOUNT_TYPE_OF_PTA_PARENT_FAMILY.sub;
      } else {
        return null;
      }
    },
  });

  return {
    accountTypeOfPTAParentFamily: useMemo(
      () => query.data || null,
      [query.data]
    ),
    ...query,
  };
};
