import { z } from "zod";
import { ptaParentFamilyInvoiceSchema } from "../validator/models/invoice/ptaParentFamilyInvoice.schema";
import { ptaChildInvoiceItemSchema } from "../validator/models/invoice/ptaChildInvoiceItem.schema";
import { childSchema } from "../validator/models/child.schema";
import { baseInfoSchema } from "../validator/models/baseInfo.schema";
import { ptaInvoiceProductSchema } from "../validator/models/invoice/ptaInvoiceProduct.schema";

const PTAParentFamilyMainAccountSchema = z.object({
  mainAccount: z
    .object({
      user: z.object({
        baseInfo: baseInfoSchema.pick({
          lastName: true,
          firstName: true,
          lastNameKana: true,
          firstNameKana: true,
        }),
        id: z.string().uuid(),
      }),
    })
    .nullish()
    .transform((val) => val ?? undefined),
});

export const ptaParentFamilyInvoiceListSchema =
  ptaParentFamilyInvoiceSchema.extend({
    PTAChildInvoiceItems: z.array(
      ptaChildInvoiceItemSchema.extend({
        child: childSchema
          .nullish()
          .transform((val) => (!val || val?.deletedAt ? undefined : val)),
      })
    ),
    PTAParentFamily: PTAParentFamilyMainAccountSchema,
  });

export type PTAParentFamilyInvoiceListType = z.infer<
  typeof ptaParentFamilyInvoiceListSchema
>;

export const ptaParentFamilyInvoiceWithProductListSchema =
  ptaParentFamilyInvoiceListSchema.extend({
    PTAInvoiceProduct: ptaInvoiceProductSchema,
  });

export type PTAParentFamilyInvoiceWithProductListType = z.infer<
  typeof ptaParentFamilyInvoiceWithProductListSchema
>;

export const ptaParentFamilyInvoiceListCountSchema = z.object({
  pendingInvoiceCount: z.number(),
  allInvoiceCount: z.number(),
  paidInvoiceCount: z.number(),
  ptaParentFamilyCount: z.number(),
});

export type PTAParentFamilyInvoiceListCountType = z.infer<
  typeof ptaParentFamilyInvoiceListCountSchema
>;

export const ptaParentFamilyInvoiceListResponseSchema = z.object({
  PTAParentFamilyInvoiceItems: z.array(ptaParentFamilyInvoiceListSchema),
  ...ptaParentFamilyInvoiceListCountSchema.shape,
});

export type PTAParentFamilyInvoiceListResponseType = z.infer<
  typeof ptaParentFamilyInvoiceListResponseSchema
>;

export const InvoiceStatusLabel = {
  PENDING: "未払い",
  PROCESSING: "支払い処理中",
  PAID: "支払い済み",
  CANCELED: "キャンセル",
} as const;
