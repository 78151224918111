import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  EditModalFormLabel,
  ModalWrapper,
} from "../../../pages/pta/admin/ProfilePage";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import { ModalBody, ModalHeader, ModalPortal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import styled from "styled-components";
import {
  DayOfWeekType,
  dayOfWeekOptions,
} from "../../../utils/types/dayOfWeek";
import {
  TeachingLicenseType,
  MedicalLicenseType,
  teachingLicenseOptions,
  medicalLicenseOptions,
  skillOptions,
  SkillType,
} from "../../../utils/types/license";
import {
  PaidType,
  VolunteerType,
  paidOption,
  volunteerOption,
} from "../../../@shared/types/volunteerType";
import { updateResidentInfo } from "../../../apiClients/auth";
import {
  ButtonGroupExtend,
  ButtonSelect,
} from "../../../components/ButtonSelect";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useInvalidateGetResidentUsers } from "src/hooks/query/residentUsers";
import {
  convertPreferredPaid,
  residentInfoFormSchema,
} from "src/validator/form/residentInfo/residentInfoFormSchema";
import { residentInfoSchema } from "@shared/validator/models/residentInfo.schema";

type Props = {
  onClose: () => void;
  residentInfo: FormValues;
  fetch: () => void;
};

const FormValueSchema = residentInfoFormSchema.omit({
  privateEnterprise: true,
  university: true,
  externalOrganization: true,
  pta: true,
  walkMinute: true,
});

type FormValues = z.infer<typeof FormValueSchema>;

export const ResidentInfoEditModal = ({
  onClose,
  residentInfo,
  fetch,
}: Props) => {
  const toast = useToast();
  const { invalidateGetResidentUsers } = useInvalidateGetResidentUsers();

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(FormValueSchema),
    defaultValues: {
      preferredDays: residentInfo.preferredDays,
      teacherLicenses: residentInfo.teacherLicenses,
      medicalLicenses: residentInfo.medicalLicenses,
      skills: residentInfo.skills,
      preferredPaid: residentInfo.preferredPaid,
      preferredVolunteerType: residentInfo.preferredVolunteerType,
    },
    mode: "onBlur",
  });
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const onSubmit = async (data: FormValues) => {
    setDoubleClickBlocked(true);

    try {
      const mergedData = { ...residentInfo, ...data };
      const convertedPreferredPaid = convertPreferredPaid(
        mergedData.preferredPaid
      );
      const parsedData = residentInfoSchema.parse({
        ...mergedData,
        preferredPaid: convertedPreferredPaid,
      });

      await updateResidentInfo(parsedData);
      invalidateGetResidentUsers();
      toast.success("配信・応募条件を更新しました");
      fetch();
    } catch {
      toast.error("配信・応募条件の更新に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
      onClose();
    }
  };

  const handlePreferredDaysChange = (value: DayOfWeekType) => {
    const list = watch("preferredDays");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("preferredDays", newLists);
    } else {
      const newLists = [...list, value];
      setValue("preferredDays", newLists);
    }
  };

  const handleTeacherChange = (value: TeachingLicenseType) => {
    const list = watch("teacherLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("teacherLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("teacherLicenses", newLists);
    }
  };

  const handleMedicalChange = (value: MedicalLicenseType) => {
    const list = watch("medicalLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("medicalLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("medicalLicenses", newLists);
    }
  };

  const handleSkillChange = (value: SkillType) => {
    const list = watch("skills");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("skills", newLists);
    } else {
      const newLists = [...list, value];
      setValue("skills", newLists);
    }
  };

  const handlePreferredPaidChange = (value: PaidType) => {
    setValue("preferredPaid", value);
  };

  const handleVolunteerChange = (value: VolunteerType) => {
    const list = watch("preferredVolunteerType");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("preferredVolunteerType", newLists);
    } else {
      const newLists = [...list, value];
      setValue("preferredVolunteerType", newLists);
    }
  };

  return (
    <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
      <ModalWrapper>
        <ModalHeader>配信・応募条件</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <EditModalFormLabel>希望活動曜日</EditModalFormLabel>
              <ButtonGroupExtend>
                {dayOfWeekOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("preferredDays").includes(option.value)}
                    onClick={() => handlePreferredDaysChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              {errors?.preferredDays != null ? (
                <ErrorMessage>
                  {Array.isArray(errors?.preferredDays) &&
                    errors?.preferredDays?.map((e) => e.message)}
                </ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}
            </div>
            <div>
              <EditModalFormLabel>教員免許状</EditModalFormLabel>
              <ButtonGroupExtend>
                {teachingLicenseOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("teacherLicenses").includes(option.value)}
                    onClick={() => handleTeacherChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              {errors?.teacherLicenses != null ? (
                <ErrorMessage>
                  {/* @ts-ignore */}
                  {errors?.teacherLicenses?.map((e) => e.message)}
                </ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}
            </div>
            <div>
              <EditModalFormLabel>医療・看護</EditModalFormLabel>
              <ButtonGroupExtend>
                {medicalLicenseOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("medicalLicenses").includes(option.value)}
                    onClick={() => handleMedicalChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              {errors?.medicalLicenses != null ? (
                <ErrorMessage>
                  {/* @ts-ignore */}
                  {errors?.medicalLicenses?.map((e) => e.message)}
                </ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}
            </div>
            <div>
              <EditModalFormLabel>スキル</EditModalFormLabel>
              <ButtonGroupExtend>
                {skillOptions.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("skills").includes(option.value)}
                    onClick={() => handleSkillChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              {errors?.skills != null ? (
                <ErrorMessage>
                  {/* @ts-ignore */}
                  {errors?.skills?.map((e) => e.message)}
                </ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}
            </div>
            <div>
              <EditModalFormLabel>有償無償選択</EditModalFormLabel>
              <ButtonGroupExtend>
                {paidOption.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("preferredPaid") === option.value}
                    onClick={() => handlePreferredPaidChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              {errors?.preferredPaid != null ? (
                <ErrorMessage>選択必須項目です</ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}
            </div>
            <div>
              <EditModalFormLabel>ボランティアの項目選択</EditModalFormLabel>
              <ButtonGroupExtend>
                {volunteerOption.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("preferredVolunteerType").includes(
                      option.value
                    )}
                    onClick={() => handleVolunteerChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>
              {errors?.medicalLicenses != null ? (
                <ErrorMessage>
                  {/* @ts-ignore */}
                  {errors?.medicalLicenses?.map((e) => e.message)}
                </ErrorMessage>
              ) : (
                <Margin marginTop={16} />
              )}
            </div>
            <Margin marginTop={25} />
            <Flex>
              <Button
                type="button"
                color="primary"
                $empty
                size="large"
                onClick={onClose}
              >
                キャンセル
              </Button>
              <Button
                type="submit"
                color="primary"
                $fill
                size="large"
                disabled={doubleClickBlocked}
              >
                保存
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
