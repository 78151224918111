import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import { getRecruitApplicationsByScheduleId } from "src/apiClients/recruitmentApplication";

export const useGetRecruitApplicationsByScheduleId = (
  recruitmentId: string,
  recruitmentScheduleId: string,
  queryOptions: QueryOptions = {}
) => {
  const query = useQuery({
    queryKey: [
      "api",
      "recruitment",
      "getRecruitApplicationsByScheduleId",
      recruitmentId,
      recruitmentScheduleId,
    ],
    queryFn: async () =>
      await getRecruitApplicationsByScheduleId({
        recruitmentId,
        recruitmentScheduleId,
      }),
    ...queryOptions,
  });

  return {
    recruitment: useMemo(() => query.data || null, [query.data]),
    ...query,
  };
};
