import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import {
  usePTAParentFamily,
  usePTAParentFamilyBaseInfo,
} from "src/hooks/query/ptaParentFamily";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Button } from "src/components/Button";
import { PTAParentFamilyInvitationModal } from "./PTAParentFamilyInvitationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CurrentUser } from "src/apiClients/auth";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { BREAKPOINTS } from "src/components/Responsive";
import * as theme from "../../../theme";

export const PTAParentFamilyInfoContent = ({ user }: { user: CurrentUser }) => {
  const { ptaParentFamily, isLoading: isLoadingPtaParentFamily } =
    usePTAParentFamily();
  const {
    ptaParentFamilyBaseInfo,
    isLoading: isLoadingPtaParentFamilyBaseInfo,
  } = usePTAParentFamilyBaseInfo();
  const [isOpenInvitationModal, setIsOpenInvitationModal] = useState(false);

  const isMainAccount = (accountId: string | undefined): boolean => {
    return accountId === ptaParentFamily?.mainAccount?.id;
  };

  const myselfBaseInfo = useMemo(() => {
    return isMainAccount(user?.account?.id)
      ? ptaParentFamilyBaseInfo?.mainAccountBaseInfo
      : ptaParentFamilyBaseInfo?.subAccountBaseInfo;
  }, [ptaParentFamilyBaseInfo]);
  const partnerBaseInfo = useMemo(() => {
    return isMainAccount(user?.account?.id)
      ? ptaParentFamilyBaseInfo?.subAccountBaseInfo
      : ptaParentFamilyBaseInfo?.mainAccountBaseInfo;
  }, [ptaParentFamilyBaseInfo]);

  const onCloseInvitationModal = useCallback(() => {
    setIsOpenInvitationModal(false);
  }, [setIsOpenInvitationModal]);

  const onOpenInvitationModal = useCallback(() => {
    setIsOpenInvitationModal(true);
  }, [setIsOpenInvitationModal]);

  const displayNameCreator = (
    lastName: string | undefined,
    firstName: string | undefined,
    lastNameKana: string | undefined,
    firstNameKana: string | undefined
  ) => {
    return `${lastName} ${firstName} (${lastNameKana} ${firstNameKana})`;
  };

  const displayBelongPartCreator = (
    belongName: string | undefined,
    partName: string | undefined
  ): string => {
    return `${belongName || "所属なし"}・${partName || "役職なし"}`;
  };

  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoadingPtaParentFamily || isLoadingPtaParentFamilyBaseInfo ? (
            <>読み込み中…</>
          ) : (
            <div>
              <StyledContainer>
                <PersonFormLabel>ご本人様の情報</PersonFormLabel>
                {isMainAccount(user.account?.id) ? (
                  <FontAwesomeIcon icon={faStar as IconProp} />
                ) : null}
                <PersonInfoTable>
                  <thead>
                    <StyledTr>
                      <StyledTh>お名前</StyledTh>
                      <StyledTd>
                        {displayNameCreator(
                          myselfBaseInfo?.lastName,
                          myselfBaseInfo?.firstName,
                          myselfBaseInfo?.lastNameKana,
                          myselfBaseInfo?.firstNameKana
                        )}
                      </StyledTd>
                    </StyledTr>
                    <StyledTr>
                      <StyledTh>メールアドレス</StyledTh>
                      <StyledTd>{user?.email}</StyledTd>
                    </StyledTr>
                    <StyledTr>
                      <StyledTh>所属・役職</StyledTh>
                      <StyledTd>
                        {displayBelongPartCreator(
                          myselfBaseInfo?.belong?.name,
                          myselfBaseInfo?.part?.name
                        )}
                      </StyledTd>
                    </StyledTr>
                  </thead>
                </PersonInfoTable>
              </StyledContainer>

              {ptaParentFamily?.subAccount ? (
                <StyledContainer>
                  <PersonFormLabel>パートナー様の情報</PersonFormLabel>
                  {isMainAccount(user.account?.id) ? null : (
                    <FontAwesomeIcon icon={faStar as IconProp} />
                  )}
                  <PersonInfoTable>
                    <thead>
                      <StyledTr>
                        <StyledTh>お名前</StyledTh>
                        <StyledTd>
                          {displayNameCreator(
                            partnerBaseInfo?.lastName,
                            partnerBaseInfo?.firstName,
                            partnerBaseInfo?.lastNameKana,
                            partnerBaseInfo?.firstNameKana
                          )}
                        </StyledTd>
                      </StyledTr>
                      <StyledTr>
                        <StyledTh>メールアドレス</StyledTh>
                        <StyledTd>
                          {isMainAccount(user.account?.id)
                            ? ptaParentFamily.subAccount.user.email
                            : ptaParentFamily.mainAccount.user.email}
                        </StyledTd>
                      </StyledTr>
                      <StyledTr>
                        <StyledTh>所属・役職</StyledTh>
                        <StyledTd>
                          {displayBelongPartCreator(
                            partnerBaseInfo?.belong?.name,
                            partnerBaseInfo?.part?.name
                          )}
                        </StyledTd>
                      </StyledTr>
                    </thead>
                  </PersonInfoTable>
                </StyledContainer>
              ) : null}
            </div>
          )}

          <Flex style={{ justifyContent: "flex-end" }}>
            {!isLoadingPtaParentFamily && isMainAccount(user.account?.id) ? (
              <InvitationButton
                $fill
                color="text"
                disabled={Boolean(ptaParentFamily?.subAccount)}
                onClick={onOpenInvitationModal}
              >
                パートナーを招待
              </InvitationButton>
            ) : null}
          </Flex>

          {isOpenInvitationModal && (
            <PTAParentFamilyInvitationModal onClose={onCloseInvitationModal} />
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>
    </>
  );
};

const StyledContainer = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

const InvitationButton = styled(Button)`
  margin-right: 10px;
`;

const PersonInfoTable = styled.table`
  margin-top: 0;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const PCBaseInfoWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
  padding: 24px;
  flex: 1;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 580px) {
    display: block;
  }
`;

const StyledTr = styled.tr``;

const StyledTh = styled.th`
  min-width: 300px;
  text-align: left;
  padding-right: 50px;
  padding-bottom: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.colorsPallet.darkestShade};
  vertical-align: top;

  @media (max-width: ${BREAKPOINTS.SP}) {
    padding-right: 30px;
    white-space: nowrap;
    font-size: 12px;
  }
`;

const StyledTd = styled.td`
  word-break: break-all;
  text-align: left;
  font-weight: 400;
  padding-bottom: 5px;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${theme.colorsPallet.darkestShade};

  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 12px;
    white-space: wrap;
  }
`;

const EditModalFormLabel = styled.label`
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-top: 14px;
  margin-bottom: 4px;
  display: inline-block;
`;

const PersonFormLabel = styled(EditModalFormLabel)`
  font-size: 16px;
  color: #343741;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 15px;
`;
