import { z } from "zod";
import { zRequiredString } from "../../rules/string";

export const invoiceStatusSchema = z.enum([
  "PENDING",
  "PROCESSING",
  "PAID",
  "CANCELED",
]);
export type InvoiceStatus = z.infer<typeof invoiceStatusSchema>;

export const invoicePaymentMethodSchema = z.enum([
  "INSTANT_PAYMENT",
  "CASH",
  "KONBINI",
]);
export type InvoicePaymentMethod = z.infer<typeof invoicePaymentMethodSchema>;

export const invoicePaymentMethodLabel = {
  INSTANT_PAYMENT: "カード",
  CASH: "現金",
  KONBINI: "コンビニ支払い",
} as const;

export const invoiceCancelReasonSchema = z.enum([
  "MANUAL",
  "DELETE_ACCOUNT",
  "DELETE_CHILD",
]);
export type InvoiceCancelReason = z.infer<typeof invoiceCancelReasonSchema>;

export const invoiceCancelReasonLabel = {
  MANUAL: "管理者によるキャンセル",
  DELETE_ACCOUNT: "アカウント削除によるキャンセル",
  DELETE_CHILD: "子供の削除によるキャンセル",
} as const;

export const ptaParentFamilyInvoiceSchema = z.object({
  id: zRequiredString,
  organizationId: zRequiredString,
  PTAParentFamilyId: zRequiredString,
  PTAInvoiceProductId: zRequiredString,
  unitAmount: z.number().int().positive(),
  status: invoiceStatusSchema,
  paymentMethod: invoicePaymentMethodSchema.nullable(),
  stripePaymentLinkId: zRequiredString,
  stripePaymentLinkURL: zRequiredString,
  stripePaymentId: z.string().nullable(),
  inactive: z.date().nullable().optional(),
  cancelComment: z.string().max(3000).nullable().optional(),
  cancelReason: invoiceCancelReasonSchema.nullable().optional(),
  idempotencyKey: zRequiredString,
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  paidAt: z.date().nullable().optional(),
});

export type PTAParentFamilyInvoiceType = z.infer<
  typeof ptaParentFamilyInvoiceSchema
>;
