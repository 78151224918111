import React from "react";
import { ChatRoom, ChatRoomMember } from "@shared/types/chatRoom";
import { useGetChatRoomMembers } from "src/hooks/query/boardEducation/chatRooms/chatRoomMembers";
import { usePolyfitHistory } from "src/hooks/router";
import { BaseGroupMemberStatusModal } from "src/features/common/ChatMessage/components/ChatRoomGroup/BaseGroupMemberStatusModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToEditModal: () => void;
  onSwitchToPendingInvitationModal: () => void;
  chatRoom: ChatRoom;
};

export const BEGroupMemberStatusModal: React.FC<Props> = (props) => {
  const history = usePolyfitHistory();
  const { members, isLoading, error } = useGetChatRoomMembers(
    props.chatRoom.id
  );

  const handleMemberClick = (member: ChatRoomMember) => {
    history.push({
      to: "BE_ADMIN_MEMBER_DETAIL",
      query: {
        accountId: member.user.id,
        communityId: props.chatRoom.communityId ?? "",
      },
    });
  };

  return (
    <BaseGroupMemberStatusModal
      {...props}
      members={members}
      isLoading={isLoading}
      error={error}
      disableMemberLinks={true}
      onMemberClick={handleMemberClick}
    />
  );
};
