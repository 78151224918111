import { useEffect } from "react";
import { get, set } from "idb-keyval";
import { atom, useAtom } from "jotai";

const STORAGE_KEY = "currentStep";

const currentStepAtom = atom<number>(-1);

export function usePWAOnboardingSteps(length: number, close: () => void) {
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);

  useEffect(() => {
    get(STORAGE_KEY).then((storedStep) => {
      if (typeof storedStep === "number") {
        setCurrentStep(storedStep);
      }
    });
  }, [setCurrentStep]);

  const updateStep = (step: number) => {
    setCurrentStep(step);
    set(STORAGE_KEY, step);
  };

  const handleCountReset = () => updateStep(-1);
  const handleNext = () => {
    if (currentStep < length - 1) {
      updateStep(currentStep + 1);
    }
  };
  const handlePrevious = () => {
    if (currentStep > 0) {
      updateStep(currentStep - 1);
    }
  };
  const handleClose = () => {
    close();
    updateStep(length > 0 ? 0 : -1);
  };
  const isLastStep = (length: number) => currentStep === length - 1;

  return {
    currentStep,
    handleCountReset,
    handleNext,
    handlePrevious,
    handleClose,
    isLastStep,
  };
}
