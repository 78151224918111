import * as client from "./client";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import {
  ApplicationCountsForFilter,
  RecruitmentApplicationListOrder,
  RecruitmentApplicationsByScheduleIdResponseSchema,
  RecruitmentApplicationWithSingleEventStatus,
  RecruitmentApplicationWithSingleEventStatusForQR,
} from "@shared/types/recruitmentApplication";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import {
  applicationAddingScheduleBodySchema,
  ApplicationAddingScheduleBodySchema,
  applicationCountsForFilterSchema,
  ApplicationCountsForFilterType,
  applicationListFilterSchema,
  ApplicationListFilterSchema,
  downloadApplicationListSchema,
  DownloadApplicationListSchemaType,
  GetApplicationListByUserIdSchema,
  getApplicationListByUserIdSchema,
  GetMyApplicationByRecruitmentIdSchema,
  getMyApplicationByRecruitmentIdSchema,
  GetRecruitmentApplicationsByScheduleIdQuerySchema,
  RecruitApplicationByEventIdForQRQuerySchema,
  recruitApplicationByEventIdQuerySchema,
  RecruitApplicationByEventIdQuerySchema,
} from "@shared/validator/features/recruitment-application.schema";
import { ApplicationStatusWithAll } from "@shared/validator/features/recruitment.schema";

export const applicationStatusOptions = [
  { value: "APPLIED", text: "選考中" },
  { value: "APPROVED", text: "参加決定" },
  { value: "REJECTED", text: "見送り" },
  { value: "DECLINED", text: "不参加" },
] as const;

export async function getMyApplicationByRecruitmentId(
  recruitmentId: string
): Promise<RecruitmentApplication> {
  const args = getMyApplicationByRecruitmentIdSchema.parse({
    recruitmentId,
  });
  return await client.get<
    GetMyApplicationByRecruitmentIdSchema,
    RecruitmentApplication
  >("/recruitment-application/getByRecruitmentId", args);
}

export async function getRecruitApplicationByEventId(
  data: RecruitApplicationByEventIdQuerySchema
): Promise<RecruitmentApplicationWithSingleEventStatus> {
  recruitApplicationByEventIdQuerySchema.parse(data);
  return client.get<
    RecruitApplicationByEventIdQuerySchema,
    RecruitmentApplicationWithSingleEventStatus
  >("/recruitment-application/getByEventId", data);
}

export async function getRecruitApplicationsByEventIdForQR(
  data: RecruitApplicationByEventIdForQRQuerySchema
): Promise<RecruitmentApplicationWithSingleEventStatusForQR> {
  return client.get<
    RecruitApplicationByEventIdForQRQuerySchema,
    RecruitmentApplicationWithSingleEventStatusForQR
  >("/recruitment-application/getByEventIdForQR", data);
}
export async function getRecruitApplicationsByScheduleId(
  data: Omit<
    GetRecruitmentApplicationsByScheduleIdQuerySchema,
    "currentCommunityId"
  >
): Promise<RecruitmentApplicationsByScheduleIdResponseSchema> {
  return client.get<
    Omit<
      GetRecruitmentApplicationsByScheduleIdQuerySchema,
      "currentCommunityId"
    >,
    RecruitmentApplicationsByScheduleIdResponseSchema
  >("/recruitment-application/getByScheduleId", data);
}
export async function getApplicationListByUserId(
  userId: string
): Promise<AppliedRecruitment[]> {
  const args = getApplicationListByUserIdSchema.parse({ userId });
  return await client.get<
    GetApplicationListByUserIdSchema,
    AppliedRecruitment[]
  >("/recruitment-application/getListByUserId", args);
}

export async function getApplicationListByFilter(
  status: ApplicationStatusWithAll,
  name: string,
  recruitmentId: string | undefined,
  recruitmentScheduleId: string | undefined,
  order?: RecruitmentApplicationListOrder
): Promise<RecruitmentApplication[]> {
  const args = applicationListFilterSchema.parse({
    status,
    name,
    recruitmentId,
    recruitmentScheduleId,
    order,
  });
  return await client.get<
    ApplicationListFilterSchema,
    RecruitmentApplication[]
  >("/recruitment-application/getListByFilter", args);
}

/**
 * Filter用のデータ取得。UserTypeごとのCountとStatusごとのCountを取得
 */
export async function getApplicationCountsForFilter({
  name,
  status,
  recruitmentId,
  recruitmentScheduleId,
}: ApplicationCountsForFilterType): Promise<ApplicationCountsForFilter> {
  const args = applicationCountsForFilterSchema.parse({
    name,
    status,
    recruitmentId,
    recruitmentScheduleId,
  });
  return await client.get<
    ApplicationCountsForFilterType,
    ApplicationCountsForFilter
  >("/recruitment-application/getApplicationCountsForFilter", args);
}

export async function downloadApplicationListPdfByFilter(
  data: DownloadApplicationListSchemaType
): Promise<Blob> {
  const args = downloadApplicationListSchema.parse(data);
  return await client.getBlob<DownloadApplicationListSchemaType, Blob>(
    "/recruitment-application/downloadListPdfByFilter",
    args
  );
}

export type CountApplicationResponse = {
  count: number;
};
export async function countApplication(): Promise<CountApplicationResponse> {
  return await client.get<{}, CountApplicationResponse>(
    "/recruitment-application/count",
    {}
  );
}

export async function updateAddingScheduleToExistApplication(
  body: ApplicationAddingScheduleBodySchema
): Promise<void> {
  const args = applicationAddingScheduleBodySchema.parse(body);
  await client.post<ApplicationAddingScheduleBodySchema, { result: string }>(
    "/recruitment-application/update-adding-schedule",
    args
  );
}
