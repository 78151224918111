type BaseParams = {
  frontUrl: string;
  recruitmentId: string;
  recruitmentScheduleId: string;
  recruitApplicationEventId: string;
};

type BEParams = BaseParams & {
  isBE: true;
};

type FEParams = BaseParams & {
  isBE: false;
  communityId: string;
};

type RecruitmentAttendanceUrlParams = BEParams | FEParams;

export const generateRecruitmentAttendanceUrl = (
  params: RecruitmentAttendanceUrlParams
) => {
  return params.isBE
    ? `${params.frontUrl}/resident/recruitment/application_scan_result?recruitmentId=${params.recruitmentId}&recruitmentScheduleId=${params.recruitmentScheduleId}&recruitmentApplicationEventId=${params.recruitApplicationEventId}`
    : `${params.frontUrl}/resident/recruitment/application_scan_result?recruitmentId=${params.recruitmentId}&recruitmentScheduleId=${params.recruitmentScheduleId}&recruitmentApplicationEventId=${params.recruitApplicationEventId}&communityId=${params.communityId}`;
};
