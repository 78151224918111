import React, { useCallback, useEffect, useState } from "react";
import { Title } from "../../../components/Title";
import { updateRecruitment } from "../../../apiClients/recruitment";
import { upload } from "../../../apiClients/storage";
import ResidentRecruitmentForm from "../../../features/Recruitment/recruitment/RecruitmentForm";
import RecruitmentPreview from "../../../features/Recruitment/recruitment/RecruitmentFormPreview";
import {
  convertRecruitmentCommunityUserTypes,
  RecruitmentFormValue,
  useRecruitmentForm,
} from "../../../features/Recruitment/recruitment/useRecruitmentForm";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import {
  useInvalidateRecruitmentDetail,
  useRecruitmentDetail,
} from "src/hooks/query/recruitments/recruitmentDetail";
import {
  toRecruitmentDateFormat,
  toRecruitmentTimeFormat,
} from "../../../utils/time";
import { useGeoCording } from "../../../hooks/useGeocoding";
import { transformIsAutoApprovalToEntryMethod } from "src/validator/form/recruitment/recruitmentSchema";
import { RecruitmentCommunityUserTypes } from "@shared/validator/features/recruitment.schema";

export default function ResidentRecruitmentEditOpenedPage() {
  const { communityId } = useCurrentCommunityId();
  const { id: recruitmentId } = usePolyfitLocationQuery(
    "RESIDENT_RECRUITMENT_EDIT_OPENED",
    { id: "" }
  );
  const { recruitment, isLoading, isError, error } =
    useRecruitmentDetail(recruitmentId);
  const { invalidateRecruitmentDetail } = useInvalidateRecruitmentDetail();
  const [isPostLoading, setIsPostLoading] = useState(false);

  const [isPreview, setIsPreview] = useState(false);
  const history = usePolyfitHistory();

  const [prvPictureUploadPath, setPrvPictureUploadPath] = useState<
    string | null
  >(null);

  const { getLocationAddressFromPostcode } = useGeoCording();

  const {
    control,
    register,
    setValue,
    trigger,
    handleSubmit,
    errors,
    isSubmitted,
    watch,
    setError,
    clearErrors,
    getValues,
  } = useRecruitmentForm({
    createdAt: recruitment?.createdAt,
  });

  useEffect(() => {
    if (recruitment) {
      if (recruitment.status !== "OPENED") {
        history.push({ to: "RESIDENT_INDEX" });
        return;
      }

      setValue("title", recruitment.title);
      if (recruitment.isPaidVolunteer !== null) {
        setValue("isPaidVolunteer", recruitment.isPaidVolunteer);
      }
      if (recruitment.volunteerType) {
        setValue("volunteerType", recruitment.volunteerType);
      }
      if (recruitment.volunteerCount === -1) {
        setValue("isUnlimitedVolunteerCount", true);
        setValue("volunteerCount", undefined);
      } else {
        setValue("isUnlimitedVolunteerCount", false);
        setValue("volunteerCount", recruitment.volunteerCount);
      }
      setValue("volunteerDetailType", recruitment.volunteerDetailType);
      setValue("description", recruitment.description);
      setValue("postalCode", recruitment.postalCode ?? "");
      setValue("prefecture", recruitment.prefecture ?? "");
      setValue("city", recruitment.city ?? "");
      setValue("address1", recruitment.address1 ?? "");
      setValue("address2", recruitment.address2);
      setValue("treatment", recruitment.treatment);
      setValue("teacherLicenses", recruitment.teacherLicenses);
      setValue("medicalLicenses", recruitment.medicalLicenses);
      setValue("skills", recruitment.skills);
      setValue("wageType", recruitment.wageType);
      setValue("wageAmount", recruitment.wageAmount);
      setValue("latitude", recruitment.latitude);
      setValue("longitude", recruitment.longitude);
      setValue("alreadyUploadedPdfFiles", recruitment.recruitmentPDFs ?? []);
      setValue("pdfFiles", []);
      setValue("isAutoApproval", recruitment.entryMethod === "AUTO_APPROVAL");

      if (recruitment.targetCommunities.length === 0) {
        setValue("recruitmentCommunityUserTypes", {});
      } else {
        recruitment.targetCommunities.forEach((targetCommunity, index) => {
          const isSchoolSelected = RecruitmentCommunityUserTypes.every((type) =>
            targetCommunity.communityUserTypes.includes(type)
          );
          setValue(
            `recruitmentCommunityUserTypes.${targetCommunity.communityId}`,
            {
              parent:
                isSchoolSelected ||
                targetCommunity.communityUserTypes.includes("PARENT"),
              teacher:
                isSchoolSelected ||
                targetCommunity.communityUserTypes.includes("TEACHER"),
              insideResident:
                isSchoolSelected ||
                targetCommunity.communityUserTypes.includes("INSIDE_RESIDENT"),
              outsideResident:
                isSchoolSelected ||
                targetCommunity.communityUserTypes.includes("OUTSIDE_RESIDENT"),
            }
          );
        });
      }

      if (recruitment.picture && recruitment.pictureUploadPath) {
        setValue("uploadImage", recruitment.picture);
        setPrvPictureUploadPath(recruitment.pictureUploadPath);
      }
      recruitment.schedule.forEach((s, i) => {
        if (s.id !== null && s.id !== undefined) {
          setValue(`schedule.${i}.id`, s.id);
        }
        if (s.date !== null) {
          setValue(
            `schedule.${i}.date`,
            toRecruitmentDateFormat(new Date(s.date))
          );
        }
        if (s.start !== null) {
          setValue(
            `schedule.${i}.startTime`,
            toRecruitmentTimeFormat(new Date(s.start))
          );
        }
        if (s.end !== null) {
          setValue(
            `schedule.${i}.endTime`,
            toRecruitmentTimeFormat(new Date(s.end))
          );
        }
      });
      if (recruitment.deadline) {
        setValue("deadline", recruitment.deadline.toString());
      }
      setValue("saveTemplate", recruitment.saveTemplate);
    }
  }, [recruitment]);

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getLocationAddressFromPostcode(value);
      setValue("prefecture", res.prefecture);
      setValue("city", res.city);
      setValue("address1", res.address1);
      setValue("address2", "");
      setValue("latitude", res.latitude);
      setValue("longitude", res.longitude);
      clearErrors("postalCode");
    } catch (err) {
      setError("postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  const watchAllValue = watch();

  const onPreviewClick = useCallback(() => {
    setIsPreview(true);
  }, []);

  const onPreviewSubmitClick = useCallback(
    async (formValues: RecruitmentFormValue) => {
      if (!communityId) return;
      setIsPostLoading(true);
      const files = formValues.pdfFiles ?? [];
      const pictureUploadPath =
        typeof formValues.uploadImage === "string"
          ? prvPictureUploadPath
          : formValues?.uploadImage?.length === 1 &&
            formValues.uploadImage[0] instanceof File
          ? await upload({ file: formValues.uploadImage[0] })
          : undefined;

      const schedule = formValues.schedule.map((s) => ({
        id: s.id,
        date: new Date(s.date),
        start: new Date(s.date + "T" + s.startTime),
        end: new Date(s.date + "T" + s.endTime),
      }));

      await updateRecruitment(
        {
          id: recruitmentId,
          communityId: communityId,
          status: "OPENED",
          title: formValues.title,
          volunteerDetailType: formValues.volunteerDetailType,
          isPaidVolunteer: formValues.isPaidVolunteer,
          volunteerType: formValues.volunteerType,
          schedule,
          deadline: formValues.deadline,
          description: formValues.description,
          postalCode: formValues.postalCode,
          prefecture: formValues.prefecture,
          city: formValues.city,
          address1: formValues.address1,
          address2: formValues.address2,
          latitude: formValues.latitude,
          longitude: formValues.longitude,
          wageType: formValues.wageType,
          wageAmount: formValues.wageAmount,
          treatment: formValues.treatment,
          teacherLicenses: formValues.teacherLicenses,
          medicalLicenses: formValues.medicalLicenses,
          skills: formValues.skills,
          alreadyUploadedPdfFiles: formValues.alreadyUploadedPdfFiles,
          targetCommunities: convertRecruitmentCommunityUserTypes(
            formValues.recruitmentCommunityUserTypes
          ),
          saveTemplate: formValues.saveTemplate,
          pictureUploadPath: pictureUploadPath ?? null,
          volunteerCount: formValues.isUnlimitedVolunteerCount
            ? -1
            : formValues.volunteerCount ?? 0,
          entryMethod: transformIsAutoApprovalToEntryMethod(
            formValues.isAutoApproval
          ),
        },
        files
      );
      invalidateRecruitmentDetail(recruitmentId);

      setIsPostLoading(false);
      history.push({ to: "RESIDENT_RECRUITMENT_LIST" });
    },
    [prvPictureUploadPath, communityId]
  );

  const onPreviewCancelClick = useCallback(() => {
    setIsPreview(false);
  }, []);

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (isError || !recruitment) {
    return <span>{error?.message || "募集情報が見つかりません"}</span>;
  }

  return (
    <>
      <Title>{isPreview ? "プレビュー" : "編集"}</Title>
      {!isPreview && (
        <ResidentRecruitmentForm
          control={control}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          trigger={trigger}
          isSubmitted={isSubmitted}
          watchAllValue={watchAllValue}
          onPreviewClick={handleSubmit(onPreviewClick)}
          onDraftSubmit={undefined}
          getAddress={getAddress}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
      {isPreview && (
        <RecruitmentPreview
          watchAllValue={watchAllValue}
          onSubmit={handleSubmit(onPreviewSubmitClick)}
          onCancel={onPreviewCancelClick}
          isLoading={isPostLoading}
          recruitmentPreview={recruitment}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
    </>
  );
}
