import * as client from "../client";
import {
  ChatRoom,
  ChatRoomFilterType,
  ChatRoomMember,
  CreateChatRoomRequest,
  CreateGroupChatRoomRequest,
  GetChatRoomRequest,
  UpdateAdminOnlyChatRoomRequest,
  UpdateChatRoomRequest,
  UpdateGroupChatRoomRequest,
} from "@shared/types/chatRoom";
import { ChatRoomEntity } from "@shared/types/boardEducation/chatRoom";
import {
  GetChatRoomMembersType,
  ResendGroupChatInvitationType,
} from "@shared/validator/features/chatRoom.schema";

export async function fetchChatRooms(filter: ChatRoomFilterType) {
  const filterQuery = JSON.stringify(filter);
  const chatRooms = await client.get<{ filter: string }, ChatRoomEntity[]>(
    "/boardEducation/chatRoom/fetchChatRooms",
    { filter: filterQuery }
  );
  return chatRooms;
}

export async function fetchChatRoomById(chatRoomId: string) {
  const chatRoom = await client.get<
    Omit<GetChatRoomRequest, "currentCommunityId">,
    ChatRoomEntity
  >("/boardEducation/chatRoom/fetchChatRoomById", {
    chatRoomId,
  });
  return chatRoom;
}

export async function getOrCreateChatRoom(
  accountId: string
): Promise<ChatRoom> {
  const chatRoom = await client.post<CreateChatRoomRequest, ChatRoom>(
    "/boardEducation/chatRoom/getOrCreateIndividualChatRoom",
    {
      accountId,
    }
  );
  return chatRoom;
}

export async function createGroupChatRoom(
  name: string,
  accountIds: string[],
  file?: File | undefined
): Promise<ChatRoom> {
  const chatRoom = await client.postWithFormData<
    CreateGroupChatRoomRequest & { file?: File },
    ChatRoom
  >("/boardEducation/chatRoom/createGroupChatRoom", {
    accountIds,
    name,
    file,
  });

  return chatRoom;
}

export async function updateGroupChatRoom(
  chatRoomId: string,
  name: string,
  accountIds: string[],
  file?: File | undefined
): Promise<void> {
  await client.postWithFormData<
    UpdateGroupChatRoomRequest & { file?: File },
    ChatRoom
  >("/boardEducation/chatRoom/updateGroupChatRoom", {
    chatRoomId,
    accountIds,
    name,
    file,
  });
}

export async function deleteChatRoom(chatRoomId: string) {
  await client.post<UpdateChatRoomRequest, {}>(
    "/boardEducation/chatRoom/deleteChatRoom",
    {
      chatRoomId,
    }
  );
}

export async function updateAdminOnly(chatRoomId: string, adminOnly: boolean) {
  await client.post<UpdateAdminOnlyChatRoomRequest, {}>(
    "/boardEducation/chatRoom/updateAdminOnly",
    {
      chatRoomId,
      adminOnly,
    }
  );
}

export async function getChatRoomMembers(
  args: GetChatRoomMembersType
): Promise<ChatRoomMember[]> {
  return client.get<GetChatRoomMembersType, ChatRoomMember[]>(
    `/boardEducation/chatRoom/members`,
    args
  );
}

export async function resendGroupChatInvitation(
  args: ResendGroupChatInvitationType
): Promise<void> {
  await client.post<ResendGroupChatInvitationType, void>(
    "/boardEducation/chatRoom/resendGroupChatInvitation",
    args
  );
}
