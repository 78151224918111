import React, { useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createOrUpdateCommunityIcon } from "src/apiClients/communityIcon";
import { useGetCommunityIcon } from "src/hooks/query/communityIcon";
import { Spinner } from "src/components/icons/Spinner";
import { useCurrentCommunityId } from "src/hooks/router";
import { useToast } from "src/components/Toast";
import { PageBody, PageContainer } from "src/components/Page";
import { SubTitle, Title } from "src/components/Title";
import styled from "styled-components";
import { BREAKPOINTS } from "src/components/Responsive";
import { Button } from "src/components/Button";
import { CameraIcon } from "src/components/icons/CameraIcon";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { z } from "zod";
import { useOrganization } from "src/hooks/recoil/organization";

const communityIconSchema = z.object({
  icon: z
    .instanceof(FileList)
    .transform((list) => list.item(0))
    .refine((file) => file !== null, "アイコン画像を選択してください")
    .refine(
      (file) => file && file.size / 1024 / 1024 <= 5,
      "ファイルサイズは5MB以下にしてください"
    )
    .refine(
      (file) => file && ["image/jpeg", "image/png"].includes(file.type),
      "JPEG または PNG 形式のファイルを選択してください"
    ),
});

type CommunityIconSchemaType = z.infer<typeof communityIconSchema>;

export default function ResidentCommunityIconPage() {
  const { communityId } = useCurrentCommunityId();
  const { organization } = useOrganization({});
  const toast = useToast();
  const {
    communityIcon,
    isLoading: isLoadingCommunityIcon,
    refetch,
  } = useGetCommunityIcon(communityId, organization?.id ?? "");
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CommunityIconSchemaType>({
    resolver: zodResolver(communityIconSchema),
    defaultValues: {
      icon: undefined,
    },
    mode: "onChange",
  });

  const defaultIconUrl = useMemo(
    () => getStaticImageUrl("/defaultCommunityIcon.png"),
    []
  );

  const [uploadedIconUrl, setUploadedIconUrl] = useState<string | undefined>(
    undefined
  );

  const onSubmit = async (data: CommunityIconSchemaType) => {
    try {
      if (!data.icon) {
        toast.error("アイコン画像を選択してください");
        return;
      }
      setDoubleClickBlocked(true);
      await createOrUpdateCommunityIcon({ icon: data.icon });
      toast.success("アイコンのアップロードに成功しました");
    } catch (error) {
      toast.error("アイコンのアップロードに失敗しました");
    } finally {
      refetch();
      setDoubleClickBlocked(false);
    }
  };

  return (
    <PageContainer>
      <Title>アイコン設定</Title>
      <SubTitle>コミュニティのアイコンを設定できます</SubTitle>
      <PageBody>
        <IconSettingContainer>
          {isLoadingCommunityIcon ? (
            <Spinner />
          ) : (
            <>
              <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="icon"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <IconSection>
                      <IconContainer>
                        {communityIcon || uploadedIconUrl ? (
                          <>
                            <Img
                              src={uploadedIconUrl ?? communityIcon?.iconUrl}
                            />
                          </>
                        ) : (
                          <>
                            <Img src={defaultIconUrl} />
                          </>
                        )}
                        <UploadImgButton
                          as="label"
                          htmlFor="icon-input"
                          role="button"
                        >
                          <CameraIcon size={40} />
                        </UploadImgButton>
                        <input
                          style={{ display: "none" }}
                          id="icon-input"
                          type="file"
                          onChange={(e) => {
                            onChange(e.target.files);
                            const file = e.target.files?.[0];
                            setUploadedIconUrl(
                              file ? URL.createObjectURL(file) : undefined
                            );
                          }}
                          accept="image/jpeg,image/png"
                        />
                      </IconContainer>
                    </IconSection>
                  )}
                />
                {errors.icon && (
                  <ErrorMessage>{errors.icon.message}</ErrorMessage>
                )}
                <Button
                  type="submit"
                  $fill
                  disabled={!isValid || doubleClickBlocked}
                >
                  変更する
                </Button>
              </form>
            </>
          )}
        </IconSettingContainer>
      </PageBody>
    </PageContainer>
  );
}

const Img = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 200px;
  object-fit: cover;
`;

const IconSettingContainer = styled.div`
  display: flex;
  padding: 0 40px;

  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
  }
  align-items: center;
`;

const UploadImgButton = styled.label`
  display: inline-block;
  background-color: gray;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

const IconSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ErrorMessage = styled.p`
  color: red;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 14px;
`;
const IconContainer = styled.div`
  position: relative;
  width: 200px;
`;
