import * as client from "./client";
import { Account, BaseInfo } from "./auth";
import { Partner } from "./partner";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
} from "../utils/types/license";
import { VolunteerType } from "../@shared/types/volunteerType";
import {
  GradeNumberSchemaType,
  ClassNumberSchemaType,
} from "@shared/validator/rules/child";
import { UserType } from "@shared/types/userType";
import { Role } from "../utils/types/role";
import {
  getAdminUsersByOrganizationIdSchema,
  GetAdminUsersByOrganizationIdSchemaType,
  getMemberUserSchema,
  GetMemberUserSchemaType,
  ResidentFilterType,
  residentFilterTypeSchema,
  ResidentUserSchemaType,
  updateApproversSchema,
  UpdateApproversSchemaType,
  updateUserByInternalAdminSchema,
} from "@shared/validator/features/user.schema";
import { InternalUserRole } from "@shared/types/internalUserRole";
import {
  disabledTeacherUsersSchema,
  DisabledTeacherUsersSchemaType,
  teacherFilterTypeSchema,
  TeacherFilterType,
  UpdateUserByInternalAdminSchemaType,
  usersByCommunityIdSchema,
  UsersByCommunityIdSchemaType,
  usersByOrganizationIdSchema,
  UsersByOrganizationIdSchemaType,
  TeacherUsersSchemaType,
  teacherUsersSchema,
  residentUserSchema,
} from "@shared/validator/features/user.schema";
import { Approver } from "@shared/types/user";
export type User = {
  id: string;
  email: string;
  name: string;
  role: Role;
  isApprover: boolean;
  able: boolean;
  signUpState: boolean;
  internalRole: InternalUserRole;
  picture: string;
  type: UserType;
  firebaseUid: string;
  walkMinute?: number;
  baseInfo?: BaseInfo;
  account?: Account;
  partner?: Partner;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};

export function inPTA(user: User) {
  return user.role.startsWith("PTA");
}

export function inCommunitySchool(user: User) {
  return user.role.startsWith("CS");
}

export function inBoardEducation(user: User) {
  return user.role.startsWith("BE");
}

export function isTeacherUser(
  user: User | TeacherUser | ResidentUser
): user is TeacherUser {
  return user.type === "TEACHER";
}

export function isResidentUser(
  user: User | TeacherUser | ResidentUser
): user is ResidentUser {
  return user.type === "RESIDENT";
}

export type TeacherUser = User & {
  type: "TEACHER";
  teacherInfo: {
    hasHomeroom: boolean;
    grade: GradeNumberSchemaType;
    class: ClassNumberSchemaType;
    accountId?: string;
  };
};

export type ResidentUser = User & {
  type: "RESIDENT";
  residentInfo: {
    preferredDays: (
      | "MONDAY"
      | "TUESDAY"
      | "WEDNESDAY"
      | "THURSDAY"
      | "FRIDAY"
      | "SATURDAY"
      | "SUNDAY"
    )[];
    teacherLicenses: TeachingLicenseType[];
    medicalLicenses: MedicalLicenseType[];
    skills: SkillType[];
    preferredPaid: ("PAID" | "FREE")[];
    preferredVolunteerType: VolunteerType[];
    privateEnterprise: string | null;
    university: string | null;
    externalOrganization: string | null;
    pta: string | null;
  };
};

/**
 * PTAの全ユーザーを取得する
 */
export async function getPTAUsers(): Promise<User[]> {
  const res = await client.get<{}, { users: User[] }>("/users/ptaUsers", {});
  return res.users;
}

/**
 * PTA/CSの両ユーザーを取得する（Internal用）
 */
export async function getUsersByCommunityId({
  communityId,
  serviceType,
}: UsersByCommunityIdSchemaType): Promise<User[]> {
  usersByCommunityIdSchema.parse({ communityId, serviceType });
  const res = await client.get<UsersByCommunityIdSchemaType, { users: User[] }>(
    "/users/usersByCommunityId",
    {
      communityId,
      serviceType,
    }
  );
  return res.users;
}

/**
 * PTA/CSの両ユーザーを取得する（Internal用）
 */
export async function getUsersByOrganizationId({
  organizationId,
}: UsersByOrganizationIdSchemaType): Promise<User[]> {
  usersByOrganizationIdSchema.parse({ organizationId });
  const res = await client.get<
    UsersByOrganizationIdSchemaType,
    { users: User[] }
  >("/users/usersByOrganizationId", {
    organizationId,
  });
  return res.users;
}

/**
 * 未承認メンバー取得
 */
export async function getDisabledUsersByOrganizationId(): Promise<User[]> {
  const res = await client.get<{}, { users: User[] }>(
    "/users/disabledUsersByOrganizationId",
    {}
  );
  return res.users;
}

export async function getDisabledTeacherUser(
  q: DisabledTeacherUsersSchemaType["q"],
  filter?: TeacherFilterType
): Promise<TeacherUser[]> {
  //このAPIは現状filterが利用されることはない & filterを渡しても使用されていないが、一応バリデーションをかけておく
  teacherFilterTypeSchema.optional().parse(filter);
  const filterQuery = JSON.stringify(filter);
  disabledTeacherUsersSchema.parse({ q, filterQuery });
  const res = await client.get<
    DisabledTeacherUsersSchemaType,
    { users: TeacherUser[] }
  >("/users/disabledTeacherUsers", { q, filterQuery });
  return res.users;
}

/**
 * 教員メンバー一覧・検索
 */
export async function getTeacherUsers(
  q?: TeacherUsersSchemaType["q"],
  filter?: TeacherFilterType
): Promise<TeacherUser[]> {
  teacherFilterTypeSchema.optional().parse(filter);
  const filterQuery = JSON.stringify(filter);
  teacherUsersSchema.optional().parse({ q, filterQuery });
  const res = await client.get<{}, { users: TeacherUser[] }>(
    "/users/teacherUsers",
    { q, filterQuery }
  );
  return res.users;
}

/**
 * 地域住民一覧・検索
 */
export async function getResidentUsers(
  filter?: Partial<ResidentFilterType>
): Promise<ResidentUser[]> {
  residentFilterTypeSchema.partial().parse(filter);
  const filterQuery = JSON.stringify(filter);
  residentUserSchema.optional().parse({ filterQuery });
  const res = await client.get<
    ResidentUserSchemaType,
    { users: ResidentUser[] }
  >("/users/residentUsers", { filterQuery });
  return res.users;
}

export async function updateUserByInternalAdmin(
  args: UpdateUserByInternalAdminSchemaType
): Promise<User> {
  updateUserByInternalAdminSchema.parse(args);
  const res = await client.post<
    UpdateUserByInternalAdminSchemaType,
    { user: User }
  >("/users/updateUserByInternalAdmin", args);
  return res.user;
}

export async function updateApprovers(
  approverIds: UpdateApproversSchemaType["approverIds"]
): Promise<User> {
  updateApproversSchema.parse({ approverIds });
  const res = await client.post<UpdateApproversSchemaType, { user: User }>(
    "/users/updateApprovers",
    { approverIds }
  );
  return res.user;
}

/**
 * ID指定でユーザー取得
 */
export async function getMemberUser(
  args: GetMemberUserSchemaType
): Promise<User> {
  getMemberUserSchema.parse(args);
  const res = await client.get<GetMemberUserSchemaType, { user: User }>(
    "/users/getMemberUser",
    args
  );
  return res.user;
}

/**
 * organizationId指定で同じcommunityに所属するAdminユーザーを取得
 */
export async function getAdminUserByOrganizationId(
  args: GetAdminUsersByOrganizationIdSchemaType
): Promise<{ users: User[] }> {
  getAdminUsersByOrganizationIdSchema.parse(args);
  const res = await client.get<
    GetAdminUsersByOrganizationIdSchemaType,
    { users: User[] }
  >("/users/getAdminUsersByOrganizationId", args);
  return res;
}

/**
 * 同じorganizationに所属するAdminユーザーを取得
 */
export async function getAdminUsersBelongingToOrganization(): Promise<{
  users: User[];
}> {
  const res = await client.get<{}, { users: User[] }>(
    "/users/getAdminUsersBelongingToOrganization",
    {}
  );
  return res;
}

export async function getApprovers(): Promise<Approver[]> {
  const res = await client.get<{}, { approvers: Approver[] }>(
    "/users/getApprovers",
    {}
  );
  return res.approvers;
}
