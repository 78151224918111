import React, { useCallback, useMemo, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "src/components/Modal";
import styled from "styled-components";
import { Margin } from "src/components/Margin";
import {
  InvoiceStatusLabel,
  PTAParentFamilyInvoiceListType,
} from "@shared/types/ptaParentFamilyInvoice";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { getClassName } from "src/utils/getChildClass";
import { LoadingCard } from "src/components/LoadingCard";
import { zIndexes } from "src/zIndex";
import { sortChildInvoiceItemsByGrade } from "../utils/sortChildInvoiceItemsByGrade";
import { colorsPallet } from "src/theme";

interface CancelInvoiceModalProps {
  invoice: PTAParentFamilyInvoiceListType;
  onSubmit: (cancelComment: string) => Promise<void>;
  onClose: () => void;
  disabled: boolean;
}

export const CancelInvoiceModal = ({
  invoice,
  onSubmit,
  onClose,
  disabled,
}: CancelInvoiceModalProps) => {
  const { organization } = useGetCurrentOrganization();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cancelComment, setCancelComment] = useState("");
  const [errorCancelComment, setErrorCancelComment] = useState(false);

  const maxLength = 3000;

  const handleCancelReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= maxLength) {
        setCancelComment(e.target.value);
        setErrorCancelComment(false);
      } else {
        setErrorCancelComment(true);
      }
    },
    []
  );

  const childInvoiceItemsSortedByGrade = useMemo(
    () => sortChildInvoiceItemsByGrade(invoice),
    [invoice]
  );

  const handleSubmit = useCallback(async () => {
    if (errorCancelComment) {
      return;
    }
    setIsSubmitting(true);
    try {
      await onSubmit(cancelComment);
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit, errorCancelComment, cancelComment]);

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>請求キャンセル</ModalHeader>

      <ModalBody>
        <ModalContent>
          <Description>
            この操作を実行すると、以下の保護者・子どもに紐づく請求がキャンセルされます。
          </Description>

          <InfoSection>
            <Label>保護者名</Label>
            <InfoText>
              {invoice.PTAParentFamily.mainAccount
                ? `${
                    invoice.PTAParentFamily.mainAccount?.user.baseInfo
                      ?.lastName || ""
                  } ${
                    invoice.PTAParentFamily.mainAccount?.user.baseInfo
                      ?.firstName || ""
                  }`
                : "[削除済み]"}
            </InfoText>
          </InfoSection>

          <InfoSection>
            <Label>合計請求金額</Label>
            <InfoText>{`${invoice.unitAmount.toLocaleString()}円`}</InfoText>
          </InfoSection>

          <InfoSection>
            <Label>ステータス</Label>
            <InfoText>{InvoiceStatusLabel[invoice.status]}</InfoText>
          </InfoSection>

          <ChildTable>
            <thead>
              <tr>
                <ChildTh>子ども名</ChildTh>
                <ChildTh>学年</ChildTh>
                <ChildTh>クラス</ChildTh>
                <ChildTh>請求金額</ChildTh>
              </tr>
            </thead>
            <tbody>
              {childInvoiceItemsSortedByGrade.map((item) => (
                <tr key={item.id}>
                  <ChildTd>
                    {item.child ? (
                      <>
                        {item.child.childLastName} {item.child.childFirstName}
                      </>
                    ) : (
                      "[削除済み]"
                    )}
                  </ChildTd>
                  <ChildTd>
                    {item.child?.grade
                      ? getGradeLabel(
                          item.child.grade,
                          organization?.schoolDisplayType,
                          true,
                          false,
                          "short"
                        )
                      : "-"}
                  </ChildTd>
                  <ChildTd>
                    {item.child?.class ? getClassName(item.child.class) : "-"}
                  </ChildTd>
                  <ChildTd>{`${item.amount.toLocaleString()}円`}</ChildTd>
                </tr>
              ))}
            </tbody>
          </ChildTable>

          <InfoSection>
            <Label>コメント</Label>
            <CancelReasonTextArea
              value={cancelComment}
              onChange={handleCancelReasonChange}
              placeholder={`コメントを入力してください（3000文字以内）。 \nコメントは保護者に通知されます。`}
            />
            {errorCancelComment && (
              <ErrorText>3000文字以内で入力してください</ErrorText>
            )}
          </InfoSection>

          <Margin marginTop={16} />
          <LoadingSection>
            {isSubmitting && (
              <LoadingCardWrapper>
                <LoadingCard />
              </LoadingCardWrapper>
            )}
          </LoadingSection>
          <ModalSubmitButtons
            submitColor="danger"
            submitText="請求をキャンセルする"
            cancelText="閉じる"
            onSubmit={handleSubmit}
            disabled={disabled || isSubmitting}
            onCancel={onClose}
          />
        </ModalContent>
      </ModalBody>
    </ModalPortal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #343741;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const InfoText = styled.div`
  color: #343741;
  font-size: 16px;
`;

const ChildTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
  border: 1px solid #e3e6eb;
  border-radius: 5px;
  overflow: hidden;
`;

const ChildTh = styled.th`
  background-color: #f9fafb;
  padding: 8px;
  font-size: 13px;
  text-align: left;
  border-bottom: 1px solid #e3e6eb;
`;

const ChildTd = styled.td`
  padding: 8px;
  font-size: 13px;
  border-bottom: 1px solid #e3e6eb;
`;

const LoadingCardWrapper = styled.div`
  position: absolute;
  z-index: ${zIndexes.modalOnModal};
  top: -16px;
  height: 0;
  & > div {
    padding: 0;
  }
`;

const LoadingSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  position: relative;
`;

const CancelReasonTextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
  min-height: 120px;
  max-width: 100%;
`;

const ErrorText = styled.div`
  color: ${colorsPallet.danger};
  font-size: 12px;
  margin-top: 4px;
`;
