import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useApiContext } from "src/apiClients/client";
import { useMemo } from "react";
import { getCommunityIcon } from "src/apiClients/communityIcon";

export function useGetCommunityIcon(
  communityId: string,
  organizationId: string,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();
  const query = useQuery({
    queryKey: ["api", "communityIcon", apiContext, communityId, organizationId],
    queryFn: async () => {
      const icon = await getCommunityIcon({ communityId, organizationId });
      return icon || null;
    },
    ...queryOptions,
  });
  return {
    communityIcon: useMemo(() => query.data || undefined, [query.data]),
    ...query,
  };
}
