import React from "react";
import styled from "styled-components";
import { Avatar } from "src/components/Common/Avatar";
import { DEFAULT_USER_IMAGE } from "src/utils/constants/images";
import { ChatRoomMember as ChatRoomMemberType } from "@shared/types/chatRoom";

type Props = {
  member: ChatRoomMemberType;
  isSelected: boolean;
  onMemberClick: (accountId: string) => void;
};

export const PendingMemberRow: React.FC<Props> = ({
  member,
  isSelected,
  onMemberClick,
}) => {
  const handleClick = () => {
    onMemberClick(member.user.accountId);
  };

  return (
    <NameListColumnNameRow onClick={handleClick}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => {}}
        onClick={(e) => e.stopPropagation()}
      />
      <Avatar
        src={member.user?.picture || DEFAULT_USER_IMAGE}
        alt={member.user?.name || "-"}
        size={40}
      />
      <MemberName>{member.user?.name || "-"}</MemberName>
    </NameListColumnNameRow>
  );
};

const NameListColumnNameRow = styled.div`
  width: 100%;
  padding: 10px 16px;
  display: flex;
  gap: 14px;
  align-items: center;
  border-top: 1px solid #e3e6eb;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
`;

const MemberName = styled.div`
  font-size: 14px;
  color: #343741;
  flex: 1;
`;
