import React from "react";
import { completeResidentRegistration } from "../../../apiClients/auth";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import styled from "styled-components";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import { Margin } from "../../../components/Margin";
import { useResidentBaseInfo } from "../../../hooks/api/baseInfo";
import { getAgeGroupText } from "../../../utils/types/ageGroup";
import { validateInvitationToken } from "../../../apiClients/invitation";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import {
  useFindAdditionalCommunities,
  useFindMainCommunity,
} from "src/features/Member/Community/utils/hooks";
import { getCommunityUserTypeText } from "src/features/Member/Community/utils/utils";
import { useGetCSChildren } from "src/hooks/query/csChild";

export default function ResidentRegisterPreviewPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { currentBaseInfo, isLoading: isBaseInfoLoading } =
    useResidentBaseInfo();
  const { csChildren } = useGetCSChildren(user.account?.id);
  const { token } = usePolyfitLocationQuery("LOADING", {
    token: undefined,
  });
  const communityRoles = user.account?.communityRoles ?? [];
  const {
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    isLoading,
  } = useOrganizationCommunity();

  const mainElementarySchool = useFindMainCommunity(
    elementarySchoolCommunities,
    communityRoles
  );
  const mainJuniorHighSchool = useFindMainCommunity(
    juniorHighSchoolCommunities,
    communityRoles
  );
  const elementarySchools = useFindAdditionalCommunities(
    elementarySchoolCommunities,
    communityRoles
  );
  const juniorHighSchools = useFindAdditionalCommunities(
    juniorHighSchoolCommunities,
    communityRoles
  );

  const onSubmit = async () => {
    try {
      await completeResidentRegistration();
      if (token) {
        const res = await validateInvitationToken({ token });
        if (res.valid && res.flow === "ORGANIZATION_CHAT") {
          history.push({
            to: "SIGNUP_COMPLETE_RESIDENT_START_ORGANIZATION_CHAT",
            query: { token },
          });
          return;
        }
      }
      history.push({ to: "SIGNUP_COMPLETE_RESIDENT" });
    } catch (err) {
      console.log(err);
    }
  };

  if (isBaseInfoLoading || isLoading) return <></>;

  return (
    <>
      <TitleWrapper>
        <Typo.Heading3>こちらの内容で登録しますか？</Typo.Heading3>
      </TitleWrapper>

      <StyledContainer>
        <PreviewCard>
          <ProfileLabel>名前</ProfileLabel>
          <ProfileContent>
            {currentBaseInfo.lastName} {currentBaseInfo.firstName}（
            {currentBaseInfo.lastNameKana} {currentBaseInfo.firstNameKana}）
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>年代</ProfileLabel>
          <ProfileContent>
            {getAgeGroupText(currentBaseInfo.ageGroup)}
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>性別</ProfileLabel>
          <ProfileContent>
            {currentBaseInfo.gender === "MALE" ? "男性" : "女性"}
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>住所</ProfileLabel>
          <ProfileContent>〒{currentBaseInfo.postalCode}</ProfileContent>
          <ProfileContent>
            {currentBaseInfo.prefecture}
            {currentBaseInfo.city}
            {currentBaseInfo.address1}
            {currentBaseInfo.address2}
          </ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>電話番号</ProfileLabel>
          <ProfileContent>{currentBaseInfo.phoneNumber}</ProfileContent>
          <Margin marginBottom={32} />
          <ProfileLabel>最寄りの小学校区</ProfileLabel>
          <ProfileContent>
            {mainElementarySchool?.community?.name ?? ""}
          </ProfileContent>
          <Margin marginBottom={20} />
          <ProfileLabel>最寄りの小学校区での役割</ProfileLabel>
          <ProfileContent>
            {getCommunityUserTypeText(
              mainElementarySchool?.communityRole?.type
            )}
          </ProfileContent>
          <Margin marginBottom={20} />
          <ProfileLabel>最寄りの中学校区</ProfileLabel>
          <ProfileContent>
            {mainJuniorHighSchool?.community.name ?? ""}
          </ProfileContent>
          <Margin marginBottom={20} />
          <ProfileLabel>最寄りの中学校区での役割</ProfileLabel>
          <ProfileContent>
            {getCommunityUserTypeText(mainJuniorHighSchool?.communityRole.type)}
          </ProfileContent>
          <Margin marginBottom={20} />

          {csChildren && csChildren.length ? (
            <>
              <ProfileLabel>お子さま情報</ProfileLabel>
              <ProfileContent>
                {csChildren.map((child) => (
                  <Responsive.Row key={child.id}>
                    <Responsive.Col>
                      名前: {child.childLastName} {child.childFirstName}
                    </Responsive.Col>
                    <Responsive.Col>
                      名前（かな）: {child.childLastNameKana}{" "}
                      {child.childFirstNameKana}
                    </Responsive.Col>
                    <Responsive.Col>学年: {child.grade}年</Responsive.Col>
                    <Responsive.Col>クラス: {child.class}組</Responsive.Col>
                  </Responsive.Row>
                ))}
              </ProfileContent>
            </>
          ) : null}

          <Margin marginBottom={20} />

          <ProfileLabel>追加小学校区</ProfileLabel>
          {elementarySchools.length > 0 &&
            elementarySchools.map((community) => (
              <ProfileContent key={community.id}>
                {community.name}
              </ProfileContent>
            ))}
          <Margin marginBottom={20} />
          <ProfileLabel>追加中学校区</ProfileLabel>
          {juniorHighSchools.length > 0 &&
            juniorHighSchools.map((community) => (
              <ProfileContent key={community.id}>
                {community.name}
              </ProfileContent>
            ))}
        </PreviewCard>
      </StyledContainer>

      <RegistrationFooter onClickNext={onSubmit} />
    </>
  );
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 24px;
`;

const StyledContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding-bottom: 88px;

  @media (max-width: 1024px) {
    max-width: auto;
  }
`;

const PreviewCard = styled.div`
  background: white;
  padding: 24px;
  margin-top: 16px;
`;

const ProfileLabel = styled.p`
  color: #343741;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: bold;
`;

const ProfileContent = styled.p`
  color: #343741;
  font-size: 18px;
`;
