import * as client from "./client";
import {
  ChatRoom,
  ChatRoomEntity,
  ChatRoomFilterType,
  ChatRoomListResponse,
  ChatRoomMember,
} from "@shared/types/chatRoom";
import {
  approveChatRoomSchema,
  ApproveChatRoomSchemaType,
  createGroupChatRoomSchema,
  CreateGroupChatRoomType,
  deleteChatRoomSchema,
  DeleteChatRoomSchemaType,
  fetchChatRoomByIdSchema,
  FetchChatRoomByIdSchemaType,
  fetchChatRoomsSchema,
  FetchChatRoomsSchemaType,
  GetChatRoomMembersType,
  getOrCreateIndividualChatRoomByCsAdminSchema,
  GetOrCreateIndividualChatRoomByCsAdminSchemaType,
  rejectChatRoomSchema,
  RejectChatRoomSchemaType,
  ResendGroupChatInvitationType,
  updateAdminOnlyChatRoomSchema,
  UpdateAdminOnlyChatRoomSchemaType,
  updateGroupChatRoomSchema,
  UpdateGroupChatRoomType,
} from "@shared/validator/features/chatRoom.schema";

export async function fetchChatRooms(
  filter: ChatRoomFilterType,
  page: number,
  take: number
): Promise<ChatRoomListResponse> {
  const filterQuery = JSON.stringify(filter);
  const args = fetchChatRoomsSchema.parse({
    filter: filterQuery,
    page,
    take,
  });
  return client.get<FetchChatRoomsSchemaType, ChatRoomListResponse>(
    "/chat-room/fetchChatRooms",
    args
  );
}

export async function fetchChatRoomById(args: FetchChatRoomByIdSchemaType) {
  fetchChatRoomByIdSchema.parse(args);
  const chatRoom = await client.get<
    FetchChatRoomByIdSchemaType,
    ChatRoomEntity
  >("/chat-room/fetchChatRoomById", args);
  return chatRoom;
}

export async function approveChatRoom(args: ApproveChatRoomSchemaType) {
  approveChatRoomSchema.parse(args);
  await client.post<ApproveChatRoomSchemaType, {}>(
    "/chat-room/approveChatRoom",
    args
  );
}

export async function rejectChatRoom(args: RejectChatRoomSchemaType) {
  rejectChatRoomSchema.parse(args);
  await client.post<RejectChatRoomSchemaType, {}>(
    "/chat-room/rejectChatRoom",
    args
  );
}

export async function deleteChatRoom(args: DeleteChatRoomSchemaType) {
  deleteChatRoomSchema.parse(args);
  await client.post<DeleteChatRoomSchemaType, {}>(
    "/chat-room/deleteChatRoom",
    args
  );
}

export async function deleteCSAdminChatRoom(args: DeleteChatRoomSchemaType) {
  deleteChatRoomSchema.parse(args);
  await client.post<DeleteChatRoomSchemaType, {}>(
    "/chat-room/deleteCSAdminChatRoom",
    args
  );
}

export async function updateAdminOnly(args: UpdateAdminOnlyChatRoomSchemaType) {
  updateAdminOnlyChatRoomSchema.parse(args);
  await client.post<UpdateAdminOnlyChatRoomSchemaType, {}>(
    "/chat-room/updateAdminOnly",
    args
  );
}

/**
 * メンバー権限者がINDIVIDUAL_CHAT_WITH_ORGANIZATION用のチャットルームを作成または取得する
 */
export async function getOrCreateChatRoomByMember(): Promise<ChatRoom> {
  const chatRoom = await client.post<{}, ChatRoom>(
    "/chat-room/getOrCreateIndividualChatRoomByMember"
  );

  return chatRoom;
}

/**
 * CsAdmin権限者がINDIVIDUAL_CHAT_WITH_ORGANIZATION用のチャットルームを作成または取得する
 */
export async function getOrCreateChatRoomByCsAdmin(
  args: GetOrCreateIndividualChatRoomByCsAdminSchemaType
): Promise<ChatRoom> {
  getOrCreateIndividualChatRoomByCsAdminSchema.parse(args);
  const chatRoom = await client.post<
    GetOrCreateIndividualChatRoomByCsAdminSchemaType,
    ChatRoom
  >("/chat-room/getOrCreateIndividualChatRoomByCsAdmin", args);
  return chatRoom;
}

/**
 * CsAdmin権限者がGROUP_CHAT_WITH_ORGANIZATION用のチャットルームを作成する
 */
export async function createGroupChatRoomByCsAdmin(
  name: string,
  accountIds: string[],
  file?: File | undefined
): Promise<ChatRoom> {
  const args = createGroupChatRoomSchema.parse({
    accountIds,
    name,
  });
  const chatRoom = await client.postWithFormData<
    CreateGroupChatRoomType & { file?: File },
    ChatRoom
  >("/chat-room/createGroupChatRoomByCsAdmin", { ...args, file });

  return chatRoom;
}

export async function updateGroupChatRoomByCsAdmin(
  chatRoomId: string,
  name: string,
  accountIds: string[],
  file?: File | undefined
): Promise<void> {
  const args = updateGroupChatRoomSchema.parse({
    chatRoomId,
    accountIds,
    name,
  });
  await client.postWithFormData<
    UpdateGroupChatRoomType & { file?: File },
    ChatRoom
  >("/chat-room/updateGroupChatRoomByCsAdmin", {
    ...args,
    file,
  });
}

/**
 * CSAdmin権限者が GROUP_CHAT_WITH_CS_ADMIN 用のチャットルームを作成する
 */
export async function createCSAdminChatRoom(
  name: string,
  accountIds: string[],
  file?: File | undefined
): Promise<ChatRoom> {
  const args = createGroupChatRoomSchema.parse({
    accountIds,
    name,
  });
  const chatRoom = await client.postWithFormData<
    CreateGroupChatRoomType & { file?: File },
    ChatRoom
  >("/chat-room/createCSAdminChatRoom", { ...args, file });

  return chatRoom;
}

/**
 * CSAdmin権限者が GROUP_CHAT_WITH_CS_ADMIN 用のチャットルームを作成する
 */
export async function updateCSAdminChatRoom(
  args: UpdateGroupChatRoomType,
  file: File | undefined
): Promise<ChatRoom> {
  const chatRoom = await client.postWithFormData<
    CreateGroupChatRoomType & { file?: File },
    ChatRoom
  >("/chat-room/updateCSAdminChatRoom", { ...args, file });

  return chatRoom;
}

export async function getChatRoomMembers(
  args: GetChatRoomMembersType
): Promise<ChatRoomMember[]> {
  return client.get<GetChatRoomMembersType, ChatRoomMember[]>(
    `/chat-room/members`,
    args
  );
}

export async function resendGroupChatInvitation(
  args: ResendGroupChatInvitationType
): Promise<void> {
  await client.post<ResendGroupChatInvitationType, void>(
    "/chat-room/resendGroupChatInvitation",
    args
  );
}
