import React from "react";
import styled from "styled-components";
import {
  checkIsIOS,
  checkIsPWA,
} from "src/features/Profile/NotificationSetting/utils";
import { usePushNotificationSetting } from "src/hooks/query/pushNotification";
import { useNotificationPermission } from "src/hooks/useNotificationPermissions";
import { Spinner } from "src/components/icons/Spinner";
import { Button } from "src/components/Button";

export const PushNotificationEnabled = () => {
  const isPWA = checkIsPWA();
  const isIOS = checkIsIOS();
  const {
    updatePushNotificationEnabled,
    isLoading,
    isPending,
    isPushNotificationEnabled,
  } = usePushNotificationSetting();

  const notificationPermission = useNotificationPermission();
  const isNotificationDenied =
    notificationPermission === "denied" ||
    (isIOS && notificationPermission === "default" && isPWA) ||
    isPushNotificationEnabled === true;

  const handleClickEnablePushNotify = async () => {
    updatePushNotificationEnabled(true);
  };
  const displayButton = !isIOS || (isIOS && isPWA);

  return (
    <>
      {isIOS && !isPWA ? (
        <InstallAppMessage>
          この画面でプッシュ通知の許可をすることはできません。
          <br />
          アプリをインストールしてプッシュ通知を受け取りましょう。
        </InstallAppMessage>
      ) : (
        <InstallAppMessage>「プッシュ通知設定」をONにする</InstallAppMessage>
      )}

      {displayButton && (
        <ButtonWrapper>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClickEnablePushNotify();
            }}
            color={"subPrimary"}
            $fill={true}
            disabled={isNotificationDenied}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(isLoading || isPending) && <Spinner size={"forSwitch"} />}
            {isNotificationDenied ? "通知許可済み" : "プッシュ通知を許可する"}
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

const InstallAppMessage = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.5;
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;
`;
