import { ApprovalStatus } from "@shared/types/chatRoom";

export const getStatusLabel = (status: ApprovalStatus): string => {
  switch (status) {
    case "UNAPPROVED":
      return "承認待ち";
    case "PENDING":
      return "招待中";
    case "APPROVED":
      return "参加中";
    case "REJECTED":
      return "退会";
  }
};
