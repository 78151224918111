import React, { useState, useMemo, useEffect, useRef } from "react";
import { createChatMessage } from "src/apiClients/chatMessage";
import { updateParticipantChatRoom } from "src/apiClients/participantChatRoom";
import { useMessageList } from "src/features/Recruitment/chatMessage/hooks/useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import { ApprovalStatus, ChatRoom } from "@shared/types/chatRoom";
import { GroupContentChatMessageInputArea } from "./GroupContentChatMessageInputArea";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { MenuModal } from "./MenuModal";
import { Col } from "src/components/Responsive";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";
import { useChatSearchText } from "src/features/common/ChatMessage/hooks/useChatSearchText";
import { SearchInput } from "src/features/common/ChatMessage/components/SearchInput";
import {
  ChatRoomContainer,
  ChatRoomInfo,
  ChatBox,
  Header,
  FloatWrapper,
  HeaderTitle,
  SearchInputWrapper,
  FlexWrapper,
  FloatDateLabel,
  MenuButton,
} from "src/features/common/ChatMessage/components/ChatRoomContent/ChatRoomContentStyled";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoom;
  lastReadAt: Date;
  lastReadAts: Date[];
  refetch: () => void;
};

export const ChatRoomGroupBEContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const toast = useToast();
  const {
    displayItemList,
    NewMessageNotification,
    lastReadAts,
    fetchChatMessages,
  } = useMessageList({
    initLastReadAt: props.lastReadAt,
    initLastReadAts: props.lastReadAts,
    messageListRef,
    chatRoomParticipantId: props.chatRoomParticipantId,
  });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  const {
    currentSearchIndex,
    searchResults,
    handlePrevResult,
    handleNextResult,
    searchText,
    setSearchText,
  } = useChatSearchText({
    displayItemList,
  });

  // チャットルームの最終アクセス日時を更新
  useEffect(() => {
    // 参加してないチャットルームに関しては既読処理をしない
    if (
      props.chatRoom.ParticipantChatRooms.find(
        (participantChatRoom) =>
          participantChatRoom.participantId === props.chatRoomParticipantId
      )?.approvalStatus !== ApprovalStatus.APPROVED
    ) {
      return;
    }
    const updateParticipantChatRoomHandler = () => {
      updateParticipantChatRoom({
        chatRoomId: props.chatRoom.id,
      });
    };

    updateParticipantChatRoomHandler();

    const interval = setInterval(updateParticipantChatRoomHandler, 10000);

    return () => {
      clearInterval(interval);
      updateParticipantChatRoomHandler();
    };
  }, [props.chatRoom]);

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const handleApprove = async () => {
    fetchChatMessages();
    props.refetch();
  };

  const onSend = async () => {
    if (!canSend) return;
    setSending(true);
    try {
      await createChatMessage(props.chatRoom.id, input, files);
      setInput("");
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        return;
      }
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setSending(false);
    }
  };

  const canSend = useMemo(() => {
    return !sending && (input.length > 0 || files.length > 0);
  }, [sending, input, files]);

  const participantChatRoom = props.chatRoom.ParticipantChatRooms.find(
    (participantChatRoom) =>
      participantChatRoom.participantId === props.chatRoomParticipantId
  );

  const participantBoardEducationRoom =
    props.chatRoom.ParticipantChatRooms.find(
      (participantChatRoom) =>
        participantChatRoom.participantType === "BOARD_EDUCATION"
    );

  if (!displayItemList) return <></>;
  if (
    props.chatRoom.approvalStatus === ApprovalStatus.REJECTED ||
    participantChatRoom?.approvalStatus === ApprovalStatus.REJECTED
  )
    return <></>;

  return (
    <ChatRoomContainer $isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <FlexWrapper>
            <ChatRoomBackButton targetRoute="RESIDENT_CHAT" />
            <Col>
              <HeaderTitle>{props.chatRoom.name}</HeaderTitle>
            </Col>

            <SearchInputWrapper>
              <SearchInput
                searchText={searchText}
                setSearchText={setSearchText}
                onPrevResult={handlePrevResult}
                onNextResult={handleNextResult}
                resultCount={searchResults.length}
              />
            </SearchInputWrapper>

            {participantChatRoom?.approvalStatus ===
              ApprovalStatus.APPROVED && (
              <>
                <MenuButton
                  type="button"
                  onClick={() => {
                    setIsOpenMenu(true);
                  }}
                >
                  ...
                </MenuButton>
                <MenuModal
                  isOpen={isOpenMenu}
                  onClose={() => {
                    setIsOpenMenu(false);
                  }}
                  chatRoom={props.chatRoom}
                  refetch={props.refetch}
                />
              </>
            )}
          </FlexWrapper>
        </Header>
      </ChatRoomInfo>
      <ChatBox $paddingTop={chatRoomInfoHeight}>
        {currentDate && (
          <FloatWrapper>
            <FloatDateLabel>{currentDate}</FloatDateLabel>
          </FloatWrapper>
        )}
        <ChatMessages messageListRef={messageListRef}>
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    searchText={searchText}
                    lastReadAts={lastReadAts}
                    isGroupChat={true}
                    participantAdminId={
                      participantBoardEducationRoom?.participantId
                    }
                    currentSearchIndex={currentSearchIndex}
                    searchResults={searchResults}
                  />
                );
            }
          })}
        </ChatMessages>
        <NewMessageNotification />
        <GroupContentChatMessageInputArea
          input={input}
          setInput={setInput}
          setFiles={setFiles}
          canSend={canSend}
          onSend={onSend}
          onApprove={handleApprove}
          chatRoom={props.chatRoom}
          chatRoomParticipantId={props.chatRoomParticipantId}
        />
      </ChatBox>
    </ChatRoomContainer>
  );
};
