import Encoding from "encoding-japanese";
import {
  CSVRowErrorType,
  ImportRowType,
} from "@shared/validator/features/ptaInvoiceImport.schema";
import { ptaInvoiceImportRowSchema } from "@shared/validator/models/invoice/ptaInvoiceImportRow.schema";

type ImportRow = ImportRowType[0];

export interface CSVParseResult {
  rows: ImportRow[];
  errorRows: CSVRowErrorType[];
}

/**
 * 請求CSVファイルをパースします。
 * エラーがある行の情報を別に返します。
 *
 * ヘッダー行は最初の1行として除外しています。
 */
export async function parseInvoiceCSVFile(file: File): Promise<CSVParseResult> {
  const arrayBuffer = await file.arrayBuffer();
  const byteArray = new Uint8Array(arrayBuffer);
  const detectedEncoding = Encoding.detect(byteArray);
  const unicodeArray = Encoding.convert(byteArray, {
    to: "UNICODE",
    from: detectedEncoding || "SJIS",
    type: "array",
  });
  const csvData = Encoding.codeToString(unicodeArray);

  const csvRows = csvData
    .split(/\r\n|\n|\r/)
    .filter((row) => row.trim() !== "");
  const bodyRows = csvRows.slice(1);

  const rows: ImportRow[] = [];
  const errorRows: CSVRowErrorType[] = [];

  const rowValidationSchema = ptaInvoiceImportRowSchema.omit({
    id: true,
  });

  //childIdの重複チェック用
  const childIdMap = new Map<string, number[]>();
  bodyRows.forEach((row, index) => {
    const columns = row.split(",");
    const childId = columns[3] || "";
    if (childId) {
      const indices = childIdMap.get(childId) || [];
      indices.push(index);
      childIdMap.set(childId, indices);
    }
  });

  bodyRows.forEach((row, index) => {
    const columns = row.split(",");
    const rowData = {
      index: index,
      PTAParentFamilyId: columns[0] || "",
      parentName: columns[1] || "",
      parentNameKana: columns[2] || "",
      childId: columns[3] || "",
      childName: columns[4] || "",
      childNameKana: columns[5] || "",
      grade: columns[6] ? Number(columns[6]) : undefined,
      class:
        columns[7] === "その他"
          ? "その他"
          : columns[7]
          ? Number(columns[7])
          : undefined,
      amount: columns[8] ? Number(columns[8]) : 0,
    } as const;

    rows.push(rowData);
    const errorMessages: string[] = [];
    const validationResult = rowValidationSchema.safeParse(rowData);
    if (!validationResult.success) {
      const issues = [
        ...new Set(
          validationResult.error.issues.map((i) =>
            i.message === "Invalid input"
              ? "不正な形式の値があります"
              : i.message
          )
        ),
      ];
      errorMessages.push(...issues);
    }
    if (rowData.childId) {
      const duplicateRows = childIdMap.get(rowData.childId) || [];
      if (duplicateRows.length > 1) {
        const otherRows = duplicateRows
          .filter((rowNum) => rowNum !== index)
          .map((rowNum) => rowNum + 2)
          .join(",");
        errorMessages.push(
          `子どもID「${rowData.childId}」が${otherRows}行目と重複しています`
        );
      }
    }
    if (errorMessages.length > 0) {
      errorRows.push({ ...rowData, errorMessage: errorMessages });
    }
  });

  return { rows, errorRows };
}
