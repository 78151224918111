import { z } from "zod";
import { zRequiredString } from "../../rules/string";

export const ptaChildInvoiceItemSchema = z.object({
  id: zRequiredString,
  organizationId: zRequiredString,
  childId: zRequiredString,
  PTAInvoiceProductId: zRequiredString,
  PTAParentFamilyInvoiceId: z.string().nullable(),
  PTAInvoiceImportRowId: z.string().nullable(),
  amount: z.number().int().positive(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type PTAChildInvoiceItemType = z.infer<typeof ptaChildInvoiceItemSchema>;
