import React, { useCallback, useEffect, useState } from "react";
import { createRecruitment } from "../../../apiClients/recruitment";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
} from "../../../hooks/router";
import { upload } from "../../../apiClients/storage";
import RecruitmentPreview from "../../../features/Recruitment/recruitment/RecruitmentFormPreview";
import {
  convertRecruitmentCommunityUserTypes,
  RecruitmentFormValue,
  useRecruitmentForm,
} from "../../../features/Recruitment/recruitment/useRecruitmentForm";
import ResidentRecruitmentForm from "../../../features/Recruitment/recruitment/RecruitmentForm";
import { useGeoCording } from "../../../hooks/useGeocoding";
import { Header } from "../../../components/Header";
import styled from "styled-components";
import { Spinner } from "../../../components/icons/Spinner";
import { useCommunityById } from "src/hooks/recoil/community";
import { transformIsAutoApprovalToEntryMethod } from "src/validator/form/recruitment/recruitmentSchema";
import { formatSchedule } from "src/features/Recruitment/recruitment/utils/formatSchedule";

export default function ResidentRecruitmentCreatePage() {
  const [isPreview, setIsPreview] = useState(false);
  const history = usePolyfitHistory();
  const { communityId } = useCurrentCommunityId();
  const [{ community }] = useCommunityById(communityId);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (!community || isPreview) return;
      if (community.postalCode) setValue("postalCode", community.postalCode);
      if (community.prefecture) setValue("prefecture", community.prefecture);
      if (community.city) setValue("city", community.city);
      if (community.address1) setValue("address1", community.address1);
      if (community.name)
        setValue(
          "address2",
          community.name + community.address2
            ? `${community.address2 ? " " : ""}${community.name} 内`
            : ""
        );
      setValue("longitude", community.longitude);
      setValue("latitude", community.latitude);
      setIsLoaded(true);
    })();
  }, [community]);

  const { getLocationAddressFromPostcode } = useGeoCording();

  const {
    control,
    register,
    getValues,
    setValue,
    trigger,
    isSubmitted,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors,
  } = useRecruitmentForm({
    defaultValues: {
      wageType: "HOURLY",
      wageAmount: 0,
      teacherLicenses: [],
      medicalLicenses: [],
      skills: [],
      isUnlimitedVolunteerCount: false,
      saveTemplate: false,
      recruitmentCommunityUserTypes: {},
      isAutoApproval: false,
    },
  });

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getLocationAddressFromPostcode(value);
      setValue("prefecture", res.prefecture);
      setValue("city", res.city);
      setValue("address1", res.address1);
      setValue("address2", "");
      setValue("latitude", res.latitude);
      setValue("longitude", res.longitude);
      clearErrors("postalCode");
    } catch (err) {
      setError("postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  const watchAllValue = watch();

  const onPreviewClick = useCallback(() => {
    setIsPreview(true);
  }, []);

  const onDraftClick = useCallback(async () => {
    if (!community) return;
    const formValues = getValues();
    const files = formValues.pdfFiles ?? [];

    if (typeof formValues.uploadImage === "string") {
      throw new Error("画像のアップロードに失敗しました");
    }

    const pictureUploadPath =
      formValues?.uploadImage?.length === 1
        ? await upload({ file: formValues.uploadImage[0] })
        : null;

    const schedule = formatSchedule({
      schedule: formValues.schedule,
    });
    await createRecruitment(
      {
        communityId: community.id,
        status: "DRAFT",
        title: formValues.title,
        volunteerDetailType: formValues.volunteerDetailType,
        isPaidVolunteer: formValues.isPaidVolunteer ?? null,
        volunteerType: formValues.volunteerType,
        schedule,
        deadline: formValues.deadline,
        description: formValues.description,
        postalCode: formValues.postalCode,
        prefecture: formValues.prefecture,
        city: formValues.city,
        address1: formValues.address1,
        address2: formValues.address2,
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        treatment: formValues.treatment,
        wageType: formValues.wageType,
        wageAmount: formValues.wageAmount,
        teacherLicenses: formValues.teacherLicenses,
        medicalLicenses: formValues.medicalLicenses,
        skills: formValues.skills,
        targetCommunities: convertRecruitmentCommunityUserTypes(
          formValues.recruitmentCommunityUserTypes
        ),
        pictureUploadPath,
        saveTemplate: formValues.saveTemplate,
        volunteerCount: formValues.isUnlimitedVolunteerCount
          ? -1
          : isNaN(formValues.volunteerCount ?? 0)
          ? null
          : formValues.volunteerCount ?? 0,
        alreadyUploadedPdfFiles: formValues.alreadyUploadedPdfFiles,
        entryMethod: transformIsAutoApprovalToEntryMethod(
          formValues.isAutoApproval
        ),
      },
      files
    );

    history.push({ to: "RESIDENT_RECRUITMENT_DRAFT_LIST" });
  }, [community]);
  const [isLoading, setIsLoading] = useState(false);
  const onPreviewSubmitClick = useCallback(
    async (formValues: RecruitmentFormValue) => {
      if (!community) return;
      setIsLoading(true);
      if (typeof formValues.uploadImage === "string") {
        throw new Error("画像のアップロードに失敗しました");
      }
      const files = formValues.pdfFiles ?? [];
      const pictureUploadPath =
        formValues?.uploadImage?.length === 1
          ? await upload({ file: formValues.uploadImage[0] })
          : null;

      const schedule = formatSchedule({
        schedule: formValues.schedule,
      });

      await createRecruitment(
        {
          communityId: community.id,
          status: "OPENED",
          title: formValues.title,
          volunteerDetailType: formValues.volunteerDetailType,
          isPaidVolunteer: formValues.isPaidVolunteer,
          volunteerType: formValues.volunteerType,
          schedule,
          deadline: formValues.deadline,
          description: formValues.description,
          postalCode: formValues.postalCode,
          prefecture: formValues.prefecture,
          city: formValues.city,
          address1: formValues.address1,
          address2: formValues.address2,
          latitude: formValues.latitude,
          longitude: formValues.longitude,
          wageType: formValues.wageType,
          wageAmount: formValues.wageAmount,
          treatment: formValues.treatment,
          teacherLicenses: formValues.teacherLicenses,
          medicalLicenses: formValues.medicalLicenses,
          skills: formValues.skills,
          targetCommunities: convertRecruitmentCommunityUserTypes(
            formValues.recruitmentCommunityUserTypes
          ),
          pictureUploadPath,
          saveTemplate: formValues.saveTemplate,
          volunteerCount: formValues.isUnlimitedVolunteerCount
            ? -1
            : formValues.volunteerCount ?? 0,
          alreadyUploadedPdfFiles: formValues.alreadyUploadedPdfFiles,
          entryMethod: transformIsAutoApprovalToEntryMethod(
            formValues.isAutoApproval
          ),
        },
        files
      );

      setIsLoading(false);
      history.push({ to: "RESIDENT_RECRUITMENT_LIST" });
    },
    [community]
  );

  const onPreviewCancelClick = useCallback(() => {
    setIsPreview(false);
  }, []);

  if (!isLoaded)
    return (
      <>
        <Header title={isPreview ? "プレビュー" : "新規作成"} />
        <SpinnerCard>
          <Spinner />
        </SpinnerCard>
      </>
    );

  return (
    <>
      <Header title={isPreview ? "プレビュー" : "新規作成"} />
      {!isPreview && (
        <ResidentRecruitmentForm
          control={control}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          setValue={setValue}
          trigger={trigger}
          isSubmitted={isSubmitted}
          watchAllValue={watchAllValue}
          onPreviewClick={handleSubmit(onPreviewClick)}
          onDraftSubmit={onDraftClick}
          getAddress={getAddress}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
      {isPreview && (
        <RecruitmentPreview
          watchAllValue={watchAllValue}
          onSubmit={handleSubmit(onPreviewSubmitClick)}
          onCancel={onPreviewCancelClick}
          isRecruitmentCreateNew={true}
          isLoading={isLoading}
          defaultPdfFiles={getValues().pdfFiles}
          defaultAlreadyUploadedPdfFiles={getValues().alreadyUploadedPdfFiles}
        />
      )}
    </>
  );
}

const SpinnerCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-bottom: 40px;
  padding-top: 40px;
`;
