import { z } from "zod";
import {
  transformStringOrBooleanToBoolean,
  transformStringOrNumberToNumber,
} from "../rules/transform";
import { baseRequestSchema } from "./common.schema";
import { zRequiredString } from "../rules/string";

export const ApplicationStatus = {
  APPLIED: "APPLIED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DECLINED: "DECLINED",
} as const;

export type ApplicationStatus =
  (typeof ApplicationStatus)[keyof typeof ApplicationStatus];

export const ApplicationStatusWithAll = {
  ...ApplicationStatus,
  ALL: "ALL",
} as const;

export type ApplicationStatusWithAll =
  (typeof ApplicationStatusWithAll)[keyof typeof ApplicationStatusWithAll];

export const recruitmentListQuerySchema = z.object({
  volunteerType: z.string().optional(),
  isPaidVolunteer: z.enum(["PAID", "FREE"]).optional(),
  date: z.string().optional(),
  isOpen: transformStringOrBooleanToBoolean().optional(),
  origin: z.enum(["CS", "BOARD_EDUCATION"]).optional(),
  page: transformStringOrNumberToNumber().optional(),
  take: transformStringOrNumberToNumber().optional(),
  currentCommunityId: z.string().optional(),
});

export type RecruitmentListQueryType = z.infer<
  typeof recruitmentListQuerySchema
>;

/**
 * 応募作成APIのリクエストボディのスキーマ
 */
export const recruitmentApplyBodySchema = z.object({
  recruitmentId: z.string(),
  comment: z
    .string()
    .max(3000, {
      message: "コメントは3000文字以内で入力してください",
    })
    .optional(),
  selectedScheduleIds: z.array(z.string()).min(1, {
    message: "少なくとも1つの日程を選択してください",
  }),
});

export type RecruitmentApplyBodyType = z.infer<
  typeof recruitmentApplyBodySchema
>;

/**
 * 応募した募集一覧取得APIのリクエストクエリーのスキーマ
 */
export const recruitmentApplyListQuerySchema = baseRequestSchema.extend({
  status: z.nativeEnum(ApplicationStatus).optional(),
});

export type RecruitmentApplyListQuerySchema = z.infer<
  typeof recruitmentApplyListQuerySchema
>;

const wageTypeSchema = z.enum(["HOURLY", "DAILY", "MONTHLY"]).nullable();

export const recruitmentStatusSchema = z.enum([
  "DRAFT",
  "OPENED",
  "CLOSED",
  "DELETED",
]);
export type RecruitmentStatusSchemaType = z.infer<
  typeof recruitmentStatusSchema
>;

const recruitmentStatusWithOutDeleteSchema = z.enum([
  "DRAFT",
  "OPENED",
  "CLOSED",
]);
const recruitmentEntryMethodSchema = z.enum([
  "AUTO_APPROVAL",
  "MANUAL_SCREENING",
]);

const recruitmentScheduleSchema = z.object({
  date: z.coerce
    .date()
    .nullish()
    .transform((val) => val || null),
  start: z.coerce
    .date()
    .nullish()
    .transform((val) => val || null),
  end: z.coerce
    .date()
    .nullish()
    .transform((val) => val || null),
  id: z.string().optional(),
});

export const RecruitmentCommunityUserType = {
  PARENT: "PARENT",
  TEACHER: "TEACHER",
  INSIDE_RESIDENT: "INSIDE_RESIDENT",
  OUTSIDE_RESIDENT: "OUTSIDE_RESIDENT",
  ADMIN: "ADMIN",
} as const;
const recruitmentCommunityUserTypeSchema = z.nativeEnum(
  RecruitmentCommunityUserType
);

export const RecruitmentCommunityUserTypes = Object.values(
  RecruitmentCommunityUserType
);

export const RecruitmentCommunityUserTypesSchema = z.array(
  recruitmentCommunityUserTypeSchema
);

export type RecruitmentCommunityUserTypesSchemaType = z.infer<
  typeof RecruitmentCommunityUserTypesSchema
>;

export type RecruitmentCommunityUserType = z.infer<
  typeof recruitmentCommunityUserTypeSchema
>;

const recruitmentTargetCommunitySchema = z.object({
  communityId: z.string(),
  community: z
    .object({
      name: z.string(),
    })
    .optional(),
  communityUserTypes: RecruitmentCommunityUserTypesSchema,
});

const alreadyUploadedPdfFileSchema = z.object({
  name: z.string(),
  path: z.string(),
});

export const recruitmentCreateSchema = z.object({
  communityId: zRequiredString,
  status: recruitmentStatusWithOutDeleteSchema,
  pictureUploadPath: z.string().nullable(),
  title: z.string().nullable(),
  isPaidVolunteer: z.boolean().nullable(),
  volunteerType: z.string().nullable(),
  volunteerDetailType: z.string().nullable(),
  volunteerCount: z.number().nullable(),
  description: z.string().nullable(),
  postalCode: z.string().nullable(),
  prefecture: z.string().nullable(),
  city: z.string().nullable(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  wageType: wageTypeSchema,
  wageAmount: z.number().nullable(),
  treatment: z.string().nullable(),
  teacherLicenses: z.array(z.string()),
  medicalLicenses: z.array(z.string()),
  skills: z.array(z.string()),
  targetCommunities: z.array(recruitmentTargetCommunitySchema),
  schedule: z.array(recruitmentScheduleSchema),
  deadline: z
    .string()
    .nullable()
    .refine(
      (value) => {
        if (value) {
          const date = new Date(value);
          return date.getTime() >= Date.now();
        }
        return true;
      },
      {
        message: "過去の日時は設定できません",
      }
    ),
  saveTemplate: z.boolean(),
  alreadyUploadedPdfFiles: z.array(alreadyUploadedPdfFileSchema),
  entryMethod: recruitmentEntryMethodSchema,
});

export type RecruitmentCreateSchemaType = z.infer<
  typeof recruitmentCreateSchema
>;

export const recruitmentUpdateSchema = recruitmentCreateSchema.extend({
  id: zRequiredString,
  createdBy: z.string().optional(),
});

export type RecruitmentUpdateSchemaType = z.infer<
  typeof recruitmentUpdateSchema
>;

/**
 * 募集IDを含むリクエストボディのスキーマ
 * close, resend, delete, deleteOpen APIで使用
 */
export const recruitmentIdSchema = z.object({
  recruitmentId: z.string(),
});

export type RecruitmentIdSchemaType = z.infer<typeof recruitmentIdSchema>;

/**
 * 募集詳細取得APIのリクエストクエリーのスキーマ
 */
export const getRecruitmentByIdQuerySchema = baseRequestSchema.extend({
  recruitmentId: zRequiredString,
});

export type GetRecruitmentByIdQuerySchemaType = z.infer<
  typeof getRecruitmentByIdQuerySchema
>;
