import styled from "styled-components";

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  overflow-x: auto;
  white-space: nowrap;
  /* Font-Style */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #343741;
  a {
    color: #69707d;
    font-weight: 400;
  }
  * {
    position: relative;
  }
  *:not(:first-child):before {
    position: absolute;
    left: -9px;
    top: 2.5px;
    content: "";
    width: 1px;
    height: 16px;
    background: #d3dae6;
    transform: rotate(15deg);
  }
`;
