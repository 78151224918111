import React, { useMemo } from "react";
import { Button } from "src/components/Button";
import { RegisterStepper } from "src/components/RegisterStepper";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import * as Typo from "src/components/Typo";
import { Margin } from "src/components/Margin";
import { useGetPTAOrganizationInvoiceAccount } from "src/hooks/query/invoice/ptaOrganizationInvoiceAccount";
import { useCurrentUser } from "src/hooks/recoil/user";
import {
  useExecutePTAParentFamilyInvoice,
  useInvalidatePTAParentFamilyInvoiceList,
} from "../../../../hooks/query/invoice/ptaParentFamilyInvoices";
import { PTAConfirmExecutingInvoiceModal } from "./PTAConfirmExecutingInvoiceModal";
import { useModal } from "src/components/Modal";
import {
  Container,
  PaddingWrapper,
  ContentWrapper,
  SubTitleWrapper,
  ButtonWrapper,
} from "./components/styles";
import { useGetPTAInvoiceImportRows } from "src/hooks/query/invoice/ptaInvoiceImport";
import {
  useGetPTAInvoiceProduct,
  useInvalidateGetPTAInvoiceProduct,
} from "src/hooks/query/invoice/ptaInvoiceProduct";
import { useToast } from "src/components/Toast";
import styled from "styled-components";
import { summarizeInvoiceImport } from "./utils/summarizeInvoiceImport";
import { colorsPallet } from "src/theme";
import { INVOICE_PROCESS_PATH, InvoiceFlowType } from "./utils/types";

const CURRENT_STEP = 4;

type Props = {
  flowType: InvoiceFlowType;
};

export const PTAInvoiceProcessImportReviewPage = ({ flowType }: Props) => {
  const currentUser = useCurrentUser();
  const [isConfirmModalOpen, { show: showConfirmModal, close }] = useModal();
  const toast = useToast();
  const { mutate: mutateExecuteInvoice, isPending } =
    useExecutePTAParentFamilyInvoice();
  const history = usePolyfitHistory();
  const { id: invoiceProductId, importId } = usePolyfitLocationQuery(
    INVOICE_PROCESS_PATH[flowType],
    {
      id: "",
      importId: "",
    }
  );
  const { ptaOrganizationInvoiceAccount } = useGetPTAOrganizationInvoiceAccount(
    {
      organizationId: currentUser.account?.organizationId ?? "",
    }
  );
  const { invalidatePTAParentFamilyInvoiceList } =
    useInvalidatePTAParentFamilyInvoiceList();
  const { invalidateGetPTAInvoiceProduct } =
    useInvalidateGetPTAInvoiceProduct();

  const { invoiceImportRows } = useGetPTAInvoiceImportRows(
    invoiceProductId,
    importId ?? ""
  );

  const { ptaInvoiceProduct } = useGetPTAInvoiceProduct(invoiceProductId);

  const groupedMap = summarizeInvoiceImport(invoiceImportRows?.rows ?? []);
  const { totalAmount, totalCount } = useMemo(() => {
    const totals = {
      totalAmount: 0,
      totalCount: 0,
    };

    groupedMap.forEach((amountMap) => {
      amountMap.forEach((rows, amount) => {
        totals.totalAmount += amount * rows.length;
        totals.totalCount += rows.length;
      });
    });

    return totals;
  }, [groupedMap]);

  if (!ptaOrganizationInvoiceAccount?.enabled) {
    return <div>閲覧できません</div>;
  }

  if (ptaInvoiceProduct?.deletedAt) {
    return <div>この請求項目は削除されています</div>;
  }

  const prevStep = () => {
    history.push({
      to: INVOICE_PROCESS_PATH[flowType],
      query: { id: invoiceProductId, step: `${CURRENT_STEP - 1}` },
    });
  };

  const handleConfirmInvoice = async () => {
    if (!invoiceProductId || !importId) {
      toast.error("有効な請求データがありません");
      return;
    }
    await new Promise<void>((resolve, reject) => {
      mutateExecuteInvoice(
        { importId, PTAInvoiceProductId: invoiceProductId },
        {
          onSuccess: () => {
            invalidatePTAParentFamilyInvoiceList(invoiceProductId);
            invalidateGetPTAInvoiceProduct(invoiceProductId);
            resolve();
            history.push({
              to: INVOICE_PROCESS_PATH[flowType],
              query: {
                id: invoiceProductId,
                importId,
                step: `${CURRENT_STEP + 1}`,
              },
            });
          },
          onError: (error) => {
            toast.error(`請求の作成に失敗しました: ${error.message}`, {
              duration: 10000,
            });
            reject(error);
          },
        }
      );
    });
  };

  return (
    <Container>
      <RegisterStepper count={5} current={CURRENT_STEP} title="" />
      <Margin marginBottom={16} />
      <PaddingWrapper>
        <ContentWrapper>
          <SubTitleWrapper>
            <Typo.Heading3>請求の確認</Typo.Heading3>
          </SubTitleWrapper>
          <Margin marginBottom={16} />
          <Description>
            インポートしたCSVデータを確認してください。
            <br />
            確定ボタンを押すと、請求情報が作成されます。
          </Description>
          <Margin marginBottom={24} />
          <Typo.Heading4>請求情報</Typo.Heading4>
          <InvoiceInfoSection>
            <ProductName>
              <span>請求項目名：</span>
              {ptaInvoiceProduct?.name}
            </ProductName>
            <InvoiceDetailsSection>
              {Array.from(groupedMap.entries()).map(([grade, amountMap]) => (
                <InvoiceDetailRow key={grade}>
                  <GradeLabel>{grade}年生</GradeLabel>
                  <DetailsList>
                    {Array.from(amountMap.entries()).map(([amount, rows]) => (
                      <DetailItem key={amount}>
                        <AmountText>{amount.toLocaleString()}円</AmountText>
                        <CountText>× {rows.length}名</CountText>
                      </DetailItem>
                    ))}
                  </DetailsList>
                </InvoiceDetailRow>
              ))}
              <TotalSection>
                <TotalRow>
                  <TotalLabel>総請求件数</TotalLabel>
                  <TotalValue>{totalCount}件</TotalValue>
                </TotalRow>
                <TotalRow>
                  <TotalLabel>合計請求金額</TotalLabel>
                  <TotalValue>{totalAmount.toLocaleString()}円</TotalValue>
                </TotalRow>
              </TotalSection>
            </InvoiceDetailsSection>
          </InvoiceInfoSection>
        </ContentWrapper>
      </PaddingWrapper>

      <ButtonWrapper>
        <Button color="text" $fill onClick={prevStep} size="large">
          戻る
        </Button>
        <Button
          color="primary"
          $fill
          onClick={showConfirmModal}
          disabled={isPending}
          size="large"
        >
          請求を確定
        </Button>
      </ButtonWrapper>

      {isConfirmModalOpen && (
        <PTAConfirmExecutingInvoiceModal
          onClose={close}
          onConfirm={handleConfirmInvoice}
          isPending={isPending}
          invoiceCount={totalCount}
        />
      )}
    </Container>
  );
};

const Description = styled.p`
  font-size: 14px;
`;

const InvoiceInfoSection = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  padding: 16px;
  background-color: #f5f7fa;
`;

const ProductName = styled(Typo.Heading4)`
  margin-bottom: 8px;
`;

const InvoiceDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
`;

const InvoiceDetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const GradeLabel = styled.div`
  /* min-width: 80px; */
  font-weight: bold;
`;

const DetailsList = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 4px;
`;

const DetailItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;
`;

const AmountText = styled.div`
  font-weight: bold;
`;

const CountText = styled.div`
  text-align: right;
  color: ${colorsPallet.primary};
`;

const TotalSection = styled.div`
  margin-top: 8px;
  padding-top: 16px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalLabel = styled.div`
  font-weight: bold;
`;

const TotalValue = styled.div`
  font-weight: bold;
  color: ${colorsPallet.primary};
`;
