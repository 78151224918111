import { z } from "zod";
import { zRequiredString } from "../../rules/string";
import { gradeNumberSchema } from "../../../validator/rules/child";
import { ptaInvoiceImportRowAmountSchema } from "./ptaInvoiceImportRow.schema";

export const gradeByAmountsSchema = z.array(
  z.object({
    grade: gradeNumberSchema,
    amount: ptaInvoiceImportRowAmountSchema,
  })
);
export type GradeByAmountsSchemaType = z.infer<typeof gradeByAmountsSchema>;

export const ptaInvoiceProductSchema = z.object({
  id: zRequiredString,
  organizationId: zRequiredString,
  name: zRequiredString,
  stripeProductId: zRequiredString,
  isProcessing: z.boolean(),
  amountsByGrades: gradeByAmountsSchema,
  dueDate: z.date(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  deletedAt: z.date().nullish(),
});

export type PTAInvoiceProductType = z.infer<typeof ptaInvoiceProductSchema>;
